import { useQueryClient } from '@tanstack/react-query';
import { useCreatePageProductMutation } from '../../../../graphql/generated';
import { graphqlClientRequest } from '../../../../graphql/services/graphql-client';

export const useCreatePageProduct = () => {
  const queryClient = useQueryClient();

  return useCreatePageProductMutation(graphqlClientRequest(true), {
    onSuccess: () => {
      queryClient.invalidateQueries(['getPageProduct']);
    },
    onError(error: any) {},
    retry: false,
  });
};
