import {
  FriendRequestingStatus,
  Notification,
  NotificationType,
  TermStatus,
} from '../../../graphql/generated';
import useIDUser from '../../../zustand/IDUser';
import TitleNotify from './TitleNotify';

import { useNavigate } from 'react-router-dom';
import { DiffDate } from '../../../Ultis/Datetime';
import userImg from '../../../assets/images/user.jpeg';
import { useMarkNotificationAsRead } from '../services/api';
import AcceptRequestFriend from './AcceptRequestFriend';
import IconNotify from './IconNotify';
import NotifyContent from './NotifyContent';
import { AlertText } from './textNotification';

interface NotifyItemProps {
  itemNoti: Notification | any;
}

const NotifyItem = (props: NotifyItemProps) => {
  const { itemNoti } = props;
  const navigate = useNavigate();
  const { idUser } = useIDUser();
  const { mutateAsync } = useMarkNotificationAsRead();
  const variables = itemNoti.variables ? JSON.parse(itemNoti.variables) : null;
  const showRequestFriendButton =
    itemNoti.type != NotificationType.NewFriendRequested ||
    itemNoti.forFriendRequestingStatus == FriendRequestingStatus.Rejected ||
    itemNoti.forFriendRequestingStatus == FriendRequestingStatus.Accepted;

  const handleReadNoti = () => {
    if (itemNoti.read) return;
    // if (itemNoti.type && itemNoti.type === NotificationType.System) {
    // }
    if (!itemNoti || !itemNoti._id) return;
    mutateAsync({
      id: itemNoti._id,
      type: itemNoti.type,
    });
  };

  const openDetailNotify = () => {
    handleReadNoti();

    switch (itemNoti.type) {
      case NotificationType.LikedPost:
      case NotificationType.LikedPageProduct:
      case NotificationType.DislikedPost:
      case NotificationType.DislikedPageProduct:
      case NotificationType.AddedPost:
      case NotificationType.MentionInAPost:
      case NotificationType.TaggedInAPost:
      case NotificationType.AddedCommentToPost:
      case NotificationType.LikedPostThatTaggedYou:
      case NotificationType.AddedCommentToPostThatTaggedYou:
      case NotificationType.CommentProductOnPage:
      case NotificationType.SharedProductOnPage:
      case NotificationType.AddedCommentToPostOnPage:
      case NotificationType.AddedPostFromPageYouFollowed:
      case NotificationType.ChangePostStatus:
        if (!itemNoti.forPost) return;
        if (
          itemNoti.fromPage &&
          itemNoti.fromPage.termStatus == TermStatus.Blocked
        ) {
          alert(AlertText.page_block);
        }
        navigate('/detail/' + itemNoti?.forPost?._id);
        break;

      case NotificationType.InvitedToJoinAConversation:
      case NotificationType.NewGroupConversation:
        navigate('/messenger');
        break;

      case NotificationType.InvitedToLikeAPage:
        if (!itemNoti.fromPage) return;
        navigate('/page/' + itemNoti.fromPage?._id);
        break;

      case NotificationType.LikedPage:
      case NotificationType.FollowedPage:
      case NotificationType.VerifyPageAccepted:
        if (!itemNoti.toPage) return;
        navigate('/page/' + itemNoti.toPage?._id);
        break;

      case NotificationType.LikedComment:
      case NotificationType.DislikedComment:
      case NotificationType.MentionInAComment:
      case NotificationType.RepliedComment:
      case NotificationType.RepliedCommentOnYourPost:
      case NotificationType.TaggedInAComment:
      case NotificationType.AlsoRepliedCommentByFriend:
      case NotificationType.RepliedMentionComment:
      case NotificationType.AddedCommentToMedia:
      case NotificationType.LikedMedia:
      case NotificationType.DislikedMedia:
      case NotificationType.SharedPost:
      case NotificationType.SharedTaggedPost:
        if (!itemNoti?.forPost) return;
        navigate('/detail/' + itemNoti?.forPost?._id);
        break;

      case NotificationType.AcceptedFriendRequest:
      case NotificationType.NewFriendRequested:
        if (!itemNoti.fromUser) return;
        navigate('/profile/' + itemNoti.fromUser._id);
        break;

      case NotificationType.VerifyUserAccepted:
        navigate('/profile');
        break;

      case NotificationType.VerifyUserRejected:
        navigate('/menu/edit');
        break;

      case NotificationType.VerifyPageRejected:
        if (itemNoti?.toPage) {
          navigate('/page/' + itemNoti?.toPage._id);
        }
        break;

      case NotificationType.System:
        {
          if (!itemNoti.customMessage) return;
          alert(itemNoti.customMessage);
        }
        break;

      case NotificationType.WinningALuckyWheelPrize ||
        NotificationType.LuckyWheel:
        break;

      case NotificationType.SetTermStatus:
        {
          const customMessage = JSON.parse(itemNoti.customMessage);
          alert(customMessage ? customMessage.vi : null);
        }
        break;

      case NotificationType.EnteredReferralCode:
        break;

      case NotificationType.ApprovedRequestBadge:
        navigate('profile');
        break;

      case NotificationType.RejectedRequestBadge:
        break;

      case NotificationType.DeleteProduct:
        {
          const customMessage = JSON.parse(itemNoti.customMessage);
          alert(customMessage ? customMessage.vi : null);
        }

        break;

      case NotificationType.GotPrizeReferralCampaign: {
        break;
      }

      default:
        break;
    }
  };

  const renderNotifyTitle = (item: Notification, variables: any) => {
    if (
      item.type == NotificationType.EnteredReferralCode ||
      item.type == NotificationType.ApprovedRequestBadge ||
      item.type == NotificationType.RejectedRequestBadge ||
      item.type == NotificationType.DeleteProduct
    ) {
      return <NotifyContent idUser={idUser} dataNoti={item} />;
    }
    return (
      <span>
        <TitleNotify item={item} variables={variables} />
        <NotifyContent idUser={idUser} dataNoti={item} />
      </span>
    );
  };

  return (
    <div onClick={() => openDetailNotify()}>
      <div
        className={`px-3 py-2 w-full flex justify-between items-center hover:bg-white bg-${
          itemNoti.read ? 'white' : 'third-gray'
        }`}>
        <div className="flex items-center  gap-[10px] w-[calc(100%-_34px)]">
          <div className="w-[40px] h-[40px]">
            <div className="img-1-1">
              <img src={itemNoti.fromUser?.avatar?.uri || userImg} />
            </div>
          </div>
          <div className="w-[calc(100%-_50px)] pl-[10px]">
            <div>{renderNotifyTitle(itemNoti, variables)}</div>
            <div className="text-sm mt-2">{DiffDate(itemNoti.updatedAt)}</div>
            {!showRequestFriendButton && <AcceptRequestFriend />}
          </div>
        </div>
        <div className="h-[24px] w-[24px]">
          <IconNotify item={itemNoti} />
        </div>
      </div>
      <hr className="h-[1px]" />
    </div>
  );
};

export default NotifyItem;
