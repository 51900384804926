import { AiOutlinePlayCircle } from 'react-icons/ai';
import { Media, MediaType } from '../../graphql/generated';
import '../photo/photo-item.scss';
import { Slide } from 'yet-another-react-lightbox/*';
import { useEffect, useState } from 'react';
import { tranFormSlider } from '../photo/PhotoAlbum';
import LightboxImage from '../lightbox/LightboxImage';
import LightboxImageAvt from '../lightbox/LightboxImageAvt';

interface Props {
  medias: Media[] | undefined;
}

const ManyImageComponent = (props: Props) => {
  const [slider, setSlider] = useState<Slide[]>([]);
  const [idxMedia, setIdxMedia] = useState(-1);
  const { medias } = props;

  useEffect(() => {
    setSlider(tranFormSlider((medias as Media[]) || []));
  }, [medias]);

  const onCloseMedia = () => {
    setIdxMedia(-1);
  };

  const onOpenMedia = (index: number) => {
    setIdxMedia(index);
  };

  const checkMedia = (media: Media, className?: string) => {
    return media.type === MediaType.Video ? (
      <img src={media?.thumbnail ?? ''} alt="" className={`${className}`} />
    ) : (
      <img src={media?.uri} alt="" className={`${className} object-cover`} />
    );
  };
  const renderImage = () => {
    if (medias?.length === 1) {
      return (
        <div
          className="max-h-[40em] overflow-hidden relative"
          onClick={() => onOpenMedia(0)}>
          {checkMedia(medias[0], 'w-full md:mt-[-5em]')}
          {medias[0].type === MediaType.Video && (
            <AiOutlinePlayCircle
              className="absolute top-[40%] left-[50%]"
              size={48}
              color="lightGrey"
            />
          )}
        </div>
      );
    } else if (medias?.length === 2) {
      return (
        <div className="grid-rows-2">
          <div
            className="max-h-[20em] overflow-hidden relative"
            onClick={() => onOpenMedia(0)}>
            {checkMedia(medias[0], 'h-[20em] w-full')}{' '}
            {medias[0].type === MediaType.Video && (
              <AiOutlinePlayCircle
                className="absolute top-[40%] left-[50%]"
                size={48}
                color="lightGrey"
              />
            )}
          </div>
          <div
            className="max-h-[20em] overflow-hidden relative"
            onClick={() => onOpenMedia(1)}>
            {checkMedia(medias[1], 'h-[20em] w-full')}{' '}
            {medias[1].type === MediaType.Video && (
              <AiOutlinePlayCircle
                className="absolute top-[40%] left-[50%]"
                size={48}
                color="lightGrey"
              />
            )}
          </div>
        </div>
      );
    } else if (medias?.length === 3) {
      return (
        <div className="flex justify-between">
          <div className="relative mr-2" onClick={() => onOpenMedia(0)}>
            {checkMedia(medias[0], '')}{' '}
            {medias[0].type === MediaType.Video && (
              <AiOutlinePlayCircle
                className="absolute top-1/2 left-[49%]"
                size={48}
                color="lightGrey"
              />
            )}
          </div>
          <div className="relative mr-2" onClick={() => onOpenMedia(1)}>
            {checkMedia(medias[1])}{' '}
            {medias[0].type === MediaType.Video && (
              <AiOutlinePlayCircle
                className="absolute top-1/2 left-[49%]"
                size={48}
                color="lightGrey"
              />
            )}
          </div>
          <div className="relative" onClick={() => onOpenMedia(2)}>
            {checkMedia(medias[2])}{' '}
            {medias[0].type === MediaType.Video && (
              <AiOutlinePlayCircle
                className="absolute top-1/2 left-[49%]"
                size={48}
                color="lightGrey"
              />
            )}
          </div>
        </div>
      );
    } else if (medias?.length === 4) {
      return (
        <>
          <div className="flex justify-between max-h-[420px] overflow-hidden">
            <div
              className="relative mr-2 md:mt-[-10em]"
              onClick={() => onOpenMedia(0)}>
              {checkMedia(medias[0])}
            </div>
            <div
              className="relative  md:mt-[-10em]"
              onClick={() => onOpenMedia(1)}>
              {checkMedia(medias[1])}
            </div>
            {/* <img src={medias[0]?.uri} alt="" className="w-[49%] !height-[500px]" />
            <img src={medias[1]?.uri} alt="" className="w-[49%] !height-[680px]" /> */}
          </div>
          <div className="flex justify-between mt-4 max-h-[420px] overflow-hidden">
            <div
              className="relative mr-2 md:mt-[-10em]"
              onClick={() => onOpenMedia(2)}>
              {checkMedia(medias[2])}
            </div>
            <div
              className="relative md:mt-[-10em]"
              onClick={() => onOpenMedia(3)}>
              {checkMedia(medias[3])}
            </div>
          </div>
        </>
      );
    } else if (medias && medias.length > 4) {
      return (
        <>
          <div
            className="max-h-[50em] overflow-hidden"
            onClick={() => onOpenMedia(0)}>
            {checkMedia(medias[0], 'w-full md:mt-[-10em]')}
          </div>
          <div className="flex justify-between relative mt-2">
            <div className="relative mr-2" onClick={() => onOpenMedia(1)}>
              {checkMedia(
                medias[1],
                'md:h-[420px] h-[210px] w-[135px] md:w-[310px]',
              )}
            </div>
            <div className="relative mr-2" onClick={() => onOpenMedia(2)}>
              {checkMedia(
                medias[2],
                'md:h-[420px] h-[210px] w-[135px] md:w-[310px]',
              )}
            </div>
            <div onClick={() => onOpenMedia(3)} className="w-[33%]">
              {checkMedia(
                medias[3],
                'md: opacity-40 w-full md:h-[420px] h-[210px]',
              )}{' '}
              <span className="absolute z-5 right-[10%] top-[40%] md:text-6xl text-3xl text-[#383838]">
                +{medias.length - 3}
              </span>
            </div>
          </div>
        </>
      );
    }
    return <></>;
  };

  return (
    <>
      {renderImage()}{' '}
      {medias?.length === 1 ? (
        <LightboxImageAvt
          index={idxMedia}
          slides={slider}
          isOpen={idxMedia >= 0}
          onClose={onCloseMedia}
        />
      ) : (
        <LightboxImage
          index={idxMedia}
          slides={slider}
          isOpen={idxMedia >= 0}
          onClose={onCloseMedia}
        />
      )}
    </>
  );
};

export default ManyImageComponent;
