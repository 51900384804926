import { useQueryClient } from '@tanstack/react-query';
import {
  GetDirectConversationQueryVariables,
  GetPageDirectConversationQueryVariables,
  useClearMessagesMutation,
  useCreateGroupConversationMutation,
  useGetDirectConversationQuery,
  useGetPageDirectConversationQuery,
  useLeaveGroupConversationMutation,
  useSendMessageMutation,
  useUpdateGroupConversationMutation,
} from '../../../graphql/generated';
import { graphqlClientRequest } from '../../../graphql/services/graphql-client';

export const useGetDirectConversation = (
  input: GetDirectConversationQueryVariables,
) => {
  const { data } = useGetDirectConversationQuery(
    graphqlClientRequest(true),
    input,
    {
      enabled: !!input.userId,
    },
  );
  return { data };
};

export const useGetPageDirectConversation = (
  input: GetPageDirectConversationQueryVariables,
) => {
  const { data } = useGetPageDirectConversationQuery(
    graphqlClientRequest(true),
    input,
    {
      enabled: !!input.pageId,
    },
  );
  return { data };
};

export const useSendMessage = () => {
  const { data, isLoading, isError, mutate, isSuccess } =
    useSendMessageMutation(graphqlClientRequest(true), {
      onSuccess(data) {},

      retry: false,
    });

  return { data, isLoading, isError, mutate, isSuccess };
};

export const useLeaveGroupConversation = () => {
  const { data, isLoading, isError, mutate, isSuccess, mutateAsync } =
    useLeaveGroupConversationMutation(graphqlClientRequest(true), {
      onSuccess(data) {},

      retry: false,
    });

  return { data, isLoading, isError, mutate, isSuccess, mutateAsync };
};

export const useClearMessages = () => {
  const { data, isLoading, isError, mutate, isSuccess, mutateAsync } =
    useClearMessagesMutation(graphqlClientRequest(true), {
      onSuccess(data) {},

      retry: false,
    });

  return { data, isLoading, isError, mutate, isSuccess, mutateAsync };
};

export const useCreateGroupConversation = () => {
  const { data, isLoading, isError, mutate, isSuccess } =
    useCreateGroupConversationMutation(graphqlClientRequest(true), {
      onSuccess(data) {},

      retry: false,
    });

  return { data, isLoading, isError, mutate, isSuccess };
};

export const useUpdateGroupConversation = () => {
  const queryClient = useQueryClient();

  return useUpdateGroupConversationMutation(graphqlClientRequest(true), {
    onSuccess(data) {
      queryClient.invalidateQueries(['getChat']);
      queryClient.invalidateQueries(['getConversation']);
    },
    retry: false,
  });
};
