export interface UserNotiType {
  _id: string;
  name: string;
}

export interface VariablesNotiType {
  commentContent?: string;
  commentId?: string;
  for: string;
  postId?: string;
  totalLikedUsers?: number;
  totalCommentedUsers?: number;
  totalRepliedCommentUsers?: number;
  totalSharedUsers?: number;
  creators?: UserNotiType[];
  reactors?: UserNotiType[];
  taggedUsers?: UserNotiType[];
  pageId?: string;
  commentFatherId?: string;
  totalTaggedUsers?: number;
  totalInvites?: number;
  points?: number;
  msgType?: number;
}

export enum keyNameAction {
  REACTION = 'reactors',
  CREATORS = 'creators',
  TAGGED_USERS = 'taggedUsers',
}

export enum typeActionUser {
  LIKE = 'totalLikedUsers',
  COMMENT = 'totalCommentedUsers',
  SHARE = 'totalSharedUsers',
  TAGGED_IN_A_POST = 'totalTaggedUsers',
  INVITES = 'totalInvites',
  REPLIED_COMMENT = 'totalRepliedCommentUsers',
}
