import { ChangeEvent, useEffect, useState } from 'react';
import { AiFillCamera } from 'react-icons/ai';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { queryClient } from '..';
import { getUserLogin } from '../Ultis/token';
import userImg from '../assets//images/user.jpeg';
import cover from '../assets/images/groups-default-cover-photo.png';
import { useUpdatePage } from '../common/services/api';
import LightboxImageAvt from '../components/lightbox/LightboxImageAvt';
import LoadingGlobal from '../components/loading/LoadingGlobal';
import { GroupPrivacy, PageType } from '../graphql/generated';
import { useUploadPhoto } from '../pages/DetailPost/services/api';
import { TypeMediaFile } from '../pages/Menu/Page/Create/components/StepsLayout';
import { useGetPage } from '../pages/Page/services/api';
import { useGetUser } from '../pages/Profile/services/api';
import Toast, { ToastProps, ToastType } from '../components/toast/Toast';
import {
  useGetGroup,
  useGetGroupMembers,
  useUpdateGroupMembers,
} from '../pages/Groups/services/api';

interface Props {}

const _URL = window.URL || window.webkitURL;

export interface Preview {
  id?: string;
  key: string;
  idx: number;
  uri: string;
  type: 'image' | 'video';
  file: File;
  status: 'Uploading' | 'Completed' | 'Error' | 'Cancel';
  isSkip?: boolean;
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const GroupLayout = (props: Props) => {
  const { pathname } = useLocation();
  const url = pathname.split('/')[1].toLocaleLowerCase();
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );
  const [isOpenCover, setIsOpenCover] = useState(false);
  const [isLoadingCover, setIsLoadingCover] = useState(false);
  const [toast, setToast] = useState<ToastProps>({
    className: 'fixed top-0 right-0',
    isHidden: true,
    type: ToastType.Success,
    message: '',
  });

  const user = getUserLogin();
  const { groupId } = useParams();
  const navigate = useNavigate();
  const { data: page } = useGetGroup({
    id: groupId || '',
  });

  const { mutate: updateGroup } = useUpdateGroupMembers();
  const { mutateAsync: uploadPhotoAsync } = useUploadPhoto();

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    let resetToast: any;
    if (!toast.isHidden) {
      resetToast = setTimeout(() => {
        setToast((preState) => {
          return { ...preState, isHidden: true };
        });
      }, 3000);
    }
    return () => {
      clearTimeout(resetToast);
    };
  }, [toast]);

  const getDimensionOfPhoto = async (file: File) => {
    return new Promise((rs, rj) => {
      const img = new Image();
      const objectUrl = _URL.createObjectURL(file);

      img.onload = () => {
        rs({
          width: img.width,
          height: img.height,
        });
      };
      img.onerror = (e) => {
        rj(e);
      };
      img.src = objectUrl;
    });
  };

  const uploadImage = async (file: File, type: TypeMediaFile) => {
    const imageDimensions: any = await getDimensionOfPhoto(file);
    const uploadPhoto = await uploadPhotoAsync({
      file,
      dimensions: {
        width: imageDimensions.width,
        height: imageDimensions.height,
      },
    });
    return { uploadPhoto, type };
  };

  const handleChangeInputCoverFile = async (e: any) => {
    const file = e.target.files;

    if (file) {
      // const newPreview = createPreviewMedia(file, file.length);
      // setAvatarPreview(newPreview);
      setIsLoadingCover(true);
      if (
        file[0].type === 'image/jpeg' ||
        file[0].type === 'image/png' ||
        file[0].type === 'image/jpg'
      ) {
        uploadImage(file[0], TypeMediaFile.Cover).then((data) => {
          updateGroup(
            {
              data: {
                name: page?.getGroup?.name || '',
                privacy: GroupPrivacy.Public,
                categoryId: page?.getGroup?.categoryId || '',
                coverMediaId: data?.uploadPhoto?.uploadPhoto?.uri,
              },
              id: groupId || '',
            },
            {
              onSuccess() {
                setIsLoadingCover(false);
                setToast({
                  ...toast,
                  message: `Cập nhật ảnh bìa thành công`,
                  isHidden: false,
                  type: ToastType.Success,
                });
                queryClient.invalidateQueries(['getPage']);
              },
              onError() {
                setIsLoadingCover(false);
                setToast({
                  ...toast,
                  message: `Cập nhật ảnh bìa thất bại`,
                  isHidden: false,
                  type: ToastType.Danger,
                });
                queryClient.invalidateQueries(['getPage']);
              },
            },
          );
        });
      } else {
        setIsLoadingCover(false);
        setToast({
          ...toast,
          message: `Không đúng định dạng, vui lòng thử lại`,
          isHidden: false,
          type: ToastType.Danger,
        });
        e.target.value = null;
      }
    }
  };

  const handleClickChangeCover = (e: any) => {
    if (!isLoadingCover) {
      e.stopPropagation();
      document.getElementById('cover')?.click();
    }
  };

  const isOwner = page?.getGroup?.owner?._id === user?._id;
  return (
    <>
      <Toast
        message={toast.message}
        className={`${toast.className} absolute top-1`}
        type={toast.type}
        isHidden={toast.isHidden}
      />
      <div className="bg-third-gray relative min-h-screen">
        <div className="container m-auto relative">
          <div
            className={`profileImage relative h-[280px] !max-w-[${windowDimensions.width}px] mb-[-24px]`}
            style={{
              backgroundImage: `url(" ${
                page?.getGroup?.cover?.uri || cover
              } ")`,
            }}
            onClick={() => setIsOpenCover(true)}>
            {isOwner && (
              <div
                onClick={handleClickChangeCover}
                className="cursor-pointer absolute bottom-[10px] right-[10px] z-50 h-[30px] w-[30px] rounded-full bg-slate-400 border-[1px] border-solid flex items-center justify-center border-gray-50">
                <AiFillCamera color="white" />
              </div>
            )}

            {isLoadingCover && <LoadingGlobal right={50} top={5} />}
          </div>
          <input
            type="file"
            name="cover"
            id="cover"
            hidden={true}
            onChange={handleChangeInputCoverFile}
          />
          <LightboxImageAvt
            index={0}
            slides={[
              {
                src: page?.getGroup?.cover?.thumbnail || cover,
                width: page?.getGroup?.cover?.dimensions?.width,
                height: page?.getGroup?.cover?.dimensions?.height,
              },
            ]}
            isOpen={isOpenCover}
            onClose={() => setIsOpenCover(false)}
          />
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default GroupLayout;
