import {
  NotificationType,
  Notification,
  MediaType,
  FriendRequestingStatus,
  PostType,
} from '../../../graphql/generated';
import {
  VariablesNotiType,
  keyNameAction,
  typeActionUser,
} from '../../../types/NotificationTypes';
import { Notify, Code } from './textNotification';
import TitleNotify from './TitleNotify';
import TypeAddedCommentToMedia from './TypeAddCommentToMedia';
import TypeAddedCommentToPost from './TypeAddedCommentToPost';
import TypeRepliedComment from './TypeRepliedComment';
import TypeRepliedCommentOnYourPost from './TypeRepliedCommentOnYourPost';
import TypeAlsoRepliedCommentByFriend from './TypeAlsoRepliedCommentByFriend';

const PAGE_PRODUCT_TYPES: PostType[] = [PostType.Tour, PostType.Hotel];

interface NotifyContentProps {
  idUser: any;
  dataNoti: Notification;
}

const NotifyContent = (props: NotifyContentProps) => {
  const { idUser, dataNoti } = props;
  const variables = dataNoti.variables ? JSON.parse(dataNoti.variables) : null;

  const isCheckUsersAction = (
    variables: VariablesNotiType,
    keyNameAction: keyNameAction,
    actionType?: typeActionUser,
  ) => {
    const typeListUserAction = actionType || typeActionUser.LIKE;
    if (
      variables &&
      variables[keyNameAction]! &&
      variables[keyNameAction]!.length > 1
    ) {
      return (
        <span>
          {variables[typeListUserAction] && variables[typeListUserAction]! > 2
            ? `, `
            : ` ${Notify.and} `}
          <span className="font-bold">{variables[keyNameAction]![1].name}</span>
          {variables[typeListUserAction] && variables[typeListUserAction]! > 2
            ? ` ${Notify.and} ${variables[typeListUserAction]! - 2} người khác`
            : ' '}
        </span>
      );
    }
    return ' ';
  };

  const renderNotiContent = (
    item: Notification,
    variables: VariablesNotiType,
  ) => {
    if (
      item.type == NotificationType.System ||
      item.type == NotificationType.SetTermStatus
    ) {
      return null;
    }

    const isCreater = !!(
      item.forPost &&
      idUser &&
      item.forPost.creator &&
      item.forPost.creator._id == idUser
    );

    let isPostToPage = !!item.toPageId;

    const isVideo = !!(
      item.forMedia &&
      item.forMedia.type &&
      item.forMedia.type == MediaType.Video
    );

    const isProduct = !!(
      item.forPost &&
      item.forPost.type &&
      PAGE_PRODUCT_TYPES.includes(item.forPost.type)
    );

    const isCheckPostYourSelf = !!(
      item.forPost &&
      item.forPost.creator &&
      item.forPost.creator._id &&
      item.fromUser &&
      item.fromUser._id &&
      item.forPost.creator._id == item.fromUser._id
    );

    switch (item.type) {
      case NotificationType.AddedPost: {
        return ` ${
          isProduct ? Notify.friend_add_product : Notify.friend_add_post
        } `;
      }
      case NotificationType.AddedPostFromPageYouFollowed: {
        return ` ${
          isProduct ? Notify.friend_add_product : Notify.friend_add_post
        } `;
      }

      case NotificationType.LikedPost:
      case NotificationType.LikedPageProduct: {
        const labelLikePost = isPostToPage
          ? ` ${Notify.like_post_page} `
          : ` ${Notify.friend_like_post} `;
        return (
          <span>
            {isCheckUsersAction(variables, keyNameAction.REACTION)}
            {isProduct ? `${Notify.like_product_page}` : labelLikePost}
            {isPostToPage ? (
              <span className="font-semibold">
                {(item.toPage && `${item.toPage.name}.`) || ''}
              </span>
            ) : null}
          </span>
        );
      }

      case NotificationType.DislikedPost:
      case NotificationType.DislikedPageProduct: {
        const labelLikePost = isPostToPage
          ? ` ${Notify.dislike_post_page} `
          : ` ${Notify.friend_dislike_post} `;
        return (
          <span>
            {isCheckUsersAction(variables, keyNameAction.REACTION)}
            {isProduct ? `${Notify.dislike_product_page}` : labelLikePost}
            {isPostToPage ? (
              <span className="font-semibold">
                {(item.toPage && `${item.toPage.name}.`) || ''}
              </span>
            ) : null}
          </span>
        );
      }

      case NotificationType.CommentProductOnPage:
        return (
          <span>
            {isCheckUsersAction(variables, keyNameAction.CREATORS)}
            {Notify.comment_post_on_page}
            {isPostToPage ? (
              <span className="font-semibold">
                {(item.toPage && `${item.toPage.name}.`) || ''}
              </span>
            ) : null}
          </span>
        );

      case NotificationType.AddedCommentToPostOnPage:
        return (
          <span>
            {isCheckUsersAction(variables, keyNameAction.CREATORS)}
            {Notify.comment_post_on_page}
            {isPostToPage ? (
              <span className="font-semibold">
                {(item.toPage && `${item.toPage.name}.`) || ''}
              </span>
            ) : null}
          </span>
        );

      case NotificationType.SharedPost: {
        const lableSharedPost = isPostToPage ? (
          <span>{Notify.like_product_page}</span>
        ) : (
          <span>{Notify.share_post}</span>
        );
        return (
          <span>
            {isCheckUsersAction(
              variables,
              keyNameAction.CREATORS,
              typeActionUser.SHARE,
            )}
            {isProduct ? (
              <span>{Notify.share_product_page}</span>
            ) : (
              lableSharedPost
            )}
            {isPostToPage ? (
              <span className="font-semibold">
                {(item.toPage && `${item.toPage.name}`) || ''}
              </span>
            ) : null}
            {/* {isPostToPage &&
                                            `${
                                              isProduct
                                              ? 'notify.is_product'
                                              : 'notify.is_post'
                                            }.`} */}
          </span>
        );
      }

      case NotificationType.SharedTaggedPost:
        return (
          <span>
            <span>{Notify.share_post_tag}</span>
            {isPostToPage ? (
              <span className="font-semibold">
                {(item.toPage && `${item.toPage.name}`) || ''}
              </span>
            ) : null}
          </span>
        );

      case NotificationType.LikedPage:
      case NotificationType.FollowedPage:
        return (
          <span>
            {isCheckUsersAction(variables, keyNameAction.REACTION)}
            {Notify.follow_page}
            {isPostToPage ? (
              <span>
                <span className="font-semibold">
                  {(item.toPage && `${item.toPage.name} `) || ''}
                </span>
                {Notify.your}
              </span>
            ) : null}
          </span>
        );

      case NotificationType.LikedMedia: {
        const lableLikeMedia = isPostToPage ? (
          <span>{Notify.like_media_page}</span>
        ) : (
          <span>{Notify.like_media}</span>
        );
        const lableLikeVideo = isPostToPage ? (
          <span>{Notify.like_video_page}</span>
        ) : (
          <span>{Notify.like_video}</span>
        );
        return (
          <span>
            {isCheckUsersAction(variables, keyNameAction.REACTION)}
            {isVideo ? lableLikeVideo : lableLikeMedia}
            {isPostToPage ? (
              <span className="font-semibold">
                {(item.toPage && `${item.toPage.name}`) || ''}
              </span>
            ) : null}
          </span>
        );
      }

      case NotificationType.DislikedMedia: {
        const lableLikeMedia = isPostToPage ? (
          <span>{Notify.dislike_media_page}</span>
        ) : (
          <span>{Notify.dislike_media}</span>
        );
        const lableLikeVideo = isPostToPage ? (
          <span>{Notify.dislike_video_page}</span>
        ) : (
          <span>{Notify.dislike_video}</span>
        );
        return (
          <span>
            {isCheckUsersAction(variables, keyNameAction.REACTION)}
            {isVideo ? lableLikeVideo : lableLikeMedia}
            {isPostToPage ? (
              <span className="font-semibold">
                {(item.toPage && `${item.toPage.name}`) || ''}
              </span>
            ) : null}
          </span>
        );
      }

      case NotificationType.InvitedToLikeAPage:
        return (
          <span>
            {isCheckUsersAction(
              variables,
              keyNameAction.CREATORS,
              typeActionUser.INVITES,
            )}
            {` ${Notify.invite_like_page}`}
            <span className="font-semibold">
              {(item.fromPage && `${item.fromPage.name}.`) || ''}
            </span>
          </span>
        );

      case NotificationType.LikedPostThatTaggedYou: {
        if (variables && variables.reactors && variables.reactors.length > 1) {
          return (
            <span>
              {isCheckUsersAction(variables, keyNameAction.REACTION)}
              <span>{Notify.like_post_tag_you}</span>
              {`: ${(item.forPost && item.forPost!.content) || ''}`}
            </span>
          );
        }

        const content = item.forPost ? item.forPost.content || '' : '';
        // return `・${content}`
        return ` ${Notify.like_post_tag_you}: ${
          (item.forPost && content) || ''
        }`;
      }

      case NotificationType.DislikedPostThatTaggedYou: {
        if (variables && variables.reactors && variables.reactors.length > 1) {
          return (
            <span>
              {isCheckUsersAction(variables, keyNameAction.REACTION)}
              <span>{Notify.dislike_post_tag_you}</span>
              {`: ${(item.forPost && item.forPost!.content) || ''}`}
            </span>
          );
        }

        const content = item.forPost ? item.forPost.content || '' : '';
        // return `・${content}`
        return ` ${Notify.like_post_tag_you}: ${
          (item.forPost && content) || ''
        }`;
      }

      case NotificationType.NewGroupConversation: {
        return ` ${Notify.new_group_conversation} `;
      }

      case NotificationType.LikedComment: {
        isPostToPage = !!(item.forPost && item.forPost.page);
        const txtMediaLikeComment = isVideo ? (
          <span>{Notify.video}</span>
        ) : (
          <span>{Notify.picture}</span>
        );
        return (
          <span>
            {isCheckUsersAction(variables, keyNameAction.REACTION)}
            {item.forMedia ? (
              <span>
                {Notify.like_comment_media} {`${txtMediaLikeComment}`}
              </span>
            ) : (
              <span>
                {Notify.like_comment_post}{' '}
                {`${isProduct ? Notify.product : Notify.post}`}
              </span>
            )}
            {isPostToPage ? ` ${Notify.on_page} ` : '.'}
            <span className="font-semibold">
              {(isPostToPage &&
                item.forPost &&
                item.forPost.page &&
                `${item.forPost.page.name}.`) ||
                ''}
            </span>
          </span>
        );
      }

      case NotificationType.DislikedComment: {
        isPostToPage = !!(item.forPost && item.forPost.page);
        const txtMediaLikeComment = isVideo ? (
          <span>{Notify.video}</span>
        ) : (
          <span>{Notify.picture}</span>
        );
        return (
          <span>
            {isCheckUsersAction(variables, keyNameAction.REACTION)}
            {item.forMedia ? (
              <span>
                {Notify.dislike_comment_media} {`${txtMediaLikeComment}`}
              </span>
            ) : (
              <span>
                {Notify.dislike_comment_post}{' '}
                {`${isProduct ? Notify.product : Notify.post}`}
              </span>
            )}
            {isPostToPage ? ` ${Notify.on_page} ` : '.'}
            <span className="font-semibold">
              {(isPostToPage &&
                item.forPost &&
                item.forPost.page &&
                `${item.forPost.page.name}.`) ||
                ''}
            </span>
          </span>
        );
      }

      case NotificationType.AddedCommentToPostThatTaggedYou:
        if (variables && variables.creators && variables.creators.length > 1) {
          return (
            <span>
              {isCheckUsersAction(
                variables,
                keyNameAction.CREATORS,
                typeActionUser.COMMENT,
              )}
              <span>{Notify.added_comment_to_post_that_tagged_you}</span>
            </span>
          );
        }
        return ` ${Notify.added_comment_to_post_that_tagged_you}`;

      case NotificationType.AddedCommentToMedia:
        return (
          <TypeAddedCommentToMedia
            isVideo={isVideo}
            item={item}
            isCreater={isCreater}
            isPostToPage={isPostToPage}
            isCheckPostYourSelf={isCheckPostYourSelf}
            isCheckUsersAction={isCheckUsersAction(
              variables,
              keyNameAction.CREATORS,
              typeActionUser.COMMENT,
            )}
          />
        );

      case NotificationType.AcceptedFriendRequest:
        return ` ${Notify.accept_request_friend}`;

      case NotificationType.AddedCommentToPost:
        return (
          <TypeAddedCommentToPost
            item={item}
            isCreater={isCreater}
            isPostToPage={isPostToPage}
            isCheckPostYourSelf={isCheckPostYourSelf}
            isCheckUsersAction={isCheckUsersAction(
              variables,
              keyNameAction.CREATORS,
              typeActionUser.COMMENT,
            )}
            isProduct={isProduct}
          />
        );

      case NotificationType.RepliedComment:
        return (
          <TypeRepliedComment
            item={item}
            isPostToPage={isPostToPage}
            isCreater={isCreater}
            isCheckPostYourSelf={isCheckPostYourSelf}
            isCheckUsersAction={isCheckUsersAction(
              variables,
              keyNameAction.CREATORS,
              typeActionUser.REPLIED_COMMENT,
            )}
            isProduct={isProduct}
          />
        );

      case NotificationType.TaggedInAPost: {
        let otherTagged = 0;
        let newVariables: any[] = [];
        const isCheckTaged =
          item.forPost &&
          item.forPost.taggedUserIds &&
          item.forPost.taggedUserIds.length > 0 &&
          item.forPost.taggedUserIds.includes(idUser!);

        if (
          item.forPost &&
          item.forPost?.allTaggedUserIds &&
          item.forPost?.allTaggedUserIds.length > 1
        ) {
          newVariables = item.forPost?.allTaggedUserIds?.filter(
            (val) => val !== idUser,
          );
          otherTagged = item.forPost.allTaggedUserIds.length - 1;
        }

        if (newVariables && newVariables.length > 0) {
          return (
            <span>
              {Notify.tag_you_andfriend}
              {otherTagged}
              {Notify.tag_you_andfriend_2}
              {!isCheckTaged && ` ${Notify.accept_tag_post}`}
            </span>
          );
        }
        return (
          <span>
            {Notify.tag_in_post}
            {!isCheckTaged && ` ${Notify.accept_tag_post}`}
          </span>
        );
      }

      case NotificationType.RepliedCommentOnYourPost:
        isPostToPage = !!(item.forPost && item.forPost.page);
        return (
          <TypeRepliedCommentOnYourPost
            item={item}
            isPostToPage={isPostToPage}
            isProduct={isProduct}
            isCheckUsersAction={isCheckUsersAction(
              variables,
              keyNameAction.CREATORS,
              typeActionUser.REPLIED_COMMENT,
            )}
          />
        );

      case NotificationType.VerifyPageAccepted:
        return `${Notify.page} ${Notify.verified_success}`;

      case NotificationType.LuckyWheel: {
        const customMessage = JSON.parse(item?.customMessage as string);
        return ` ${customMessage ? customMessage.bodyViVn : ''}`;
      }

      //TODO:
      case NotificationType.WinningALuckyWheelPrize:
        //const gift = renderContentLucky(variables.prize);
        return ` ${Notify.spin_point}`;

      case NotificationType.VerifyUserAccepted:
        return `${Notify.account} ${Notify.verified_success}`;

      case NotificationType.MentionInAPost:
        return `${Notify.mention_in_a_post}`;

      case NotificationType.MentionInAComment:
      case NotificationType.RepliedMentionComment:
        return `${Notify.reply_mention_comment}`;

      case NotificationType.VerifyUserRejected:
        return `${Notify.verified_acc_fail}`;

      case NotificationType.VerifyPageRejected:
        return `${Notify.verified_page_fail}`;

      //TODO:
      case NotificationType.AlsoRepliedCommentByFriend:
        return <TypeAlsoRepliedCommentByFriend item={item} />;

      case NotificationType.EnteredReferralCode: {
        let message = '';
        if (variables) {
          message = ` ${Code.refer_code_notify_point_zero}`;
          const creators = variables.creators;
          if (creators && creators.length > 0) {
            const creator = creators[0];
            if (variables.msgType == 1) {
              message = `${Code.reference_code_noti_from} ${creator.name} ${Code.reference_code_noti_added} ${variables.points} ${Code.refer_code_notify_points}`;
            } else {
              message = ` ${Code.refer_code_notify} ${variables.points} ${Code.refer_code_notify_points}`;
            }
          }
        }
        return (
          <span>
            {variables.msgType && variables.msgType == 2 && (
              <TitleNotify item={item} variables={variables} />
            )}
            <span>{message}</span>
          </span>
        );
      }

      case NotificationType.SharedProductOnPage: {
        return (
          <span>
            {isCheckUsersAction(
              variables,
              keyNameAction.CREATORS,
              typeActionUser.SHARE,
            )}
            {Notify.shared_product_on_page}
            {isPostToPage ? (
              <span className="font-semibold">
                {(item.toPage && `${item.toPage.name} `) || ''}
              </span>
            ) : null}
            {Notify.your}
          </span>
        );
      }

      case NotificationType.ApprovedRequestBadge:
      case NotificationType.RejectedRequestBadge:
      case NotificationType.DeleteProduct:
        if (item?.customMessage) {
          const mess = JSON.parse(item?.customMessage as string);
          return <span>{mess.vi}</span>;
        }
        break;

      case NotificationType.NewFriendRequested:
        if (
          item.forFriendRequestingStatus === FriendRequestingStatus.Accepted
        ) {
          return ` ${Notify.became_friend}`;
        }
        return ` ${Notify.request_friend}`;

      case NotificationType.GotPrizeReferralCampaign: {
        //const vars: any = JSON.parse(item!.variables);
        // return ` ${translate(NOTIFY_LABEL[item.type], {
        //   totalInvited: vars.totalInvitedUser,
        //   voucherTitle: vars.voucherTitle,
        // })}`;

        //return `${Notify.got_prize_referral_campaign}`;
        return '';
      }

      case NotificationType.ChangePostStatus:
        {
          if (item?.customMessage) {
            // const mess = JSON.parse(item?.customMessage as string);

            // return ` ${mess.vi}`;
            return ` ${Notify.verified_post}`;
          }
        }
        break;
      default:
        return ``;
    }
  };

  return <span className='text-sm'>{renderNotiContent(dataNoti, variables)}</span>;
};

export default NotifyContent;
