import { Notify } from './textNotification';
import { Notification } from '../../../graphql/generated';

interface TypeRepliedCommentOnYourPostProps {
  item: Notification;
  isPostToPage: boolean;
  isProduct: boolean;
  isCheckUsersAction?: any;
}

const TypeRepliedCommentOnYourPost = (props: TypeRepliedCommentOnYourPostProps) => {
  /* eslint-disable prefer-const */
  let { item, isPostToPage, isCheckUsersAction, isProduct } = props;

  const commentOnPage = isProduct ? ` ${Notify.reply_comment_on_post_page_not_me_product} ` : ` ${Notify.reply_comment_on_post_page_not_me} `;

  const lableReplyCommentOnYourPageOrPost = isPostToPage ? commentOnPage : ` ${Notify.reply_comment_on_post_not_me}`;

  return (
    <span>
      {isCheckUsersAction || ' '}
      {/* TODO: */}
      {/* {translate(NOTIFY_LABEL[item.type])} */}
      {`${Notify.reply_comment_on_your_post} `}
      <span className="font-semibold">{(item.forComment && item.forComment.creator && item.forComment.creator.fullName) || ''}</span>

      {`${lableReplyCommentOnYourPageOrPost}`}

      <span className="font-semibold">
        {(isPostToPage && item.forPost && item.forPost.page && `${item.forPost.page.name}`) || ''}
        {isPostToPage && '.'}
      </span>

      {!isPostToPage && `${Notify.me}.`}
    </span>
  );
};

export default TypeRepliedCommentOnYourPost;
