import { useMatch, useParams } from 'react-router-dom';
import PostItem from '../../components/posts/Post';
import { usePost } from './services/api';

const DetailPost = () => {
  const { postId } = useParams();

  const matchDetailPost = useMatch(`/detail/${postId}`);
  const isDetailPost = Boolean(matchDetailPost);
  const { data, isLoading } = usePost({ id: postId || '' });
  return (
    <div>
      {isDetailPost && (
        <div className="header_edit">
          <p className=" text-2xl font-semibold">
            Bài viết của {data?.post?.creator?.fullName}
          </p>
        </div>
      )}
      {isLoading ? <p>Loading...</p> : <PostItem post={data?.post} />}
    </div>
  );
};

export default DetailPost;
