import { AiOutlineCamera } from 'react-icons/ai';
import {
  ForwardedRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useUploadPhoto } from '../../../pages/DetailPost/services/api';
import { uploadVideoApi } from '../../../Ultis/upload';
import { IoMdClose } from 'react-icons/io';
import { MdSend } from 'react-icons/md';
import { useSendMessage } from '../services/api';
import { Message } from '../../../graphql/generated';
import { uniqueId } from 'lodash';
import { getUserLogin } from '../../../Ultis/token';

const _URL = window.URL || window.webkitURL;

export interface InputChatRef {
  focus: () => void;
}
interface Props {
  refId: string;
  isReply?: boolean;
  fatherId?: string;
  asPageId?: string;
  onSendMessage?: (value: Message) => void;
}

interface Preview {
  uri: string;
  type: 'image' | 'video';
  file: File;
}

const InputChat = (
  { refId, asPageId, isReply, fatherId, onSendMessage }: Props,
  refInput: ForwardedRef<InputChatRef>,
) => {
  const ref = useRef<HTMLTextAreaElement>(null);
  const refUpload = useRef<HTMLInputElement>(null);
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [preview, setPreview] = useState<Preview[]>([]);
  const { mutateAsync: uploadPhotoAsync } = useUploadPhoto();
  const user = getUserLogin();
  const { mutate } = useSendMessage();
  const handleInputChange = (event: any) => {
    setMessage(event.target.value);
  };

  const clear = () => {
    setPreview([]);
    setMessage('');
    setIsLoading(false);
    setTimeout(() => ref.current && ref.current.focus(), 100);
  };

  useImperativeHandle(refInput, () => ({
    focus: () => {
      ref.current && ref.current.focus();
    },
  }));

  const handleUploadPhoto = (file: File): Promise<string> =>
    new Promise((resolve) => {
      const img = new Image();
      const objectUrl = _URL.createObjectURL(file);
      img.onload = async () => {
        _URL.revokeObjectURL(objectUrl);
        return uploadPhotoAsync({
          file,
          dimensions: {
            width: img.width,
            height: img.height,
          },
        }).then((data) => resolve(data.uploadPhoto._id));
      };
      img.src = objectUrl;
    });

  const handleUploadVideo = async (file: File) => {
    const { data: media } = await uploadVideoApi(file);
    return media._id as string;
  };

  const handleMessage = async (data: {
    preview: Preview[];
    message: string;
  }) => {
    const message = {
      _id: uniqueId(),
      text: data.message,
      creator: {
        _id: user._id,
      },
      medias: preview.map((item) => {
        if (item.type === 'video') {
          return {
            _id: uniqueId(),
            type: 'VIDEO',
            uri: item.uri,
          };
        }
        return {
          _id: uniqueId(),
          type: 'PHOTO',
          uri: item.uri,
        };
      }),
    } as Message;
    onSendMessage && onSendMessage(message);
    clear();
  };

  const handleSendMessage = async (data: {
    preview: Preview[];
    message: string;
  }) => {
    handleMessage({
      preview,
      message,
    });
    let mediaIds: string[] = [];
    if (data.preview.length > 0) {
      mediaIds = await Promise.all(
        data.preview.map((media) => {
          if (media.type === 'image') {
            return handleUploadPhoto(media.file);
          } else {
            return handleUploadVideo(media.file);
          }
        }),
      );
    }

    if (!!asPageId) {
      mutate(
        {
          data: {
            mediaIds,
            toConversationId: refId,
            text: data.message,
          },
          asPageId: asPageId,
        },
        {
          onSuccess(data, variables, context) {
            //do something when success
          },
        },
      );
    } else {
      mutate(
        {
          data: {
            mediaIds,
            toConversationId: refId,
            text: data.message,
          },
        },
        {
          onSuccess(data, variables, context) {
            //do something when success
          },
        },
      );
    }
  };

  const onSend = () => {
    handleSendMessage({
      preview,
      message,
    });
  };

  const handleKeyDown = async (
    event: React.KeyboardEvent<HTMLTextAreaElement>,
  ) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();

      onSend();
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (!files || files?.length === 0) {
      return;
    }
    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      const objectUrl = URL.createObjectURL(file);

      if (file.type.split('/')[0] === 'image') {
        setPreview((p) => [...p, { uri: objectUrl, file, type: 'image' }]);
      } else {
        setPreview((p) => [...p, { uri: objectUrl, file, type: 'video' }]);
      }
    }
  };

  useEffect(() => {
    if (ref.current) {
      ref.current.style.height = 'auto'; // reset height to auto to recalculate
      ref.current.style.height = `${ref.current.scrollHeight}px`;
    }
  }, [message]);

  const onOpenUploadFile = () => {
    refUpload.current && refUpload.current.click();
  };

  const onClearMedia = (index: number) => {
    const newPreview = [...preview];
    newPreview.splice(index, 1);
    setPreview(newPreview);
  };

  const isContent = !!(preview.length || message);

  return (
    <>
      <div className="rounded-xl flex flex-col w-full pt-2 pb-2 flex-grow box-border md:py-3 relative  bg-[#f3f3f3] max-w-[90%]    ">
        {preview.length > 0 && (
          <div className="py-2 box-border md:py-3 relative flex overflow-x-auto max-w-[90%]">
            {preview.map((item, index) => (
              <div key={index} className="media-upload w-[80px] md:w-20">
                {item.type === 'video' ? (
                  <video
                    className="object-cover h-[80px] w-[80px] md:h-20 md:w-20 rounded-md"
                    src={item.uri}
                  />
                ) : (
                  <img
                    className="object-cover h-[80px] w-[80px] md:h-20 md:w-20 rounded-md"
                    src={item.uri}
                  />
                )}
                <span
                  className="remove-media"
                  onClick={() => onClearMedia(index)}>
                  <IoMdClose color="#fff" />
                </span>
              </div>
            ))}
          </div>
        )}
        <textarea
          disabled={isLoading}
          ref={ref}
          tabIndex={0}
          data-id="root"
          rows={1}
          placeholder="Viết bình luận"
          className="h-[26px] min-h-[26px] max-h-[200px] outline-none border-transparent focus:border-transparent text-base m-0 w-full resize-none border-0 bg-transparent p-0 pr-7 focus:ring-0 focus-visible:ring-0 dark:bg-transparent pl-2 md:pl-1 md:pr-[3rem] "
          value={message}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
        />

        <span
          onClick={onOpenUploadFile}
          className="color-[#888888] cursor-pointer h-full items-center md:text-3xl text-xl flex absolute p-1 rounded-md text-gray-500 top-[0] hover:bg-gray-100 enabled:dark:hover:text-gray-400 dark:hover:bg-gray-900 disabled:hover:bg-transparent dark:disabled:hover:bg-transparent right-1 md:right-2 disabled:opacity-40">
          <AiOutlineCamera className="" />
        </span>

        <input
          type="file"
          ref={refUpload}
          style={{ display: 'none' }}
          accept="image/*,video/*"
          multiple={true}
          onChange={handleFileChange}
        />
      </div>
      <span
        onClick={onSend}
        className="color-[#888888] flex justify-center min-w-[50px] ml-[5px] items-center cursor-pointer md:text-3xl text-xl  p-1 rounded-md text-gray-500 top-[8px] md:top-2.5 right-1 md:right-2 disabled:opacity-40">
        <MdSend
          className="md:mr-2 mr-1"
          color={isContent ? '#0073f5' : '#bcc0c4'}
        />
      </span>
    </>
  );
};

export default forwardRef(InputChat);
