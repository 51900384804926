import {
  GetChildCommentsQueryVariables,
  GetCommentsQueryVariables,
  GetHomeFeedsQueryVariables,
  GetHotNewsQueryVariables,
  GetMyTotalUnreadNotificationsQueryVariables,
  GetSummaryUserReactionsQueryVariables,
  GetUserReactionsQueryVariables,
  GetUserTimelinePostQueryVariables,
  useGetChildCommentsQuery,
  useGetCommentsQuery,
  useGetHomeFeedsQuery,
  useGetHotNewsQuery,
  useGetMyTotalUnreadNotificationsQuery,
  useGetSummaryUserReactionsQuery,
  useGetUserReactionsQuery,
  useGetUserTimelinePostQuery,
} from '../../../graphql/generated/index';
import { graphqlClientRequest } from '../../../graphql/services/graphql-client';

export const useGetPostUser = (input: GetUserTimelinePostQueryVariables) => {
  const { data, refetch, isSuccess } = useGetUserTimelinePostQuery(
    graphqlClientRequest(true),
    input,
    {
      onSuccess(data) {},
      onError(error: any) {
        //   Notification.Error({ message: getMessageError(error) });
      },
      retry: false,
    },
  );

  return { data, refetch, isSuccess };
};

export const useGetPost = (
  input: GetHomeFeedsQueryVariables,
  cacheTime?: number,
) => {
  const { data, isLoading, refetch } = useGetHomeFeedsQuery(
    graphqlClientRequest(true),
    input,
    {
      onSuccess(data) {},
      onError(error: any) {
        //   Notification.Error({ message: getMessageError(error) });
      },
      retry: false,
      refetchOnMount: 'always',
      cacheTime: 0,
    },
  );
  return { data, isLoading, refetch };
};
export const useGetHotNews = (input: GetHotNewsQueryVariables) => {
  const { data, isLoading, refetch } = useGetHotNewsQuery(
    graphqlClientRequest(true),
    input,
    {
      onSuccess(data) {},
      onError(error: any) {
        //   Notification.Error({ message: getMessageError(error) });
      },
      retry: false,
    },
  );
  return { data, isLoading, refetch };
};

export const useGetCommentPost = (input: GetCommentsQueryVariables) => {
  const { data, isSuccess } = useGetCommentsQuery(
    graphqlClientRequest(true),
    input,
    {
      onSuccess(data) {},
      onError(error: any) {
        //   Notification.Error({ message: getMessageError(error) });
      },
      retry: false,
      cacheTime: 0,
    },
  );
  return { data, isSuccess };
};

export const useGetChildComments = (
  input: GetChildCommentsQueryVariables,
  enabled = false,
) => {
  const { data, isSuccess } = useGetChildCommentsQuery(
    graphqlClientRequest(true),
    input,
    {
      onSuccess(data) {},
      onError(error: any) {
        //   Notification.Error({ message: getMessageError(error) });
      },
      retry: false,
      cacheTime: 0,
      enabled,
    },
  );
  return { data, isSuccess };
};

export const useGetNotificationUnRead = (
  input: GetMyTotalUnreadNotificationsQueryVariables,
) => {
  const { data } = useGetMyTotalUnreadNotificationsQuery(
    graphqlClientRequest(true),
    input,
    {
      onSuccess(data) {},
      onError(error: any) {
        //   Notification.Error({ message: getMessageError(error) });
      },
      retry: false,
    },
  );
  return { data };
};

export const useGetSummaryPostReactions = (
  input: GetSummaryUserReactionsQueryVariables,
) => {
  const { data } = useGetSummaryUserReactionsQuery(
    graphqlClientRequest(true),
    input,
    {
      onSuccess(data) {},
      onError(error: any) {
        //   Notification.Error({ message: getMessageError(error) });
      },
      retry: false,
    },
  );
  return { data };
};

export const useGetUserPostReactions = (
  input: GetUserReactionsQueryVariables,
) => {
  const { data } = useGetUserReactionsQuery(graphqlClientRequest(true), input, {
    onSuccess(data) {},
    onError(error: any) {
      //   Notification.Error({ message: getMessageError(error) });
    },
    retry: false,
  });
  return { data };
};
