class AvatarProps {
  src?: string;
  width?: number;
  height?: number;
  alt?: string;
  className?: string;
}

const Avatar = ({ src = '/', alt = 'avatar', className }: AvatarProps) => {
  return (
    <div
      className={
        `avatar_menu border border-solid border-gray-200 rounded-full  ` +
        className
      }>
      <div className="img-1-1">
        <img src={src} alt={alt} />
      </div>
    </div>
  );
};

export default Avatar;
