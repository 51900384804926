import { FC, useEffect, useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import StarRatingComponent from 'react-star-rating-component';

interface Props {
  defaultValue?: any;
  isShow: boolean;
  address?: string;
  voteContent?: string;
  currentWord?: number;
  star: number;
  isUpdate?: boolean;
  onchangeStar: (nextValue: number, prevValue: number, name: string) => void;
  handleClickVote: (star: number) => void;
  handleClickCancel?: () => void;
  handleVoteContentChange?: (e: any) => void;
}
const VotePageModal: FC<Props> = ({
  defaultValue,
  star,
  isShow,
  voteContent,
  currentWord,
  isUpdate,
  handleVoteContentChange,
  handleClickVote,
  onchangeStar,
  handleClickCancel,
}) => {
  return (
    <div
      className={`${
        isShow ? '' : 'hidden'
      } fixed top-0 bg-[#00000075] left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-full justify-center items-center flex modal-vote-address`}>
      <div className="relative w-full max-w-md max-h-full">
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
          <div className="p-4  text-center ">
            <div className="text-lg font-semibold">
              <span>Bài viết đánh giá </span>
            </div>
            <StarRatingComponent
              name={'Đánh giá'}
              value={star || 0}
              onStarClick={(
                nextValue: number,
                prevValue: number,
                name: string,
              ) => onchangeStar(nextValue, prevValue, name)}
              starCount={5}
              starColor={'#FFB800'}
              emptyStarColor="#DDDDDD"
            />
            <textarea
              rows={6}
              tabIndex={0}
              maxLength={1000}
              value={voteContent}
              onChange={handleVoteContentChange}
              className={`text-xl max-[500px]:text-base w-full mt-3  min-h-[80px] bg-white border-[1px] border-solid border-gray-300 p-2 rounded-md`}
              placeholder="Viết đánh giá của bạn"
            />
            <div>
              <span
                className={`${
                  !!currentWord && currentWord < 1000
                    ? 'text-red-600'
                    : 'text-gray-400'
                }`}>
                {currentWord || 0}
              </span>
              <span className="text-gray-400">
                /1000 - Bài viết không được dài hơn 1000 ký tự.
              </span>
            </div>

            <div className="pt-6 x">
              <button
                type="button"
                disabled={!(star > 0)}
                onClick={() => handleClickVote(star || 0)}
                className={`${
                  !(star > 0) ? 'bg-primary-gray' : 'bg-primary-yellow'
                }  text-slate-100 w-full h-[40px] rounded-md`}>
                {isUpdate ? 'Sửa đánh giá' : 'Gửi đánh giá'}
              </button>
            </div>
          </div>
          <div
            className="absolute top-[15px] right-[15px]"
            onClick={handleClickCancel}>
            <AiOutlineClose size={25} />
          </div>
          {/* Modal footer */}
        </div>
      </div>
    </div>
  );
};

export default VotePageModal;
