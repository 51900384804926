import { AiOutlinePlusSquare } from 'react-icons/ai';
import { BsMessenger } from 'react-icons/bs';
import { FaUserPlus } from 'react-icons/fa';
import { useGetDirectConversation } from '../../pages/Messenger/services/api';
import { useNavigate } from 'react-router-dom';

interface Props {
  userId?: string;
}

const Action = ({ userId }: Props) => {
  const { data } = useGetDirectConversation({ userId: userId || '' });
  const navigate = useNavigate();
  const onMessage = () => {
    navigate(`/messenger/${data?.getDirectConversation._id}`);
  };
  return (
    <div className="flex justify-center md:mt-6 mt-2">
      {/* <button className="border-0 bg-primary-yellow rounded-lg p-3 md:pr-6 md:pl-6 !text-white flex items-center md:mr-6 mr-3">
        <FaUserPlus className="text-2xl" />
        <div className=" ml-3">Kết bạn</div>
      </button>
      <button className="border-0 bg-secondary-yellow text-primary-yellow  rounded-lg p-3 pr-6 pl-6  flex items-center mr-3">
        <AiOutlinePlusSquare className="text-2xl" />
        <div className=" ml-3">Theo dõi</div>
      </button> */}
      {userId && (
        <button
          onClick={onMessage}
          className="border-0 bg-secondary-yellow text-primary-yellow rounded-lg p-3 flex items-center">
          <BsMessenger className="text-2xl" />
        </button>
      )}
    </div>
  );
};

export default Action;
