export interface IFriendItem {
    id: string
    avatar: string,
    name: string,
    isChecked?: boolean
}

export interface IFriendItemParams {
    friend: IFriendItem,
    handleSelectFriend: (id: string) => void
}

export default function Friend({ friend, handleSelectFriend }: IFriendItemParams) {
    return <>
        <div className="flex justify-stretch items-center">
            <div className="border rounded-full w-16">
                <img className="rounded-full" src={friend.avatar} alt="avatar" />
            </div>
            <div className="flex justify-between w-full items-center border-b py-5">
                <p className="text-[#111111] font-medium px-3">{friend.name}</p>
                <input value={friend.id} className="w-6 h-6 rounded-full" type="checkbox" name="invite-members" onClick={() => handleSelectFriend(friend.id)} checked={friend.isChecked}/>
            </div>
        </div>
    </>
}