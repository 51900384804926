import { BiSearch } from 'react-icons/bi';
import logo from '../assets/images/logo-header.svg';
import { StateFetch } from '../pages/Home/Home';
import { usePosts } from '../pages/Home/hooks/usePosts';

interface Props {}

const Header = (props: Props) => {
  const { setFilter, refetchData: refetch, setStateFetch } = usePosts();
  const refetchData = () => {
    setFilter({ limit: 5, offset: 0 });
    setStateFetch(StateFetch.GETTING_FIRST);
    refetch();
  };

  return (
    <div className="bg-white p-2 hidden items-center justify-between border-0 show-on-screen-tablet">
      <button className="border-0 bg-none" onClick={() => refetchData()}>
        <img src={logo} alt="logo" className="w-[100px] h-[44px]" />
      </button>
      <div className="flex items-center justify-center w-[32px] h-[32px] border-[1px] border-solid border-gray-300 rounded-full mr-2.5 bg-third-gray">
        <a href={'/search'} className="h-[20px]">
          <BiSearch color="#64748B" className="text-xl" size="xs" />
        </a>
      </div>
    </div>
  );
};

export default Header;
