import { FC } from 'react';
import { FriendEntity } from '../friend-menu/FriendItem';
import userDefaultImg from '../../assets/images/user.jpeg';
import { Link } from 'react-router-dom';
import ButtonComponent from '../button/ButtonComponent';

interface Props {
  isMe?: boolean;
  isMyFriend?: boolean;
  user: FriendEntity;
}

const UserItemReaction: FC<Props> = ({ isMe, isMyFriend, user }) => {
  const {
    avatar,
    username,
    fullName,
    _id: userId,
    totalMutualFriends,
    requestedAt,
    verified,
  } = user;
  return (
    <div className="flex h-[50px] bg-white flex-row ">
      <div className="w-1/6 avatar-wrap flex ml-5 rounded-full">
        <img
          className={`max-[400px]:h-[35px] max-[400px]:w-[35px]  friend-avatar bg-cover self-center rounded-full ${
            verified ? 'avartar-shadow_verify' : 'avartar-border_default'
          }`}
          src={avatar?.uri ?? userDefaultImg}
          height={50}
          width={50}
          alt={'avatar_' + username}
        />
      </div>
      <div
        className={`flex flex-col place-content-center justify-items-center w-3/6 gap-0`}>
        <Link to={'/profile/' + userId} className="">
          <span className="max-[400px]:text-xs text-md w-15 font-semibold text-black line-clamp-1">
            {fullName}
          </span>
        </Link>
        {!isMe && totalMutualFriends !== 0 && (
          <p className="text-slate-400 text-sm max-[400px]:text-xs">
            {totalMutualFriends} bạn chung
          </p>
        )}
      </div>
      {!isMe && !isMyFriend && (
        <div className="flex mr-5 w-1/4 items-center justify-end">
          <div className="">
            <ButtonComponent
              content={'Kết bạn'}
              hover="hover:bg-teal-400"
              text_size="text-sm max-[400px]:text-xs"
              padding="py-2 px-3 max-[400px]:py-1 max-[400px]:px-1"
              margin=""
              size="w-20 max-[400px:w-[50px]"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default UserItemReaction;
