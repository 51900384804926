import { images } from '../../../assets/images';

const EmptyNotification = () => {
  return (
    <center className="justify-center items-center ">
      <img src={images.No_Notification} placeholder="Bạn không có thông báo nào" />
      <div className="mt-4">
        <p className="font-semibold text-primary-gray">Bạn không có thông báo nào</p>
      </div>
    </center>
  );
};

export default EmptyNotification;
