import { SubscriptionClient } from 'graphql-subscriptions-client';
import { getAuthToken } from '../../Ultis/token';
import env from '../../configs/configs';

const GRAPHQL_ENDPOINT = env.wsEndPoint;

// set up the client, which can be reused
const wsClient = new SubscriptionClient(GRAPHQL_ENDPOINT, {
  reconnect: true,
  connectionParams: () => {
    console.log('=== connectionParams ===');
    return {
      Authorization: `Bearer ${getAuthToken()}`,
      idToken: `${getAuthToken()}`,
    };
  },
  // lazy: true,
  connectionCallback: (error: any) => {
    error && console.error(error);
  },
});

export default wsClient;
