import { FC, memo } from 'react';
import { IoIosArrowBack } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import { Media } from '../../graphql/generated';
import { images } from '../../assets/images';
interface Props {
  title: string;
  actionName?: any;
  isAbsolute?: boolean;
  action?: () => void;
}
const HeaderExtra: FC<Props> = ({ title, actionName, isAbsolute, action }) => {
  const navigate = useNavigate();
  return (
    <div
      className={` ${
        isAbsolute ? 'relative' : 'absolute'
      } top-0 h-16 w-full flex justify-between items-center z-40 px-3`}>
      <IoIosArrowBack
        size="35px"
        className={`${isAbsolute ? 'text-gray-400' : 'text-white'}`}
        onClick={() => {
          navigate(-1);
        }}
      />
      <div className="flex w-full justify-center mr-6">
        <p
          className={`${
            isAbsolute ? 'text-black' : 'text-white'
          } lg:text-2xl md:text-xl sm:text-xl font-semibold`}>
          {title}
        </p>
      </div>
      {actionName && actionName != '' && (
        <>
          <p
            className="cursor-pointer absolute right-3 text-lg text-[#FFD600] font-semibold"
            onClick={action}>
            {actionName}
          </p>
        </>
      )}
    </div>
  );
};
export default memo(HeaderExtra);
