import { useEffect, useState } from 'react';
import { FaGraduationCap } from 'react-icons/fa';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { getAuthToken, getUserLogin } from '../Ultis/token';
import { images } from '../assets/images';
import { useGetMyFriends } from '../common/services/api';
import ButtonComponent from '../components/button/ButtonComponent';
import { FriendItemType } from '../components/friend-menu/FriendItem';
import FriendItem from '../pages/FriendListMenu/components/FriendItem';
import { useGetMyPendingFriends } from '../pages/FriendListMenu/services/api';
import Header from './Header';
import MenuComponents from './MenuComponents';
import './index.scss';
import FeatureItem from '../components/feature/FeatureItem';
import bgAvatar from '../assets/images/bg-avatar.png';

interface Props {}

interface FeatureItem {
  name: string;
  image: string;
  urlTo: string;
}

const listFeature: FeatureItem[] = [
  {
    image: images.Book,
    name: 'Trang tin',
    urlTo: '/#',
  },
  {
    image: images.Friend,
    name: 'Bạn bè',
    urlTo: '/friends',
  },
  {
    image: images.IconMessageActive,
    name: 'Tin nhắn',
    urlTo: '/messenger',
  },
  {
    image: images.IconMediaActive,
    name: 'Video',
    urlTo: '/video',
  },
  {
    image: images.Bookmark,
    name: 'Đã lưu',
    urlTo: '/#',
  },
  {
    image: images.Flag,
    name: 'Trang',
    urlTo: '/profile/pages',
  },
];

const ContainerLayout = (props: Props) => {
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const navigate = useNavigate();
  const token = getAuthToken();
  const me = getUserLogin();

  const { pathname } = useLocation();
  const url = pathname.split('/')[1].toLocaleLowerCase();

  const myFriendsData = useGetMyFriends({
    limit: 3000,
  });

  const myPendingFriendsData = useGetMyPendingFriends({
    limit: 3,
  });

  const handleScroll = () => {
    const currentScrollPos = window.scrollY;

    if (currentScrollPos > prevScrollPos) {
      setVisible(false);
    } else {
      setVisible(true);
    }

    setPrevScrollPos(currentScrollPos);
  };

  const handleViewAll = () => {
    navigate('/friends');
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  });
  return (
    <div className="bg-third-gray min-h-screen">
      <div className=" bg-third-gray !h-full ">
        <div
          className={`sticky top-[-2px] main-header z-50 ${
            !visible ? 'nav-hide' : 'nav-appear'
          }`}>
          <Header />
          <MenuComponents />
        </div>
        <div
          className={`container-content flex  px-4 ${
            url !== 'menu' && url !== 'messenger' && url !== 'friends'
              ? 'justify-between'
              : 'justify-center'
          }`}>
          {url !== 'menu' && url !== 'messenger' && url !== 'friends' && (
            <div className="container-content-left w-[300px] mt-2">
              <div
                className="flex gap-2 items-center pt-4"
                onClick={() => {
                  navigate('/profile');
                }}>
                <div className="h-[40px] w-[40px] bg-white rounded-full shadow_verify">
                  <div className="img-1-1">
                    <img src={me?.avatar?.uri || images.AvatarDefault} />
                  </div>
                </div>
                <h3 className="font-semibold text-base capitalize">
                  {me?.fullName}
                </h3>
              </div>
              <div className="cursor-pointer mt-3">
                {(listFeature || [])?.map((item, index) => {
                  return (
                    <FeatureItem
                      image={item?.image}
                      name={item?.name}
                      urlTo={item?.urlTo}
                      key={index}
                    />
                  );
                })}
              </div>
              <hr className="my-4" />
            </div>
          )}

          <div className="container mt-2">
            <Outlet />
          </div>

          {url !== 'menu' && url !== 'messenger' && url !== 'friends' && (
            <div className="container-content-right w-[300px] mt-2">
              <h3 className="font-semibold text-base pb-3">Lời mời kết bạn</h3>
              {!!myPendingFriendsData && myPendingFriendsData?.length > 0 ? (
                <>
                  {!!myPendingFriendsData &&
                    (myPendingFriendsData || []).map((item) => {
                      return (
                        <FriendItem
                          key={item?.user?._id}
                          friendItem={item}
                          type={FriendItemType.MyInvite}
                        />
                      );
                    })}
                  <ButtonComponent
                    content="Xem tất cả"
                    hover="hover:bg-gray-300"
                    text_size="text-sm"
                    padding="py-3 px-3"
                    size="w-full"
                    margin=""
                    type="bg-gray-200"
                    textColor="text-black"
                    onPress={handleViewAll}
                  />{' '}
                </>
              ) : (
                <h3 className="text-center">Không có lời mời kết bạn nào </h3>
              )}

              <hr className="my-4" />
              <h3 className="font-semibold text-base pb-3">Bạn bè</h3>
              {!!myFriendsData &&
              (myFriendsData?.data?.getFriends || []).length > 0 ? (
                (myFriendsData?.data?.getFriends || []).map((item) => {
                  return <FriendItem friendItem={item} key={item?._id} />;
                })
              ) : (
                <h3>Bạn chưa có bạn bè nào!</h3>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContainerLayout;
