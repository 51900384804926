import { Notify } from './textNotification';
import { Notification } from '../../../graphql/generated';

interface TypeAddedCommentToPostProps {
  item: Notification;
  isCreater: boolean;
  isPostToPage: boolean;
  isCheckPostYourSelf: boolean;
  isCheckUsersAction: any;
  isProduct: boolean;
}

const TypeAddedCommentToPost = (props: TypeAddedCommentToPostProps) => {
  const { item, isCreater, isPostToPage, isCheckPostYourSelf, isCheckUsersAction, isProduct } = props;

  const txtPostOrProductPage = isProduct ? ` ${Notify.comment_post_page_product} ` : ` ${Notify.comment_post_page} `;

  const lableAddCommentPageOrPost = isPostToPage ? txtPostOrProductPage : `${Notify.friend_comment_post}`;

  const valueAddCommentPageOrPost = isPostToPage
    ? `${(item.toPage && item.toPage.name) || ''}`
    : `${(item.forPost && item.forPost.creator && item.forPost.creator.fullName) || ''}`;

  return (
    <span>
      {isCheckUsersAction}
      {isCreater ? lableAddCommentPageOrPost : `${Notify.comment_post}`}
      {!isPostToPage && !isCreater && !isCheckPostYourSelf && ' '}
      {!isPostToPage && !isCreater && isCheckPostYourSelf && ` ${Notify.they}.`}
      <span className="font-semibold">{!isCheckPostYourSelf && (!isCreater || isPostToPage) && `${valueAddCommentPageOrPost}.`}</span>
    </span>
  );
};

export default TypeAddedCommentToPost;
