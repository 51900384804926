import React, { FC, useEffect, useRef, useState } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { HiOutlineArrowSmLeft } from 'react-icons/hi';
import TagFriendSelection from './TagFriendSelection';
import { User } from '../../graphql/generated';

interface Props {
  hidden: boolean;
  defaultValue?: any;
  isCloseModal?: number;
  handleClickBack: () => void;
  handleChangeFriendTaggeds: (users: any) => void;
}

const TagFriend: FC<Props> = ({
  hidden,
  defaultValue,
  isCloseModal,
  handleClickBack,
  handleChangeFriendTaggeds,
}) => {
  const [query, setQuery] = useState('');
  const inputSearchFriendRef = useRef<HTMLInputElement>(null);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value.trim());
  };

  useEffect(() => {
    return () => {
      setQuery('');
      inputSearchFriendRef.current && (inputSearchFriendRef.current.value = '');
    };
  }, [hidden]);

  return (
    <div className="w-full overflow-hidden" hidden={hidden}>
      <div className="form-createPost_header relative h-[60px] ">
        <h2 className="leading-[60px] text-xl font-bold text-center">
          <span>{'Tag bạn bè'}</span>
        </h2>
        <div
          onClick={handleClickBack}
          className="w-[30px] h-[30px] absolute top-[30%] left-[2%] rounded-full bg-slate-100 cursor-pointer hover:bg-slate-200">
          <HiOutlineArrowSmLeft size={'30px'} className="" />
        </div>
      </div>
      <div className="p-4 pb-2 flex">
        <label
          htmlFor="tag-friend_input"
          className="flex h-[36px] rounded-full w-full bg-slate-100 px-4">
          <span className="mt-[9px]">
            <AiOutlineSearch size={'18px'} />
          </span>
          <input
            onChange={handleSearchChange}
            ref={inputSearchFriendRef}
            type="text"
            className="ml-1 bg-slate-100 w-full outline-none"
            id="tag-friend_input"
            placeholder="Tìm kiếm bạn bè"
          />
        </label>
        <div className="" onClick={handleClickBack} role="button">
          <span className="ml-4 leading-[36px] text-primary-yellow">Done</span>
        </div>
      </div>
      <div className="">
        <TagFriendSelection
          hidden={hidden}
          handleChangeFriendTaggeds={handleChangeFriendTaggeds}
          query={query}
          defaultValue={defaultValue || []}
          isCloseModal={isCloseModal}
        />
      </div>
    </div>
  );
};

export default TagFriend;
