export interface ENV {
  apiEndPoint: string;
  wsEndPoint: string;
  assetsEndPoint: string;
  pageTitle: string;
  tokenKey: string;
  isDevMode: boolean;
  apiUrl: string;
  rootDomain: string;
  apiUrlDownloadTemplate: string;
  uploadEndPoint: string;
}

const env: ENV = {
  apiEndPoint:
    process.env.REACT_APP_API_END_POINT_SUB_GRAPH ||
    process.env.REACT_APP_API_END_POINT ||
    '',
  wsEndPoint: process.env.REACT_APP_API_WS || '',
  assetsEndPoint: process.env.REACT_APP_ASSETS_END_POINT || '',
  apiUrl: process.env.REACT_APP_API_URL || '',
  pageTitle: process.env.REACT_APP_PAGE_TITLE || '',
  tokenKey: process.env.REACT_APP_TOKEN_KEY || '',
  isDevMode: !process.env.NODE_ENV || process.env.NODE_ENV === 'development',
  rootDomain: process.env.REACT_APP_ROOT_DOMAIN || '',
  apiUrlDownloadTemplate:
    process.env.REACT_APP_LINK_DOWNLOAD_TEMPLATE_IMPORT || '',
  uploadEndPoint: process.env.API_UPLOAD_VIDEO_V1 || '',
};

export default env;
