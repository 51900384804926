import { useEffect, useRef, useState } from 'react';
import { BiPlus } from 'react-icons/bi';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { queryClient } from '../../..';
import { getUserLogin } from '../../../Ultis/token';
import { images } from '../../../assets/images';
import CreatePost from '../../../components/create-post/CreatePost';
import Loading, { LoadingRef } from '../../../components/loading/Loading';
import NotifyModal from '../../../components/modal/NotifyModal';
import ListImage from '../../../components/page/ListImage';
import PostItem from '../../../components/posts/Post';
import { ToastProps, ToastType } from '../../../components/toast/Toast';
import { GroupPrivacy } from '../../../graphql/generated';
import {
  useGetGroup,
  useGetGroupMedia,
  useGetGroupMembers,
  useGetGroupPosts,
} from '../services/api';
import ModalListFriendNotInGroup, {
  ModalListFriendRefNotInGroup,
} from '../../../components/modal/ModalListFriendNotInGroup';

interface Props {}

export function countVoteString(voteString: string) {
  voteString = voteString.trim();
  const arrayVote = voteString.split(' ');
  if (!!voteString) {
    return arrayVote.length;
  } else {
    return 0;
  }
}

const DetailGroup = (props: Props) => {
  const { pageId, groupId } = useParams();
  const navigate = useNavigate();
  const [filter, setFilter] = useState({
    limit: 5,
    id: groupId || '',
    after: '',
  });
  const [isShowModalNotify, setIsShowModalNotify] = useState(false);
  const [posts, setPosts] = useState<any[]>([]);
  const [next, setNext] = useState(true);
  const [toast, setToast] = useState<ToastProps>({
    className: 'fixed top-0 right-0',
    isHidden: true,
    type: ToastType.Success,
    message: '',
  });

  const loadingRef = useRef<LoadingRef>(null);
  const modalListFriendRef = useRef<ModalListFriendRefNotInGroup>(null);
  const user = getUserLogin();
  const { data: group, isLoading } = useGetGroup({
    id: groupId || '',
  });
  const { data: mediaData } = useGetGroupMedia({ id: groupId || '', limit: 5 });
  const { data: post } = useGetGroupPosts({
    groupId: filter.id,
    limit: filter.limit,
    after: filter.after,
  });

  const { data: groupMembers } = useGetGroupMembers({
    groupId: groupId || '',
    limit: 20,
  });

  useEffect(() => {
    let resetToast: any;
    if (!toast.isHidden) {
      resetToast = setTimeout(() => {
        setToast((preState) => {
          return { ...preState, isHidden: true };
        });
      }, 2000);
    }
    return () => {
      clearTimeout(resetToast);
    };
  }, [toast]);

  useEffect(() => {
    if (post?.getGroupPosts) {
      setPosts(post?.getGroupPosts || []);
    }
  }, [post?.getGroupPosts, post?.getGroupPosts?.length]);

  useEffect(() => {
    queryClient.invalidateQueries(['getPage']);
    window.scrollTo(0, 0);
  }, [pageId]);

  const fetchData = () => {
    setFilter({ ...filter, limit: 999 });
    setNext(false);
  };
  const refresh = () => {};

  useEffect(() => {
    if (isLoading) {
      loadingRef.current?.open();
    } else {
      loadingRef.current?.close();
    }
  }, [isLoading]);

  const handleOnPostCreate = (post: any) => {
    setPosts([post, ...posts]);
  };

  const isOwner = group?.getGroup?.owner?._id === user._id;

  const handleCloseModalNotify = () => {
    setIsShowModalNotify(false);
  };

  const renderPrivacy = (item: GroupPrivacy) => {
    switch (item) {
      case GroupPrivacy.Close:
        return 'Nhóm đã đóng';
      case GroupPrivacy.Private:
        return 'Nhóm riêng tư';
      case GroupPrivacy.Public:
        return 'Nhóm công khai';
      default:
        break;
    }
  };

  const renderListMember = (item: any) => {
    return (
      <>
        <div className="flex items-center relative w-fit gap-1 ">
          {item?.slice(0, 4).map((item: any) => {
            return (
              <div className="h-10 w-10">
                <div className="img-1-1">
                  <img
                    className="w-[40px] h-[40px] object-cover"
                    src={item?.user?.avatar?.thumbnail || images.AvatarDefault}
                  />
                </div>
              </div>
            );
          })}
          {item?.length - 4 > 0 && (
            <>
              <div className="h-10 w-10 bg-slate-800 opacity-[0.5] rounded-full absolute right-0 top-0 z-10"></div>
              <div className="h-10 w-10 rounded-full absolute right-0 top-0 z-20 text-white flex justify-center items-center">
                {`+${item?.length - 4}`}
              </div>
            </>
          )}
        </div>
      </>
    );
  };

  const handleOpenModal = () => {
    modalListFriendRef.current?.open();
  };

  return (
    <>
      <ModalListFriendNotInGroup ref={modalListFriendRef} id={groupId || ''} />
      <div className="fixed top-[15%] left-[47%] z-50">
        <Loading ref={loadingRef} />
      </div>
      <div
        className="w-[40px] h-[40px] cursor-pointer absolute left-4 top-4  bg-slate-950 flex items-center justify-center rounded-full p-3 opacity-50"
        onClick={() => navigate(-1)}></div>
      <div
        className="w-[40px] h-[40px] cursor-pointer absolute left-4 top-4  flex items-center justify-center z-40"
        onClick={() => navigate(-1)}>
        <img src={images.LeftWhite} className="w-[20px] h-[20px]" />
      </div>
      <Link to={`/groups/setting/${groupId}`} state={window.location.href}>
        <div className="w-[40px] h-[40px] cursor-pointer absolute right-4 top-4  bg-slate-950 flex items-center justify-center rounded-full p-3 opacity-50"></div>
        <div className="w-[40px] h-[40px] cursor-pointer absolute right-4 top-4  flex items-center justify-center  z-40">
          <img src={images.DotDotDot} className="w-[20px] h-[20px]" />
        </div>
      </Link>
      <div className="info-group p-2 mt-6 pt-5 bg-white">
        <h1 className="text-2xl font-semibold text-break-word">
          {group?.getGroup?.name}
        </h1>
        <div className="flex items-center gap-2 pt-2 pb-3">
          <span className="text-slate-500 text-sm">
            {renderPrivacy(group?.getGroup?.privacy || GroupPrivacy.Public)}
          </span>
          <div className="h-1 w-1 bg-slate-500 rounded-full flex items-center justify-center"></div>
          <span className="text-slate-500 text-sm">
            {group?.getGroup?.totalMembers} thành viên
          </span>
        </div>
        <div className="flex items-center pb-4 gap-2">
          {renderListMember(groupMembers?.getGroupMembers)}
          <button
            className="bg-primary-yellow text-white rounded-full pl-2 pr-3 py-2 flex items-center"
            onClick={handleOpenModal}>
            <BiPlus className="mr-1"></BiPlus>
            Mời bạn bè
          </button>
        </div>
      </div>
      <div className=" bg-white p-2  rounded-lg mt-2  pb-3">
        <div className="flex justify-between items-center">
          <h2 className="text-2xl font-semibold">Về nhóm</h2>
          {isOwner && !group?.getGroup?.description && (
            <h3 className="text-base text-primary-yellow">Thêm thông tin</h3>
          )}
        </div>
        {group?.getGroup?.description ? (
          <div>{group?.getGroup?.description}</div>
        ) : (
          <h2 className="py-4 text-center">
            Tạm thời chưa có thông tin về nhóm.
          </h2>
        )}
      </div>
      <div className="bg-white p-2 rounded-lg mt-2">
        <ListImage media={mediaData?.getGroupMediaList || []} />
      </div>
      {(group?.getGroup?.joined || isOwner) && (
        <div className="">
          <CreatePost onPostCreated={handleOnPostCreate} />
        </div>
      )}
      {posts?.length || 0 ? (
        <InfiniteScroll
          dataLength={posts?.length || 0} //This is important field to render the next data
          next={fetchData}
          hasMore={next}
          loader={<h4>Loading...</h4>}
          endMessage={<p style={{ textAlign: 'center' }}></p>}
          // below props only if you need pull down functionality
          refreshFunction={refresh}
          pullDownToRefresh
          pullDownToRefreshThreshold={50}>
          {posts?.map((item) => (
            <div className="mb-4">
              <PostItem post={item} />
            </div>
          ))}
        </InfiniteScroll>
      ) : (
        <h2 className="text-center font-semibold pt-6">
          Hiện tại chưa có bài viết nào.
        </h2>
      )}

      {isShowModalNotify && (
        <NotifyModal
          isShow={isShowModalNotify}
          message="Gửi đánh giá thành công"
          onClose={handleCloseModalNotify}
        />
      )}
    </>
  );
};

export default DetailGroup;
