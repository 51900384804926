import { FC } from 'react';
import VoteStar from '../../assets/images/rating.png';

interface Props {
  isShow: boolean;
  address: string;
  onCancel: () => void;
  onOk: () => void;
}
const ConfirmVoteAddressModal: FC<Props> = ({
  address,
  onCancel,
  onOk,
  isShow,
}) => {
  return (
    <div
      className={`${
        isShow ? '' : 'hidden'
      } fixed top-0 bg-[#00000075] left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full justify-center items-center flex`}>
      <div className="relative w-full max-w-md max-h-full">
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
          {/* Modal body */}

          <div className="p-4  text-center ">
            <div className="w-full flex justify-center">
              <img src={VoteStar} alt="vote" />
            </div>
            <h3 className="text-lg font-semibold pt-3 pb-1">
              Đánh giá địa điểm
            </h3>
            <div className="text-base leading-relaxed pb-8">
              <span>
                Bạn có muốn đánh giá địa điểm{' '}
                <span className="font-semibold">{`${address}`}</span> không?
              </span>
            </div>
            <div className="flex justify-center gap-4">
              <button
                onClick={onCancel}
                type="button"
                className="bg-slate-200 text-black min-w-[100px]  focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center ">
                Không
              </button>
              <button
                onClick={onOk}
                type="button"
                className="text-white min-w-[100px] bg-primary-yellow focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                Có
              </button>
            </div>
          </div>
          {/* Modal footer */}
        </div>
      </div>
    </div>
  );
};

export default ConfirmVoteAddressModal;
