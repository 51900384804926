import { queryClient } from '../..';
import {
  GetListTaggedUserPostQueryVariables,
  GetMyFriendIdsQueryVariables,
  GetMyFriendsQueryVariables,
  GetSummaryUserReactionsQueryVariables,
  GetUserReactionsQueryVariables,
  useCreatePageReviewMutation,
  useCreatePostMutation,
  useCreateSharePostMutation,
  useDeletePostMutation,
  useFollowUserMutation,
  useGetListTaggedUserPostQuery,
  useGetMyFriendIdsQuery,
  useGetMyFriendsQuery,
  useGetSummaryUserReactionsQuery,
  useGetUserReactionsQuery,
  usePinPostMutation,
  useRemovePinPostMutation,
  useSavePostMutation,
  useUnfollowUserMutation,
  useUpdatePageMutation,
  useUpdatePostMutation,
} from '../../graphql/generated';
import { graphqlClientRequest } from '../../graphql/services/graphql-client';

export const useGetSummaryReactions = (
  input: GetSummaryUserReactionsQueryVariables,
) => {
  const { data } = useGetSummaryUserReactionsQuery(
    graphqlClientRequest(true),
    input,
    {
      onSuccess(data) {},
      onError(error: any) {
        //   Notification.Error({ message: getMessageError(error) });
      },
      retry: false,
    },
  );
  return data?.getReactions;
};

export const useGetUserReactions = (input: GetUserReactionsQueryVariables) => {
  const { status, isSuccess, data, error, isLoading } =
    useGetUserReactionsQuery(graphqlClientRequest(true), input, {
      onSuccess(data) {},
      cacheTime: 0,
      onError(error: any) {
        //   Notification.Error({ message: getMessageError(error) });
      },
      retry: false,
    });
  return { status, isSuccess, data, error, isLoading };
};

export const useGetMyFriendIds = (input: GetMyFriendIdsQueryVariables) => {
  const { status, isSuccess, data, error, isLoading, refetch } =
    useGetMyFriendIdsQuery(graphqlClientRequest(true), input, {
      onSuccess(data) {},
      onError(error: any) {
        //   Notification.Error({ message: getMessageError(error) });
      },
      retry: false,
    });
  return { status, isSuccess, data, error, isLoading, refetch };
};

export const useCreatePost = () => {
  const { data, isLoading, isError, mutate, isSuccess } = useCreatePostMutation(
    graphqlClientRequest(true),
    {
      onSuccess(data) {
        queryClient.invalidateQueries(['getHomeFeeds']);
        queryClient.invalidateQueries(['getUserTimelinePost']);
        setTimeout(() => {
          queryClient.invalidateQueries(['getPagePosts']);
        }, 1000);
      },
      onError(error, variables, context) {
        console.log(error);
      },
      retry: false,
    },
  );

  return { data, isLoading, isError, mutate, isSuccess };
};

export const useCreateSharePost = () => {
  const { data, isLoading, isError, mutate, isSuccess } =
    useCreateSharePostMutation(graphqlClientRequest(true), {
      onSuccess(data) {
        queryClient.invalidateQueries(['getHomeFeeds']);
        queryClient.invalidateQueries(['getUserTimelinePost']);
      },
      onError(error, variables, context) {
        console.log(error);
      },
      retry: false,
    });

  return { data, isLoading, isError, mutate, isSuccess };
};

export const useUpdatePost = () => {
  const { data, isLoading, isError, mutate, isSuccess } = useUpdatePostMutation(
    graphqlClientRequest(true),
    {
      onSuccess(data) {
        setTimeout(() => {
          queryClient.invalidateQueries(['getHomeFeeds']);
          queryClient.invalidateQueries(['getUserTimelinePost']);
          queryClient.invalidateQueries(['getPagePosts']);
        }, 2000);
      },
      onError(error, variables, context) {
        console.log(error);
      },
      retry: false,
    },
  );

  return { data, isLoading, isError, mutate, isSuccess };
};

export const useDeletePost = () => {
  const { data, isLoading, isError, mutate, isSuccess } = useDeletePostMutation(
    graphqlClientRequest(true),
    {
      onSuccess(data) {
        queryClient.invalidateQueries(['getHomeFeeds']);
        queryClient.invalidateQueries(['getUserTimelinePost']);
        queryClient.invalidateQueries(['getPagePosts']);
        queryClient.invalidateQueries(['getUser']);
      },
      onError(error, variables, context) {
        console.log(error);
      },
      retry: false,
    },
  );

  return { data, isLoading, isError, mutate, isSuccess };
};

export const useSavePost = () => {
  const { data, isLoading, isError, mutate, isSuccess } = useSavePostMutation(
    graphqlClientRequest(true),
    {
      onSuccess(data) {
        window.location.reload();
        queryClient.invalidateQueries(['getHomeFeeds']);
      },
      onError(error, variables, context) {
        console.log(error);
      },
      retry: false,
    },
  );

  return { data, isLoading, isError, mutate, isSuccess };
};

export const useUpdatePage = () => {
  const { data, isLoading, isError, mutate, isSuccess } = useUpdatePageMutation(
    graphqlClientRequest(true),
    {
      onSuccess(data) {
        queryClient.invalidateQueries(['getPage']);
      },
      onError(error, variables, context) {
        console.log(error);
      },
      retry: false,
    },
  );

  return { data, isLoading, isError, mutate, isSuccess };
};

export const useCreatePageReview = () => {
  const { data, isLoading, isError, mutate, isSuccess, mutateAsync } =
    useCreatePageReviewMutation(graphqlClientRequest(true), {
      onSuccess(data) {
        queryClient.invalidateQueries(['getHomeFeeds']);
      },
      onError(error, variables, context) {
        console.log(error);
      },
      retry: false,
    });

  return { data, isLoading, isError, mutate, isSuccess, mutateAsync };
};

export const usePinPost = () => {
  const { data, isLoading, mutate, isError, isSuccess } = usePinPostMutation(
    graphqlClientRequest(true),
    {
      onSuccess() {
        queryClient.invalidateQueries(['getPagePosts']);
      },
      onError() {},
      retry: false,
    },
  );
  return { data, isLoading, mutate, isError, isSuccess };
};

export const useRemovePinPost = () => {
  const { data, isLoading, mutate, isError, isSuccess } =
    useRemovePinPostMutation(graphqlClientRequest(true), {
      onSuccess() {
        queryClient.invalidateQueries(['']);
      },
      onError() {},
      retry: false,
    });
  return { data, isLoading, mutate, isError, isSuccess };
};

export const useFollowUser = () => {
  const { data, isLoading, isError, mutate, isSuccess } = useFollowUserMutation(
    graphqlClientRequest(true),
    {
      onSuccess(data) {
        queryClient.invalidateQueries(['getHomeFeeds']);
      },
      onError(error, variables, context) {
        console.log(error);
      },
      retry: false,
    },
  );

  return { data, isLoading, isError, mutate, isSuccess };
};

export const useUnFollowUser = () => {
  const { data, isLoading, isError, mutate, isSuccess } =
    useUnfollowUserMutation(graphqlClientRequest(true), {
      onSuccess(data) {
        queryClient.invalidateQueries(['getHomeFeeds']);
      },
      onError(error, variables, context) {
        console.log(error);
      },
      retry: false,
    });

  return { data, isLoading, isError, mutate, isSuccess };
};

export const useGetMyFriends = (input: GetMyFriendsQueryVariables) => {
  const { status, isSuccess, data, error, isLoading } = useGetMyFriendsQuery(
    graphqlClientRequest(true),
    input,
    {
      onSuccess(data) {},
      onError(error: any) {
        //   Notification.Error({ message: getMessageError(error) });
      },
      retry: false,
    },
  );
  return { status, isSuccess, data, error, isLoading };
};

export const useListTaggedUserPost = (
  input: GetListTaggedUserPostQueryVariables,
) => {
  const { status, isSuccess, data, error, isLoading } =
    useGetListTaggedUserPostQuery(graphqlClientRequest(true), input, {
      onSuccess(data) {},
      onError(error: any) {
        //   Notification.Error({ message: getMessageError(error) });
      },
      retry: false,
    });
  return { status, isSuccess, data, error, isLoading };
};
