import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { IoIosArrowBack } from 'react-icons/io';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { FormatDate } from '../../../Ultis/Datetime';
import { getUserLogin } from '../../../Ultis/token';
import { images } from '../../../assets/images';
import ModalChat, { ModalChatRef } from '../../../components/modal/ModalChat';
import ModalConfirmLeave, {
  ModalConfirmLeaveRef,
} from '../../../components/modal/ModalConfirmLeave';
import ModalListFriend, {
  ModalListFriendRef,
} from '../../../components/modal/ModalListFriend';
import ModalShowMember, {
  ModalShowMemberRef,
} from '../../../components/modal/ModalShowMember';
import {
  Conversation,
  Media,
  Message,
  useGetConversationQuery,
  useGetDetailChatQuery,
  useGetPageConversationQuery,
  useUpdateSeenConversationMutation,
} from '../../../graphql/generated';
import { graphqlClientRequest } from '../../../graphql/services/graphql-client';
import { useGetPage } from '../../Page/services/api';
import InputChat from '../components/InputChat';
import MediaMessage from '../components/MediaMessage';
import { useGetPageDirectConversation } from '../services/api';

const MessengerItem = () => {
  const me = getUserLogin();
  const uid = me._id;
  const max_length = 2;
  const navigate = useNavigate();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const [conversation, setConversation] = useState<Conversation>();
  const messagesEndRef = useRef<any>(null);
  const modalChatRef = useRef<ModalChatRef>(null);
  const modalShowMemberRef = useRef<ModalShowMemberRef>(null);
  const modalShowListFriendRef = useRef<ModalListFriendRef>(null);
  const modalConfirmLeaveRef = useRef<ModalConfirmLeaveRef>(null);
  const [listChatSort, setListChatSort] = useState<Message[]>([]);

  const { data: currentPage } = useGetPage(searchParams.get('pageId') || '');

  //For next task
  // const [newConversation, setNewConversation] = useState<any>();
  // useEffect(() => {
  //   if (!!id && !!searchParams.get('pageId')) {
  //     const subscription = appSubscription(OnMessageCreatedDocument, {
  //       focusConversationId: id,
  //       pageId: searchParams.get('pageId'),
  //     });
  //     const listener = subscription.subscribe({
  //       next({ data }: { data: any }) {
  //         console.log('dât', data?.onMessageCreated);
  //         setNewConversation(data?.onMessageCreated);
  //         // listChatSort.push(data?.onMessageCreated);
  //       },
  //     });
  //     return () => {
  //       return listener.unsubscribe();
  //     };
  //   }
  // }, [id, searchParams.get('pageId')]);

  // useEffect(() => {
  //   if (!!newConversation) {
  //     setListChatSort([...listChatSort, newConversation]);
  //   }
  // }, [newConversation]);

  const [currentPageId, setCurrentPageId] = useState<string>('');
  const { data: conversationData } = useGetConversationQuery(
    graphqlClientRequest(true),
    {
      conversationId: id,
    },
  );

  const { data: conversationMeToPage } = useGetPageDirectConversation({
    pageId: searchParams.get('pageId') || '',
  });

  useEffect(() => {
    if (searchParams.get('pageId')) {
      setCurrentPageId(searchParams.get('pageId') || '');
    }
  }, [searchParams.get('pageId')]);

  const { data: conversationsPageData } = useGetPageConversationQuery(
    graphqlClientRequest(true),
    {
      conversationId: id || '',
      pageId: currentPageId,
    },
    {
      enabled: !!currentPageId,
    },
  );

  const { mutateAsync } = useUpdateSeenConversationMutation(
    graphqlClientRequest(true),
  );

  useEffect(() => {
    id && mutateAsync({ conversationId: id });
  }, []);

  useEffect(() => {
    if (!!currentPageId) {
      if (
        currentPage?.getPage &&
        conversationMeToPage?.getPageDirectConversation &&
        currentPage?.getPage?.owner?._id !== me?._id
      ) {
        setConversation(
          conversationMeToPage?.getPageDirectConversation as Conversation,
        );
      } else if (conversationsPageData?.getPageConversation) {
        setConversation(
          conversationsPageData?.getPageConversation as Conversation,
        );
      }
    } else {
      if (conversationData?.getConversation) {
        setConversation(conversationData?.getConversation as Conversation);
      }
    }
  }, [
    conversationData?.getConversation,
    conversationsPageData?.getPageConversation,
    conversationMeToPage?.getPageDirectConversation,
    currentPageId,
    id,
  ]);

  const { data } = useGetDetailChatQuery(
    graphqlClientRequest(true),
    {
      conversationId: id || '',
      limit: 999,
    },
    {
      cacheTime: 0,
    },
  );

  useEffect(() => {
    if (data?.getConversationMessages) {
      setListChatSort(
        _.orderBy(
          data?.getConversationMessages,
          [(obj) => new Date(obj.createdBy)],
          ['desc'],
        ).reverse() as Message[],
      );
    }
  }, [data]);

  useEffect(() => {
    messagesEndRef?.current?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  const openModalChat = () => {
    modalChatRef.current?.open();
  };

  const openModalShow = () => {
    modalShowMemberRef.current?.open();
  };

  const openModalListFriend = () => {
    modalShowListFriendRef.current?.open();
  };
  const openModalShowConfirm = () => {
    modalConfirmLeaveRef.current?.open();
  };

  const onSendMessage = (message: Message) => {
    setListChatSort((p) => [...p, message]);
  };

  const renderNameConversation = (item: Conversation) => {
    switch (item?.conversationType) {
      case 'SUPPORT': {
        return (
          <div>
            {item.partners?.map((value) => {
              return (
                <div>
                  <p className="text-sm lg:text-xl">{value.fullName}</p>
                </div>
              );
            })}
          </div>
        );
      }
      case 'DIRECT': {
        return (
          <>
            {item.partners?.map((value) => {
              return (
                <div>
                  <p className="text-sm lg:text-xl">{value.fullName}</p>
                </div>
              );
            })}
          </>
        );
      }
      case 'PAGE': {
        if ((item?.users || [])[0]?._id === me?._id) {
          return <>{item.page?.name}</>;
        }
        return <>{(item?.users || [])[0]?.fullName}</>;
      }
    }
    if (item.conversationType === 'GROUP') {
      return <>{item.name}</>;
    }
  };

  const renderAvatar = (item: Conversation) => {
    switch (item.conversationType) {
      case 'SUPPORT': {
        return (
          <div className="h-10 w-10">
            <div className="img-1-1">
              <img
                src={
                  (conversation?.partners &&
                    conversation?.partners.length > 0 &&
                    conversation?.partners[0].avatar?.uri) ||
                  images.AvatarDefault
                }
              />
            </div>
          </div>
        );
      }
      case 'DIRECT': {
        return (
          <>
            {item.partners?.map((value) => {
              return (
                <div className="h-10 w-10">
                  <div className="img-1-1">
                    <img
                      src={
                        value.avatar ? value.avatar.uri : images.AvatarDefault
                      }
                    />
                  </div>
                </div>
              );
            })}
          </>
        );
      }
      case 'PAGE':
        return (
          <div className="h-10 w-10">
            <div className="img-1-1">
              {(item?.users || [])[0]?._id === me?._id ? (
                <img src={item?.page?.logo?.uri || images.AvatarDefault} />
              ) : (
                <img
                  src={
                    (item?.users || [])[0]?.avatar?.uri || images.AvatarDefault
                  }
                />
              )}
            </div>
          </div>
        );
    }
    if (item.conversationType === 'GROUP') {
      return (
        <>
          {item?.avatar || item?.avatar?.uri ? (
            <>
              <div className="h-10 w-10">
                <div className="img-1-1">
                  <img
                    className="w-[40px] h-[40px] object-cover"
                    src={item?.avatar?.uri || images.AvatarDefault}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              {(item?.users || []).length > 3
                ? item.users?.slice(0, max_length).map((item) => {
                    return (
                      <div className="h-10 w-10">
                        <div className="img-1-1">
                          <img
                            className="w-[40px] h-[40px] object-cover"
                            src={item?.avatar?.uri || images.AvatarDefault}
                          />
                        </div>
                      </div>
                    );
                  })
                : item.users?.map((item) => {
                    return (
                      <div className="h-10 w-10">
                        <div className="img-1-1">
                          <img
                            className="w-[40px] h-[40px] object-cover"
                            src={item?.avatar?.uri || images.AvatarDefault}
                          />
                        </div>
                      </div>
                    );
                  })}
            </>
          )}

          {(conversation?.users &&
            conversation.users?.length - (max_length + 1) <= 0) ||
          item?.avatar ? (
            ''
          ) : (
            <div className=" h-10 w-10">
              <div className="overflow-hidden relative pb-[100%] ">
                <div className="bg-secondary-gray h-full w-full rounded-full flex justify-center items-center absolute  -translate-x-2/4 -translate-y-2/4 left-2/4 top-2/4">
                  +
                  <p className="lg:text-xl text-sm">
                    {(conversation?.users?.length || 0) - max_length}
                  </p>
                </div>
              </div>
            </div>
          )}
        </>
      );
    }
  };

  return (
    <>
      <div className="pt-4 flex items-center bg-white border-b-[1px] border-solid">
        <div className=" flex mb-5 lg:-space-x-4 items-center justify-between w-full -space-x-2">
          <div className="flex">
            <div
              className="cursor-pointer lg:h-10 h-10 mr-4 pl-4 justify-center items-center flex"
              onClick={() => navigate('/messenger')}>
              <IoIosArrowBack size={30} className="text-gray-400" />
            </div>

            <div
              className="flex items-center"
              onClick={() => {
                navigate(`/messenger/${id}/sharing-photos`);
              }}>
              {' '}
              {conversation && renderAvatar(conversation)}
              <p className="ml-5 text-black capitalize text-break-word">
                {conversation && renderNameConversation(conversation)}
              </p>
            </div>
          </div>

          <div className="flex items-center justify-between px-5">
            {conversation && conversation.conversationType === 'GROUP' && (
              <div
                className="flex cursor-pointer relative"
                onClick={openModalChat}>
                <img src={images.DotDotDotGray} className="w-[20px] h-[20px]" />
                <ModalChat
                  ref={modalChatRef}
                  onClickAddFriend={openModalListFriend}
                  onClick={openModalShow}
                  onClickLeave={openModalShowConfirm}
                  conversationId={conversation?._id}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-col flex-grow h-full content-text-message bg-white">
        <div className="pt-5  h-full flex flex-col-reverse	overflow-auto">
          <div className="flex justify-center  ">
            {conversation?.users && (
              <ModalShowMember
                ref={modalShowMemberRef}
                info={conversation.users}
              />
            )}
            <ModalConfirmLeave
              conversationId={conversation?._id || ''}
              ref={modalConfirmLeaveRef}
            />
            <ModalListFriend id={id || ''} ref={modalShowListFriendRef} />
          </div>
          <div style={{ animationDelay: `0.8s` }} className="pb-[10px]">
            {listChatSort.map((item) => {
              return (
                <div className="chat_item" key={item?._id}>
                  {item.type === 'CREATE_CONVERSATION' ? (
                    <div className="flex justify-center w-full">
                      <div className="text-xs text-gray-400 text-center">
                        {FormatDate(item.createdAt)}
                        <br />
                        <p className="text-break-word m-0 pl-[20px]">
                          {item.createdBy === uid?.replace(/['"]+/g, '')
                            ? 'Bạn'
                            : item.creator?.fullName}{' '}
                          đã tạo nhóm {conversation?.name}
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div
                      className={`${
                        item.creator?._id === uid?.replace(/['"]+/g, '')
                          ? 'justify-end pr-3'
                          : 'items-center pl-3'
                      } chat_item_content flex items-center  `}
                      ref={messagesEndRef}>
                      {item.creator?._id !== uid?.replace(/['"]+/g, '') &&
                        !item.type && (
                          <img
                            src={
                              (conversation?.partners &&
                                conversation?.partners.length > 0 &&
                                conversation?.partners[0].avatar?.uri) ||
                              (conversation?.page &&
                                conversation.page?.logo?.uri) ||
                              images.AvatarDefault
                            }
                            className=" rounded-full h-10 lg:h-10"
                          />
                        )}

                      <div className="max-w-[80%] ">
                        {!item.type && item.text && (
                          <div
                            className={`${
                              item.creator?._id === uid?.replace(/['"]+/g, '')
                                ? 'bg-primary-yellow text-white justify-end '
                                : 'bg-secondary-gray text-black '
                            } chat_msg ml-3 py-3 px-2 mt-3 `}>
                            <p className="text-sm lg:text-xl break-all">
                              {item.text}
                            </p>
                          </div>
                        )}
                        {item.medias && (
                          <MediaMessage medias={item.medias as Media[]} />
                        )}
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
        {id && (
          <div className="flex p-4 justify-between border-t-[1px] border-solid border-slate-300">
            <InputChat
              refId={id}
              onSendMessage={onSendMessage}
              asPageId={currentPageId}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default MessengerItem;
