import { AiOutlinePlayCircle } from 'react-icons/ai';
import { Media, MediaType, VideoStatus } from '../../graphql/generated';
import '../photo/photo-item.scss';
import { Slide } from 'yet-another-react-lightbox/*';
import { useEffect, useState } from 'react';
import { tranFormSlider } from '../photo/PhotoAlbum';
import LightboxImage from '../lightbox/LightboxImage';
import LightboxImageAvt from '../lightbox/LightboxImageAvt';

interface Props {
  medias?: Media[];
}

const MediaComment = (props: Props) => {
  const [slider, setSlider] = useState<Slide[]>([]);
  const [idxMedia, setIdxMedia] = useState(-1);
  const { medias } = props;

  useEffect(() => {
    setSlider(tranFormSlider((medias as Media[]) || []));
  }, [medias]);

  const onCloseMedia = () => {
    setIdxMedia(-1);
  };

  const onOpenMedia = (index: number) => {
    if (
      medias &&
      medias[index].type === MediaType.Video &&
      medias[index].videoStatus === VideoStatus.Pending
    )
      return;
    setIdxMedia(index);
  };

  const renderImage = () => {
    return (
      <>
        {medias && medias?.length > 0 && (
          <div
            onClick={() => onOpenMedia(0)}
            className={` cursor-pointer my-3 mr-1 relative flex justify-end w-40 h-40 md:w-48 md:h-48 bg-slate-200 rounded-md`}>
            <img
              src={
                medias[0]?.type === 'PHOTO'
                  ? medias[0].uri
                  : medias[0]?.thumbnail || ''
              }
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.style.display = 'none';
              }}
              className="rounded-md object-cover w-full thumbnail_video object-cover h-40 w-40 md:h-48 md:w-48"
            />
            {medias[0]?.type === 'VIDEO' && <div className="play-btn"></div>}
          </div>
        )}
      </>
    );
  };

  return (
    <>
      {renderImage()}{' '}
      {medias?.length === 1 ? (
        <LightboxImageAvt
          index={idxMedia}
          slides={slider}
          isOpen={idxMedia >= 0}
          onClose={onCloseMedia}
        />
      ) : (
        <LightboxImage
          index={idxMedia}
          slides={slider}
          isOpen={idxMedia >= 0}
          onClose={onCloseMedia}
        />
      )}
    </>
  );
};

export default MediaComment;
