import { FC, useEffect, useState } from 'react';

export enum ToastType {
  Success,
  Danger,
  Warning,
}

export interface ToastProps {
  type: ToastType;
  message: string;
  className: string;
  isUseForModal?: boolean;
  isHidden: boolean;
}

const Toast: FC<ToastProps> = ({
  type,
  message,
  className,
  isHidden,
  isUseForModal,
}) => {
  const [color, setColor] = useState<string>('');
  const [unable, setUnable] = useState<boolean>();

  useEffect(() => {
    setUnable(isHidden);
  }, [isHidden]);

  useEffect(() => {
    switch (type) {
      case ToastType.Success:
        setColor('bg-green-400');
        break;
      case ToastType.Danger:
        setColor('bg-red-400');
        break;
      case ToastType.Warning:
        setColor('bg-yellow-400');
        break;
      default:
        setColor('bg-yellow-400');
    }
  }, [type]);

  const handleUnable = () => {
    setUnable(true);
  };

  return (
    <div
      className={`${
        isUseForModal ? 'fixed top-0 right-0' : 'absolute'
      } items-center w-full max-w-xs text-gray-500 ${color} rounded-lg shadow dark:text-gray-400 dark:bg-gray-800 z-[100] ${className} ${
        unable && 'hidden'
      }`}
      role="alert">
      <div className={`flex bg-white p-4 ml-1 rounded-l-lg`}>
        <div className="ml-3 text-sm font-normal">{message}</div>
        <button
          type="button"
          className="ml-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
          data-dismiss-target="#toast-danger"
          aria-label="Close"
          onClick={handleUnable}>
          <span className="sr-only">Close</span>
          <svg
            aria-hidden="true"
            className="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"></path>
          </svg>
        </button>
      </div>
    </div>
  );
};

export default Toast;
