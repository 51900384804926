import { useState } from 'react';
import {
  AiFillDislike,
  AiFillLike,
  AiOutlineDislike,
  AiOutlineLike,
} from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { DiffDate } from '../../Ultis/Datetime';
import userImg from '../../assets/images/user.jpeg';
import {
  Comment,
  Media,
  ReactToModel,
  ReactionType,
  ReactionTypeInput,
  useCreateReactionMutation,
} from '../../graphql/generated';
import { graphqlClientRequest } from '../../graphql/services/graphql-client';
import ButtonReaction from '../reaction/ButtonReaction';
import ListUserReactionModal from '../reaction/ListUserReaction';
import SummamryUserReaction from '../reaction/SummaryUserReaction';
import CommentAction from '../posts/CommentAction';
import MediaComment from './MediaComment';
import InputCommentUpdate from '../posts/InputCommentUpdate';

interface Props {
  comment: Comment;
  onDelete: (_id: string) => void;
  onUpdateComment: (value: Comment) => void;
}

const CommentChildItem = ({ comment, onDelete, onUpdateComment }: Props) => {
  const [reactiveAtribute, setReactiveAtribute] = useState({
    isLiked: comment.liked,
    isDisliked: comment.disliked,
    totalLikes: comment.totalLikes,
    totalDislikes: comment.totalDislikes,
  });
  const [isEdit, setIsEdit] = useState(false);

  const [isOpenLikeReactionModal, setIsOpenLikeReactionModal] = useState(false);

  const [isOpenDislikeReactionModal, setIsOpenDisLikeReactionModal] =
    useState(false);

  const { mutateAsync } = useCreateReactionMutation(graphqlClientRequest(true));

  const [isShowSummaryUserLikeReaction, setIsShowSummaryUserLikeReaction] =
    useState(false);

  const [
    isShowSummaryUserDisLikeReaction,
    setIsShowSummaryUserDisLikeReaction,
  ] = useState(false);

  const handleOnClickTotalLike = () => {
    if (reactiveAtribute.totalLikes) {
      document.body.classList.add('body_modal');
      setIsOpenLikeReactionModal(true);
    }
  };

  const handleOnClickTotalDisLike = () => {
    if (reactiveAtribute.totalDislikes) {
      document.body.classList.add('body_modal');
      setIsOpenDisLikeReactionModal(true);
    }
  };
  const handleDislike = async () => {
    mutateAsync({
      data: {
        reactionToModel: ReactToModel.Comment,
        reactionToId: comment._id,
        reactionType: ReactionTypeInput.Dislike,
      },
    });
    setReactiveAtribute({
      ...reactiveAtribute,
      isLiked: false,
      isDisliked: true,
      totalLikes: reactiveAtribute.isLiked
        ? (reactiveAtribute.totalLikes ?? 0) - 1
        : reactiveAtribute.totalLikes,
      totalDislikes: (reactiveAtribute.totalDislikes ?? 0) + 1,
    });
  };
  const handleUnDislike = async () => {
    mutateAsync({
      data: {
        reactionToModel: ReactToModel.Comment,
        reactionToId: comment._id,
        reactionType: ReactionTypeInput.Undislike,
      },
    });
    setReactiveAtribute({
      ...reactiveAtribute,
      isDisliked: false,
      totalDislikes: (reactiveAtribute.totalDislikes ?? 0) - 1,
    });
  };
  const handleLike = async () => {
    mutateAsync({
      data: {
        reactionToModel: ReactToModel.Comment,
        reactionToId: comment._id,
        reactionType: ReactionTypeInput.Like,
      },
    });
    setReactiveAtribute({
      ...reactiveAtribute,
      isLiked: true,
      isDisliked: false,
      totalLikes: (reactiveAtribute?.totalLikes ?? 0) + 1,
      totalDislikes: reactiveAtribute.isDisliked
        ? (reactiveAtribute.totalDislikes ?? 0) - 1
        : reactiveAtribute.totalDislikes,
    });
  };
  const handleUnLike = async () => {
    mutateAsync({
      data: {
        reactionToModel: ReactToModel.Comment,
        reactionToId: comment._id,
        reactionType: ReactionTypeInput.Unlike,
      },
    });
    setReactiveAtribute({
      ...reactiveAtribute,
      isLiked: false,
      totalLikes: (reactiveAtribute.totalLikes ?? 0) - 1,
    });
  };

  const onOpenEdit = () => {
    setIsEdit(true);
  };

  return (
    <>
      {isEdit ? (
        <>
          <div className="pt-4 pl-6 min-[700px]:pl-16 min-[700px]:pt-6">
            <InputCommentUpdate
              onClose={() => {
                setIsEdit(false);
              }}
              onUpdate={onUpdateComment}
              comment={comment}
              isReply={true}
            />
          </div>
        </>
      ) : (
        <div className="pt-4 pl-6 min-[700px]:pl-16 min-[700px]:pt-6">
          <div className="flex items-start">
              <Link to={`/profile${comment.creator?._id}`}>
              <div
                style={{
                  backgroundImage: `url(${
                    comment.creator?.avatar?.uri || userImg
                  })`,
                }}
                className={`w-[40px] h-[40px] rounded-full profileImage ${
                  comment?.creator?.verified ? 'shadow_verify' : 'avartar-border_default'
                }`}
              />
            </Link>
            <div className="flex items-center">
              <div className="ml-4 text-base rounded-2xl bg-[#f0f2f5] py-3 px-4 min-w-[220px] md:min-w-[260px]">
                <b>
                    <Link to={`/profile${comment.creator?._id}`}>{comment.creator?.fullName}</Link>
                </b>
                <br />
                <span className="text-lg">{comment.content}</span>
                <MediaComment medias={comment.medias as Media[]} />

                <div>
                  <div className="flex justify-start items-center md:text-base text-sm mt-2">
                    <div className="relative mr-4 flex items-center">
                      {reactiveAtribute.isLiked ? (
                        <ButtonReaction
                          children={
                            <AiFillLike
                              className="mr-2 text-primary-yellow"
                              onClick={handleUnLike}
                            />
                          }
                        />
                      ) : (
                        <ButtonReaction
                          children={
                            <AiOutlineLike
                              className="mr-2"
                              onClick={handleLike}
                            />
                          }
                        />
                      )}
                      {isShowSummaryUserLikeReaction && (
                        <SummamryUserReaction
                          filter={{
                            reactionToId: comment._id,
                            reactionToModel: ReactToModel.Comment,
                            reactionType: ReactionType.Like,
                          }}
                          totalReaction={comment.totalLikes ?? 0}
                        />
                      )}
                      <div>
                        <span
                          className="hover:underline cursor-pointer"
                          onClick={handleOnClickTotalLike}
                          onPointerEnter={() => {
                            setIsShowSummaryUserLikeReaction(true);
                          }}
                          onPointerLeave={() => {
                            setIsShowSummaryUserLikeReaction(false);
                          }}>
                          {reactiveAtribute.totalLikes}
                        </span>
                      </div>
                    </div>
                    <div className="relative mr-4 flex items-center">
                      {reactiveAtribute.isDisliked ? (
                        <ButtonReaction
                          children={
                            <AiFillDislike
                              className="mr-2 text-primary-yellow"
                              onClick={handleUnDislike}
                            />
                          }
                        />
                      ) : (
                        <ButtonReaction
                          children={
                            <AiOutlineDislike
                              className="mr-2"
                              onClick={handleDislike}
                            />
                          }
                        />
                      )}
                      {isShowSummaryUserDisLikeReaction && (
                        <SummamryUserReaction
                          filter={{
                            reactionToId: comment._id,
                            reactionToModel: ReactToModel.Comment,
                            reactionType: ReactionType.Dislike,
                          }}
                          totalReaction={comment.totalDislikes ?? 0}
                        />
                      )}
                      <div>
                        <span
                          className="hover:underline cursor-pointer"
                          onClick={handleOnClickTotalDisLike}
                          onPointerEnter={() => {
                            setIsShowSummaryUserDisLikeReaction(true);
                          }}
                          onPointerLeave={() => {
                            setIsShowSummaryUserDisLikeReaction(false);
                          }}>
                          {reactiveAtribute.totalDislikes}
                        </span>
                      </div>
                    </div>
                    {/* <div className="flex mr-4">
                <b className="text-xs">Trả lời</b>
              </div> */}
                    <div className="flex">
                      <p className="text-xs">{DiffDate(comment.createdAt)}</p>
                    </div>
                  </div>
                </div>
                {isOpenLikeReactionModal && (
                  <ListUserReactionModal
                    filter={{
                      data: {
                        reactionToId: comment._id,
                        reactionToModel: ReactToModel.Comment,
                        reactionType: ReactionType.Like,
                      },
                      limit: 10,
                    }}
                    onCloseModal={() => {
                      document.body.classList.remove('body_modal');
                      setIsOpenLikeReactionModal(false);
                    }}
                    totalReaction={comment.totalLikes ?? 0}
                  />
                )}
                {isOpenDislikeReactionModal && (
                  <ListUserReactionModal
                    filter={{
                      data: {
                        reactionToId: comment._id,
                        reactionToModel: ReactToModel.Comment,
                        reactionType: ReactionType.Dislike,
                      },
                      limit: 10,
                    }}
                    onCloseModal={() => {
                      document.body.classList.remove('body_modal');
                      setIsOpenDisLikeReactionModal(false);
                    }}
                    totalReaction={comment.totalDislikes ?? 0}
                  />
                )}
              </div>
              <CommentAction
                comment={comment as Comment}
                onOpenEdit={onOpenEdit}
                onDelete={onDelete}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CommentChildItem;
