import React, { forwardRef } from 'react';
import { images } from '../../assets/images';
import './input.scss';

interface PropsInput {
  forgot?: boolean;
  onChange: (type: string, value: string | number) => void;
  allow?: boolean;
}

const InputComponent = (props: PropsInput, ref: any) => {
  const { forgot, onChange, allow } = props;

  const getPassword =
    (type: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      onChange(value, type);
    };
  return (
    <div className="mt-4 input_container relative flex items-center">
      <img
        src={images.Lock}
        alt="password"
        className="absolute top-[15px] left-4"
      />
      <input
        type="password"
        placeholder="Mật khẩu"
        className={`${
          allow ? 'not_allow' : ''
        } input w-full border-[1px] border-solid border-gray-200 rounded-[16px] h-[50px]`}
        onChange={getPassword('password')}
        ref={ref}
      />
      {forgot && (
        <span className="text-sm absolute right-4 top-[15px] text-primary-yellow">
          Quên mật khẩu?
        </span>
      )}
    </div>
  );
};

export default forwardRef(InputComponent);
