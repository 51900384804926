import { useEffect, useState } from 'react';
import { IoMdClose } from 'react-icons/io';
import {
  useGetMyFriendIds,
  useListTaggedUserPost,
} from '../../common/services/api';
import { FriendEntity } from '../friend-menu/FriendItem';
import LoadingExtra from '../loading/LoadingExtra';
import UserItemReaction from '../reaction/UserItemReaction';
import './index.scss';
interface Props {
  postId: string;
}

const ListTaggedUser = ({ postId }: Props) => {
  const { data: myFriendData } = useGetMyFriendIds({ limit: 5000 });
  const { data: listTaggedUserData, isLoading } = useListTaggedUserPost({
    id: postId,
  });
  const [users, setUsers] = useState<any[]>([]);

  useEffect(() => {
    setUsers([...users, ...(listTaggedUserData?.post?.allTaggedUsers ?? [])]);
  }, [listTaggedUserData]);
  return (
    <>
      <div className="w-[450px]">
        <div className="relative mt-[2rem] h-[10px] border-b-2 border-bg-slate-200">
          <div className="absolute cursor-pointer rounded-full w-[35px] h-[35px] right-0 max-[400px]:right-[4px] top-[-35px] bg-slate-200 hover:bg-slate-300">
            <IoMdClose size={'25px'} className="mt-[5px] ml-[5px]" />
          </div>
        </div>
        {isLoading && (
          <center className='mt-5'>
            <LoadingExtra className="" label="" />
          </center>
        )}
        <div className="flex flex-col gap-4 max-h-[470px] taggedUser-post_list overflow-auto pt-4 pb-4">
          {users.map((user, idx) => {
            return (
              <UserItemReaction
                key={user._id}
                isMe={false}
                isMyFriend={
                  myFriendData?.getFriends
                    ?.map((e) => e._id)
                    .includes(user._id) ?? false
                }
                user={user as FriendEntity}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

export default ListTaggedUser;
