import { useEffect, useState } from 'react';
import { IoMdArrowBack } from 'react-icons/io';
import { Outlet, useNavigate } from 'react-router-dom';

interface Props {}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const DetailPostLayout = (props: Props) => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );
  const navigate = useNavigate();

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className="bg-third-gray min-h-[100vh]">
      <div className="container m-auto relative ">
        <button className="border-0 bg-none" onClick={handleGoBack}>
          <IoMdArrowBack className="absolute text-white text-3xl top-14 left-6 z-50" />
        </button>
        {/* <div
          className={`profileImage h-[100px] !max-w-[${windowDimensions.width}px] bg-primary-yellow`}></div> */}
        <Outlet />
      </div>
    </div>
  );
};

export default DetailPostLayout;
