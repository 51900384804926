import React, { useState, useImperativeHandle, forwardRef } from 'react';
import './index.scss';

export interface LoadingRef {
  open: () => void;
  close: () => void;
}

const Loading = (props: any, ref: any) => {
  const [visible, setVisible] = useState(false);
  const open = () => {
    setVisible(!visible);
  };
  const close = () => {
    setVisible(false);
  };

  useImperativeHandle(ref, () => ({
    open: () => open(),
    close: () => close(),
  }));

  return (
    <>
      {visible && (
        <div className="absolute lds-ripple">
          <div></div>
          <div></div>
        </div>
      )}
    </>
  );
};

export default forwardRef(Loading);
