import { FC, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import FriendItem, { FriendItemType } from '../../../components/friend-menu/FriendItem';
import { GetMyPendingFriendRequestsQueryVariables } from '../../../graphql/generated';
import { useGetMyPendingFriends } from '../services/api';

const LIMIT_PAGE = 20;
interface Props {
  totalItems: number;
}
const ListMyPendingFriendRequest: FC<Props> = ({ totalItems }: Props) => {
  const [isHasMore, setHasMore] = useState(false);
  const [filter, setFiter] = useState<GetMyPendingFriendRequestsQueryVariables>({
    limit: LIMIT_PAGE,
  });
  const myPendingFriendsData = useGetMyPendingFriends(filter);
  const [myPendingFriends, setMyPendingFriends] = useState<any[]>([]);
  useEffect(() => {
    myPendingFriends.length < totalItems && setHasMore(true);
  });
  useEffect(() => {
    setMyPendingFriends([
      ...myPendingFriends,
      ...(myPendingFriendsData?.map((request) => ({
        ...request.user,
        requestedAt: request.createdAt,
      })) || []),
    ]);
  }, [myPendingFriendsData]);
  const fetchData = () => {
    setFiter({
      ...filter,
      after: myPendingFriends[myPendingFriends.length - 1]?._id,
    });
  };
  const refresh = () => {};
  return (
    <InfiniteScroll
      className="p-4 bg-slate-50 grid min-[375px]:grid-cols-1 min-[375px]:gap-5 sm:grid-cols-2 sm:gap-2"
      dataLength={myPendingFriends.length}
      next={fetchData}
      hasMore={isHasMore}
      loader={isHasMore && <center>Loading ... </center>}
      // endMessage={
      //   <p className="mt-5" style={{ textAlign: 'center' }}>
      //     <b>Yay! You have seen it all</b>
      //   </p>
      // }
      endMessage={''}
      refreshFunction={refresh}>
      {myPendingFriends.map((friend, idx) => {
        return <FriendItem key={friend._id + idx} friend={friend} type={FriendItemType.MyInvite} isDisplayTimeRequest={true} />;
      })}
    </InfiniteScroll>
  );
};

export default ListMyPendingFriendRequest;
