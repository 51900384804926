import { ChangeEvent, useRef, useState } from 'react';
import CameraSvg from '../../../../../assets/images/icon/camera.svg';
import { createPreviewMedia } from '../../../../../Ultis/files';
import { Preview } from './SelectAvatar';
import HeaderExtra from '../../../../../components/header/HeaderExtra';
import NotifyModal from '../../../../../components/modal/NotifyModal';

export enum Steps {
  SelectType,
  SetName,
  SelectCategory,
  SelectAvatar,
}

export enum TypeMediaFile {
  Avatar,
  Cover,
}

export interface IPersonalPageMedia {
  file: FileList;
  type: TypeMediaFile;
}

export interface IStepsLayoutParams {
  title: string;
  step: Steps;
  bg: any;
  child: React.ReactNode;
  disableButton?: boolean;
  handleChangeStep: (isStep: Steps) => void;
  handleMedia?: (file: FileList, type: TypeMediaFile) => void;
}

export default function StepsLayout({
  title,
  step,
  bg,
  child,
  disableButton: isDisableButton,
  handleChangeStep,
  handleMedia,
}: IStepsLayoutParams) {
  const refCoverUploadInput = useRef<HTMLInputElement>(null);
  const [preview, setPreview] = useState<Preview[]>([]);
  const [isShowModalNotify, setIsShowModalNotify] = useState(false);

  const handleSelectCover = () => {
    if (step == Steps.SelectAvatar) {
      refCoverUploadInput.current?.click();
    }
  };

  const handleChangeFileInput = (e: any) => {
    const file = e.target.files;

    if (file) {
      if (
        file[0].type === 'image/jpeg' ||
        file[0].type === 'image/png' ||
        file[0].type === 'image/jpg'
      ) {
        const newPreview = createPreviewMedia(file, file.length);
        setPreview(newPreview);
        if (handleMedia) {
          handleMedia(file, TypeMediaFile.Cover);
        }
      } else {
        setIsShowModalNotify(true);
        e.target.value = null;
      }
    }
  };
  const handleCloseModalNotify = () => {
    setIsShowModalNotify(false);
  };

  return (
    <>
      {isShowModalNotify && (
        <NotifyModal
          isShow={isShowModalNotify}
          message="Sai định dạng ảnh, vui lòng thử lại."
          onClose={handleCloseModalNotify}
        />
      )}
      <HeaderExtra title={title} />
      <div className="relative" style={{ height: 'calc(100vh - 100px)' }}>
        <div
          className="relative flex justify-center items-center w-full min-h-[25%] bg-[#80C3F0]/30 rounded-t-lg"
          onClick={handleSelectCover}>
          {step == Steps.SelectAvatar ? (
            <>
              {preview && preview.length > 0 ? (
                <img
                  className="absolute inset-0 object-cover h-full w-full"
                  src={preview[0].uri}
                  alt="preview"
                />
              ) : (
                <>
                  <div>
                    <div className="flex justify-center items-center mb-3">
                      <img
                        src={step == Steps.SelectAvatar ? CameraSvg : bg}
                        alt="banner"
                      />
                    </div>
                    <span className="text-xs text-[#818B95]">Ảnh bìa</span>
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              <img src={bg} alt="banner" />
            </>
          )}
        </div>
        <div className="relative">
          {child}
          <input
            type="file"
            ref={refCoverUploadInput}
            accept="image/png, image/gif, image/jpeg"
            name="cover"
            hidden={true}
            onChange={handleChangeFileInput}
          />
        </div>
        <div className=" w-full px-3 absolute left-0 bottom-0">
          <button
            className="w-full py-3.5 text-white font-semibold bg-[#05A2AB] rounded-md disabled:opacity-50"
            onClick={() => {
              handleChangeStep(step);
            }}
            disabled={isDisableButton}>
            {step == Steps.SelectAvatar ? 'Hoàn thành' : 'Tiếp tục'}
          </button>
        </div>
      </div>
    </>
  );
}
