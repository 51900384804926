import { useEffect, useState } from 'react';
import { AiOutlineEye } from 'react-icons/ai';
import { useNavigate, useParams } from 'react-router-dom';
import gallerySlash from '../../assets/images/icon/gallery-slash.svg';
import {
  Comment,
  CommentFor,
  GetCommentsQueryVariables,
  MediaType,
  PostStatus,
} from '../../graphql/generated';
import { useGetCommentPost } from '../../pages/Home/services/api';
import ButtonGray from '../button/ButtonGray';
import Action from './Action';
import CommentComponent from './Comment';
import HeaderPost from './HeaderPost';
import InputComment from './InputComment';
import ManyImageComponent from './ManyImageComponent';
interface Props {
  post: any;
  onChangePost?: any;
  onPostCreated?: (post: any) => void;
}

export const ITEM_LOAD = 2;

const PostItem = (props: Props) => {
  const { post, onChangePost, onPostCreated } = props;
  const { postId } = useParams();
  const [comments, setComments] = useState<any[]>([]);
  const [totalComment, setTotalComment] = useState<number>(0);
  const [lastComment, setLastComment] = useState<boolean>();
  const [filterComment, setFilterComment] = useState<GetCommentsQueryVariables>(
    {
      limit: ITEM_LOAD,
      refId: post?._id,
      for: CommentFor.Post,
      CLimit: ITEM_LOAD,
      orderAsc: false,
    },
  );

  const { data: commentData, isSuccess } = useGetCommentPost(filterComment);

  useEffect(() => {
    if (isSuccess && commentData && commentData?.getComments.items) {
      setComments(commentData?.getComments.items);
      setTotalComment(commentData.getComments.counter || 0);
    }
  }, [commentData]);

  const navigate = useNavigate();

  const onAddComment = (value: Comment) => {
    setComments((prevItems) => [value, ...prevItems]);
  };

  const onRemove = (_id: string) => {
    setComments((prevItems) => prevItems.filter((obj) => obj._id !== _id));
  };

  const onLoadMoreCmt = () => {
    setFilterComment({
      limit: 999,
      refId: post?._id,
      for: CommentFor.Post,
      CLimit: 999,
      orderAsc: false,
    });
    setLastComment(true);
  };

  const onHideCmt = () => {
    //  setComments(comments.slice(0,2))
    setFilterComment({
      limit: ITEM_LOAD,
      refId: post?._id,
      for: CommentFor.Post,
      CLimit: ITEM_LOAD,
      orderAsc: false,
    });
    setLastComment(false);
  };

  const onUpdateComment = (value: Comment) => {
    setComments(comments.map((obj) => (obj._id === value._id ? value : obj)));
  };

  const renderPost = () => {
    return (
      <div className="mt-4">
        <HeaderPost post={post} onPostCreated={() => onPostCreated} />
        {post.status === PostStatus.Incorrect && !postId && (
          <div className="flex justify-center m-2 mt-4">
            <div className="border rounded-xl p-8">
              <img
                src={gallerySlash}
                alt="gallerySlash"
                className=" m-auto"></img>
              <div className="mt-2 mb-2">
                Bài viết đã bị ẩn đi do chứa thông tin không chính xác.
              </div>
              <div className="flex justify-center">
                <ButtonGray
                  text="Xem bài viết"
                  icon={<AiOutlineEye className="text-black" />}
                  className="w-fit m-auto"
                  onClick={() => {
                    navigate('/detail/' + post._id);
                  }}
                />
              </div>
            </div>
          </div>
        )}
        {(post.status !== PostStatus.Incorrect || !!postId) && (
          <div
            className={`${
              (post?.status === PostStatus.Pending ||
                post?.status === PostStatus.NotVerified ||
                post.status === PostStatus.Incorrect) &&
              'opacity-60'
            }`}>
            <ManyImageComponent
              medias={
                post?.medias?.length
                  ? post.medias
                  : [
                      {
                        uri: post.place?.location.staticMapUri,
                        type: MediaType.Photo,
                      },
                    ]
              }
            />
          </div>
        )}
        <Action handleOpenComment={() => {}} post={post} />
        <div id="comment" className={'block'}>
          {!!comments.length && (
            <>
              <hr className=".solid mt-2" />
              {totalComment > ITEM_LOAD &&
                (lastComment ? (
                  <button
                    className="border-0 bg-none pl-6 mt-4 text-xs md:text-base"
                    onClick={onHideCmt}>
                    Ẩn bớt bình luận
                  </button>
                ) : (
                  <button
                    className="border-0 bg-none min-[700px]:pl-6 pl-4  mt-4 text-xs md:text-base"
                    onClick={onLoadMoreCmt}>
                    Xem thêm bình luận
                  </button>
                ))}
              {comments?.map((item: any) => (
                <CommentComponent
                  key={item._id}
                  onDelete={onRemove}
                  comment={item}
                  onUpdateComment={onUpdateComment}
                />
              ))}
            </>
          )}
        </div>
        <hr className=".solid my-2" />
        <InputComment
          refId={post._id}
          commentFor={CommentFor.Post}
          onAdd={onAddComment}
        />
      </div>
    );
  };

  const renderPostShare = () => {
    return (
      <>
        <HeaderPost post={post} onPostCreated={() => onPostCreated} />

        {!!post?.sharedPost && (
          <div className="m-2">
            <div
              className={` border  ${
                !!post?.sharedPost.medias.length
                  ? 'border-b-0 rounded-t-lg'
                  : 'rounded-lg pb-6'
              }`}>
              <HeaderPost
                onPostCreated={() => onPostCreated}
                post={post?.sharedPost}
                isSharedPost={!!post?.sharedPost}
              />
            </div>
            {!!post?.sharedPost.medias.length && (
              <div
                className={` ${
                  post?.status === PostStatus.Pending && 'opacity-60'
                }`}>
                <ManyImageComponent medias={post?.sharedPost.medias} />
              </div>
            )}
          </div>
        )}

        <Action
          handleOpenComment={() => {}}
          post={post}
          onPostCreated={onPostCreated}
        />
        <div id="comment" className="block">
          {!!comments?.length && (
            <div className="">
              <hr className=".solid mt-2" />
              {totalComment > ITEM_LOAD &&
                (lastComment ? (
                  <button
                    className="border-0 bg-none pl-6 mt-4 text-xs md:text-base"
                    onClick={onHideCmt}>
                    Ẩn bớt bình luận
                  </button>
                ) : (
                  <button
                    className="border-0 bg-none pl-6 mt-4 text-xs md:text-base"
                    onClick={onLoadMoreCmt}>
                    Xem thêm bình luận
                  </button>
                ))}
              {comments.map((item: any) => (
                <CommentComponent
                  key={item._id}
                  comment={item}
                  onDelete={onRemove}
                  onUpdateComment={onUpdateComment}
                />
              ))}
            </div>
          )}
        </div>
        <hr className=".solid" />
        <InputComment
          refId={post._id}
          commentFor={CommentFor.Post}
          onAdd={onAddComment}
        />
      </>
    );
  };

  return (
    <div className={`bg-white w-full pb-2 rounded-lg`}>
      {!!post?.sharedPost ? renderPostShare() : renderPost()}
    </div>
  );
};

export default PostItem;
