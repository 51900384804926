import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { createPreviewMedia } from '../../../Ultis/files';
import CameraSvg from '../../../assets/images/icon/camera.svg';
import ButtonComponent from '../../../components/button/ButtonComponent';
import { Preview } from '../../../parts/ProfileLayout';
import { useUploadPhoto } from '../../DetailPost/services/api';
import {
  IPersonalPageMedia,
  TypeMediaFile,
} from '../../Menu/Page/Create/components/StepsLayout';
import { useGetPersonalCategories } from '../../Menu/Page/Create/services/apis';
import { useCreateGroup } from '../services/api';
import { GroupPrivacy } from '../../../graphql/generated';
import { queryClient } from '../../..';
import { useNavigate } from 'react-router-dom';
import Toast, { ToastProps, ToastType } from '../../../components/toast/Toast';
import { IoIosArrowBack } from 'react-icons/io';
import NotifyModal from '../../../components/modal/NotifyModal';

const _URL = window.URL || window.webkitURL;

const CreateGroupsPage = () => {
  const refAvatarUploadInput = useRef<HTMLInputElement>(null);
  const [preview, setPreview] = useState<Preview[]>([]);
  const { mutateAsync: uploadPhotoAsync } = useUploadPhoto();
  const [mediaFile, setMediaFile] = useState<IPersonalPageMedia[]>();
  const [name, setName] = useState<string>('');
  const [isErrorName, setIsErrorName] = useState<boolean>(false);
  const [isErrorCategory, setIsErrorCategory] = useState<boolean>(false);
  const [categoryId, setCategoryId] = useState<string>('');
  const [currentCoverId, setCurrentCoverId] = useState<string>('');
  const [isShowModalNotify, setIsShowModalNotify] = useState(false);
  const [toast, setToast] = useState<ToastProps>({
    className: 'fixed top-0 right-0',
    isHidden: true,
    type: ToastType.Success,
    message: '',
  });

  const navigate = useNavigate();
  const { data: personalCategories } = useGetPersonalCategories();
  const { mutateAsync } = useCreateGroup();

  useEffect(() => {
    let resetToast: any;
    if (!toast.isHidden) {
      resetToast = setTimeout(() => {
        setToast((preState) => {
          return { ...preState, isHidden: true };
        });
      }, 3000);
    }
    return () => {
      clearTimeout(resetToast);
    };
  }, [toast]);

  const getDimensionOfPhoto = async (file: File) => {
    return new Promise((rs, rj) => {
      const img = new Image();
      const objectUrl = _URL.createObjectURL(file);

      img.onload = () => {
        rs({
          width: img.width,
          height: img.height,
        });
      };
      img.onerror = (e) => {
        rj(e);
      };
      img.src = objectUrl;
    });
  };

  const uploadImage = async (file: File, type: TypeMediaFile) => {
    const imageDimensions: any = await getDimensionOfPhoto(file);
    const uploadPhoto = await uploadPhotoAsync({
      file,
      dimensions: {
        width: imageDimensions.width,
        height: imageDimensions.height,
      },
    });
    return { uploadPhoto, type };
  };

  const handleMedia = (file: FileList, type: TypeMediaFile) => {
    setMediaFile((preState) => {
      if (preState) {
        const mediaFiles = preState.filter((media) => {
          return media.type != type;
        });
        mediaFiles.push({ file: file, type: type });
        return [...mediaFiles];
      }
      return [{ file: file, type: type }];
    });
  };

  const handleCloseModalNotify = () => {
    setIsShowModalNotify(false);
  };

  const handleSelectAvatar = () => {
    refAvatarUploadInput.current?.click();
  };

  const handleChangeInputFile = (e: any) => {
    const file = e.target.files;

    if (file) {
      if (
        file[0].type === 'image/jpeg' ||
        file[0].type === 'image/png' ||
        file[0].type === 'image/jpg'
      ) {
        const newPreview = createPreviewMedia(file, file.length);
        setPreview(newPreview);
        if (handleMedia) {
          handleMedia(file, TypeMediaFile.Avatar);
        }
      } else {
        setIsShowModalNotify(true);
        e.target.value = null;
      }
    }
  };

  useEffect(() => {
    if (!!mediaFile && mediaFile.length > 0) {
      Promise.any(
        mediaFile.map((media) => {
          return uploadImage(media.file[0], media.type);
        }),
      ).then((data) => {
        setCurrentCoverId(data?.uploadPhoto?.uploadPhoto?._id);
      });
    }
  }, [mediaFile]);

  const handleChangeName = (e: any) => {
    setIsErrorName(false);
    setName(e.target.value);
  };

  const handleChangeCategory = (e: any) => {
    setIsErrorCategory(false);
    setCategoryId(e.target.value);
  };

  const handleCreate = () => {
    if (!name.trim() && !!categoryId) {
      setIsErrorName(true);
    } else if (!categoryId && !!name.trim()) {
      setIsErrorCategory(true);
    } else if (!categoryId && !name.trim()) {
      setIsErrorCategory(true);
      setIsErrorName(true);
    }

    if (!!name && !!categoryId) {
      if (currentCoverId) {
        mutateAsync(
          {
            input: {
              categoryId: categoryId,
              name: name,
              privacy: GroupPrivacy.Public,
              coverMediaId: currentCoverId,
            },
          },
          {
            onSuccess(data) {
              queryClient.invalidateQueries(['getMyGroups']);
              navigate(`/groups/${data?.createGroup?._id}`);
            },
          },
        );
      } else {
        mutateAsync(
          {
            input: {
              categoryId: categoryId,
              name: name,
              privacy: GroupPrivacy.Public,
            },
          },
          {
            onSuccess() {
              queryClient.invalidateQueries(['getMyGroups']);
              navigate('/groups');
            },
          },
        );
      }
    }
  };

  return (
    <>
      <Toast
        message={toast.message}
        className={`${toast.className} absolute top-1 right-1`}
        type={toast.type}
        isHidden={toast.isHidden}
      />
      {isShowModalNotify && (
        <NotifyModal
          isShow={isShowModalNotify}
          message="Sai định dạng ảnh, vui lòng thử lại."
          onClose={handleCloseModalNotify}
        />
      )}
      <div className=" bg-white py-2 px-3 relative">
        <IoIosArrowBack
          size="30px"
          className=" absolute text-gray-400 left-2 top-6"
          onClick={() => {
            navigate(-1);
          }}
        />
        <h1 className="text-center font-semibold text-2xl py-3">Tạo nhóm</h1>
        <div className="pb-5">
          <h2 className="pb-2 font-semibold">Ảnh cover</h2>
          <div
            onClick={handleSelectAvatar}
            className="flex justify-center items-center  text-center w-full  bg-[#80C3F0]/30 h-[200px] rounded-[8px] ">
            <div className={preview && preview.length > 0 ? ' h-full' : ''}>
              {preview && preview.length > 0 ? (
                <img
                  className="w-full h-full bg-white "
                  src={preview[0].uri}
                  alt="preview"
                />
              ) : (
                <>
                  <div className="flex justify-center items-center mb-3">
                    <img src={CameraSvg} alt="icon" />
                  </div>
                  <span className="block text-xs text-[#818B95]">
                    Ảnh cover
                  </span>
                </>
              )}

              <input
                type="file"
                name="avatar"
                accept=".jpg, .png, .jpeg"
                ref={refAvatarUploadInput}
                hidden={true}
                onChange={handleChangeInputFile}
              />
            </div>
          </div>
        </div>
        <div className="pb-5 relative">
          <h2 className="pb-2 font-semibold">Tên nhóm</h2>
          <input
            placeholder="Đặt tên nhóm"
            maxLength={50}
            onChange={handleChangeName}
            className={`h-[40px] rounded-[8px] w-full border-[1px] border-solid ${
              isErrorName ? 'border-red-600' : 'border-gray-300'
            }  px-[10px] focus-visible:outline-offset-0`}
          />
          {isErrorName && (
            <p className="absolute bottom-[-3px] left-1 text-red-600">
              Bạn chưa nhập tên nhóm
            </p>
          )}
        </div>
        <div className="pb-5 relative">
          <h2 className="pb-2 font-semibold">Lĩnh vực</h2>
          <select
            name="category"
            id="category"
            defaultValue={'defaultValue'}
            onChange={handleChangeCategory}
            className={`h-[40px] w-full border-[1px] border-solid ${
              isErrorCategory ? 'border-red-600' : 'border-gray-300'
            } px-[10px] rounded-[8px]`}>
            <option value={'defaultValue'} disabled>
              Chọn lĩnh vực
            </option>
            {(personalCategories?.getPageCategories || []).map((item) => {
              return (
                <option value={item?._id} key={item?._id}>
                  {item?.name?.VI_VN}
                </option>
              );
            })}
          </select>
          {isErrorCategory && (
            <p className="absolute bottom-[-3px] left-1 text-red-600">
              Bạn chưa chọn lĩnh vực
            </p>
          )}
        </div>
        <div className="pb-8">
          <ButtonComponent
            content="Tạo nhóm"
            size="w-full"
            onPress={handleCreate}
          />
        </div>
      </div>
    </>
  );
};

export default CreateGroupsPage;
