import { ChangeEvent, FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { EditModal, IEditModal } from '../../../components/modal/EditModal';
import NotifyModal from '../../../components/modal/NotifyModal';
import PhotoAlbumPro from '../../../components/photo/PhotoAlbumPro';
import {
  Conversation,
  ConversationType,
  Media,
  UpdateGroupConversationInput,
  useGetConversationMediaAlbumQuery,
  useGetConversationQuery,
} from '../../../graphql/generated';
import { graphqlClientRequest } from '../../../graphql/services/graphql-client';
import { useUpdateGroupConversation } from '../services/api';
import DirectConversationInfo from './components/DirectConversationInfo';
import GroupConversationInfo from './components/GroupConversationInfo';
import './index.scss';

interface Props {}

const SharingPhotos: FC<Props> = (props: Props) => {
  const { id: conversationId } = useParams();
  const [editModal, setEditModal] = useState<IEditModal>({
    isVisible: false,
    content: '',
  });
  const [updateGroupConversation, setUpdateConversation] =
    useState<UpdateGroupConversationInput>();
  const [notify, setNotify] = useState<{
    message: string;
    isSuccess: boolean;
  }>();

  const { data: conversationData } = useGetConversationQuery(
    graphqlClientRequest(true),
    { conversationId },
  );

  const { data: mediasData } = useGetConversationMediaAlbumQuery(
    graphqlClientRequest(true),
    { conversationId: conversationId ?? '', limit: 999 },
  );

  const {
    isLoading: isLoadingGroupConversation,
    mutate: mutateUpdateGroupConversation,
  } = useUpdateGroupConversation();

  const typeConversation: any =
    conversationData?.getConversation.conversationType;
  const isEmptyMedia = !mediasData?.getConversationMedias.length;

  const handleCancelModal = () => {
    setEditModal({ ...editModal, isVisible: false });
    if (conversationId) {
      setUpdateConversation({ conversationId: conversationId, name: '' });
    }
  };

  const handleChangeInputNameGroupConversation = (
    e: ChangeEvent<HTMLInputElement>,
  ) => {
    if (conversationId) {
      setUpdateConversation({ conversationId, name: e.target.value });
    }
  };

  const handleUpdateGroupConversation = () => {
    if (
      !updateGroupConversation?.name ||
      updateGroupConversation?.name.length < 2
    ) {
      setNotify({ isSuccess: false, message: 'Tên nhóm phải từ 2 ký tự' });
      return;
    }

    if (!isLoadingGroupConversation) {
      mutateUpdateGroupConversation(
        { data: updateGroupConversation },
        {
          onSuccess() {
            if (conversationId) {
              setUpdateConversation({
                conversationId: conversationId,
                name: '',
              });
            }
            setEditModal({ ...editModal, isVisible: false, content: '' });
            setNotify({ isSuccess: true, message: 'Cập nhật thành công' });
          },
          onError() {
            setNotify({ isSuccess: false, message: 'Cập nhật thất bại' });
          },
        },
      );
    }
  };

  const handleOkModal = () => {
    handleUpdateGroupConversation();
  };

  return (
    <>
      <div className="relative bg-white p-4">
        {conversationData && (
          <div className="w-full conversation-info">
            {typeConversation !== ConversationType.Group ? (
              <DirectConversationInfo
                conversation={conversationData?.getConversation as Conversation}
              />
            ) : (
              <GroupConversationInfo
                conversation={conversationData?.getConversation as Conversation}
                showModal={() => {
                  setEditModal({ ...editModal, isVisible: true });
                }}
              />
            )}
            <div className="text-lg font-semibold text-black p-4">
              <h1>Chia sẻ ảnh</h1>
            </div>
            {!isEmptyMedia ? (
              <PhotoAlbumPro
                medias={mediasData?.getConversationMedias as Media[]}
              />
            ) : (
              <center>{'Chưa có ảnh'}</center>
            )}
          </div>
        )}
      </div>
      <EditModal
        content={updateGroupConversation?.name || ''}
        isVisible={editModal.isVisible}
        onCancel={handleCancelModal}
        onOk={handleOkModal}
        handleChangeInput={(e) => handleChangeInputNameGroupConversation(e)}
      />
      <NotifyModal
        isSuccess={notify?.isSuccess}
        isShow={!!notify?.message}
        message={notify?.message || ''}
        onClose={() => {
          if (notify) setNotify({ ...notify, message: '' });
        }}
      />
    </>
  );
};

export default SharingPhotos;
