import { FC } from 'react';
import { Page } from '../../../graphql/generated';
import { useNavigate } from 'react-router-dom';
import userImg from '../../../assets/images/user.jpeg';
import Avatar from '../../../components/avatar/Avatar';
import { images } from '../../../assets/images';
import ButtonOrange from '../../../components/button/ButtonOrange';
import { FaHeart } from 'react-icons/fa';

interface ListPageItemProps {
  item: Page;
}

const ListPageItem: FC<ListPageItemProps> = ({ item }) => {
  const navigate = useNavigate();

  const onPressPage = () => {
    navigate('/page/' + item._id);
  };

  return (
    <div
      className="flex items-center mt-5 hover:bg-white pr-5"
      onClick={onPressPage}>
      {/* avatar */}
      <div className="mr-5 ml-5 min-w-[70px] min-h-[70px]">
        <Avatar
          src={item?.logo?.uri || userImg}
          width={80}
          height={80}
          className="rounded-full !h-[70px]"
        />
      </div>
      <div className="w-full">
        <div className="flex justify-between items-center">
          <p className="font-semibold text-lg sm:max-w-md min-w-min">
            {item.name}
          </p>
          <div>
            {!item.liked ? (
              <div className="flex items-center pl-5 min-w-[100px]">
                <FaHeart className="text-primary-yellow text-2xl" />
                <span className="text-xs ml-2  ">Đã thích </span>
              </div>
            ) : (
              <ButtonOrange text="Thích" icon={<FaHeart />} />
            )}
          </div>
        </div>
        <div className="flex">
          <p className="mr-1">{item.totalLikes}</p>
          <p>lượt thích</p>
        </div>
        <hr className="w-full mt-4" />
      </div>
    </div>
  );
};

export default ListPageItem;
