import { FC, useRef, useState } from 'react';
import { useOnClickOutside } from '../../Ultis/hooks/useClickOutside';
import { BiDotsHorizontalRounded } from 'react-icons/bi';
import { Comment } from '../../graphql/generated';
import { getUserLogin } from '../../Ultis/token';
import { useDeleteComment } from '../../pages/DetailPost/services/api';

interface Props {
  comment: Comment;
  onDelete: (_id: string) => void;
  onOpenEdit?: () => void;
}
const CommentAction: FC<Props> = ({ comment, onDelete, onOpenEdit }) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const user = getUserLogin();
  const ref = useRef<any>();
  const { mutate } = useDeleteComment();
  useOnClickOutside(ref, () => setModalOpen(false));

  const handlerDelete = () => {
    setModalOpen(false);
    onDelete && onDelete(comment._id);
    mutate({ _id: comment._id });
  };

  const handlerDEdit = () => {
    setModalOpen(false);
    onOpenEdit && onOpenEdit();
  };

  return (
    <div className="relative">
      <button
        className="ml-3 md:ml-6"
        type="button"
        onClick={() => setModalOpen(true)}>
        <BiDotsHorizontalRounded />
      </button>
      <div
        ref={ref}
        className={`${
          isModalOpen ? 'block' : 'hidden'
        } z-10 model-dropdown bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700`}>
        <ul className="py-2 text-sm text-gray-700 dark:text-gray-200">
          {comment.creator?._id === user._id ? (
            <>
              <li className="cursor-pointer " onClick={handlerDelete}>
                <span className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                  Xóa
                </span>
              </li>
              <li className="cursor-pointer " onClick={handlerDEdit}>
                <span className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                  Chỉnh sửa
                </span>
              </li>
            </>
          ) : (
            <li className="cursor-pointer ">
              <span className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                Báo cáo
              </span>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};
export default CommentAction;
