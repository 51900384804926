import { FC, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import FriendItem, {
  FriendItemType,
} from '../../../components/friend-menu/FriendItem';
import { GetMyRecommendedFriendsQueryVariables } from '../../../graphql/generated';
import { useGetMyRecommendedFriend } from '../services/api';

const LIMIT_PAGE = 20;
interface Props {
  totalItems: number;
}
const ListMyRecommendFriend: FC<Props> = ({ totalItems }: Props) => {
  const [isHasMore, setHasMore] = useState(false);
  const [filter, setFilter] = useState<GetMyRecommendedFriendsQueryVariables>({
    limit: LIMIT_PAGE,
  });
  const myRecommendedFriendsData = useGetMyRecommendedFriend(filter);
  const [myRecommendedFriends, setMyRecommendedFriends] = useState<any[]>([]);

  useEffect(() => {
    const currentCountItems =
      myRecommendedFriends.length + (myRecommendedFriendsData?.length || 0);
    currentCountItems < totalItems && setHasMore(true);
  });

  useEffect(() => {
    setMyRecommendedFriends([
      ...myRecommendedFriends,
      ...(myRecommendedFriendsData || []),
    ]);
  }, [myRecommendedFriendsData]);

  const fetchData = () => {
    setFilter({
      ...filter,
      offset: (filter.offset ?? 0) + LIMIT_PAGE,
    });
  };
  const refresh = () => {};
  return (
    <InfiniteScroll
      className="p-4 bg-slate-50 grid min-[375px]:grid-cols-1 min-[375px]:gap-5 sm:grid-cols-2 sm:gap-2"
      dataLength={myRecommendedFriends.length}
      next={fetchData}
      hasMore={isHasMore}
      loader={''}
      // endMessage={
      //   <p className="mt-5" style={{ textAlign: 'center' }}>
      //     <b>Yay! You have seen it all</b>
      //   </p>
      // }
      endMessage={''}
      refreshFunction={refresh}>
      {myRecommendedFriends.map((friend, idx) => {
        return (
          <FriendItem
            key={friend._id + idx}
            friend={friend}
            type={FriendItemType.MySuggestion}
          />
        );
      })}
    </InfiniteScroll>
  );
};

export default ListMyRecommendFriend;
