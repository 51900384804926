import { ChangeEvent, useEffect, useRef, useState } from 'react';
import SearchBlackSvg from '../../../../assets/images/icon/search_black.svg';
import Friend, { IFriendItem } from './components/FriendItem';
import InfiniteScroll from 'react-infinite-scroll-component';
import HeaderExtra from '../../../../components/header/HeaderExtra';
import {
  useMutationInviteAllLikePage,
  useMutationInviteLikePage,
  useQueryListFriendNotLikedPage,
} from './services/apis';
import { useParams } from 'react-router-dom';
import * as _ from 'lodash';
import { ListFriendNotLikedPageQueryVariables } from '../../../../graphql/generated';
import Loading, { LoadingRef } from '../../../../components/loading/Loading';
import Toast, {
  ToastProps,
  ToastType,
} from '../../../../components/toast/Toast';

const LIMIT_RECORD = 45;

export default function InviteFriend() {
  const params = useParams();
  const [filter, setFilter] = useState<ListFriendNotLikedPageQueryVariables>({
    _id: params?.id || '',
    q: '',
    limit: LIMIT_RECORD,
  });
  const [friendIds, setFriendIds] = useState<string[]>([]);
  const { mutateAsync: inviteLikePageMutation, isSuccess: isInviteSuccess } =
    useMutationInviteLikePage();
  const {
    mutateAsync: inviteAllLikePageMutation,
    isSuccess: isInviteAllSuccess,
  } = useMutationInviteAllLikePage();
  const {
    data: myFriends,
    isSuccess: isSuccessGetMyFriends,
    isLoading: isLoadingMyFriends,
    refetch: refetchMyFriends,
  } = useQueryListFriendNotLikedPage(filter);
  const [friendsMapping, setFriendsMapping] = useState<IFriendItem[]>();
  const [pageId, setPageId] = useState<string>('');
  const [isSelectAll, setIsSelectAll] = useState(false);
  const loadingRef = useRef<LoadingRef>(null);
  const [toast, setToast] = useState<ToastProps>({
    className: 'fixed top-16 right-0',
    isHidden: true,
    type: ToastType.Success,
    message: '',
  });

  useEffect(() => {
    setPageId(params?.id || '');
  }, []);

  useEffect(() => {
    let resetToast: any;
    if (!toast.isHidden) {
      resetToast = setTimeout(() => {
        setToast((preState) => {
          return { ...preState, isHidden: true };
        });
      }, 3000);
    }
    return () => {
      clearTimeout(resetToast);
    };
  }, [toast]);

  useEffect(() => {
    if (myFriends?.listFriendNotLikedPage) {
      const feMp: IFriendItem[] = _.map(
        myFriends?.listFriendNotLikedPage.items,
        (item) => {
          const isSelected = _.includes(friendIds, item._id);
          return {
            id: item._id,
            avatar: item.avatar?.uri || '',
            name: item.fullName,
            isChecked: isSelected,
          };
        },
      );

      setFriendsMapping((preState) => {
        if (!preState) {
          return feMp;
        }
        preState.concat(feMp);
        return preState;
      });
    }
  }, [myFriends]);

  useEffect(() => {
    if (isLoadingMyFriends) {
      loadingRef.current?.open();
    } else {
      loadingRef.current?.close();
    }
  }, [isLoadingMyFriends]);

  const handleSelectFriend = (id: string) => {
    setFriendIds((preState) => {
      const existAt = _.indexOf(preState, id);
      if (existAt != -1) {
        preState.splice(existAt, 1);
      } else {
        preState.push(id);
      }
      return preState;
    });

    const feMp = _.map(friendsMapping, (item) => {
      if (item.id == id) {
        return _.assign({}, item, { isChecked: !item.isChecked });
      }
      return item;
    });

    setFriendsMapping(feMp);
  };

  const fetchData = () => {
    if (
      myFriends?.listFriendNotLikedPage?.items &&
      myFriends?.listFriendNotLikedPage.items?.length > 0
    ) {
      setFilter({
        ...filter,
        after:
          myFriends?.listFriendNotLikedPage.items[
            myFriends?.listFriendNotLikedPage.items?.length - 1
          ]._id,
      });
    }
  };

  const handleSendInvitation = () => {
    if (friendIds.length <= 0) {
      return;
    }

    if (isSelectAll) {
      inviteAllLikePageMutation({ pageId, excludeIds: friendIds })
        .then((data) => {
          if (data) {
              setFriendsMapping((preState) => {
                  if (preState) {
                      return _.remove(preState, (item) => {
                          const inFriendRemovedList = _.includes(friendIds, item.id)
                          return !inFriendRemovedList
                      })
                  }
                  return preState
              })
              
            setToast({
              ...toast,
              message: 'Mời bạn thích trang thành công',
              isHidden: false,
              type: ToastType.Success,
            });
          } else {
            setToast({
              ...toast,
              message: 'Mời bạn thích trang không thành công',
              isHidden: false,
              type: ToastType.Danger,
            });
            setFriendIds([])
          }
        })
        .catch((err) => {
          setToast({
            ...toast,
            message: 'Mời bạn thích trang không thành công',
            isHidden: false,
            type: ToastType.Danger,
          });
        });
    } else {
      inviteLikePageMutation({ userIds: friendIds, pageId })
        .then((data) => {
          if (data) {
              setFriendsMapping((preState) => {
                  if (preState) {
                      return _.remove(preState, (item) => {
                          const inFriendRemovedList = _.includes(friendIds, item.id)
                          return !inFriendRemovedList
                      })
                  }
                  return preState
              })
            setFriendIds([])
            setToast({
              ...toast,
              message: 'Mời bạn thích trang thành công',
              isHidden: false,
              type: ToastType.Success,
            });
          } else {
            setToast({
              ...toast,
              message: 'Mời bạn thích trang không thành công',
              isHidden: false,
              type: ToastType.Danger,
            });
          }
        })
        .catch((err) => {
          setToast({
            ...toast,
            message: err,
            isHidden: false,
            type: ToastType.Danger,
          });
        });
    }
  };

  const handleSelectAll = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    e.preventDefault();

    let ids: string[] = [];
    setIsSelectAll((preState) => !preState);

    if (!isSelectAll) {
      ids = _.map(friendsMapping, (item) => {
        return item.id;
      });
    }

    const feMp = _.map(friendsMapping, (item) => {
      return { ...item, isChecked: !isSelectAll };
    });

    setFriendsMapping(feMp);
    setFriendIds(ids);
  };

  const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const searchChar = e.target.value;
    setFilter({ ...filter, q: searchChar });
  };

  return (
    <>
      <HeaderExtra
        title="Mời bạn bè"
        actionName={`Gửi ${friendIds.length}`}
        action={handleSendInvitation}
      />
      <Toast
        message={toast.message}
        className={toast.className}
        type={toast.type}
        isHidden={toast.isHidden}
      />

      <div className="-top-[35px]">
        <div className="flex justify-between border focus:outline-0 rounded-full my-3 mx-3 px-3 py-2">
          <input
            className="w-full"
            type="text"
            placeholder="Tìm kiếm"
            onChange={(e) => handleChangeSearch(e)}
          />
          <img src={SearchBlackSvg} alt="search icon" />
        </div>
        <div className="my-5 px-3">
          <div className="flex justify-between">
            <h3 className="text-xl font-semibold">Bạn bè</h3>
            <a
              href="#"
              className="text-[#1B76D2]"
              onClick={(e) => {
                handleSelectAll(e);
              }}>
              {isSelectAll ? 'Bỏ chọn tất cả' : 'Chọn tất cả'}{' '}
              <span>({myFriends?.listFriendNotLikedPage?.counter || 0})</span>
            </a>
          </div>
        </div>
        <div className="px-3">
          <InfiniteScroll
            next={fetchData}
            hasMore={true}
            loader={
              <>
                <div className="w-full flex justify-center">
                  <Loading ref={loadingRef} />
                </div>
              </>
            }
            dataLength={myFriends?.listFriendNotLikedPage?.items?.length || 0}>
            {friendsMapping?.map((friend) => {
              return (
                <>
                  <Friend
                    key={friend.id}
                    friend={friend}
                    handleSelectFriend={handleSelectFriend}
                  />
                </>
              );
            })}
          </InfiniteScroll>
        </div>
      </div>
    </>
  );
}
