import HeaderExtra from '../../components/header/HeaderExtra';
import { TabProduct } from './components/TabProduct';
import {images} from "../../assets/images"
import { useNavigate, useParams } from 'react-router-dom';

const ProductsPage = () => {
  const params = useParams()
  const navigate = useNavigate()

  return (
    <div>
      <HeaderExtra title="Sản phẩm và dịch vụ" actionName={<><img src={images.CirclePlus} alt="add product" /></>} action={()=>{
        navigate(`/page/${params?.pageId || ""}/products/create`)
      }} />
      <TabProduct />
    </div>
  );
};

export default ProductsPage;
