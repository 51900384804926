import React, { useState } from 'react';
import { useGetMyNotificationsQuery } from '../../graphql/generated';
import NotifyItem from './components/NotifyItem';
import { useGetNotification } from './services/api';
import InfiniteScroll from 'react-infinite-scroll-component';
import EmptyNotification from './components/EmptyNotification';

interface NotificationProps {}

export enum StateFetch {
  GETTING_FIRST = 'GETTING_FIRST',
  GETTING_MORE = 'GETTING_MORE',
}

const Notification = (props: NotificationProps) => {
  const [params, setParams] = useState({ limit: 20, offset: 0 });
  const [stateFetch, setStateFetch] = useState(StateFetch.GETTING_FIRST);
  const [notification, setNotification] = useState<any>([]);
  const { data } = useGetNotification(params);

  React.useEffect(() => {
    if (stateFetch === StateFetch.GETTING_FIRST) {
      setNotification(data?.getMyNotifications || []);
    } else {
      setNotification([...notification, ...(data?.getMyNotifications || [])]);
    }
  }, [data?.getMyNotifications, data?.getMyNotifications?.length]);

  const fetchData = () => {
    setStateFetch(StateFetch.GETTING_MORE);
    setParams({ ...params, offset: params.offset + 10 });
  };

  const refresh = () => {};

  return (
    <div>
      <InfiniteScroll
        dataLength={notification?.length || 0} //This is important field to render the next data
        next={fetchData}
        hasMore={true}
        loader={<></>}
        endMessage={
          <p style={{ textAlign: 'center' }}>
            <b>Yay! You have seen it all</b>
          </p>
        }
        // below props only if you need pull down functionality
        refreshFunction={refresh}
        pullDownToRefresh
        pullDownToRefreshThreshold={50}>
        {notification?.map((itemNoti: any) => {
          return <NotifyItem itemNoti={itemNoti} key={itemNoti?._id} />;
        })}
      </InfiniteScroll>
      {notification?.length === 0 && (
        <div className="mt-40 h-[100vh]">
          <EmptyNotification />
        </div>
      )}
    </div>
  );
};

export default Notification;
