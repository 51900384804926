import { FC, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import FriendItem, { FriendItemType } from '../../../components/friend-menu/FriendItem';
import { GetUserFriendsQueryVariables } from '../../../graphql/generated';
import { useGetUserFriends } from '../services/api';
import { getUserLogin } from '../../../Ultis/token';

const LIMIT_PAGE = 20;
interface Props {
  totalItems: number;
  userId: string;
  mutualFriendIds: string[];
}
const ListUserFriend: FC<Props> = ({ userId, totalItems, mutualFriendIds }: Props) => {
  const [isHasMore, setHasMore] = useState(false);
  const [filter, setFiter] = useState<GetUserFriendsQueryVariables>({
    limit: LIMIT_PAGE,
    uid: userId,
  });
  const userFriendsData = useGetUserFriends(filter);
  const [userFriends, setUserFriends] = useState<any[]>([]);
  const me = getUserLogin();
  useEffect(() => {
    userFriends.length + (userFriendsData?.length || 0) < totalItems && setHasMore(true);
  });
  useEffect(() => {
    setUserFriends([...userFriends, ...(userFriendsData || [])]);
  }, [userFriendsData]);
  const fetchData = () => {
    setFiter({
      ...filter,
      after: userFriends[userFriends.length - 1]?._id,
    });
  };
  const refresh = () => {};
  return (
    <InfiniteScroll
      className="p-4 bg-slate-50 grid min-[375px]:grid-cols-1 min-[375px]:gap-5 sm:grid-cols-2 sm:gap-2"
      dataLength={userFriends.length}
      next={fetchData}
      hasMore={isHasMore}
      loader={''}
      // endMessage={
      //   <p className="mt-5" style={{ textAlign: 'center' }}>
      //     <b>Yay! You have seen it all</b>
      //   </p>
      // }
      endMessage={''}
      refreshFunction={refresh}>
      {userFriends.map((friend, idx) => {
        if (friend._id === me._id) return;
        return <FriendItem key={friend._id + idx} friend={friend} type={FriendItemType.UserFriend} isDisplayYellowBtn={!mutualFriendIds.includes(friend._id)} />;
      })}
    </InfiniteScroll>
  );
};

export default ListUserFriend;
