import { FC, memo, useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import { IoIosArrowBack } from 'react-icons/io';
import { MdClear } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
interface Props {
  query: string;
  onChange: (value: string) => void;
  onSearch: (keyword: string) => void;
  onGotoSearch?: () => void;
}
const HeaderSearch: FC<Props> = ({ query, onChange, onSearch, onGotoSearch }) => {
  const [value, setValue] = useState<string>(query || '');
  const navigate = useNavigate();

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      // 👇 Get input value
      onChange && onChange(query);
      onSearch(query);
    }
  };

  return (
    <>
      <div className="flex justify-center absolute top-3 w-full h-16 header-extra">
        <div className="w-header-search mx-8">
          <div className="relative">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <FaSearch className="primaryColor" />
            </div>
            <input
              value={query}
              onChange={(e) => onChange(e.target.value || '')}
              type="search"
              id="default-search"
              className="pl-10 rounded-full block w-full p-2 text-sm text-gray-900 border border-gray-100 "
              placeholder="Tìm kiếm"
              //onKeyDown={handleKeyDown}
            />
            {query.length ? (
              <span onClick={() => onChange('')} className=" bg-slate-100 p-1 rounded-full absolute right-2.5 bottom-2">
                <MdClear className="text-current" />
              </span>
            ) : (
              <></>
            )}
          </div>
        </div>

        <IoIosArrowBack
          size="35px"
          className=" absolute text-white left-6 top-0"
          onClick={() => {
            navigate(-1);
          }}
        />
      </div>
    </>
  );
};
export default memo(HeaderSearch);
