import { FC, memo, useEffect, useState } from 'react';
import { Slide } from 'yet-another-react-lightbox/*';
import { Media, MediaType } from '../../graphql/generated';
import LightboxImage from '../lightbox/LightboxImage';
import PhotoItem from './PhotoItem';
import { tranFormSlider } from './PhotoAlbum';

interface Props {
  medias: Media[];
}
const PhotoAlbumProUI: FC<Props> = ({ medias }) => {
  /* State */
  const [mediaIndex, setMediaIndex] = useState<number>(-1);
  const [slider, setSlider] = useState<Slide[]>([]);
  /* State - end*/

  /* API */
  /* API - end*/

  /* useEffect */
  useEffect(() => {
    const data = tranFormSlider((medias as Media[]) || []);
    setSlider(data);
  }, [medias]);
  /* useEffect -end */

  /* Function */

  const onOpenMedia = (index: number) => {
    setMediaIndex(index);
  };

  const onCloseMedia = () => {
    setMediaIndex(-1);
  };

  /* Function - end*/
  return (
    <div className="ml-2 pr-2">
      <div className={`photo-album grid grid-cols-3 lg:grid-cols-5 items-center gap-1 mb-4`}>
        {medias.map((item, index) => {
          return <PhotoItem key={item._id} onClick={() => onOpenMedia(index)} data={item as Media} />;
        })}
        <LightboxImage index={mediaIndex} slides={slider} isOpen={mediaIndex >= 0} onClose={onCloseMedia} />
      </div>
    </div>
  );
};

export default memo(PhotoAlbumProUI);
