import { Link } from 'react-router-dom';
import {
  useGetBlockedUsers,
  useUnBlockUser,
} from '../../pages/Page/services/api';
import HeaderExtra from '../header/HeaderExtra';
import userImg from '../../assets/images/user.jpeg';

const BlockList = () => {
  const { data: blockedUsers } = useGetBlockedUsers(999);
  const { mutate } = useUnBlockUser();

  const handleUnBlock = (id: string) => {
    mutate({
      targetId: id,
    });
  };

  return (
    <>
      <HeaderExtra title="Danh sách chặn" />
      <div className="section-content-setting-page">
        <div className="section-action ">
          <div className="bg-primary-yellow">
            <div className="rounded-t-[10px] bg-white">
              {blockedUsers?.getBlockedUsers?.map((item) => {
                return (
                  <>
                    <div
                      className="border-b-[1px]  border-solid border-gray-200 justify-between items-center flex p-3"
                      key={item?.targetUser?._id}>
                      <div className="flex gap-2 items-center">
                        <div className="h-[80px] w-[80px] rounded-full">
                          <img
                            src={
                              !!item?.targetUser?.avatar?.uri
                                ? item?.targetUser?.avatar?.uri
                                : userImg
                            }
                            alt=""
                            className="rounded-full"
                          />
                        </div>
                        <Link to={`/profile/${item?.targetUser?._id}`}>
                          <h3 className="text-base font-semibold">
                            {item?.targetUser?.fullName}
                          </h3>
                        </Link>
                      </div>
                      <button
                        className="rounded-[8px] border-[1px]  border-solid border-gray-400 px-2 py-1"
                        onClick={() =>
                          handleUnBlock(item?.targetUser?._id || '')
                        }>
                        Bỏ chặn
                      </button>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default BlockList;
