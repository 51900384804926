import { AiOutlineCheckSquare, AiOutlinePlusSquare } from 'react-icons/ai';
import { FaHeart } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { queryClient } from '../..';
import { images } from '../../assets/images';
import {
  GetPageQuery,
  ReactToModel,
  ReactionTypeInput,
  useCreateReactionMutation,
} from '../../graphql/generated';
import { graphqlClientRequest } from '../../graphql/services/graphql-client';
import { useGetPageDirectConversation } from '../../pages/Messenger/services/api';

interface Props {
  data: GetPageQuery | undefined;
}

const Action = (props: Props) => {
  const { mutateAsync } = useCreateReactionMutation(graphqlClientRequest(true));
  const { data } = props;
  const { data: conversationData } = useGetPageDirectConversation({
    pageId: data?.getPage?._id || '',
  });

  const handleAction = async (reactionType: ReactionTypeInput) => {
    mutateAsync(
      {
        data: {
          reactionToModel: ReactToModel.Page,
          reactionToId: data?.getPage?._id || '',
          reactionType: reactionType,
        },
      },
      {
        onSuccess() {
          queryClient.invalidateQueries(['getPage']);
        },
      },
    );
  };

  const navigate = useNavigate();
  const onMessage = () => {
    navigate(`/messenger/${conversationData?.getPageDirectConversation?._id}`);
  };

  return (
    <div className="flex justify-center mt-3">
      <button className="border-0 bg-secondary-yellow rounded-lg p-3 !text-white flex items-center mr-3">
        {data?.getPage?.liked ? (
          <div
            className="flex"
            onClick={() => handleAction(ReactionTypeInput.Unlike)}>
            <FaHeart className="text-2xl text-primary-yellow" />
            <div className=" ml-3 text-primary-yellow">Đã thích</div>
          </div>
        ) : (
          <div
            className="flex"
            onClick={() => handleAction(ReactionTypeInput.Like)}>
            <FaHeart className="text-2xl text-primary-yellow" />
            <div className=" ml-3 text-primary-yellow">Thích</div>
          </div>
        )}
      </button>
      <button className="border-0 bg-secondary-yellow text-primary-yellow  rounded-lg p-3  flex items-center mr-3">
        {!data?.getPage?.followed ? (
          <div
            className="flex"
            onClick={() => handleAction(ReactionTypeInput.Follow)}>
            <AiOutlinePlusSquare className="text-2xl" />
            <div className=" ml-3">Theo dõi</div>
          </div>
        ) : (
          <div
            className="flex"
            onClick={() => handleAction(ReactionTypeInput.Unfollow)}>
            <AiOutlineCheckSquare className="text-2xl" />
            <div className=" ml-3">Đã theo dõi</div>
          </div>
        )}
      </button>
      <button
        className="border-0 bg-secondary-yellow text-primary-yellow rounded-lg p-3 flex items-center"
        onClick={onMessage}>
        <img src={images.MessPage} alt="Mess" />
      </button>
    </div>
  );
};

export default Action;
