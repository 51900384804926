import { FC } from 'react';
import './index.scss';

interface Props {
  className?: string;
  label?: string;
}
const LoadingExtra: FC<Props> = ({ className, label = 'Loading...' }) => {
  return (
    <div className={`loading-container ${className}`}>
      <div className="loading-spinner"></div>
      <p className="loading-text">{label}</p>
    </div>
  );
};

export default LoadingExtra;
