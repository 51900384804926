import { FC, useRef, useState } from 'react';
import { getUserLogin } from '../../Ultis/token';
import { images } from '../../assets/images';
import { GetPageQuery, Page, PostType } from '../../graphql/generated';
import CreatePostModal from './CreatePostModal';
import bgAvatar from '../../assets/images/bg-avatar.png';
interface Props {
  onPostCreated: (post: any) => void;
  type?: PostType;
  page?: GetPageQuery['getPage'] | undefined;
  isInMyPage?: string;
}

const CreatePost: FC<Props> = ({
  onPostCreated,
  type = PostType.Status,
  page,
  isInMyPage,
}) => {
  const me = getUserLogin();
  const modalRef = useRef<HTMLDialogElement>(null);
  const handleClickCreatePost = () => {
    modalRef?.current?.showModal();
    document.body.className = 'overflow-hidden';
  };
  const handleClickUploadImage = () => {
    modalRef?.current?.showModal();
    // @ts-ignore
    modalRef.current && modalRef.current?.querySelector('.create-post_uploadIcon').click();
  };
  const [isReset, setIsReset] = useState(false);
  const onReset = () => {
    setIsReset(!isReset);
  };
  return (
    <div>
      <div className="w-full bg-white mt-2 rounded-lg p-4">
        <div className="w-full flex items-center">
          <div>
            <div className="img-1-1 h-[40px] w-[40px] bg-white rounded-full shadow_verify">
              <img
                src={
                  page
                    ? page.logo?.uri ?? images.AvatarDefault
                    : me?.avatar?.uri ?? images.AvatarDefault
                }
                alt={page ? page.name ?? '' : me?.username ?? ''}
              />
            </div>
          </div>
          <div
            className="ml-4 rounded-full self-center flex h-[40px] w-full bg-slate-200 hover:bg-slate-100"
            onClick={handleClickCreatePost}>
            <p
              className="self-center mx-5 w-full justify-self-center text-sm"
              role="button">
              {`Bạn đang nghĩ gì, ${
                page ? page.name : me.fullName.split(' ').pop()
              } ?`}
            </p>
          </div>
          <img
            src={images.PhotoUpload}
            onClick={handleClickUploadImage}
            className={`ml-4 h-[20px] cursor-pointer self-center w-[20px] object-cover rounded-full}`}></img>
        </div>
      </div>
      {isReset ? (
        <CreatePostModal
          isInMyPage={isInMyPage}
          user={me}
          ref={modalRef}
          onReset={onReset}
          onPostCreated={onPostCreated}
          type={type}
          page={page}
          key="true"
        />
      ) : (
        <CreatePostModal
          isInMyPage={isInMyPage}
          user={me}
          ref={modalRef}
          onReset={onReset}
          onPostCreated={onPostCreated}
          type={type}
          page={page}
          key="false"
        />
      )}
    </div>
  );
};

export default CreatePost;
