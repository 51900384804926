import { FC, useEffect, useState } from 'react';
import StarRatingComponent from 'react-star-rating-component';

interface Props {
  defaultValue?: number;
  isShow: boolean;
  address: string;
  onCancel: () => void;
  onOk: (star: number) => void;
}
const VoteAddressModal: FC<Props> = ({
  address,
  defaultValue,
  onCancel,
  onOk,
  isShow,
}) => {
  const [star, setStar] = useState<number>(defaultValue || 0);
  const onchangeStar = (nextValue: number, prevValue: number, name: string) => {
    setStar(nextValue);
  };

  const cancel = () => {
    onCancel();
    setStar(defaultValue || 0);
  };

  return (
    <div
      className={`${
        isShow ? '' : 'hidden'
      } fixed top-0 bg-[#00000075] left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full justify-center items-center flex modal-vote-address`}>
      <div className="relative w-full max-w-md max-h-full">
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
          {/* Modal body */}

          <div className="p-4  text-center ">
            <div className="text-lg">
              <span>Đánh giá địa điểm: </span>
              <span className="font-semibold">{address}</span>
            </div>
            <StarRatingComponent
              name={'Đánh giá'}
              value={star || 0}
              onStarClick={(
                nextValue: number,
                prevValue: number,
                name: string,
              ) => onchangeStar(nextValue, prevValue, name)}
              starCount={5}
              starColor={'#FFB800'}
              emptyStarColor="#DDDDDD"
            />
            <div className="mx-auto my-0 h-[100px] w-[100px] bg-amber-200 text-amber-600 rounded-full flex items-center justify-center text-4xl">
              {star}
            </div>
            <div className="flex justify-center gap-4 pt-6">
              <button
                onClick={cancel}
                type="button"
                className="bg-slate-200 text-black min-w-[100px]  focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center ">
                Hủy
              </button>
              <button
                onClick={() => onOk(star || 0)}
                type="button"
                className="text-white min-w-[100px] bg-primary-yellow focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                Xong
              </button>
            </div>
          </div>
          {/* Modal footer */}
        </div>
      </div>
    </div>
  );
};

export default VoteAddressModal;
