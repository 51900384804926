import { useNavigate } from 'react-router-dom';
import BlueUserSvg from '../../../../../assets/images/icon/blue-user.svg';
import BagSvg from '../../../../../assets/images/icon/brief_bag.svg';
import { PageType } from '../../../../../graphql/generated';
import { Steps } from './StepsLayout';

export interface ITypePageCard {
  type: PageType;
  title: string;
  subTitle: string;
  handleChangeStep: (isStep: Steps) => void;
  handleSelectPageType: (type: PageType) => void;
}

export default function TypePageCard({
  type,
  title,
  subTitle,
  handleChangeStep,
  handleSelectPageType,
}: ITypePageCard) {
  return (
    <>
      <div className="flex justify-center items-center m-3 py-3 px-11 border rounded">
        <div className="text-center">
          <div className="flex justify-center items-center mt-3 mb-2">
            <img
              src={type == PageType.BusinessOrBrand ? BlueUserSvg : BagSvg}
              alt="icon"
            />
          </div>
          <h2 className="text-xl font-semibold">{title}</h2>
          <p className="mt-2 mb-3">{subTitle}</p>
          <button
            className="py-2 px-10 bg-[#05A2AB] rounded text-white font-semibold"
            onClick={() => {
              handleChangeStep(Steps.SetName);
              handleSelectPageType(type);
            }}>
            Chọn
          </button>
        </div>
      </div>
    </>
  );
}
