import { FC, useEffect, useRef, useState } from 'react';
import { useSearchPlace } from '../services/api';
import Loading, { LoadingRef } from '../../../components/loading/Loading';
import ListPlaceItem from './ListPlaceItem';
import InfiniteScroll from 'react-infinite-scroll-component';
import { TopPlacesQueryVariables } from '../../../graphql/generated';
import { TabItemSearchBy } from '../components/SearchTabs';
import { MdSearchOff } from 'react-icons/md';

interface ListPlaceProps {
  keyword: string;
  searchBy?: TabItemSearchBy;
}

const ListPlace: FC<ListPlaceProps> = ({ keyword, searchBy }) => {
  const [params, setParams] = useState<TopPlacesQueryVariables>({ q: keyword, limit: 20 });
  const [dataPlace, setDataPlace] = useState<any>([]);
  const { data, isLoading } = useSearchPlace(params);
  const loadingRef = useRef<LoadingRef>(null);
  const refresh = () => {};

  const handleSearchPlace = () => {
    try {
      const newData = params.after ? [...dataPlace, ...(data?.topPlaces || [])] : data?.topPlaces;
      if (data && data.topPlaces) {
        setDataPlace(newData);
        setParams({ q: keyword, limit: 20 });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchMoreData = () => {
    setParams({
      ...params,
      after: {
        q: String(dataPlace[dataPlace.length - 1]?.topPlacePoint),
        uid: dataPlace[dataPlace.length - 1]?._id,
      },
    });
  };

  useEffect(() => {
    handleSearchPlace();
  }, [keyword, data?.topPlaces?.length, data?.topPlaces]);

  useEffect(() => {
    if (isLoading) {
      loadingRef.current?.open();
    } else {
      loadingRef.current?.close();
    }
  }, [isLoading]);

  return (
    <div>
      {keyword === '' && <div className="mt-10 font-semibold text-2xl">Đề xuất</div>}

      {searchBy === TabItemSearchBy.All && dataPlace.length !== 0 && <div className="font-semibold text-2xl mt-4">Địa điểm</div>}

      {searchBy === TabItemSearchBy.All ? (
        <></>
      ) : (
        <>
          {dataPlace.length === 0 && keyword !== '' && (
            <center className="mt-20">
              <MdSearchOff size={'45px'} />
              <p className="mt-2">{`Không tìm thấy kết quả tìm kiếm cho từ khoá "${keyword}"`}</p>
            </center>
          )}
        </>
      )}

      <div className="fixed top-[15%] left-[47%] z-50">
        <Loading ref={loadingRef} />
      </div>
      <InfiniteScroll
        dataLength={dataPlace?.length || 0}
        next={fetchMoreData}
        hasMore={true}
        loader={<></>}
        endMessage={<p style={{ textAlign: 'center' }}></p>}
        refreshFunction={refresh}>
        {dataPlace.map((item: any, index:number) => {
          return <ListPlaceItem key={index} item={item} />;
        })}
      </InfiniteScroll>
    </div>
  );
};

export default ListPlace;
