import { useEffect, useRef, useState } from 'react';
import { BsPeople, BsPersonCheck } from 'react-icons/bs';
import { FaCopy } from 'react-icons/fa';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { RxExit } from 'react-icons/rx';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getUserLogin } from '../../Ultis/token';
import {
  useDeleteGroupMembers,
  useGetGroup,
  useGetGroupMembers,
  useLeaveGroupMembers,
} from '../../pages/Groups/services/api';
import HeaderExtra from '../header/HeaderExtra';
import { ConfirmModal, IConfirmModal } from '../modal/ConfirmModal';
import ModalListRequestJoinGroup, {
  ModalListRequestJoinGroupRef,
} from '../modal/ModalListRequestJoinGroup';
import ModalShowGroupMembers from '../modal/ModalShowGroupMembers';
import { ModalShowMemberRef } from '../modal/ModalShowMember';
import Toast, { ToastProps, ToastType } from '../toast/Toast';

interface IConfirm {
  action: string;
  modal: IConfirmModal;
}

const SettingGroup = () => {
  const [toast, setToast] = useState<ToastProps>({
    className: 'fixed top-0 right-0',
    isHidden: true,
    type: ToastType.Success,
    message: '',
  });
  const [confirmModal, setConfirmModal] = useState<IConfirm>({
    action: '',
    modal: {
      content: '',
      isVisible: false,
    },
  });

  const { groupId } = useParams();
  const user = getUserLogin();
  const location = useLocation();
  const navigate = useNavigate();
  const urlPage = location.state;
  const modalShowMemberRef = useRef<ModalShowMemberRef>(null);
  const modalListRequestJoinGroupRef =
    useRef<ModalListRequestJoinGroupRef>(null);
  const { data } = useGetGroup({
    id: groupId || '',
  });
  const { mutateAsync: deleteGroupAsync } = useDeleteGroupMembers();
  const { mutateAsync: leaveGroupAsync } = useLeaveGroupMembers();

  const isMyGroup = user?._id === data?.getGroup?.ownerId;

  const { data: groupMembers } = useGetGroupMembers({
    groupId: groupId || '',
    ignoreOwner: isMyGroup,
    limit: 999,
  });

  const copyContent = async () => {
    const text = document.getElementById('myUrl')?.innerHTML;
    try {
      await navigator.clipboard.writeText(text || '');
      //do something when copy success
      setToast({
        ...toast,
        message: `Copy đường dẫn nhóm thành công`,
        isHidden: false,
        type: ToastType.Success,
      });
    } catch (err) {
      setToast({
        ...toast,
        message: `Copy đường dẫn nhóm không thành công`,
        isHidden: false,
        type: ToastType.Danger,
      });
    }
  };

  const handleCancelModal = () => {
    setConfirmModal({
      ...confirmModal,
      modal: {
        isVisible: false,
        content: '',
      },
    });
  };

  const handleOkModal = () => {
    if (confirmModal.action === 'LEAVE') {
      leaveGroupAsync(
        {
          groupId: groupId || '',
        },
        {
          onSuccess() {
            navigate('/groups');
          },
          onError() {
            setToast((preState) => {
              return {
                ...preState,
                isHidden: false,
                type: ToastType.Danger,
                message: 'Có lỗi xảy ra',
              };
            });
          },
        },
      );
    } else {
      deleteGroupAsync(
        {
          id: groupId || '',
        },
        {
          onSuccess() {
            navigate('/groups');
          },
          onError() {
            setToast((preState) => {
              return {
                ...preState,
                isHidden: false,
                type: ToastType.Danger,
                message: 'Có lỗi xảy ra',
              };
            });
          },
        },
      );
    }
  };

  const openModalShow = () => {
    modalShowMemberRef.current?.open();
  };

  const openModalShowListRequest = () => {
    modalListRequestJoinGroupRef.current?.open();
  };

  useEffect(() => {
    let resetToast: any;
    if (!toast.isHidden) {
      resetToast = setTimeout(() => {
        setToast((preState) => {
          return { ...preState, isHidden: true };
        });
      }, 3000);
    }
    return () => {
      clearTimeout(resetToast);
    };
  }, [toast]);

  return (
    <>
      <HeaderExtra title="Cài đặt" isAbsolute={true} />
      <Toast
        message={toast.message}
        className={`${toast.className} absolute top-1`}
        type={toast.type}
        isHidden={toast.isHidden}
      />
      <ConfirmModal
        title={confirmModal.modal.title}
        content={confirmModal.modal.content}
        isVisible={confirmModal.modal.isVisible}
        onCancel={handleCancelModal}
        onOk={handleOkModal}
      />

      <div className="section-content-setting-page">
        <div className="section-action ">
          {isMyGroup && (
            <div
              className="flex bg-white gap-4 items-center p-4 border-b-[1px] border-solid border-gray-200 hover:bg-gray-200 cursor-pointer"
              onClick={openModalShowListRequest}>
              <BsPersonCheck className="text-2xl" />
              <p>Yêu cầu vào nhóm</p>
            </div>
          )}

          <div
            className="flex bg-white gap-4 items-center p-4 border-b-[1px] border-solid border-gray-200 hover:bg-gray-200 cursor-pointer"
            onClick={openModalShow}>
            <BsPeople className="text-2xl" />
            <p>Thành viên</p>
          </div>
          {(data?.getGroup?.joined || isMyGroup) && (
            <div
              className="flex gap-4 items-center p-4 border-b-[1px] border-solid border-gray-300 bg-white hover:bg-gray-200 cursor-pointer"
              onClick={() =>
                setConfirmModal({
                  ...confirmModal,
                  modal: {
                    title: 'Xác nhận rời nhóm',
                    content: `Bạn chắc chắn muốn rời nhóm  ${data?.getGroup?.name}?`,
                    isVisible: true,
                  },
                  action: 'LEAVE',
                })
              }>
              <RxExit className="text-2xl" />
              <p>Rời nhóm</p>
            </div>
          )}

          {isMyGroup && (
            <div
              className="flex gap-4 items-center p-4 border-b-8 border-solid border-gray-300 bg-white hover:bg-gray-200 cursor-pointer"
              onClick={() =>
                setConfirmModal({
                  ...confirmModal,
                  modal: {
                    title: 'Xác nhận xóa nhóm',
                    content: `Bạn chắc chắn muốn xóa nhóm  ${data?.getGroup?.name}?`,
                    isVisible: true,
                  },
                  action: 'DELETE',
                })
              }>
              <RiDeleteBin6Line className="text-2xl" />
              <p>Xóa nhóm</p>
            </div>
          )}

          {urlPage && (
            <div className="section-url px-4 pt-4 bg-white">
              <h3 className="title text-base font-semibold">
                Đường dẫn đến nhóm
              </h3>
              <div className="pb-2">
                <a
                  className="url text-base text-blue-600"
                  id="myUrl"
                  href={urlPage || '#'}>
                  {urlPage}
                </a>
              </div>
              <button
                onClick={copyContent}
                className="bg-secondary-yellow text-sm text-primary-yellow rounded">
                <div className="py-1 px-3 flex gap-1 items-center ">
                  <FaCopy className="text-lg" />
                  <span>Sao chép đường dẫn</span>
                </div>
              </button>
            </div>
          )}
        </div>
      </div>
      <ModalShowGroupMembers
        isOwner={isMyGroup}
        info={groupMembers?.getGroupMembers || []}
        ref={modalShowMemberRef}
      />
      <ModalListRequestJoinGroup
        isOwner={isMyGroup}
        info={groupMembers?.getGroupMembers || []}
        ref={modalListRequestJoinGroupRef}
      />
    </>
  );
};
export default SettingGroup;
