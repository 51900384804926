import { ChangeEvent } from 'react';

export interface ISetNameParams {
  isValid?: boolean;
  handleSetName: (e: ChangeEvent<HTMLInputElement>) => void;
}

export default function SetName({ isValid, handleSetName }: ISetNameParams) {
  return (
    <>
      <div className="text-center">
        <h1 className="text-xl font-semibold py-2">Đặt tên</h1>
        <p>Đặt tên mà bạn thích cho trang của bạn</p>
        <input
          className={`my-2 py-3 px-6 rounded border text-center focus:outline-0 ${
            !isValid && 'border-red-600'
          }`}
          type="text"
          placeholder="Đặt tên trang"
          onChange={(e) => handleSetName(e)}
          required
        />
        {!isValid && (
          <>
            <p className="text-red-500">Tên không đúng định dạng</p>
          </>
        )}
      </div>
    </>
  );
}
