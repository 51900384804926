import { useEffect, useRef, useState } from 'react';
import {
  AiFillDislike,
  AiFillLike,
  AiOutlineDislike,
  AiOutlineLike,
} from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { DiffDate } from '../../Ultis/Datetime';
import userImg from '../../assets/images/user.jpeg';
import {
  Comment,
  GetChildCommentsQueryVariables,
  Media,
  ReactToModel,
  ReactionType,
  ReactionTypeInput,
  useCreateReactionMutation,
} from '../../graphql/generated';
import { graphqlClientRequest } from '../../graphql/services/graphql-client';
import CommentChildItem from '../comment/ChildComment';
import ButtonReaction from '../reaction/ButtonReaction';
import ListUserReactionModal from '../reaction/ListUserReaction';
import SummamryUserReaction from '../reaction/SummaryUserReaction';
import InputComment, { InputCommentRef } from './InputComment';
import { useGetChildComments } from '../../pages/Home/services/api';
import MediaComment from '../comment/MediaComment';
import CommentAction from './CommentAction';
import InputCommentUpdate from './InputCommentUpdate';

interface Props {
  comment: Comment;
  onDelete: (_id: string) => void;
  onUpdateComment: (value: Comment) => void;
}

const CommentComponent = ({ comment, onDelete, onUpdateComment }: Props) => {
  const inputCommentRef = useRef<InputCommentRef>(null);
  const editCommentRef = useRef<InputCommentRef>(null);

  const [lastComment, setLastComment] = useState(false);
  const [isReply, setIsReply] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const refComment = useRef<HTMLDivElement>(null);
  const [childComments, setChildComments] = useState<any[]>(
    comment.childComments?.items || [],
  );
  const [filter, setFilter] = useState<GetChildCommentsQueryVariables>();
  const { data, isSuccess } = useGetChildComments(filter as any, !!filter);

  useEffect(() => {
    if (isSuccess && data?.getChildComments) {
      setChildComments([
        ...(data.getChildComments.items as Comment[]),
        // ...childComments,
      ]);
    }
  }, [data]);

  const onLoadMoreCmt = () => {
    setFilter({
      limit: 999,
      fatherId: comment._id,
      offset: 0,
    });
    setLastComment(true);
  };

  const onHideCmt = () => {
    setFilter({
      limit: 2,
      fatherId: comment._id,
      offset: 0,
    });
    setLastComment(false);
  };

  const [reactiveAtribute, setReactiveAtribute] = useState({
    isLiked: comment.liked,
    isDisliked: comment.disliked,
    totalLikes: comment.totalLikes,
    totalDislikes: comment.totalDislikes,
  });

  const [isOpenLikeReactionModal, setIsOpenLikeReactionModal] = useState(false);

  const [isOpenDislikeReactionModal, setIsOpenDisLikeReactionModal] =
    useState(false);

  const { mutateAsync } = useCreateReactionMutation(graphqlClientRequest(true));

  const [isShowSummaryUserLikeReaction, setIsShowSummaryUserLikeReaction] =
    useState(false);

  const [
    isShowSummaryUserDisLikeReaction,
    setIsShowSummaryUserDisLikeReaction,
  ] = useState(false);

  const handleOnClickTotalLike = () => {
    if (reactiveAtribute.totalLikes) {
      document.body.classList.add('body_modal');
      setIsOpenLikeReactionModal(true);
    }
  };

  const handleOnClickTotalDisLike = () => {
    if (reactiveAtribute.totalDislikes) {
      document.body.classList.add('body_modal');
      setIsOpenDisLikeReactionModal(true);
    }
  };
  const handleDislike = async () => {
    mutateAsync({
      data: {
        reactionToModel: ReactToModel.Comment,
        reactionToId: comment._id,
        reactionType: ReactionTypeInput.Dislike,
      },
    });
    setReactiveAtribute({
      ...reactiveAtribute,
      isLiked: false,
      isDisliked: true,
      totalLikes: reactiveAtribute.isLiked
        ? (reactiveAtribute.totalLikes ?? 0) - 1
        : reactiveAtribute.totalLikes,
      totalDislikes: (reactiveAtribute.totalDislikes ?? 0) + 1,
    });
  };
  const handleUnDislike = async () => {
    mutateAsync({
      data: {
        reactionToModel: ReactToModel.Comment,
        reactionToId: comment._id,
        reactionType: ReactionTypeInput.Undislike,
      },
    });
    setReactiveAtribute({
      ...reactiveAtribute,
      isDisliked: false,
      totalDislikes: (reactiveAtribute.totalDislikes ?? 0) - 1,
    });
  };
  const handleLike = async () => {
    mutateAsync({
      data: {
        reactionToModel: ReactToModel.Comment,
        reactionToId: comment._id,
        reactionType: ReactionTypeInput.Like,
      },
    });
    setReactiveAtribute({
      ...reactiveAtribute,
      isLiked: true,
      isDisliked: false,
      totalLikes: (reactiveAtribute?.totalLikes ?? 0) + 1,
      totalDislikes: reactiveAtribute.isDisliked
        ? (reactiveAtribute.totalDislikes ?? 0) - 1
        : reactiveAtribute.totalDislikes,
    });
  };
  const handleUnLike = async () => {
    mutateAsync({
      data: {
        reactionToModel: ReactToModel.Comment,
        reactionToId: comment._id,
        reactionType: ReactionTypeInput.Unlike,
      },
    });
    setReactiveAtribute({
      ...reactiveAtribute,
      isLiked: false,
      totalLikes: (reactiveAtribute.totalLikes ?? 0) - 1,
    });
  };

  const onAddComment = (value: Comment) => {
    setChildComments([value, ...childComments]);
  };

  const onDeleteChildComment = (_id: string) => {
    setChildComments((prevItems) => prevItems.filter((obj) => obj._id !== _id));
  };

  const onUpdateChildComment = (value: Comment) => {
    setChildComments(
      childComments.map((obj) => (obj._id === value._id ? value : obj)),
    );
  };

  const onReply = () => {
    setIsReply(true);
    setTimeout(() => {
      inputCommentRef.current && inputCommentRef.current.focus();
    }, 100);
  };

  const onOpenEdit = () => {
    setIsEdit(true);
    setTimeout(() => {
      editCommentRef.current && editCommentRef.current.focus();
    }, 100);
  };

  return (
    <>
      {isEdit ? (
        <>
          <InputCommentUpdate
            ref={editCommentRef}
            onClose={() => {
              setIsEdit(false);
            }}
            onUpdate={onUpdateComment}
            comment={comment}
            isReply={false}
          />
        </>
      ) : (
        <div ref={refComment} className="py-4 pl-4">
          <div className="flex items-start">
            <Link to={`/profile/${comment?.creator?._id}`}>
              <div
                style={{
                  backgroundImage: `url(${
                    comment.creator?.avatar?.uri || userImg
                  })`,
                }}
                className={`w-[40px] h-[40px] rounded-full profileImage ${
                  comment?.creator?.verified
                    ? 'shadow_verify'
                    : 'avartar-border_default'
                }`}
              />
            </Link>

            <div className="flex items-center">
              <div className="ml-4 text-base rounded-2xl bg-[#f0f2f5] py-3 px-4 min-w-[260px]">
                <b>
                  <Link to={`/profile/${comment.creator?._id}`}>{comment.creator?.fullName}</Link>
                </b>
                <br />
                <span className="text-base">{comment.content}</span>
                <MediaComment medias={comment.medias as Media[]} />
                <div>
                  <div className="flex justify-start items-center md:text-base text-sm mt-2">
                    <div className="relative mr-4 flex items-center">
                      {reactiveAtribute.isLiked ? (
                        <ButtonReaction
                          children={
                            <AiFillLike
                              className="mr-2 text-primary-yellow"
                              onClick={handleUnLike}
                            />
                          }
                        />
                      ) : (
                        <ButtonReaction
                          children={
                            <AiOutlineLike
                              className="mr-2"
                              onClick={handleLike}
                            />
                          }
                        />
                      )}
                      {isShowSummaryUserLikeReaction && (
                        <SummamryUserReaction
                          filter={{
                            reactionToId: comment._id,
                            reactionToModel: ReactToModel.Comment,
                            reactionType: ReactionType.Like,
                          }}
                          totalReaction={comment.totalLikes ?? 0}
                        />
                      )}
                      <div>
                        <span
                          className="hover:underline cursor-pointer"
                          onClick={handleOnClickTotalLike}
                          onPointerEnter={() => {
                            setIsShowSummaryUserLikeReaction(true);
                          }}
                          onPointerLeave={() => {
                            setIsShowSummaryUserLikeReaction(false);
                          }}>
                          {reactiveAtribute.totalLikes}
                        </span>
                      </div>
                    </div>
                    <div className="relative mr-4 flex items-center">
                      {reactiveAtribute.isDisliked ? (
                        <ButtonReaction
                          children={
                            <AiFillDislike
                              className="mr-2 text-primary-yellow"
                              onClick={handleUnDislike}
                            />
                          }
                        />
                      ) : (
                        <ButtonReaction
                          children={
                            <AiOutlineDislike
                              className="mr-2"
                              onClick={handleDislike}
                            />
                          }
                        />
                      )}
                      {isShowSummaryUserDisLikeReaction && (
                        <SummamryUserReaction
                          filter={{
                            reactionToId: comment._id,
                            reactionToModel: ReactToModel.Comment,
                            reactionType: ReactionType.Dislike,
                          }}
                          totalReaction={comment.totalDislikes ?? 0}
                        />
                      )}
                      <div>
                        <span
                          className="hover:underline cursor-pointer"
                          onClick={handleOnClickTotalDisLike}
                          onPointerEnter={() => {
                            setIsShowSummaryUserDisLikeReaction(true);
                          }}
                          onPointerLeave={() => {
                            setIsShowSummaryUserDisLikeReaction(false);
                          }}>
                          {reactiveAtribute.totalDislikes}
                        </span>
                      </div>
                    </div>
                    <div className="flex mr-4">
                      <b onClick={onReply} className="text-sm cursor-pointer">
                        Trả lời
                      </b>
                    </div>
                    <div className="flex">
                      <p className="text-sm">{DiffDate(comment.createdAt)}</p>
                    </div>
                  </div>
                  {isOpenLikeReactionModal && (
                    <ListUserReactionModal
                      filter={{
                        data: {
                          reactionToId: comment._id,
                          reactionToModel: ReactToModel.Comment,
                          reactionType: ReactionType.Like,
                        },
                        limit: 20,
                      }}
                      onCloseModal={() => {
                        document.body.classList.remove('body_modal');
                        setIsOpenLikeReactionModal(false);
                      }}
                      totalReaction={comment.totalLikes ?? 0}
                    />
                  )}
                  {isOpenDislikeReactionModal && (
                    <ListUserReactionModal
                      filter={{
                        data: {
                          reactionToId: comment._id,
                          reactionToModel: ReactToModel.Comment,
                          reactionType: ReactionType.Dislike,
                        },
                        limit: 20,
                      }}
                      onCloseModal={() => {
                        document.body.classList.remove('body_modal');
                        setIsOpenDisLikeReactionModal(false);
                      }}
                      totalReaction={comment.totalDislikes ?? 0}
                    />
                  )}
                </div>
              </div>
              {isOpenLikeReactionModal && (
                <ListUserReactionModal
                  filter={{
                    data: {
                      reactionToId: comment._id,
                      reactionToModel: ReactToModel.Comment,
                      reactionType: ReactionType.Like,
                    },
                    limit: 10,
                  }}
                  onCloseModal={() => {
                    document.body.classList.remove('body_modal');
                    setIsOpenLikeReactionModal(false);
                  }}
                  totalReaction={reactiveAtribute.totalLikes ?? 0}
                />
              )}
              {isOpenDislikeReactionModal && (
                <ListUserReactionModal
                  filter={{
                    data: {
                      reactionToId: comment._id,
                      reactionToModel: ReactToModel.Comment,
                      reactionType: ReactionType.Dislike,
                    },
                    limit: 10,
                  }}
                  onCloseModal={() => {
                    document.body.classList.remove('body_modal');
                    setIsOpenDisLikeReactionModal(false);
                  }}
                  totalReaction={reactiveAtribute.totalDislikes ?? 0}
                />
              )}

              <CommentAction
                comment={comment as Comment}
                onOpenEdit={onOpenEdit}
                onDelete={onDelete}
              />
            </div>
          </div>
          <div>
            {(comment.totalReplies || 0) > 2 &&
              (lastComment ? (
                <button
                  className="border-0 bg-none pl-16 mt-4 text-xl"
                  onClick={onHideCmt}>
                  Ẩn bớt trả lời
                </button>
              ) : (
                <button
                  className="border-0 bg-none pl-16 mt-4 text-xl"
                  onClick={onLoadMoreCmt}>
                  Xem thêm trả lời
                </button>
              ))}
          </div>
          {!!childComments?.length &&
            childComments.map((item) => (
              <CommentChildItem
                key={item?._id}
                onUpdateComment={onUpdateChildComment}
                comment={item}
                onDelete={onDeleteChildComment}
              />
            ))}

          {isReply && (
            <div className="pt-4 pl-6 min-[700px]:pl-16 min-[700px]:pt-6">
              <InputComment
                ref={inputCommentRef}
                refId={comment.refId}
                commentFor={comment.for}
                isReply={true}
                fatherId={comment._id}
                onAdd={onAddComment}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default CommentComponent;
