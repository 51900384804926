let ids: any = '';
export const POST_KEY = 'post';

export const setReadPost = (data: any) => {
  localStorage.setItem(POST_KEY, JSON.stringify(data || {}));
  ids = data;
};

export const getReadPost = () => {
  const data = localStorage.getItem(POST_KEY);
  if (data) {
    try {
      return JSON.parse(data);
    } catch (error) {
      return null;
    }
  }
  return null;
};
