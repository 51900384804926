import { useParams } from 'react-router-dom';
import { getUserLogin } from '../../Ultis/token';
import { GetPageQuery } from '../../graphql/generated';
import Action from './Action';

interface Props {
  data: GetPageQuery | undefined;
}

const HeaderPage = (props: Props) => {
  const { data } = props;
  const { pageId } = useParams();
  const user = getUserLogin();

  return (
    <div className="">
      <div className="ml-[185px] text-xl min-h-[50px]">
        <div className="relative flex flex-col">
          <b>{data?.getPage?.name}</b>
          <div className="flex gap-1">
            {data?.getPage?.categories?.map((item) => {
              return (
                <span className="text-gray-400 text-xs">
                  {item?.name?.VI_VN}
                </span>
              );
            })}
          </div>
        </div>
      </div>
      {pageId && !(data?.getPage?.owner?._id === user._id) && (
        <div className="mt-4 flex justify-end">
          <Action data={data} />
        </div>
      )}
      <hr className=".solid mt-6" />
    </div>
  );
};

export default HeaderPage;
