import { Ref, forwardRef, useImperativeHandle, useState } from 'react';
import { images } from '../../assets/images';
import { User } from '../../graphql/generated';
import './index.scss';

export interface ModalShowMemberRef {
  open: () => void;
  close: () => void;
}

export interface Props {
  info: User[];
}

const ModalShowMember = (props: Props, ref: Ref<ModalShowMemberRef>) => {
  const { info } = props;

  const [visible, setVisible] = useState(false);
  const closeModal = () => {
    setVisible(false);
  };

  const openModal = () => {
    setVisible(!visible);
  };

  useImperativeHandle(ref, () => ({
    open: () => openModal(),
    close: () => closeModal(),
  }));

  return (
    <>
      {visible && (
        <div className="overlay flex justify-center items-center">
          <div className="absolute   modal_show_member bg-white">
            <div className="flex justify-center py-3 bg-primary-yellow header_modal">
              <p className="text-white">Thành viên trong nhóm </p>
            </div>
            <img
              src={images.Close}
              onClick={closeModal}
              className="cursor-pointer close_modal top-[13px] right-[12px]"
            />
            <div>
              {info.map((item: any) => {
                return (
                  <>
                    <div className="flex items-center my-3 ml-3">
                      <div className="h-[40px] w-[40px]">
                        <div
                          className={`img-1-1 ${
                            item.verified
                              ? 'avartar-shadow_verify'
                              : 'avartar-border_default'
                          }`}>
                          <img src={item.avatar?.uri || images.Avatar1} />
                        </div>
                      </div>
                      <p className="ml-5">{item.fullName}</p>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default forwardRef(ModalShowMember);
