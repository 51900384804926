import { ChangeEvent, useEffect, useState } from 'react';
import { AiFillCamera } from 'react-icons/ai';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { queryClient } from '..';
import { getUserLogin } from '../Ultis/token';
import userImg from '../assets//images/user.jpeg';
import cover from '../assets/images/cover.png';
import { useUpdatePage } from '../common/services/api';
import LightboxImageAvt from '../components/lightbox/LightboxImageAvt';
import LoadingGlobal from '../components/loading/LoadingGlobal';
import { PageType } from '../graphql/generated';
import { useUploadPhoto } from '../pages/DetailPost/services/api';
import { TypeMediaFile } from '../pages/Menu/Page/Create/components/StepsLayout';
import { useGetPage } from '../pages/Page/services/api';
import { useGetUser, useUpdateProfile } from '../pages/Profile/services/api';
import Toast, { ToastProps, ToastType } from '../components/toast/Toast';
import NotifyModal from '../components/modal/NotifyModal';
import bgAvatar from '../assets/images/bg-avatar.png';

interface Props {}

const _URL = window.URL || window.webkitURL;

export interface Preview {
  id?: string;
  key: string;
  idx: number;
  uri: string;
  type: 'image' | 'video';
  file: File;
  status: 'Uploading' | 'Completed' | 'Error' | 'Cancel';
  isSkip?: boolean;
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const ProfileLayout = (props: Props) => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );
  const [isOpenAvt, setIsOpenAvt] = useState(false);
  const [isOpenCover, setIsOpenCover] = useState(false);
  const [isLoadingCover, setIsLoadingCover] = useState(false);
  const [isLoadingAvatar, setIsLoadingAvatar] = useState(false);
  const [isShowModalNotify, setIsShowModalNotify] = useState(false);
  const [toast, setToast] = useState<ToastProps>({
    className: 'fixed top-0 right-0',
    isHidden: true,
    type: ToastType.Success,
    message: '',
  });

  const { pathname } = useLocation();
  const url = pathname.split('/')[1].toLocaleLowerCase();
  const user = getUserLogin();
  const { id, pageId } = useParams();
  const { data } = useGetUser(id || (url !== 'page' && user._id));
  const { data: page } = useGetPage(pageId || '');
  const { mutate: updatePage } = useUpdatePage();
  const { mutate: updateProfile } = useUpdateProfile();
  const { mutateAsync: uploadPhotoAsync } = useUploadPhoto();

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    let resetToast: any;
    if (!toast.isHidden) {
      resetToast = setTimeout(() => {
        setToast((preState) => {
          return { ...preState, isHidden: true };
        });
      }, 3000);
    }
    return () => {
      clearTimeout(resetToast);
    };
  }, [toast]);

  const getDimensionOfPhoto = async (file: File) => {
    return new Promise((rs, rj) => {
      const img = new Image();
      const objectUrl = _URL.createObjectURL(file);

      img.onload = () => {
        rs({
          width: img.width,
          height: img.height,
        });
      };
      img.onerror = (e) => {
        rj(e);
      };
      img.src = objectUrl;
    });
  };

  const uploadImage = async (file: File, type: TypeMediaFile) => {
    const imageDimensions: any = await getDimensionOfPhoto(file);
    const uploadPhoto = await uploadPhotoAsync({
      file,
      dimensions: {
        width: imageDimensions.width,
        height: imageDimensions.height,
      },
    });
    return { uploadPhoto, type };
  };

  const handleChangeInputFile = async (e: any) => {
    const file = e.target.files;

    if (file) {
      if (
        file[0].type === 'image/jpeg' ||
        file[0].type === 'image/png' ||
        file[0].type === 'image/jpg'
      ) {
        setIsLoadingAvatar(true);
        uploadImage(file[0], TypeMediaFile.Avatar).then((data) => {
          if (url === 'page') {
            updatePage(
              {
                data: {
                  name: page?.getPage?.name || '',
                  type: !!page?.getPage?.type
                    ? page?.getPage?.type
                    : PageType.BusinessOrBrand,
                  logoMediaId: data?.uploadPhoto?.uploadPhoto?._id,
                },
                id: pageId || '',
              },
              {
                onSuccess() {
                  setIsLoadingAvatar(false);
                  setToast({
                    ...toast,
                    message: `Cập nhật ảnh đại diện thành công`,
                    isHidden: false,
                    type: ToastType.Success,
                  });
                  queryClient.invalidateQueries(['getPage']);
                },
              },
            );
          } else {
            updateProfile(
              {
                data: {
                  avatarMediaId: data?.uploadPhoto?.uploadPhoto?._id,
                },
              },
              {
                onSuccess(data) {
                  localStorage.setItem(
                    'user',
                    JSON.stringify(data?.updateProfile || {}),
                  );
                  queryClient.invalidateQueries(['getInfoUser']);
                  setIsLoadingAvatar(false);
                  setToast({
                    ...toast,
                    message: `Cập nhật ảnh đại diện thành công`,
                    isHidden: false,
                    type: ToastType.Success,
                  });
                },
              },
            );
          }
        });
      } else {
        setIsLoadingAvatar(false);
        setIsShowModalNotify(true);
        e.target.value = null;
      }
    }
  };

  const handleChangeInputCoverFile = async (e: any) => {
    const file = e.target.files;

    if (file) {
      if (
        file[0].type === 'image/jpeg' ||
        file[0].type === 'image/png' ||
        file[0].type === 'image/jpg'
      ) {
        setIsLoadingCover(true);
        uploadImage(file[0], TypeMediaFile.Cover).then((data) => {
          if (url === 'page') {
            updatePage(
              {
                data: {
                  name: page?.getPage?.name || '',
                  type: !!page?.getPage?.type
                    ? page?.getPage?.type
                    : PageType.BusinessOrBrand,
                  coverMediaId: data?.uploadPhoto?.uploadPhoto?._id,
                },
                id: pageId || '',
              },
              {
                onSuccess() {
                  setIsLoadingCover(false);
                  setToast({
                    ...toast,
                    message: `Cập nhật ảnh bìa thành công`,
                    isHidden: false,
                    type: ToastType.Success,
                  });
                  queryClient.invalidateQueries(['getPage']);
                },
              },
            );
          } else {
            updateProfile(
              {
                data: {
                  coverMediaId: data?.uploadPhoto?.uploadPhoto?._id,
                },
              },
              {
                onSuccess() {
                  setIsLoadingCover(false);
                  setToast({
                    ...toast,
                    message: `Cập nhật ảnh bìa thành công`,
                    isHidden: false,
                    type: ToastType.Success,
                  });
                },
              },
            );
          }
        });
      } else {
        setIsLoadingCover(false);
        setIsShowModalNotify(true);
        e.target.value = null;
      }
    }
  };

  const handleClickChangeAvatar = (e: any) => {
    if (!isLoadingAvatar) {
      e.stopPropagation();
      document.getElementById('avatar')?.click();
    }
  };

  const handleClickChangeCover = (e: any) => {
    if (!isLoadingCover) {
      e.stopPropagation();
      document.getElementById('cover')?.click();
    }
  };

  const handleCloseModalNotify = () => {
    setIsShowModalNotify(false);
  };

  const isOwner =
    user?._id === page?.getPage?.owner?._id || pathname === '/profile';

  return (
    <>
      {isShowModalNotify && (
        <NotifyModal
          isShow={isShowModalNotify}
          message="Sai định dạng ảnh, vui lòng thử lại."
          onClose={handleCloseModalNotify}
        />
      )}
      <div className="bg-third-gray relative">
        <div className="container m-auto relative">
          <Toast
            message={toast.message}
            className={`${toast.className} absolute top-1 `}
            type={toast.type}
            isHidden={toast.isHidden}
          />
          <div
            className={`profileImage relative h-[280px] !max-w-[${windowDimensions.width}px] mb-[-24px]`}
            style={{
              backgroundImage: `url("${
                (url === 'page'
                  ? page?.getPage?.cover?.uri
                  : data?.getPublicProfile.cover?.uri) || cover
              }")`,
            }}
            onClick={() => setIsOpenCover(true)}>
            {isOwner && (
              <div
                onClick={handleClickChangeCover}
                className="cursor-pointer absolute bottom-[10px] right-[10px] z-10 h-[30px] w-[30px] rounded-full bg-slate-400 border-[1px] border-solid flex items-center justify-center border-gray-50">
                <AiFillCamera color="white" />
              </div>
            )}

            {isLoadingCover && <LoadingGlobal right={50} top={5} />}
          </div>
          <div
            className="absolute top-[205px]  left-[30px]"
            onClick={() => setIsOpenAvt(true)}>
            <div
              className={`h-[150px] w-[150px] bg-white rounded-full ${
                (isOwner || data?.getPublicProfile.verified) &&
                'shadow_verify_lg'
              }`}>
              <div className="img-1-1  ">
                <img
                  src={
                    (url === 'page'
                      ? page?.getPage?.logo?.uri
                      : data?.getPublicProfile.avatar?.uri) || userImg
                  }
                  alt="avatar"
                />
              </div>
            </div>
            {isOwner && (
              <div
                onClick={handleClickChangeAvatar}
                className="cursor-pointer absolute bottom-[10px] right-[10px] z-50 h-[30px] w-[30px] rounded-full bg-slate-400 border-[1px] border-solid flex items-center justify-center border-gray-50">
                <AiFillCamera color="white" />
              </div>
            )}
            {isLoadingAvatar && (
              <LoadingGlobal classes="top-[2px] left-[50px]" />
            )}
          </div>

          <input
            accept=".jpeg, .jpg, .png"
            type="file"
            name="avatar"
            id="avatar"
            hidden={true}
            onChange={handleChangeInputFile}
          />
          <input
            accept=".jpeg, .jpg, .png"
            type="file"
            name="cover"
            id="cover"
            hidden={true}
            onChange={handleChangeInputCoverFile}
          />
          <LightboxImageAvt
            index={0}
            slides={[
              {
                src:
                  (url === 'page'
                    ? page?.getPage?.logo?.uri
                    : data?.getPublicProfile.avatar?.uri) || userImg,
                width:
                  url === 'page'
                    ? page?.getPage?.logo?.dimensions?.width
                    : data?.getPublicProfile.avatar?.dimensions?.width,
                height:
                  url === 'page'
                    ? page?.getPage?.logo?.dimensions?.height
                    : data?.getPublicProfile.avatar?.dimensions?.height,
              },
            ]}
            isOpen={isOpenAvt}
            onClose={() => setIsOpenAvt(false)}
          />
          <LightboxImageAvt
            index={0}
            slides={[
              {
                src:
                  (url === 'page'
                    ? page?.getPage?.cover?.uri
                    : data?.getPublicProfile.cover?.uri) || cover,
                width:
                  url === 'page'
                    ? page?.getPage?.cover?.dimensions?.width
                    : data?.getPublicProfile.cover?.dimensions?.width,
                height:
                  url === 'page'
                    ? page?.getPage?.cover?.dimensions?.height
                    : data?.getPublicProfile.cover?.dimensions?.height,
              },
            ]}
            isOpen={isOpenCover}
            onClose={() => setIsOpenCover(false)}
          />
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default ProfileLayout;
