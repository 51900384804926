import React, { useEffect, useState } from 'react';
import Header from './Header';
import MenuComponents from './MenuComponents';
import { Outlet } from 'react-router-dom';
import './index.scss';

interface Props {}

const MessageLayout = (props: Props) => {
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);

  const handleScroll = () => {
    const currentScrollPos = window.scrollY;

    if (currentScrollPos > prevScrollPos) {
      setVisible(false);
    } else {
      setVisible(true);
    }

    setPrevScrollPos(currentScrollPos);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  });

  return (
    <div className="flex flex-col items-center justify-center w-screen min-h-screen bg-white">
      <div className="container mx-auto flex flex-col flex-grow w-full bg-white shadow-xl overflow-hidden">
        <Outlet />
      </div>
    </div>
  );
};

export default MessageLayout;
