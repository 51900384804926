import {
  Ref,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { queryClient } from '../..';
import { images } from '../../assets/images';
import { JoinGroupRequestStatus } from '../../graphql/generated';
import {
  useAcceptJoinGroupRequest,
  useApproveAllJoinGroupRequest,
  useGetJoinGroupRequests,
  useRejectAllJoinGroupRequest,
  useRejectJoinGroupRequest,
} from '../../pages/Groups/services/api';
import Toast, { ToastProps, ToastType } from '../toast/Toast';
import './index.scss';
import { DiffDate } from '../../Ultis/Datetime';

export interface ModalListRequestJoinGroupRef {
  open: () => void;
  close: () => void;
}

export interface Props {
  info: any[];
  isOwner: boolean;
}

const ModalListRequestJoinGroup = (
  props: Props,
  ref: Ref<ModalListRequestJoinGroupRef>,
) => {
  const [visible, setVisible] = useState(false);
  const [toast, setToast] = useState<ToastProps>({
    className: 'fixed top-0 right-0',
    isHidden: true,
    type: ToastType.Success,
    message: '',
  });

  const { groupId } = useParams();
  const navigate = useNavigate();
  const { mutateAsync: mutateAcceptJoinGroup } = useAcceptJoinGroupRequest();
  const { mutateAsync: mutateRejectJoinGroup } = useRejectJoinGroupRequest();
  const { mutateAsync: mutateApproveAllJoinGroup } =
    useApproveAllJoinGroupRequest();
  const { mutateAsync: mutateRejectAllJoinGroup } =
    useRejectAllJoinGroupRequest();

  const { data: listRq } = useGetJoinGroupRequests({
    groupId: groupId || '',
    limit: 999,
    status: JoinGroupRequestStatus.Pending,
  });

  const closeModal = () => {
    setVisible(false);
  };

  const openModal = () => {
    setVisible(!visible);
  };

  useEffect(() => {
    let resetToast: any;
    if (!toast.isHidden) {
      resetToast = setTimeout(() => {
        setToast((preState) => {
          return { ...preState, isHidden: true };
        });
      }, 3000);
    }
    return () => {
      clearTimeout(resetToast);
    };
  }, [toast]);

  useImperativeHandle(ref, () => ({
    open: () => openModal(),
    close: () => closeModal(),
  }));

  const handleGoToProfile = (userId: string) => {
    navigate(`/profile/${userId}`);
  };

  const handleClickAccept = (item: any) => {
    mutateAcceptJoinGroup(
      {
        requestId: item?._id,
      },
      {
        onSuccess() {
          queryClient.invalidateQueries(['getJoinGroupRequests']);
          setToast({
            ...toast,
            message: `Thành công ! ${item?.user?.fullName} đã trở thành thành viên của nhóm`,
            isHidden: false,
            type: ToastType.Success,
          });
        },
        onError() {
          setToast({
            ...toast,
            message: `Có gì đó không đúng. Vui lòng thử lại`,
            isHidden: false,
            type: ToastType.Danger,
          });
        },
      },
    );
  };

  const handleClickReject = (item: any) => {
    mutateRejectJoinGroup(
      {
        requestId: item?._id,
      },
      {
        onSuccess() {
          queryClient.invalidateQueries(['getJoinGroupRequests']);
          setToast({
            ...toast,
            message: `Bạn đã xóa yêu cầu vào nhóm của ${item?.user?.fullName} `,
            isHidden: false,
            type: ToastType.Success,
          });
        },
        onError() {
          setToast({
            ...toast,
            message: `Có gì đó không đúng. Vui lòng thử lại`,
            isHidden: false,
            type: ToastType.Danger,
          });
        },
      },
    );
  };

  const handleAcceptAll = () => {
    mutateApproveAllJoinGroup(
      {
        groupId: groupId || '',
      },
      {
        onSuccess() {
          queryClient.invalidateQueries(['getJoinGroupRequests']);
          setToast({
            ...toast,
            message: `Bạn đã chấp nhận tất cả các yêu cầu vào nhóm `,
            isHidden: false,
            type: ToastType.Success,
          });
        },
        onError() {
          setToast({
            ...toast,
            message: `Có gì đó không đúng. Vui lòng thử lại`,
            isHidden: false,
            type: ToastType.Danger,
          });
        },
      },
    );
  };

  const handelClickRejectAll = () => {
    mutateRejectAllJoinGroup(
      {
        groupId: groupId || '',
      },
      {
        onSuccess() {
          queryClient.invalidateQueries(['getJoinGroupRequests']);
          setToast({
            ...toast,
            message: `Bạn đã xóa tất cả các yêu cầu vào nhóm `,
            isHidden: false,
            type: ToastType.Success,
          });
        },
        onError() {
          setToast({
            ...toast,
            message: `Có gì đó không đúng. Vui lòng thử lại`,
            isHidden: false,
            type: ToastType.Danger,
          });
        },
      },
    );
  };

  return (
    <>
      <Toast
        message={toast.message}
        className={`${toast.className} absolute top-1`}
        type={toast.type}
        isHidden={toast.isHidden}
      />
      {visible && (
        <div className="overlay flex justify-center items-center  ">
          <div className="absolute   modal_show_member bg-white w-[350px]">
            <div className="relative">
              <div className="flex justify-center py-3 bg-primary-yellow header_modal">
                <p className="text-white">Yêu cầu vào nhóm </p>
              </div>
              <img
                src={images.Close}
                onClick={closeModal}
                className="cursor-pointer close_modal absolute right-3 top-3"
              />
            </div>
            <div className="min-h-[200px] max-h-[350px] overflow-auto pb-16">
              {(listRq?.getJoinGroupRequests || []).length > 0 ? (
                (listRq?.getJoinGroupRequests || []).map((item: any) => {
                  return (
                    <>
                      <div
                        className="border-b-[1px] border-solid border-gray-200   item-last-member"
                        key={item?._id}>
                        <div className="flex items-center justify-between p-2">
                          <div className="flex items-start w-full gap-4">
                            <div
                              className=" border-[1px] border-solid border-gray-200 rounded-full cursor-pointer"
                              onClick={() =>
                                handleGoToProfile(item?.userId || '')
                              }>
                              <div className="img-1-1 h-[45px] w-[45px]">
                                <img
                                  src={item.user?.avatar?.uri || images.Avatar1}
                                />
                              </div>
                            </div>
                            <div className="w-full">
                              <p
                                className=" text-break-word  font-semibold cursor-pointer hover:text-primary-yellow capitalize"
                                onClick={() =>
                                  handleGoToProfile(item?.userId || '')
                                }>
                                {item.user.fullName}
                              </p>
                              <p className="mb-2 text-gray-500 text-sm">
                                {DiffDate(item?.createdAt)}
                              </p>
                              <div className="flex w-full gap-2">
                                <button
                                  className="bg-primary-yellow hover:bg-teal-500 text-center py-[7px] text-white rounded-[8px] w-full text-sm "
                                  onClick={() => handleClickAccept(item)}>
                                  Chấp nhận
                                </button>
                                <button
                                  className="bg-gray-300 text-center py-1 rounded-[8px] w-full text-sm hover:bg-gray-200"
                                  onClick={() => handleClickReject(item)}>
                                  Xóa
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })
              ) : (
                <h3 className="text-center pt-16">
                  Hiện tại chưa có yêu cầu tham gia nào !
                </h3>
              )}
            </div>
            {(listRq?.getJoinGroupRequests || []).length > 0 ? (
              <div className="flex w-full gap-2 absolute bottom-0 p-2 bg-white">
                <button
                  className="bg-primary-yellow text-center py-[10px] text-white rounded-[8px] w-full text-sm hover:bg-teal-500"
                  onClick={handleAcceptAll}>
                  Chấp nhận tất cả
                </button>
                <button
                  className="bg-gray-300 text-center py-[10px] rounded-[8px] w-full text-sm hover:bg-gray-200"
                  onClick={handelClickRejectAll}>
                  Xóa tất cả
                </button>
              </div>
            ) : (
              <>
                <div className="flex w-full justify-center gap-2 absolute bottom-0 px-2 py-4 bg-white">
                  <button
                    className="bg-primary-yellow text-center py-[10px] text-white rounded-[8px] w-[100px] text-sm hover:bg-teal-500"
                    onClick={closeModal}>
                    Xong
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default forwardRef(ModalListRequestJoinGroup);
