import { Link } from 'react-router-dom';
import Avatar from '../../../components/avatar/Avatar';
import PlusSvg from '../../../assets/images/icon/plus.svg';

export default function CreateGroupItem() {
  return (
    <>
      <div
        className={`flex p-2 shadow-inner bg-white rounded  items-center  border border-solid border-1 h-28`}>
        <div className="ml-2">
          <Link to="/groups/create">
            <div className="flex items-center justify-center p-3 w-14 h-14 bg-[#05A2AB]/30 rounded-full">
              <Avatar src={PlusSvg} className="rounded-full w-5 h-5" />
            </div>
          </Link>
        </div>
        <div className={`ml-3`}>
          <div>
            <Link to="/groups/create" className="h-1">
              <span className="text-md w-15 font-semibold text-black">
                Tạo nhóm
              </span>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
