import { Link, useNavigate } from 'react-router-dom';
import { formatCurrency } from '../../Ultis/number';
import user from '../../assets/images/user.jpeg';
import { GetPageProductQuery } from '../../graphql/generated/index';
import { images } from '../../assets/images';
import ButtonComponent from '../button/ButtonComponent';

interface Props {
  data: GetPageProductQuery | undefined;
  pageId: string;
}

export const maxText = (text: string, maxText: number) => {
  if (text.length >= maxText) {
    return text.slice(0, maxText - 3) + '...';
  }
  return text;
};

const Products = (props: Props) => {
  const { data, pageId } = props;
  const navigate = useNavigate() 

  return (
    <div>
      <div className="flex items-center justify-between ml-2 mt-2">
        <div>
          <b className="text-2xl">Sản phẩm và dịch vụ</b>
        </div>
        <Link className="relative md:ml-3 ml-2" to={'/page/' + pageId + '/products'}>
          <div className="text-primary-yellow">Xem tất cả</div>
        </Link>
      </div>
      <div className="ml-2 mt-2  overflow-auto">
        <div className="flex">
          {(data?.pageProducts?.total && data?.pageProducts?.total > 0) ? data?.pageProducts?.data?.map((item) => {
            return (
              <Link className="relative md:ml-3 ml-2" to={'/page/' + pageId + '/products'}>
                <div
                  className="profileImage w-44 h-44 border box-border rounded-2xl mt-4"
                  style={{ backgroundImage: `url("${(item.medias && item.medias[0]?.uri) || user}")` }}
                />
                <div>
                  <b>{maxText(item.title || '', 20)}</b>
                </div>
                <div className="text-primary-yellow">{formatCurrency(item.price || 0) + ' VNĐ'}</div>
              </Link>
            );
          }) : <>
            <div className='flex flex-col items-center justify-center gap-3 w-full'>
              <img src={images.Shop1} alt="shop icon" />
                <p className='px-12 text-center'>Thêm sản phẩm, dịch vụ
                mà trang của bạn cung cấp</p>
                <button className='px-6 py-3 w-36 rounded-md text-[#05A2AB] font-semibold bg-[#05A2AB]/25' onClick={() => navigate(`/page/${pageId}/products/create`)}>Thêm</button>
            </div>
          </>}
        </div>
      </div>
    </div>
  );
};

export default Products;
