import { useEffect, useRef, useState } from 'react';
import { AiFillCheckCircle, AiOutlineUserSwitch } from 'react-icons/ai';
import { BiSearch } from 'react-icons/bi';
import { MdClear, MdGroupAdd } from 'react-icons/md';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';
import { queryClient } from '../..';
import { convertVietnamese } from '../../Ultis/searchVietnamese';
import { getUserLogin } from '../../Ultis/token';
import { images } from '../../assets/images/index';
import Toast, { ToastProps, ToastType } from '../../components/toast/Toast';
import {
  GetChatQueryVariables,
  GetPageConversationsQueryVariables,
  useGetChatQuery,
  useGetPageConversationsQuery,
} from '../../graphql/generated';
import { graphqlClientRequest } from '../../graphql/services/graphql-client';
import { useGetMyPages } from '../ListPage/services/api';
import ItemMess from './components/ItemMess';
import './index.scss';
import { useOnClickOutside } from '../../Ultis/hooks/useClickOutside';

export enum StateFetch {
  GETTING_FIRST = 'GETTING_FIRST',
  GETTING_MORE = 'GETTING_MORE',
}

interface CurrentMess {
  id: string;
  logo: string;
  name: string;
}

const Messenger = () => {
  const [params, setParams] = useState<GetChatQueryVariables>({
    limit: 100,
    forward: false,
  });
  const [list, setList] = useState<any>([]);
  const [listConversationPage, setListConversationPage] = useState<any>([]);
  const [stateFetch, setStateFetch] = useState(StateFetch.GETTING_FIRST);
  const [stateFetchOfConversationPage, setStateFetchOfConversationPage] =
    useState(StateFetch.GETTING_FIRST);
  const [currentMess, setCurrentMess] = useState<CurrentMess>();
  const [currentPageId, setCurrentPageId] = useState<string>('');
  const [messOfUser, setMessOfUser] = useState<any>([]);
  const [isShowSelect, setIsShowSelect] = useState(false);
  const [search, setSearch] = useState<string>('');
  const [toast, setToast] = useState<ToastProps>({
    className: 'fixed top-0 right-0',
    isHidden: true,
    type: ToastType.Success,
    message: '',
  });
  const [paramsConversationsPage, setParamsConversationsPage] =
    useState<GetPageConversationsQueryVariables>({
      pageId: '',
      limit: 100,
      forward: false,
    });

  const ref = useRef<any>();
  const me = getUserLogin();
  const navigate = useNavigate();
  const { data: myPages } = useGetMyPages();
  useOnClickOutside(ref, () => setIsShowSelect(false));
  const { data } = useGetChatQuery(graphqlClientRequest(true), params, {});
  const { data: conversationsPageData } = useGetPageConversationsQuery(
    graphqlClientRequest(true),
    paramsConversationsPage,
    {
      enabled: !!currentPageId,
    },
  );

  useEffect(() => {
    if (me && !localStorage.getItem('currentChatAccount')) {
      setCurrentMess({
        id: me?._id,
        logo: me?.avatar?.uri,
        name: me?.fullName,
      });
    } else if (!!localStorage.getItem('currentChatAccount')) {
      const currentChatAccount = localStorage.getItem('currentChatAccount');
      if (!!currentChatAccount) {
        const localAccount = JSON.parse(currentChatAccount);
        setCurrentMess({
          id: localAccount?._id,
          logo: localAccount?.logo?.uri,
          name: localAccount?.name,
        });
      }
    }
  }, []);

  useEffect(() => {
    queryClient.invalidateQueries(['getChat']);
  }, []);

  useEffect(() => {
    if (stateFetch === StateFetch.GETTING_FIRST) {
      setList(data?.getConversations || []);
    } else {
      setList([...list, ...(data?.getConversations || [])]);
    }
  }, [data?.getConversations, data?.getConversations?.length]);

  useEffect(() => {
    if (stateFetchOfConversationPage === StateFetch.GETTING_FIRST) {
      setListConversationPage(
        conversationsPageData?.getPageConversations || [],
      );
    } else {
      setListConversationPage([
        ...listConversationPage,
        ...(conversationsPageData?.getPageConversations || []),
      ]);
    }
  }, [
    conversationsPageData?.getPageConversations,
    conversationsPageData?.getPageConversations?.length,
  ]);

  const onSearchChat = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const clearSearch = () => {
    setSearch('');
  };

  useEffect(() => {
    let resetToast: any;
    if (!toast.isHidden) {
      resetToast = setTimeout(() => {
        setToast((preState) => {
          return { ...preState, isHidden: true };
        });
      }, 3000);
    }
    return () => {
      clearTimeout(resetToast);
    };
  }, [toast]);

  const fetchData = () => {
    setStateFetch(StateFetch.GETTING_MORE);
    const newParams = { ...params, forward: true };
    if (data && data?.getConversations.length > 0) {
      newParams.after =
        data?.getConversations[data?.getConversations.length - 1]._id;
    }

    setParams(newParams);
  };

  const fetchConversationsPage = () => {
    setStateFetchOfConversationPage(StateFetch.GETTING_MORE);
    const newParams = { ...paramsConversationsPage, forward: true };
    if (
      conversationsPageData &&
      conversationsPageData?.getPageConversations.length > 0
    ) {
      newParams.after =
        conversationsPageData?.getPageConversations[
          conversationsPageData?.getPageConversations.length - 1
        ]._id;
    }
    setParamsConversationsPage(newParams);
  };

  const handleShowSelect = () => {
    setIsShowSelect(!isShowSelect);
  };

  const handleSelectItem = (item: any) => {
    setCurrentMess({
      id: item?._id,
      logo: item?.logo?.thumbnail,
      name: item?.name,
    });
    setIsShowSelect(false);
    localStorage.setItem('currentChatAccount', JSON.stringify(item));
  };

  const handleSelectItemMe = () => {
    setCurrentMess({
      id: me?._id,
      logo: me?.avatar?.uri,
      name: me?.fullName,
    });
    localStorage.setItem('currentChatAccount', '');
    setIsShowSelect(false);
  };

  const refresh = () => {};

  useEffect(() => {
    const mess = (list || []).filter((item: any) => {
      if (
        !item?.page ||
        (!!item?.page && (item?.users || [])[0]?._id === me?._id)
      ) {
        return item;
      } else {
        return;
      }
    });
    setMessOfUser(mess);
  }, [list]);

  useEffect(() => {
    if (currentMess?.id !== me?._id) {
      setCurrentPageId(currentMess?.id || '');
      const newParams = {
        ...paramsConversationsPage,
        pageId: currentMess?.id || '',
      };
      setParamsConversationsPage(newParams);
    }
  }, [currentMess]);

  return (
    <>
      <Toast
        message={toast.message}
        className={`${toast.className} fixed top-3`}
        type={toast.type}
        isHidden={toast.isHidden}
      />
      <div className="pt-5 bg-white px-5 section-message">
        <div className="flex mb-5 lg:-space-x-4 items-center justify-between w-full -space-x-2">
          <div className="relative ">
            <div className="flex gap-2 items-center">
              <div className="cursor-pointer relative">
                <img
                  src={currentMess?.logo || images.AvatarDefault}
                  alt=""
                  className="h-[50px] w-[50px] rounded-full border-[1px] border-solid border-green-500 p-[2px]"
                />
                <div
                  className="absolute bottom-0 right-0 p-[3px] border-[1px] rounded-full border-solid bg-green-500"
                  onClick={handleShowSelect}>
                  <AiOutlineUserSwitch color="white" />
                </div>
              </div>
              <h1 className="font-semibold text-2xl">Tin nhắn</h1>
            </div>
            {isShowSelect && (
              <div
                ref={ref}
                className="section-select absolute left-0 top-[55px] max-h-[275px] w-[250px] overflow-auto  bg-white z-50  p-2 border-[1px] border-solid border-gray-200 rounded-[10px]">
                <h3 className="text-center font-semibold py-2">
                  Chat bằng tài khoản
                </h3>
                <div
                  className="item relative flex gap-2 items-center w-full min-h-[50px] cursor-pointer hover:text-cyan-600 border-b-[1px] border-solid border-gray-100 last:border-b-0"
                  onClick={handleSelectItemMe}>
                  <img
                    src={me?.avatar?.uri || images.AvatarDefault}
                    alt=""
                    className="h-[30px] w-[30px] rounded-full border-[1px] border-solid border-gray-200"
                  />
                  <p
                    className={`text-base capitalize ${
                      me?._id === currentMess?.id && 'text-cyan-600'
                    }`}>
                    {me?.fullName}
                  </p>
                  {me?._id === currentMess?.id && (
                    <AiFillCheckCircle className="absolute right-0 top-[15px] text-cyan-600 text-xl" />
                  )}
                </div>
                {myPages?.getMyPages?.map((item) => {
                  return (
                    <div
                      className="item relative flex gap-2 items-center w-full min-h-[50px] cursor-pointer hover:text-cyan-600 border-b-[1px] border-solid border-gray-100 last:border-b-0 pr-5"
                      onClick={() => handleSelectItem(item)}
                      key={item?._id}>
                      <img
                        src={item?.logo?.thumbnail || images.AvatarDefault}
                        alt=""
                        className="h-[30px] w-[30px] rounded-full border-[1px] border-solid border-gray-200"
                      />
                      <p
                        className={`text-base capitalize ${
                          item?._id === currentMess?.id && 'text-cyan-600'
                        }`}>
                        {item?.name}
                      </p>
                      {item?._id === currentMess?.id && (
                        <AiFillCheckCircle className="absolute right-0 top-[15px] text-cyan-600 text-xl" />
                      )}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          {currentMess?.id === me?._id && (
            <div
              onClick={() => navigate('/messenger/new-group')}
              className="cursor-pointer p-1">
              <MdGroupAdd size={20} />
            </div>
          )}
        </div>
        <div className="flex justify-end  items-center relative  mt-3">
          <input
            type="text"
            placeholder="Tìm kiếm"
            className="border py-2 search_input rounded-[20px] px-5 w-full"
            onChange={onSearchChat}
            value={search}
          />
          {search ? (
            <MdClear
              size={20}
              className="absolute icon_remove cursor-pointer"
              onClick={clearSearch}
            />
          ) : (
            <BiSearch size={20} className=" icon_search " />
          )}
        </div>
        <InfiniteScroll
          dataLength={
            messOfUser?.length ||
            conversationsPageData?.getPageConversations?.length ||
            0
          } //This is important field to render the next data
          next={
            currentMess?.id === me?._id ? fetchData : fetchConversationsPage
          }
          hasMore={true}
          loader={<></>}
          endMessage={
            <p style={{ textAlign: 'center' }}>
              <b>Yay! You have seen it all</b>
            </p>
          }
          // below props only if you need pull down functionality
          refreshFunction={refresh}
          pullDownToRefresh
          pullDownToRefreshThreshold={50}>
          <div className="mt-5">
            {(currentMess?.id === me?._id
              ? messOfUser.filter((item: any) =>
                  convertVietnamese(
                    item.name
                      ? item.name.toLocaleLowerCase()
                      : item.page?.name
                      ? item.page?.name.toLocaleLowerCase()
                      : item.partners?.length !== 0
                      ? item?.partners[0].fullName.toLocaleLowerCase()
                      : '',
                  ).includes(convertVietnamese(search.toLocaleLowerCase())),
                )
              : listConversationPage.filter((item: any) =>
                  convertVietnamese(
                    item.users?.length !== 0
                      ? item.users[0].fullName.toLocaleLowerCase()
                      : '',
                  ).includes(convertVietnamese(search.toLocaleLowerCase())),
                )
            )
              // list
              .map((value: any) => {
                return <ItemMess value={value} key={value?._id} />;
              })}
          </div>
        </InfiniteScroll>
      </div>
    </>
  );
};

export default Messenger;
