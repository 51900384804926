import { useMatch, useNavigate } from 'react-router-dom';
import { images } from '../../../assets/images';
import { useGetInfoUserQuery } from '../../../graphql/generated';
import { graphqlClientRequest } from '../../../graphql/services/graphql-client';
import { formatBirthDay } from '../../../Ultis/Datetime';
import './index.scss';

const Edit = () => {
  const uid = localStorage.getItem('uid');
  const { data } = useGetInfoUserQuery(graphqlClientRequest(true), {
    id: uid?.replace(/['"]+/g, '') || '',
  });

  const navigate = useNavigate();
  const match = useMatch('/menu/edit');
  const isEdit = Boolean(match);

  return (
    <div className="px-5 bg-white">
      {isEdit && (
        <h2 className="text-2xl font-semibold text-center py-3">
          Chỉnh sửa thông tin
        </h2>
      )}
      <div className="flex items-center justify-between">
        <div className="avatar_menu flex items-center">
          {data?.getPublicProfile?.avatar?.uri && (
            <img
              src={data.getPublicProfile.avatar.uri}
              alt="avatar"
              width={120}
              height={120}
              className={`${
                data.getPublicProfile.verified && 'shadow_verify'
              } rounded-full`}
            />
          )}
          {!data?.getPublicProfile.avatar?.uri && (
            <img
              src={images.AvatarDefault}
              alt="avatar"
              width={120}
              height={120}
              className={`${
                data?.getPublicProfile.verified && 'shadow_verify'
              } rounded-full`}
            />
          )}
          <div>
            <p className="text-xl ml-5 font-semibold">Xác minh tài khoản</p>
            <div className="ml-5 flex">
              {' '}
              {data?.getPublicProfile.verified ? (
                <>
                  <img src={images.Tick} alt="tick" width={20} height={20} />
                  <p className="text-sm ml-2 font-semibold text-primary-green">
                    Đã xác minh
                  </p>
                </>
              ) : (
                <>
                  {' '}
                  <p className="text-sm ml-2 font-semibold text-primary-yellow">
                    Chưa xác minh
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="mt-6 pt-1 bg-gray-200">
        <div className="flex justify-between py-5 bg-white">
          <div className="flex  py-2">
            <img src={images.User} />
            <p className="ml-3 text-xl font-semibold ">Thông tin cá nhân</p>
          </div>
          <div className="flex items-center">
            <img src={images.Pen} width={20} height={20} />
            <p className="ml-3 text-sm font-semibold text-primary-yellow">
              Chỉnh sửa
            </p>
          </div>
        </div>
        <div className="py-5 border-b-light-gray border-t-white border border-r-white border-l-white bg-white">
          <p className="text-primary-gray">Tên hiển thị</p>
          <p>{data?.getPublicProfile.fullName}</p>
        </div>
        <div className="py-5 border-b-light-gray border-t-white border border-r-white border-l-white bg-white">
          <p className="text-primary-gray">Giới tính</p>
          {data?.getPublicProfile.gender === 'FEMALE' ? <p>Nữ</p> : <p>Nam</p>}
        </div>
        <div className="py-5 border-b-light-gray border-t-white border border-r-white border-l-white bg-white">
          <p className="text-primary-gray">Ngày sinh</p>
          <p>{formatBirthDay(data?.getPublicProfile.dateOfBirth)}</p>
        </div>
        <div className="py-5 border-b-light-gray border-t-white border border-r-white border-l-white bg-white">
          <p className="text-primary-gray">Quốc tịch</p>
          <p>Việt Nam</p>
        </div>
      </div>

      <div className="mt-5 pt-1 bg-gray-200">
        <div className="flex justify-between py-2 bg-white">
          <div className="flex  py-2">
            <img src={images.PhoneGray} />
            <p className="ml-3 text-xl font-semibold ">Thông tin liên hệ</p>
          </div>
          <div className="flex items-center">
            <img src={images.Pen} width={20} height={20} />
            <p className="ml-3 text-sm font-semibold text-primary-yellow">
              Chỉnh sửa
            </p>
          </div>
        </div>
        <div className="py-2 border-b-light-gray border-t-white border border-r-white border-l-white bg-white">
          <p className="text-primary-gray">Số điện thoại</p>
          <p>{data?.getPublicProfile.phoneNumber}</p>
        </div>
        <div className="py-2 border-b-light-gray border-t-white border border-r-white border-l-white bg-white">
          <p className="text-primary-gray">Email</p>
          <p>{data?.getPublicProfile.email}</p>
        </div>
      </div>
      {data?.getPublicProfile.informationListing?.map((item) => {
        switch (item.type) {
          case 'CURRENT_CITY': {
            return (
              <>
                <div className="mt-5 pt-1 bg-gray-200">
                  <div className="flex justify-between py-2 bg-white">
                    <div className="flex  py-2">
                      <img src={images.Location} />
                      <p className="ml-3 text-xl font-semibold ">
                        Nơi ở hiện tại
                      </p>
                    </div>
                    <div className="flex items-center">
                      <img src={images.Pen} width={20} height={20} />
                      <p className="ml-3 text-sm font-semibold text-primary-yellow">
                        Chỉnh sửa
                      </p>
                    </div>
                  </div>
                  <div className="py-2 border-b-light-gray border-t-white border border-r-white border-l-white bg-white">
                    {item.value ? (
                      <>
                        <p className="text-primary-gray">Nơi ở hiện tại</p>
                        <p className="text-primary-gray">{item.value}</p>
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </>
            );
          }

          case 'HOMETOWN': {
            return (
              <>
                <div className="mt-5 pt-1 bg-gray-200">
                  <div className="flex justify-between py-2 bg-white">
                    <div className="flex  py-2">
                      <img src={images.Location} />
                      <p className="ml-3 text-xl font-semibold ">Quê quán</p>
                    </div>
                    <div className="flex items-center">
                      <img src={images.Pen} width={20} height={20} />
                      <p className="ml-3 text-sm font-semibold text-primary-yellow">
                        Chỉnh sửa
                      </p>
                    </div>
                  </div>
                  <div className="py-2 border-b-light-gray border-t-white border border-r-white border-l-white bg-white">
                    {item.value ? (
                      <>
                        <p className="text-primary-gray">Quê quán</p>
                        <p className="text-primary-gray">{item.value}</p>
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </>
            );
          }

          case 'EDUCATION': {
            return (
              <div className="mt-5 pt-1 bg-gray-200">
                <div className="flex justify-between py-2 bg-white">
                  <div className="flex  py-2">
                    <img src={images.Graduation} />
                    <p className="ml-3 text-xl font-semibold ">Học vấn</p>
                  </div>
                  <div className="flex items-center">
                    <img src={images.Pen} width={20} height={20} />
                    <p className="ml-3 text-sm font-semibold text-primary-yellow">
                      Chỉnh sửa
                    </p>
                  </div>
                </div>
                <div className="py-2 border-b-light-gray border-t-white border border-r-white border-l-white bg-white">
                  {item.value ? (
                    <>
                      <p className="text-primary-gray">Học vấn</p>
                      <p className="text-primary-gray">{item.value}</p>
                    </>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            );
          }

          case 'WORK': {
            return (
              <div className="mt-5 pt-1 bg-gray-200">
                <div className="flex justify-between py-2 bg-white">
                  <div className="flex  py-2">
                    <img src={images.Bag} />
                    <p className="ml-3 text-xl font-semibold ">Công việc</p>
                  </div>
                  <div className="flex items-center">
                    <img src={images.Pen} width={20} height={20} />
                    <p className="ml-3 text-sm font-semibold text-primary-yellow">
                      Chỉnh sửa
                    </p>
                  </div>
                </div>
                <div className="py-2 border-b-light-gray border-t-white border border-r-white border-l-white bg-white">
                  {item.value ? (
                    <>
                      <p className="text-primary-gray">Chức danh</p>
                      <p className="text-primary-gray">{item.value}</p>
                    </>
                  ) : (
                    ''
                  )}
                </div>
                <div className="py-2 bg-white">
                  {item.extra ? (
                    <>
                      <p className="text-primary-gray">Nơi công tác</p>
                      <p className="text-primary-gray">{item.extra}</p>
                    </>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            );
          }
        }
      })}
      {/* <div className="mt-5 pt-1 bg-gray-200">
        <div className="flex justify-between py-2 bg-white">
          <div className="flex  py-2">
            <img src={images.Location} />
            <p className="ml-3 text-xl font-semibold ">Nơi ở hiện tại</p>
          </div>
          <div className="flex items-center">
            <img src={images.Pen} width={20} height={20} />
            <p className="ml-3 text-sm font-semibold text-primary-yellow">Chỉnh sửa</p>
          </div>
        </div>
        <div className="py-2 border-b-light-gray border-t-white border border-r-white border-l-white bg-white">
          <p className="text-primary-gray">Nơi ở hiện tại</p>
          <p></p>
        </div>
      </div>
      <div className="mt-5 pt-1 bg-gray-200">
        <div className="flex justify-between py-2 bg-white">
          <div className="flex  py-2">
            <img src={images.Location} />
            <p className="ml-3 text-xl font-semibold ">Quê quán</p>
          </div>
          <div className="flex items-center">
            <img src={images.Pen} width={20} height={20} />
            <p className="ml-3 text-sm font-semibold text-primary-yellow">Chỉnh sửa</p>
          </div>
        </div>
        <div className="py-2 border-b-light-gray border-t-white border border-r-white border-l-white bg-white">
          <p className="text-primary-gray">Quê quán</p>
          <p></p>
        </div>
      </div>
      <div className="mt-5 pt-1 bg-gray-200">
        <div className="flex justify-between py-2 bg-white">
          <div className="flex  py-2">
            <img src={images.Graduation} />
            <p className="ml-3 text-xl font-semibold ">Học vấn</p>
          </div>
          <div className="flex items-center">
            <img src={images.Pen} width={20} height={20} />
            <p className="ml-3 text-sm font-semibold text-primary-yellow">Chỉnh sửa</p>
          </div>
        </div>
        <div className="py-2 border-b-light-gray border-t-white border border-r-white border-l-white bg-white">
          <p className="text-primary-gray">Học vấn</p>
          <p></p>
        </div>
      </div>
      <div className="mt-5 pt-1 bg-gray-200">
        <div className="flex justify-between py-2 bg-white">
          <div className="flex  py-2">
            <img src={images.Bag} />
            <p className="ml-3 text-xl font-semibold ">Công việc</p>
          </div>
          <div className="flex items-center">
            <img src={images.Pen} width={20} height={20} />
            <p className="ml-3 text-sm font-semibold text-primary-yellow">Chỉnh sửa</p>
          </div>
        </div>
        <div className="py-2 border-b-light-gray border-t-white border border-r-white border-l-white bg-white">
          <p className="text-primary-gray">Công việc</p>
          <p></p>
        </div>
      </div> */}
    </div>
  );
};

export default Edit;
