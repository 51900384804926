import { useNavigate } from 'react-router-dom';
import { images } from '../../assets/images';

interface Props {
  urlTo: string;
  image: string;
  name: string;
}

const FeatureItem = ({ image, name, urlTo }: Props) => {
  const navigate = useNavigate();

  const goToEditInfo = () => {
    navigate(urlTo);
  };
  return (
    <>
      <div className="flex py-2 hover:bg-gray-100" onClick={goToEditInfo}>
        <img src={image} />
        <p className="ml-5 sm:text-lg text-sm font-semibold ">{name}</p>
      </div>
    </>
  );
};

export default FeatureItem;
