interface ButtonGrayProps {
  text: string;
  className?: string;
  icon?: React.ReactElement;
  onClick?: () => void;
}

const ButtonGray = (props: ButtonGrayProps) => {
  const { text, icon, className, onClick } = props;

  return (
    <button className="border-0 bg-none" onClick={onClick}>
      <div
        className={
          `bg-primary-grey flex items-center px-4 py-2 rounded-lg cursor-pointe ` +
          className
        }>
        {icon && <div className="text-white text-2xl">{icon && icon}</div>}
        <p>{text}</p>
      </div>
    </button>
  );
};

export default ButtonGray;
