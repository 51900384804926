import { useEffect, useRef, useState } from 'react';
import { IoMdClose } from 'react-icons/io';
import { useNavigate, useParams } from 'react-router-dom';
import {
  concatFileFromFileList,
  createPreviewMedia,
  removeFileFromFileList,
  validateFile,
} from '../../../Ultis/files';
import { uploadVideoApi } from '../../../Ultis/upload';
import { images } from '../../../assets/images';
import { Preview } from '../../../components/create-post/CreatePostModal';
import HeaderExtra from '../../../components/header/HeaderExtra';
import { LoadingRef } from '../../../components/loading/Loading';
import LoadingExtra from '../../../components/loading/LoadingExtra';
import Toast, { ToastProps, ToastType } from '../../../components/toast/Toast';
import {
  CreateProductInput,
  PostSharingTo,
  PostType,
} from '../../../graphql/generated';
import { useUploadPhoto } from '../../DetailPost/services/api';
import { useCreatePageProduct } from './services/api';

const _URL = window.URL || window.webkitURL;

const PAGE_PRODUCT_TYPES: PostType[] = [PostType.Tour, PostType.Hotel];

enum ProductInputName {
  NAME = 'name',
  PRICE = 'price',
}

interface Error {
  code: string;
  message: string;
}

enum ErrorCode {
  MAX_FILE_SIZE = 'MAX_FILE_SIZE',
}

export default function CreateProduct() {
  const params = useParams();

  const [preview, setPreview] = useState<Preview[]>([]);
  const [errors, setErrors] = useState<Error[]>([]);
  const [pageProduct, setPageProduct] = useState<CreateProductInput>({
    pageId: params?.pageId || '',
    title: '',
    content: '',
    type: PAGE_PRODUCT_TYPES[0],
    mediaIds: [],
    sharingTo: PostSharingTo.Public,
    price: 0,
  });
  const [toast, setToast] = useState<ToastProps>({
    className: 'fixed top-0 right-0',
    isHidden: true,
    type: ToastType.Success,
    message: '',
  });

  const navigate = useNavigate();
  const loadingRef = useRef<LoadingRef>(null);
  const [loading, setLoading] = useState(false);
  const refUploadInput = useRef<HTMLInputElement>(null);
  const { mutateAsync: uploadPhotoAsync } = useUploadPhoto();
  const {
    mutateAsync: createPageProductAsync,
    isLoading: createPageProductLoading,
    isSuccess,
  } = useCreatePageProduct();

  useEffect(() => {
    let resetToast: any;
    if (!toast.isHidden) {
      resetToast = setTimeout(() => {
        setToast((preState) => {
          return { ...preState, isHidden: true };
        });
      }, 2000);
    }
    return () => {
      clearTimeout(resetToast);
    };
  }, [toast]);

  useEffect(() => {
    loadingRef.current?.open();
  });
  const handleSelectImage = () => {
    refUploadInput.current?.click();
  };

  const handleUploadPhoto = (file: File, key: string): void => {
    const img = new Image();
    const objectUrl = _URL.createObjectURL(file);
    img.onload = () => {
      _URL.revokeObjectURL(objectUrl);
      return uploadPhotoAsync({
        file,
        dimensions: {
          width: img.width,
          height: img.height,
        },
      }).then((data) => {
        setPreview((p) => {
          const idxKey = p.findIndex((i) => i.key === key);
          if (idxKey !== -1) {
            p[idxKey].id = data.uploadPhoto._id;
            p[idxKey].status = 'Completed';
          }
          return [...p];
        });
      });
    };
    img.src = objectUrl;
  };

  const handleUploadVideo = async (file: File, key: string) => {
    const { data: media } = await uploadVideoApi(file);
    setPreview((p) => {
      const idxKey = p.findIndex((i) => i.key === key);
      if (idxKey !== -1) {
        p[idxKey].id = media._id;
        p[idxKey].status = 'Completed';
      }
      return [...p];
    });
  };

  const handleFileChange = (e: any) => {
    if (refUploadInput.current?.hidden !== undefined) {
      refUploadInput.current.hidden = true;
    }

    const files = e.target.files;
    if (!files || files.length === 0) {
      return;
    }

    console.log('type files', Array.from(files));

    if (files) {
      const listAccept = [
        'image/jpeg',
        'image/png',
        'image/jpg',
        'video/avi',
        'video/ogm',
        'video/wmv',
        'video/mpg',
        'video/webm',
        'video/ogv',
        'video/mov',
        'video/asx',
        'video/mpeg',
        'video/mp4',
        'video/m4v',
      ];

      if (
        !(Array.from(files) || [])
          .map((item: any) => {
            if (listAccept.includes(item.type)) {
              return true;
            } else {
              return false;
            }
          })
          .includes(false)
      ) {
        try {
          validateFile(
            concatFileFromFileList(
              files,
              preview
                .filter((preview) => !preview.isSkip)
                .map((preview) => preview.file),
            ),
          );
          const newPreview = createPreviewMedia(files, preview.length);
          if (newPreview.length + preview.length > 5) {
            setToast({
              ...toast,
              message: `Ảnh sản phẩm tối đa là 5. Vui lòng thử lại`,
              isHidden: false,
              type: ToastType.Warning,
            });
            e.target.value = null;
            return;
          }
          setPreview([...preview, ...newPreview]);
          Promise.all(
            newPreview.map((media) => {
              if (media.type === 'image') {
                return handleUploadPhoto(media.file, media.key);
              }
              return handleUploadVideo(media.file, media.key);
            }),
          );
        } catch (err) {
          refUploadInput.current &&
            (refUploadInput.current.files = new DataTransfer().files);
          setErrors([
            ...errors,
            {
              code: ErrorCode.MAX_FILE_SIZE,
              message: 'Upload tệp không được quá 5M',
            },
          ]);
        }
      } else {
        setToast({
          ...toast,
          message: `Định dạng file không đúng, vui lòng thử lại`,
          isHidden: false,
          type: ToastType.Warning,
        });
      }
    }
  };

  const handleRemovePreview = (idx: number, key: string) => {
    if (refUploadInput.current?.files) {
      refUploadInput.current.files = removeFileFromFileList(
        idx,
        refUploadInput.current.files,
      );
    }
    _URL.revokeObjectURL(preview.find((item) => item.uri)?.uri ?? '');
    setPreview(preview.filter((media) => media.key !== key));
  };

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputName = e.target.name;
    const inputValue = e.target.value;
    switch (inputName) {
      case ProductInputName.NAME:
        setPageProduct((prevState) => {
          return { ...prevState, title: inputValue };
        });
        break;
      case ProductInputName.PRICE:
        setPageProduct((prevState) => {
          return { ...prevState, price: Number(inputValue) };
        });
        break;
      default:
    }
  };

  const handleTextArea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setPageProduct((preState) => {
      return { ...preState, content: e.target.value };
    });
  };

  const handleSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const postType = e.target.value;

    setPageProduct((preState) => {
      return {
        ...preState,
        type: postType == PostType.Hotel ? PostType.Hotel : PostType.Tour,
      };
    });
  };

  const validateProductInfo = () => {
    if (preview.length <= 0) {
      setToast((preState) => {
        return {
          ...preState,
          type: ToastType.Warning,
          message: 'Thêm ảnh sản phẩm',
          isHidden: false,
        };
      });
      return false;
    }

    if (!pageProduct.title && pageProduct.title == '') {
      setToast((preState) => {
        return {
          ...preState,
          type: ToastType.Warning,
          message: 'Thêm tên sản phẩm',
          isHidden: false,
        };
      });
      return false;
    }

    if (!pageProduct.price) {
      setToast((preState) => {
        return {
          ...preState,
          type: ToastType.Warning,
          message: 'Thêm giá sản phẩm',
          isHidden: false,
        };
      });
      return false;
    } else if (pageProduct.price <= 0) {
      setToast((preState) => {
        return {
          ...preState,
          type: ToastType.Warning,
          message: 'Giá sản phẩm phải lớn hơn 0',
          isHidden: false,
        };
      });
      return false;
    }

    if (!pageProduct.content && pageProduct.content == '') {
      setToast((preState) => {
        return {
          ...preState,
          type: ToastType.Warning,
          message: 'Thêm mô tả sản phẩm',
          isHidden: false,
        };
      });
      return false;
    }
    return true;
  };

  const handleNavigate = () => {
    return new Promise((res, rej) => {
      setTimeout(() => {
        res(navigate(`/page/${params?.pageId || ''}`));
      }, 2000);
    });
  };
  const handleCreateProduct = () => {
    const isValid = validateProductInfo();
    if (!isValid) return false;

    createPageProductAsync({
      data: {
        ...pageProduct,
        mediaIds: preview.map((item) => item.id || ''),
      },
    })
      .then(() => {
        setLoading(true);
      })
      .then((data) => {
        handleNavigate();
      })
      .catch((err) => {
        setToast((preState) => {
          return {
            ...preState,
            type: ToastType.Danger,
            message: 'Có lỗi xảy ra, vui lòng thử lại',
            isHidden: false,
          };
        });
      });
  };

  return (
    <>
      <HeaderExtra title="Thêm sản phẩm và dịch vụ" />
      <Toast
        message={toast.message}
        className={`${toast.className} absolute top-16`}
        type={toast.type}
        isHidden={toast.isHidden}
      />
      <div className="my-3 mx-3">
        <form>
          <div className="flex gap-3">
            {preview.length !== 0 && (
              <div className="box-border flex gap-3 ">
                {preview.map((media, idx) => {
                  return (
                    <div
                      key={media.key}
                      className="inline-block border rounded">
                      {media.type === 'video' ? (
                        <div className="relative">
                          {media.status === 'Uploading' && (
                            <LoadingExtra
                              className="absolute right-[40%]"
                              label=""
                            />
                          )}
                          <video
                            className={`${
                              media.status === 'Uploading' && 'opacity-40'
                            } object-cover h-48 w-48 max-[400px]:h-38 max-[400px]:w-[38] rounded-md`}
                            src={media.uri}
                          />
                        </div>
                      ) : (
                        <div className="relative">
                          {media.status === 'Uploading' && (
                            <LoadingExtra
                              className="absolute right-[40%]"
                              label=""
                            />
                          )}
                          <img
                            key={media.key}
                            className={`object-cover h-20 w-20 rounded-md inline-block ${
                              media.status === 'Uploading' && 'opacity-40'
                            }`}
                            src={media.uri}
                          />
                          <span
                            className="absolute top-1 right-1  bg-red-500 rounded-full"
                            onClick={() => {
                              handleRemovePreview(media.idx, media.key);
                            }}>
                            <IoMdClose color="#fff" />
                          </span>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            )}
            {preview.length < 5 && (
              <div
                className="flex items-center justify-center bg-gray-100 w-20 h-20 rounded-md"
                onClick={handleSelectImage}>
                <img src={images.CameraGray} alt="camera" />
              </div>
            )}

            <input
              type="file"
              ref={refUploadInput}
              hidden={true}
              accept=".jpg, .png, .jpeg,video/*"
              multiple={true}
              onChange={handleFileChange}
            />
          </div>
          <div className="my-3">
            <label htmlFor="productName">
              Tên sản phẩm <span className="text-red-500">*</span>
            </label>
            <br />
            <input
              name={ProductInputName.NAME}
              className="w-full border border-1 border-gray-300 outline-none rounded px-3 py-2"
              id="productName"
              placeholder="Tên sản phẩm"
              type="text"
              onChange={handleInput}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="productPrice">
              Giá sản phẩm <span className="text-red-500">*</span>
            </label>
            <div className="flex justify-between border border-1 border-gray-300 rounded px-3 py-2">
              <input
                name={ProductInputName.PRICE}
                className="w-full border-0 outline-none "
                id="productName"
                type="number"
                placeholder="Nhập giá"
                min={0}
                onChange={handleInput}
              />
              <span className="block">VND</span>
            </div>
          </div>
          <div className="mb-3">
            <label htmlFor="productDescription">
              Mô tả sản phẩm <span className="text-red-500">*</span>
            </label>
            <textarea
              name="productDescription"
              className="w-full px-4 py-2 border border-gray-300 rounded outline-none"
              placeholder="Mô tả sản phẩm"
              onChange={handleTextArea}></textarea>
          </div>
          <div className="mb-3">
            <label htmlFor="productCategory">Danh mục sản phẩm</label>
            <select
              name="productCategory"
              className="block w-full px-4 py-2 border border-gray-300 rounded outline-none bg-white"
              onChange={handleSelect}>
              {PAGE_PRODUCT_TYPES.map((item) => {
                return (
                  <>
                    <option value={item}>
                      {item == PostType.Hotel ? 'Khách sạn/nhà nghỉ' : 'Tour'}
                    </option>
                  </>
                );
              })}
            </select>
          </div>

          <button
            className="bg-[#05A2AB] w-full px-3 py-2 text-white font-semibold rounded absolute bottom-3 left-0 flex justify-center items-center"
            type="button"
            onClick={handleCreateProduct}
            disabled={createPageProductLoading}>
            {loading && (
              <svg
                aria-hidden="true"
                className="inline w-4 h-4 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
            )}
            Thêm
          </button>
        </form>
      </div>
    </>
  );
}
