import { IconType } from 'react-icons';

interface ButtonOrangeProps {
  text: string;
  className?: string;
  icon?: React.ReactElement;
  onClick?: () => void;
}

const ButtonOrange = (props: ButtonOrangeProps) => {
  const { text, className, icon, onClick } = props;

  const handleClick = (e: any) => {
    e.stopPropagation();
    if (onClick) {
      onClick();
    }
  };
  return (
    <button
      className={
        `bg-primary-yellow flex items-center px-4 py-2 rounded-lg cursor-pointer ` +
        className
      }
      onClick={handleClick}>
      {icon && <div className="text-white text-2xl mr-2">{icon && icon}</div>}
      <p className="text-white">{text}</p>
    </button>
  );
};

export default ButtonOrange;
