import { BsPencil } from 'react-icons/bs';
import { FaCopy } from 'react-icons/fa';
import { MdBlock, MdOutlineDelete } from 'react-icons/md';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import HeaderExtra from '../header/HeaderExtra';
import Toast, { ToastProps, ToastType } from '../toast/Toast';
import { useState } from 'react';
import { useDeletePage, useGetPage } from '../../pages/Page/services/api';
import { ConfirmModal, IConfirmModal } from '../modal/ConfirmModal';

const SettingMyPage = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const urlPage = location.state;
  const { mutateAsync: deletePageAsync, isLoading: deletePageLoading } =
    useDeletePage();
  const [toast, setToast] = useState<ToastProps>({
    className: 'fixed top-0 right-0',
    isHidden: true,
    type: ToastType.Success,
    message: '',
  });
  const [confirmModal, setConfirmModal] = useState<IConfirmModal>({
    isVisible: false,
    content: '',
  });
  const { data } = useGetPage(id || '');

  const copyContent = async () => {
    const text = document.getElementById('myUrl')?.innerHTML;
    try {
      await navigator.clipboard.writeText(text || '');
      //do something when copy success
      alert('Copy thành công ');
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };

  const handleDeletePage = () => {
    deletePageAsync({ id: id || '' })
      .then(() => {
        setTimeout(() => {
          navigate('/profile/pages');
        }, 1500);
      })
      .catch(() => {
        setToast((preState) => {
          return {
            ...preState,
            isHidden: false,
            type: ToastType.Danger,
            message: 'Có lỗi xảy ra',
          };
        });
      });
  };

  const handleCancelModal = () => {
    setConfirmModal({ ...confirmModal, isVisible: false });
  };

  const handleOkModal = () => {
    handleDeletePage();
  };
  return (
    <>
      <HeaderExtra title="Cài đặt" />
      <Toast
        message={toast.message}
        className={`${toast.className} absolute top-16`}
        type={toast.type}
        isHidden={toast.isHidden}
      />
      <ConfirmModal
        title={confirmModal.title}
        content={confirmModal.content}
        isVisible={confirmModal.isVisible}
        onCancel={handleCancelModal}
        onOk={handleOkModal}
      />

      <div className="section-content-setting-page">
        <div className="section-action ">
          <div className="bg-primary-yellow">
            <Link to={`/profile/${id}/list-block`}>
              <div className="flex bg-white gap-4 items-center p-4 border-b-[1px] rounded-t-[10px] border-solid border-gray-200">
                <BsPencil className="text-2xl" />
                <p>Chỉnh sửa thông tin</p>
              </div>
            </Link>
          </div>
          <div className="bg-primary-yellow">
            <Link to={`/profile/${id}/list-block`}>
              <div className="flex bg-white gap-4 items-center p-4 border-b-[1px] border-solid border-gray-200">
                <MdBlock className="text-2xl" />
                <p>Danh sách chặn</p>
              </div>
            </Link>
          </div>
          <div
            className="flex gap-4 items-center p-4 border-b-8 border-solid border-gray-300"
            onClick={() =>
              setConfirmModal({
                ...confirmModal,
                title: 'Xác nhận xóa trang',
                content: `Bạn chắc chắn muốn xóa trang ${data?.getPage?.name} ?`,
                isVisible: true,
              })
            }>
            <MdOutlineDelete className="text-2xl" />
            <p>Xóa trang</p>
          </div>
          {urlPage && (
            <div className="section-url px-4 pt-4">
              <h3 className="title text-base font-semibold">
                Đường dẫn đến trang
              </h3>
              <div className="pb-2">
                <a
                  className="url text-base text-blue-600"
                  id="myUrl"
                  href={urlPage || '#'}>
                  {urlPage}
                </a>
              </div>
              <button
                onClick={copyContent}
                className="bg-secondary-yellow text-sm text-primary-yellow rounded">
                <div className="py-1 px-3 flex gap-1 items-center ">
                  <FaCopy className="text-lg" />
                  <span>Sao chép đường dẫn</span>
                </div>
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default SettingMyPage;
