import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { GetPageProductQueryVariables, ProductStatus } from '../../../graphql/generated';
import { useGetPageProducts } from '../../Page/services/api';
import FormTab from './ProductFormTabs';
import { useGetPage } from '../../Page/services/api';

const defaultFilter: GetPageProductQueryVariables = {
  limit: 10,
  pageId: '',
  status: ProductStatus.Total,
};
interface Props {}
const tabs = [
  {
    id: 1,
    name: 'Tổng quan',
  },
  {
    id: 2,
    name: 'Sản phẩm',
  },
  {
    id: 3,
    name: 'Đơn hàng',
  },
];
export const TabProduct: FC<Props> = () => {
  const uid = localStorage.getItem('uid');

  const { pageId } = useParams<{ pageId: string }>();
  const { data } = useGetPageProducts({
    ...defaultFilter,
    pageId: pageId || '',
  });
  const { data: infoPage } = useGetPage(pageId || '');
  const isOwner = infoPage?.getPage?.owner?._id === uid?.replace(/['"]+/g, '');
  const [openTab, setOpenTab] = React.useState(isOwner ? 0 : 1);

  const headerTabs = () => {
    return (
      <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
        <ul className="flex flex-wrap -mb-px" role="tablist">
          {!isOwner
            ? tabs
                .filter((e) => e.name === 'Sản phẩm')
                .map((item, index) => (
                  <li key={item.id} className="mr-2 flex-auto">
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        setOpenTab(index);
                      }}
                      href="#"
                      className="inline-block p-4 primaryColor border-b-2 border-primary-color rounded-t-lg active dark:text-blue-500 dark:border-blue-500">
                      {item.name}
                    </a>
                  </li>
                ))
            : tabs.map((item, index) => (
                <li key={item.id} className="mr-2 flex-auto">
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenTab(index);
                    }}
                    href="#"
                    className={
                      index == openTab
                        ? 'inline-block p-4 primaryColor border-b-2 border-primary-color rounded-t-lg active dark:text-blue-500 dark:border-blue-500'
                        : 'inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'
                    }>
                    {item.name}
                  </a>
                </li>
              ))}
        </ul>
      </div>
    );
  };
  return (
    <div className="product">
      {headerTabs()}
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
        <div className="px-4 py-5 flex-auto">
          {isOwner ? (
            <div className="tab-content tab-space">
              <div className={openTab === 0 ? 'block' : 'hidden'} id="link1">
                <div className="border border-zinc-300 p-4 rounded-lg">
                  <h3 className="text-black font-semibold">Sản phẩm</h3>
                  <div className="grid grid-cols-3 divide-x mt-3">
                    <div className="text-center	">
                      <span className="font-semibold primaryColor text-xl">{data?.pageProducts?.total || 0}</span>
                      <p>Tổng số lượng sản phẩm</p>
                    </div>
                    <div className="text-center	">
                      <span className="font-semibold primaryColor text-xl">{0}</span>
                      <p>Sản phẩm được ghim</p>
                    </div>
                    <div className="text-center	">
                      <span className="font-semibold primaryColor text-xl">{0}</span>
                      <p>Sản phẩm bị khóa</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={openTab === 1 ? 'block' : 'hidden'} id="link2">
                <FormTab isOwner={isOwner} />
              </div>
              <div className={openTab === 2 ? 'block' : 'hidden'} id="link3">
                <div className="text-center sm:text-sm p-4">Tính năng này sẽ được cập nhật trong thời gian tới.</div>
              </div>
            </div>
          ) : (
            <div className={openTab === 1 ? 'block' : 'hidden'} id="link2">
              <FormTab isOwner={isOwner} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
