import { forwardRef, Ref, useImperativeHandle, useRef, useState } from 'react';
import { AiOutlineUsergroupAdd } from 'react-icons/ai';
import { RiUserSearchLine } from 'react-icons/ri';
import { RxExit } from 'react-icons/rx';
import { useOnClickOutside } from '../../Ultis/hooks/useClickOutside';
import SelectOptionItem from '../selectOption/SelectOptionItem';

export interface ModalChatRef {
  open: () => void;
  close: () => void;
}

export interface PropsOption {
  content: string;
  handleClick: () => void;
  icon: any;
  subText?: string;
}

export interface Props {
  onClick: () => void;
  onClickLeave: () => void;
  onClickAddFriend: () => void;
  conversationId?: string;
}
const ModalChat = (props: Props, ref: Ref<ModalChatRef>) => {
  const modalChatRef = useRef<any>(null);
  const { onClick, onClickLeave, onClickAddFriend } = props;
  const [visible, setVisible] = useState(false);

  const refModal = useRef<any>();
  useOnClickOutside(refModal, () => setVisible(false));

  const openModal = () => {
    setVisible(!visible);
  };

  const closeModal = () => {
    setVisible(false);
  };

  useImperativeHandle(ref, () => ({
    open: () => openModal(),
    close: () => closeModal(),
  }));

  const openModalChat = () => {
    modalChatRef.current?.open();
  };

  const options: PropsOption[] = [
    {
      content: 'Xem thành viên nhóm',
      handleClick: () => onClick(),
      icon: <RiUserSearchLine color="gray" />,
    },
    {
      content: 'Thêm thành viên nhóm',
      handleClick: () => onClickAddFriend(),
      icon: <AiOutlineUsergroupAdd color="gray" />,
    },
    {
      content: 'Rời nhóm',
      handleClick: () => onClickLeave(),
      icon: <RxExit color="gray" />,
    },
  ];

  return (
    <>
      {visible && (
        <div
          ref={refModal}
          className="absolute py-1 button_show_member top-[30px] right-0 rounded-[10px] bg-white border-[1px] border-solid border-gray-300">
          {options.map((item, index) => {
            return (
              <SelectOptionItem
                content={item?.content}
                handleClick={item?.handleClick}
                icon={item?.icon}
                key={index}
              />
            );
          })}
        </div>
      )}
    </>
  );
};

export default forwardRef(ModalChat);
