import { useLocation, useNavigate } from 'react-router-dom';
import { images } from '../../../assets/images';
import VideoPlayer from '../../../components/VideoPlayer';
import { Media } from '../../../graphql/generated';
import './Video.scss';

const WatchVideo = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="content_video flex justify-center bg-black relative">
      <div className="btn_close absolute cursor-pointer" onClick={() => navigate(-1)}>
        <img src={images.Close} />
      </div>
      <div className="app_videos">
        {/* {videos.map(({ url, channel, description, song, likes, messages, shares }) => (
          <VideoItem key={url} url={url} channel={channel} description={description} song={song} likes={likes} messages={messages} shares={shares} />
        ))} */}

        <div className="video">
          <VideoPlayer
            offset={0}
            width={300}
            height={300}
            options={{
              autoplay: true,
              poster: '',
              sources: location.state.video.uri,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default WatchVideo;
