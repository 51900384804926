import { FC } from 'react';
import { User } from '../../../graphql/generated';
import { images } from '../../../assets/images';
import '../../../index.scss';
import { Link } from 'react-router-dom';
import { RiUserAddFill } from 'react-icons/ri';
interface Props {
  user: Partial<User>;
  isMyFriend: boolean;
}

const UserItem: FC<Props> = ({ user, isMyFriend }: Props) => {
  return (
    <div className="flex relative h-24 shadow-inner boder-radius bg-white rounded flex-row  border border-solid border-1 border-indigo-60">
      <div className="flex-1.5 flex mx-5 w-1/6 rounded-full">
        <img
          className={` bg-cover self-center rounded-full ${user.verified && 'avartar-shadow_verify'}`}
          src={user?.avatar?.uri ?? images.AvatarDefault}
          height={75}
          width={75}
          alt={'avatar_' + user.username}
        />
      </div>
      <div className="flex-2 flex flex-col place-content-center content-center">
        <Link to={'/profile/' + user._id}>
          <p className="text-md w-15 font-semibold text-black line-clamp-1">{user?.fullName ?? ''}</p>
        </Link>
        {user.totalMutualFriends !== 0 && <p className="text-slate-400 text-sm">{user.totalMutualFriends} bạn chung</p>}
      </div>
      {!isMyFriend && (
        <span className="absolute right-4 text-slate-400 cursor-pointer top-8">
          <RiUserAddFill size={'24px'} />
        </span>
      )}
    </div>
  );
};

export default UserItem;
