import {
  GetMyFriendIdsQueryVariables,
  SearchGroupsQueryVariables,
  SearchPagesQueryVariables,
  SearchPostsQueryVariables,
  SearchSuggestionsQueryVariables,
  SearchUsersQueryVariables,
  TopPlacesQueryVariables,
  useGetMyFriendIdsQuery,
  useSearchGroupsQuery,
  useSearchPagesQuery,
  useSearchPostsQuery,
  useSearchSuggestionsQuery,
  useSearchUsersQuery,
  useTopPlacesQuery,
} from '../../../graphql/generated';
import { graphqlClientRequest } from '../../../graphql/services/graphql-client';

export const useSearchSuggestions = (
  input: SearchSuggestionsQueryVariables,
) => {
  const { data, isLoading, isError } = useSearchSuggestionsQuery(
    graphqlClientRequest(true),
    input,
    {
      onSuccess(data) {},
      onError(error: any) {},

      retry: false,
    },
  );

  return { data, isLoading, isError };
};

export const useSearchPage = (input: SearchPagesQueryVariables) => {
  const { data, isLoading, isError } = useSearchPagesQuery(
    graphqlClientRequest(true),
    input,
    {
      onSuccess(data) {},
      onError(error: any) {},

      retry: false,
    },
  );

  return { data, isLoading, isError };
};

export const useSearchGroup = (input: SearchGroupsQueryVariables) => {
  const { data, isLoading, isError } = useSearchGroupsQuery(
    graphqlClientRequest(true),
    input,
    {
      onSuccess(data) {},
      onError(error: any) {},

      retry: false,
    },
  );

  return { data, isLoading, isError };
};

export const useSearchPlace = (input: TopPlacesQueryVariables) => {
  const { data, isLoading, isError } = useTopPlacesQuery(
    graphqlClientRequest(true),
    input,
    {
      onSuccess(data) {},
      onError(error: any) {},

      retry: false,
    },
  );

  return { data, isLoading, isError };
};

export const useSearchPost = (input: SearchPostsQueryVariables) => {
  const { data, isLoading, isError } = useSearchPostsQuery(
    graphqlClientRequest(true),
    input,
    {
      onSuccess(data) {},
      onError(error: any) {},

      retry: false,
    },
  );

  return { data, isLoading, isError };
};

export const useSearchUsers = (input: SearchUsersQueryVariables) => {
  return useSearchUsersQuery(graphqlClientRequest(true), input, {
    onSuccess(data) {},
    onError(error: any) {
      //   Notification.Error({ message: getMessageError(error) });
    },
    retry: false,
  });
};

export const useGetMyFriendIds = (input: GetMyFriendIdsQueryVariables) => {
  const { data } = useGetMyFriendIdsQuery(graphqlClientRequest(true), input, {
    onSuccess(data) {},
    onError(error: any) {
      //   Notification.Error({ message: getMessageError(error) });
    },
    retry: false,
  });
  return data?.getFriends;
};
