import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

interface Props {}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const MenuLayout = (props: Props) => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="container m-auto relative" style={{height: "100vh"}}>
      <div className={`profileImage h-16 !max-w-[${windowDimensions.width}px] bg-primary-yellow`}></div>
      <Outlet />
    </div>
  );
};

export default MenuLayout;
