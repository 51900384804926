import { memo, useRef, useState } from 'react';
import { AiFillCamera, AiOutlineSearch } from 'react-icons/ai';
import { BiLoaderAlt } from 'react-icons/bi';
import { IoIosArrowBack, IoMdClose } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import userDefaultImg from '../../assets/images/user.jpeg';
import ErrorModal from '../../components/modal/ErrorModal';
import { useUploadPhoto } from '../DetailPost/services/api';
import TagFriendList, { UserForTag } from './components/TagFriendList';
import { useCreateGroupConversation } from './services/api';

type Step = 'ADD_USER' | 'ADD_INFO';
const _URL = window.URL || window.webkitURL;
interface Preview {
  uri: string;
  file: File;
}

const CreateGroupPage = () => {
  const navigate = useNavigate();

  // ref
  const refUpload = useRef<HTMLInputElement>(null);
  const inputSearchFriendRef = useRef<HTMLInputElement>(null);

  // state
  const [preview, setPreview] = useState<Preview>();
  const [name, setName] = useState<string>();
  const [error, setError] = useState<string>();

  const [step, setStep] = useState<Step>('ADD_USER');
  const [users, setUsers] = useState<UserForTag[] | []>([]);
  const [counter, setCounter] = useState(0);

  // api
  const { mutateAsync: uploadPhotoAsync } = useUploadPhoto();
  const [query, setQuery] = useState('');
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value.trim());
  };

  const { mutate, isLoading, isSuccess } = useCreateGroupConversation();

  // function
  const handleClickTagUser = (user: UserForTag) => {
    setUsers((p) => {
      if (p.findIndex((i) => i._id === user._id) > -1) {
        return p.filter((i) => i._id !== user._id);
      }
      return [...p, user];
    });
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (!file) {
      return;
    }
    const objectUrl = URL.createObjectURL(file);
    setPreview({ uri: objectUrl, file });
  };

  const handleUploadPhoto = (file: File): Promise<string> =>
    new Promise((resolve) => {
      const img = new Image();
      const objectUrl = _URL.createObjectURL(file);
      img.onload = async () => {
        _URL.revokeObjectURL(objectUrl);
        return uploadPhotoAsync({
          file,
          dimensions: {
            width: img.width,
            height: img.height,
          },
        }).then((data) => resolve(data.uploadPhoto._id));
      };
      img.src = objectUrl;
    });

  const handleNextStep = async () => {
    switch (step) {
      case 'ADD_USER':
        // validate

        if (users.length < 2) {
          setError('Vui lòng chọn từ 2 thành viên');
          return;
        }

        setStep('ADD_INFO');
        break;
      case 'ADD_INFO': {
        if (!name || name?.length < 2) {
          setError('Tên nhóm phải từ 2 ký tự');
          return;
        }

        if (counter > 0) return;
        setCounter(1);

        const input = {
          name: name || '',
          userIds: users.map((u) => u._id),
          avatarId: preview ? await handleUploadPhoto(preview.file) : null,
        };

        mutate(
          { data: input },
          {
            onSuccess(data, variables, context) {
              navigate(`/messenger/${data.createGroupConversation._id}`);
            },
            onError() {},
          },
        );
        break;
      }
      default:
    }
  };

  const handleBack = () => {
    if (step == 'ADD_USER') {
      navigate(-1);
    } else {
      setStep('ADD_USER');
    }
  };

  const onOpenUploadFile = () => {
    refUpload.current && refUpload.current.click();
  };

  const renderListTag = () => {
    return (
      <>
        <div className="py-2 box-border px-3 md:py-3 relative flex overflow-x-auto max-w-[90%]">
          {users.map((item, index) => (
            <div key={index} className="tags-users w-[60px]">
              <div className=" tags-users h-[60px] w-[60px]">
                <div
                  className={`img-1-1 ${
                    item.verified
                      ? 'avartar-shadow_verify'
                      : 'avartar-border_default'
                  }`}>
                  <img
                    className={` friend-avatar bg-cover self-center `}
                    src={item?.avatar?.uri ?? userDefaultImg}
                    alt={'avatar_' + item?.username}
                  />
                </div>
              </div>

              <span
                className="remove-user"
                onClick={() => handleClickTagUser(item)}>
                <IoMdClose color="#fff" />
              </span>
              <span className="text-xs">{item.fullName}</span>
            </div>
          ))}
        </div>
      </>
    );
  };

  const step1 = () => {
    return (
      <>
        <div className="flex flex-col flex-grow h-0 ">
          <div className="p-4 pb-2 flex">
            <label
              htmlFor="tag-friend_input"
              className="flex h-[36px] rounded-full w-full bg-slate-100 px-4">
              <span className="mt-[9px]">
                <AiOutlineSearch size={'18px'} />
              </span>
              <input
                onChange={handleSearchChange}
                ref={inputSearchFriendRef}
                type="text"
                className="ml-1 bg-slate-100 w-full outline-none"
                id="tag-friend_input"
                placeholder="Tìm kiếm bạn bè"
              />
            </label>
          </div>

          <div className="pt-3 bg-white h-full w-full	">
            {users.length > 0 && renderListTag()}
            <TagFriendList
              query={query}
              handleClickTagUser={handleClickTagUser}
              taggedUsers={users}
            />
          </div>
        </div>
      </>
    );
  };

  const step2 = () => {
    return (
      <>
        <div className="flex flex-col flex-grow h-0 ">
          <div className="p-4 pb-0 flex w-full justify-center">
            <div
              className="w-[100px] h-[100px] rounded-full border-[1px] border-solid border-gray-300 flex justify-center items-center"
              onClick={onOpenUploadFile}>
              {preview ? (
                <div className="img-1-1 bg-white border-[1px] border-solid border-gray-300 w-[100px]">
                  <img src={preview.uri ?? userDefaultImg} />
                </div>
              ) : (
                <AiFillCamera size={50} className="text-[#05A2AB]" />
              )}
            </div>
          </div>
          <div className="p-4 flex w-full justify-center">
            <input
              className="border border-solid border-t-0 border-l-0 border-r-0 text-center focus:outline-none focus:ring-0 py-2 rounded-[8px]"
              placeholder="Tạo tên nhóm"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </div>

          <div className="pt-3 bg-white h-full w-full	">
            {users.length > 0 && renderListTag()}
          </div>

          <input
            type="file"
            ref={refUpload}
            style={{ display: 'none' }}
            accept="image/*"
            onChange={handleFileChange}
          />
        </div>
      </>
    );
  };

  return (
    <>
      <div className="pt-4 flex items-center bg-primary-yellow">
        <div className="flex mb-5 lg:-space-x-4 items-center justify-between w-full -space-x-2">
          <div
            className="cursor-pointer lg:h-10 h-10 mr-10 pl-4 justify-center items-center flex"
            onClick={handleBack}>
            <IoIosArrowBack size={30} className="text-white" />
          </div>
          <div className="text-white items-center">Tạo nhóm</div>

          <div
            onClick={isLoading ? () => {} : handleNextStep}
            className="flex items-center justify-between px-5 text-amber-400	cursor-pointer">
            Tiếp tục
          </div>
        </div>
      </div>
      {/* <div className="flex flex-col flex-grow h-0 ">
        <div className="pt-5 bg-white h-full flex	overflow-auto">{}</div>
      </div> */}
      {step === 'ADD_USER' ? step1() : step2()}

      <ErrorModal
        isShow={!!error}
        message={error || ''}
        onClose={() => setError('')}
      />

      {isLoading && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <BiLoaderAlt size={50} className="animate-spin text-gray-500" />
        </div>
      )}
    </>
  );
};

export default memo(CreateGroupPage);
