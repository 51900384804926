import { Notification } from '../../../graphql/generated';
import { Notify } from './textNotification';

interface TypeAddCommentToMediaProps {
  item: Notification;
  isCreater: boolean;
  isPostToPage: boolean;
  isCheckPostYourSelf: boolean;
  isCheckUsersAction: any;
  isVideo: boolean;
}

const TypeAddedCommentToMedia = (props: TypeAddCommentToMediaProps) => {
  const { item, isCreater, isPostToPage, isCheckPostYourSelf, isCheckUsersAction, isVideo } = props;

  const txtMediaPage = isVideo ? ` ${Notify.comment_img_page_video} ` : ` ${Notify.comment_img_page} `;
  const txtMedia = isVideo ? ` ${Notify.comment_img_video} ` : ` ${Notify.comment_img} `;

  const lableAddCommentMediaPageOrPost = isPostToPage
    ? //   ? translate('notify.comment_img_page', { media: txtMedia })
      //   : translate('notify.comment_img', { media: txtMedia })
      txtMediaPage
    : txtMedia;

  const valueAddCommentMediaPageOrPost = isPostToPage
    ? `${(item.toPage && item.toPage.name) || ''}`
    : `${(item.forPost && item.forPost.creator && item.forPost.creator.fullName) || ''}`;

  return (
    <span>
      {isCheckUsersAction}
      {isCreater ? lableAddCommentMediaPageOrPost : `${Notify.comment_img_page}`}
      {!isPostToPage && !isCreater && !isCheckPostYourSelf && ' '}
      {!isPostToPage && !isCreater && isCheckPostYourSelf && ` ${Notify.they}.`}
      <span className="font-semibold">{!isCheckPostYourSelf && (!isCreater || isPostToPage) && `${valueAddCommentMediaPageOrPost}.`}</span>
    </span>
  );
};

export default TypeAddedCommentToMedia;
