import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FriendItemType } from '../../components/friend-menu/FriendItem';
import HeaderExtra from '../../components/header/HeaderExtra';
import FriendListMenuTab from './FriendListMenuTab';
import './index.scss';
import { useGetUserListFriendTabInfo } from './services/api';
import ListUserMutualFriend from './components/ListUserMutualFriend';
import ListUserFriend from './components/ListUserFriend';
export const LIMIT_PAGE = 20;

export interface FriendListMenuProps {}

const tabs = [
  {
    key: 1,
    label: 'Tất cả',
    type: FriendItemType.UserFriend,
  },
  {
    key: 2,
    label: 'Bạn chung',
    type: FriendItemType.Mutual,
  },
];

const UserFriendListMenu: FC<FriendListMenuProps> = (props) => {
  // const loadingRef = useRef<LoadingRef>(null);
  const { id: userId } = useParams();
  const userFriendsTabInfo = useGetUserListFriendTabInfo({ userId: userId ?? '' });
  const [type, setType] = useState(FriendItemType.UserFriend);

  useEffect(() => {
    console.log('render');
  });

  return (
    <div>
      <HeaderExtra title="Bạn bè" />
      <div>
        <FriendListMenuTab
          tabs={tabs.map((item, idx) => (
            <li key={item.key} className={`mr-2 flex-auto`}>
              <a
                onClick={(e) => {
                  e.preventDefault();
                  setType(item.type);
                }}
                href="#"
                className={
                  item.type === type
                    ? 'inline-block p-4 primaryColor border-b-2 border-primary-color rounded-t-lg active dark:text-blue-500 dark:border-blue-500'
                    : 'inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'
                }>
                {item.label}
                {item.type === FriendItemType.UserFriend && ` (${userFriendsTabInfo?.totalFriends})`}
              </a>
            </li>
          ))}
        />
        {type === FriendItemType.Mutual && <ListUserMutualFriend userId={userId ?? ''} totalItems={userFriendsTabInfo?.totalMutualFriends ?? 0} />}
        {type === FriendItemType.UserFriend && (
          <ListUserFriend mutualFriendIds={userFriendsTabInfo?.mutualFriendIds ?? []} userId={userId ?? ''} totalItems={userFriendsTabInfo?.totalFriends ?? 0} />
        )}
      </div>
    </div>
  );
};

export default UserFriendListMenu;
