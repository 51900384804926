import { useEffect, useRef, useState } from 'react';
import { MdSearchOff } from 'react-icons/md';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loading, { LoadingRef } from '../../../components/loading/Loading';
import { SearchUsersQueryVariables, User } from '../../../graphql/generated';
import { useGetMyFriendIds, useSearchUsers } from '../services/api';
import { TabItemSearchBy } from './SearchTabs';
import UserItem from './UserItem';


const PAGE_LIMIT = 20;

interface Props {
  query: string;
  searchBy?: TabItemSearchBy;
}

const ListUser = ({ query, searchBy }: Props) => {
  const loadingRef = useRef<LoadingRef>(null);
  const [filter, setFilter] = useState<SearchUsersQueryVariables>({
    q: '*',
    limit: PAGE_LIMIT,
  });
  const { data: usersData , isLoading} = useSearchUsers(filter);
  const myFriendsData = useGetMyFriendIds({
    limit: 5000,
  });

  useEffect(() => {
    setFilter({
      ...filter,
      q: query,
    });
  }, [query, usersData?.searchUsers?.length]);

  useEffect(() => {
    if (isLoading) {
      loadingRef.current?.open();
    } else {
      loadingRef.current?.close();
    }
  }, [isLoading]);

  const fetchData = () => {
    if (filter.q === query && usersData?.searchUsers && usersData?.searchUsers?.length > 0) {
      setFilter({
        ...filter,
        after: usersData?.searchUsers[usersData?.searchUsers?.length - 1]?._id,
      });
    }
  };
  
  const refresh = () => {};

  return (
    <div className="">
      {query === '' && <div className="mt-10 font-semibold text-2xl">Đề xuất</div>}
      {searchBy === TabItemSearchBy.All && usersData?.searchUsers?.length !== 0 && <div className="font-semibold text-2xl mt-4">Người dùng</div>}

      <div className="fixed top-[15%] left-[47%] z-50">
        <Loading ref={loadingRef} />
      </div>
      
      <InfiniteScroll
        className="mb-5 mt-5 bg-slate-50 grid min-[374px]:grid-cols-1 min-[374px]:gap-5 sm:grid-cols-2 sm:gap-2"
        dataLength={usersData?.searchUsers?.length || 0}
        next={fetchData}
        hasMore={true}
        loader={''}
        endMessage={''}
        refreshFunction={refresh}>
        {usersData?.searchUsers?.length !== 0 &&
          usersData?.searchUsers?.map((user, idx) => {
            return <UserItem key={user._id + idx} user={user as User} isMyFriend={myFriendsData?.map((friend) => friend._id).includes(user._id) ?? false} />;
          })}
      </InfiniteScroll>
      {searchBy === TabItemSearchBy.All ? (
        <></>
      ) : (
        <>
            {usersData?.searchUsers?.length === 0 && query !== '' && (
            <center className="">
              <MdSearchOff size={'45px'} />
              <p className="mt-2">{`Không tìm thấy kết quả tìm kiếm cho từ khoá "${query}"`}</p>
            </center>
          )}
        </>
      )}
    </div>
  );
};

export default ListUser;
