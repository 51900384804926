import { ChangeEvent, useRef, useState } from 'react';
import { createPreviewMedia } from '../../../../../Ultis/files';
import CameraSvg from '../../../../../assets/images/icon/camera.svg';
import {
  InputMaybe,
  PageCategory,
  Scalars,
} from '../../../../../graphql/generated';
import { TypeMediaFile } from './StepsLayout';
import NotifyModal from '../../../../../components/modal/NotifyModal';

export interface ISelectAvatarParams {
  name: string;
  categories?: PageCategory[];
  handleMedia?: (file: FileList, type: TypeMediaFile) => void;
}

export interface Preview {
  id?: string;
  key: string;
  idx: number;
  uri: string;
  type: 'image' | 'video';
  file: File;
  status: 'Uploading' | 'Completed' | 'Error' | 'Cancel';
  isSkip?: boolean;
}

export default function SelectAvatar({
  name,
  categories,
  handleMedia,
}: ISelectAvatarParams) {
  const refAvatarUploadInput = useRef<HTMLInputElement>(null);
  const [preview, setPreview] = useState<Preview[]>([]);
  const [isShowModalNotify, setIsShowModalNotify] = useState(false);

  const handleSelectAvatar = () => {
    refAvatarUploadInput.current?.click();
  };

  const handleChangeInputFile = (e: any) => {
    const file = e.target.files;

    if (file) {
      if (
        file[0].type === 'image/jpeg' ||
        file[0].type === 'image/png' ||
        file[0].type === 'image/jpg'
      ) {
        const newPreview = createPreviewMedia(file, file.length);
        setPreview(newPreview);
        if (handleMedia) {
          handleMedia(file, TypeMediaFile.Avatar);
        }
      } else {
        setIsShowModalNotify(true);
        e.target.value = null;
      }
    }
  };

  const handleCloseModalNotify = () => {
    setIsShowModalNotify(false);
  };

  return (
    <>
      {isShowModalNotify && (
        <NotifyModal
          isShow={isShowModalNotify}
          message="Sai định dạng ảnh, vui lòng thử lại."
          onClose={handleCloseModalNotify}
        />
      )}
      <div className="absolute -top-16 left-5 w-28 h-28 p-1 bg-white rounded-full">
        <div
          onClick={handleSelectAvatar}
          className="flex justify-center items-center  text-center w-full h-full bg-[#80C3F0]/30 rounded-full">
          <div
            className={
              preview && preview.length > 0 ? 'rounded-full w-full h-full' : ''
            }>
            {preview && preview.length > 0 ? (
              <img
                className="w-full h-full bg-white rounded-full"
                src={preview[0].uri}
                alt="preview"
              />
            ) : (
              <>
                <div className="flex justify-center items-center mb-3">
                  <img src={CameraSvg} alt="icon" />
                </div>
                <span className="block text-xs text-[#818B95]">
                  Ảnh đại diện
                </span>
              </>
            )}

            <input
              accept="image/png, image/gif, image/jpeg"
              type="file"
              name="avatar"
              ref={refAvatarUploadInput}
              hidden={true}
              onChange={handleChangeInputFile}
            />
          </div>
        </div>
      </div>
      <div className="my-3 text-center">
        <h3>{name}</h3>

        <span className="text-[#888888]">
          <>
            {categories?.map((category, i) => {
              if (i < categories.length - 1) {
                return `${category.name.VI_VN}, `;
              }
              return category.name.VI_VN;
            })}
          </>
        </span>
      </div>
    </>
  );
}
