import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { accountDemo } from '../../Ultis/account';
import { setAuthData, setRefreshToken, setUser } from '../../Ultis/token';
import { images } from '../../assets/images';
import ButtonComponent from '../../components/button/ButtonComponent';
import InputComponent from '../../components/input/InputComponent';
import PhoneInput from '../../components/input/PhoneInput';
import Loading, { LoadingRef } from '../../components/loading/Loading';
import { useLoginUserMutation } from '../../graphql/generated';
import { graphqlClientRequest } from '../../graphql/services/graphql-client';
import validateLogin from '../../Ultis/validateLogin';
import './style/index.scss';

const Login = () => {
  const [allow, setAllow] = useState(false);
  const checkBoxRef = useRef<HTMLInputElement | null>(null);
  const inputPhoneRef = useRef<HTMLInputElement | null>(null);
  const inputPasswordRef = useRef<HTMLInputElement | null>(null);
  const loadingRef = useRef<LoadingRef>(null);
  const [data, setData] = useState({
    phone: '',
    password: '',
  });
  const [demo, setDemo] = useState({
    phone: accountDemo.phoneNumber,
    password: accountDemo.password,
  });
  const [errors, setErrors] = useState({
    phone: '',
    isErrors: false,
    login: '',
  });

  const [code, setCode] = useState(84);
  const navigate = useNavigate();
  const { mutateAsync } = useLoginUserMutation(graphqlClientRequest(true), {
    onMutate() {
      loadingRef.current?.open();
    },
    onSettled() {
      loadingRef.current?.close();
    },
    onError(error: any, variables, context) {
      if (error?.response?.errors) {
        setErrors((state) => ({
          ...state,
          login: error.response?.errors[0].extensions.code,
        }));
      }
    },
  });

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      navigate('/home');
    } else {
      navigate('/');
    }
  }, []);

  const onChange = (type: string) => (value: string) => {
    const valueCheck = validateLogin('phone', value);
    if (type === 'phone') {
      setErrors((state) => ({
        ...state,
        phone: valueCheck,
      }));
    }

    setData((state) => ({
      ...state,
      [type]: value,
    }));
  };

  const onPressLogin = async () => {
    if (!errors.phone || data) {
      try {
        const res = await mutateAsync({
          data: {
            phoneNumber: `+${code}${
              data.phone.substring(1) || demo.phone.substring(1)
            }`,
            password: data.password || demo.password,
          },
        }).then((data) => {
          setUser(data.login.idTokenPayload.user);
          setAuthData(data.login.idToken);
          localStorage.setItem('token', JSON.stringify(data.login.idToken));
          localStorage.setItem(
            'uid',
            JSON.stringify(data.login.idTokenPayload.uid),
          );
          setRefreshToken(data.login.refreshToken);
        });
        navigate('/home');
      } catch (err: any) {
        setErrors((state) => ({
          ...state,
          login: err.response.errors[0].extensions.code,
        }));
      }
    } else {
      setErrors((state) => ({
        ...state,
        isErrors: true,
      }));
    }
  };

  const onChangeCheckbox = (e: any) => {
    if (e.target.checked) {
      if (inputPasswordRef.current && inputPhoneRef.current) {
        inputPhoneRef.current.disabled = true;
        inputPasswordRef.current.disabled = true;
        setAllow(!allow);
      }
      setData({
        phone: '',
        password: '',
      });
      setDemo({
        phone: accountDemo.phoneNumber,
        password: accountDemo.password,
      });
    } else {
      if (inputPasswordRef.current && inputPhoneRef.current) {
        inputPhoneRef.current.disabled = false;
        inputPasswordRef.current.disabled = false;
        setAllow(false);
      }
      setDemo({ phone: '', password: '' });
    }
  };

  const getCountryCode = (value: number) => {
    setCode(value);
  };

  return (
    <div className="flex items-center justify-center  bg-third-gray h-[100vh] login-page">
      <Loading ref={loadingRef} />
      <div className=" w-full h-[100vh] flex items-center gap-[20px] login-page-content">
        <div className="content-left w-1/2 ">
          <img src={images.BgLoginPage} alt="" />
        </div>
        <div className="content-right w-1/2 ">
          <div className="flex items-center justify-start content-right-item">
            <div className="content-right-item-content text-left p-10 bg-white rounded-[16px]">
              <div className="flex justify-center">
                <img
                  src={images.LogoLogin}
                  alt="logo"
                  className="logo"
                  width={300}
                />
              </div>
              <div className="mt-8">
                <div className="mb-10">
                  <p className="text-2xl">Xin chào</p>
                  <p className="text-primary-gray text-sm ">
                    Chào mừng đến với In2spire
                  </p>
                </div>
                <form>
                  <div className="mb-8 relative">
                    <PhoneInput
                      onChange={onChange('phone')}
                      getCountryCode={getCountryCode}
                      ref={inputPhoneRef}
                      allow={allow}
                    />
                    <InputComponent
                      forgot
                      onChange={onChange('password')}
                      ref={inputPasswordRef}
                      allow={allow}
                    />
                    {errors.login === 'BAD_USER_INPUT' && (
                      <p className="text-sm text-red-400 absolute bottom-[-23px]">
                        {errors.phone ||
                          'Số điện thoại hoặc mật khẩu không đúng'}
                      </p>
                    )}
                    {errors.login === 'USER_NOT_FOUND' && (
                      <p className="text-sm text-red-400 absolute bottom-[-23px]">
                        Tài khoản không tồn tại
                      </p>
                    )}
                    {errors.login === 'INVALID_PASSWORD' && (
                      <p className="text-sm text-red-400 absolute bottom-[-23px]">
                        Số điện thoại hoặc mật khẩu không đúng
                      </p>
                    )}
                    {errors.login === 'USER_HAS_BEEN_BLOCKED' && (
                      <p className="text-sm text-red-400 absolute bottom-[-23px]">
                        Tài khoản đã bị khóa
                      </p>
                    )}
                  </div>
                  <div className="flex  items-center">
                    <input
                      type="checkbox"
                      className="w-4 h-4 cursor-pointer"
                      onChange={onChangeCheckbox}
                      ref={checkBoxRef}
                    />
                    <p className="text-primary-yellow text-sm ml-3">
                      Đăng nhập bằng tài khoản Demo
                    </p>
                  </div>
                  <ButtonComponent onPress={onPressLogin} />

                  <div className="flex justify-center items-center my-4">
                    <hr className="bg-primary-gray w-4 h-1 mr-3" />
                    <p className="text-xs italic ">Hoặc đăng nhập bằng</p>
                    <hr className="bg-primary-gray w-4 h-1 ml-3" />
                  </div>
                  <div className="flex justify-between mt-5">
                    <div className="bg-gray-200 flex items-center px-10 py-3 rounded-lg cursor-pointer">
                      <img src={images.Facebook} alt="logo" className="logo" />
                      <p className="ml-3">Facebook</p>
                    </div>
                    <div className="bg-black flex items-center px-14 py-3 rounded-lg justify-center cursor-pointer">
                      <img src={images.Apple} alt="logo" className="logo" />
                      <p className="text-white ml-3">Apple</p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
