import { Link } from 'react-router-dom';
import { requestedAtFormat } from '../../Ultis/requestedAtFormat';
import userDefaultImg from '../../assets/images/user.jpeg';
import { Media, Scalars } from '../../graphql/generated';
import ButtonComponent from '../button/ButtonComponent';
import FriendAction from './FriendAction';
import './index.scss';
import {
  useAcceptFriendRequestByUserId,
  useAddFriendRequest,
  useRejectFriendRequestByUserId,
} from '../../pages/FriendListMenu/services/api';
import { queryClient } from '../..';

export enum FriendItemType {
  MySuggestion = 'MySuggestion',
  MyFriend = 'MyFriend',
  MyInvite = 'MyInvite',
  UserFriend = 'UserFriend',
  Mutual = 'Mutual',
}

interface FriendItemProps {
  friend: FriendEntity;
  type: FriendItemType;
  isDisplayAction?: boolean;
  isDisplayBothBtn?: boolean;
  isDisplayTimeRequest?: boolean;
  isDisplayYellowBtn?: boolean;
}

export interface FriendEntity {
  avatar?: Media;
  username?: string;
  fullName?: string;
  _id?: string;
  totalMutualFriends?: number;
  requestedAt?: Scalars['DateTime'];
  verified?: boolean;
}

const FriendItem = ({
  friend,
  type = FriendItemType.MyFriend,
  isDisplayAction = false,
  isDisplayBothBtn = true,
  isDisplayTimeRequest = false,
  isDisplayYellowBtn = true,
}: FriendItemProps) => {
  const {
    avatar,
    username,
    fullName,
    _id: userId,
    totalMutualFriends,
    requestedAt,
    verified,
  } = friend;
  let gridCount = 3;

  if (
    totalMutualFriends == 0 ||
    type == FriendItemType.MyFriend ||
    type == FriendItemType.Mutual
  ) {
    gridCount = 2;
  }
  const { mutateAsync } = useAddFriendRequest();
  const { mutateAsync: rejectFriendRequest } = useRejectFriendRequestByUserId();
  const { mutateAsync: acceptFriendRequest } = useAcceptFriendRequestByUserId();

  const handleAddOrAccept = () => {
    if (type === FriendItemType.MySuggestion) {
      mutateAsync(
        {
          userId: userId || '',
        },
        {
          onSuccess() {
            queryClient.invalidateQueries(['getMyRecommendedFriends']);
          },
        },
      );
    } else {
      acceptFriendRequest(
        {
          userId: userId || '',
        },
        {
          onSuccess() {},
        },
      );
    }
  };

  const handleDeleteRecommendedFriend = () => {
    rejectFriendRequest(
      {
        userId: userId || '',
      },
      {
        onSuccess() {},
      },
    );
  };

  return (
    <div className="flex h-24 shadow-inner boder-radius bg-white rounded flex-row  border border-solid border-1 border-indigo-60">
      <div className="flex-1.5 avatar-wrap flex mx-5 rounded-full">
        <img
          className={`friend-avatar bg-cover self-center rounded-full ${
            verified && 'avartar-shadow_verify'
          }`}
          src={avatar?.uri ?? userDefaultImg}
          height={75}
          width={75}
          alt={'avatar_' + username}
        />
      </div>
      <div
        className={`flex-2 grid grid-rows-${gridCount} ${
          gridCount == 2 ? 'my-4' : 'my-2'
        }  w-[55%] gap-0`}>
        <Link to={'/profile/' + userId} className="h-1 ">
          <span className="text-md w-15 font-semibold text-black line-clamp-1">
            {fullName}
          </span>
        </Link>
        {totalMutualFriends !== 0 && (
          <p className=" h-1 text-slate-400 text-sm">
            {totalMutualFriends} bạn chung
          </p>
        )}
        {isDisplayBothBtn && (
          <div className="flex h-1">
            {isDisplayYellowBtn && (
              <div className="">
                <ButtonComponent
                  content={
                    type === FriendItemType.MySuggestion ||
                    type === FriendItemType.UserFriend
                      ? 'Kết bạn'
                      : 'Chấp nhận'
                  }
                  hover="hover:bg-yellow-300"
                  text_size="text-sm"
                  padding="py-1 px-3"
                  margin="mr-3"
                  size="w-25"
                  onPress={handleAddOrAccept}
                />
              </div>
            )}
            {type !== FriendItemType.UserFriend && (
              <div className="">
                <ButtonComponent
                  content="Xoá"
                  hover="hover:bg-gray-100"
                  text_size="text-sm"
                  padding="py-1 px-3"
                  size="w-20"
                  margin=""
                  type="bg-gray-200"
                  textColor="text-black"
                  onPress={handleDeleteRecommendedFriend}
                />
              </div>
            )}
          </div>
        )}
      </div>
      <div className="flex-1 w-1/6">
        <div className="relative">
          {isDisplayTimeRequest && (
            <span className="absolute top-5 right-5">
              <p className="whitespace-nowrap  text-slate-400 text-xs">
                {' '}
                {requestedAtFormat(requestedAt)}
              </p>
            </span>
          )}
          {isDisplayAction && (
            <div className="absolute top-8">
              <FriendAction />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FriendItem;
