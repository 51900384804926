import { motion } from 'framer-motion';
import { FC, ReactElement } from 'react';
import { AiFillLike } from 'react-icons/ai';

interface Props {
  children: ReactElement;
  onClick?: () => void;
}

const ButtonReaction: FC<Props> = ({ children, onClick }) => {
  return (
    <motion.div
      className=""
      initial={{ scale: 1 }}
      whileHover={{ rotate: -8, scale: 1.1 }}
      children={children}
      transition={{
        type: 'spring',
        stiffness: 260,
        damping: 20,
      }}
    />
  );
};

export default ButtonReaction;
