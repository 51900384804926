import { useQueryClient } from '@tanstack/react-query';
import {
  CreateCommentMutationVariables,
  GetMediaDetailQueryVariables,
  PostQueryVariables,
  useCreateCommentMutation,
  useDeleteCommentMutation,
  useGetMediaDetailQuery,
  usePostQuery,
  useUpdateCommentMutation,
  useUploadPhotoMutation,
} from '../../../graphql/generated';
import { graphqlClientRequest } from '../../../graphql/services/graphql-client';

export const usePost = (input: PostQueryVariables) => {
  const { data, isLoading, isError } = usePostQuery(
    graphqlClientRequest(true),
    input,
    {
      onSuccess(data) {},
      onError(error: any) {
        //   Notification.Error({ message: getMessageError(error) });
      },

      retry: false,
    },
  );

  return { data, isLoading, isError };
};

export const useGetMediaDetail = (
  input: GetMediaDetailQueryVariables,
  enabled = false,
) => {
  const { data, isLoading, isError } = useGetMediaDetailQuery(
    graphqlClientRequest(true),
    input,
    {
      onSuccess(data) {},
      onError(error: any) {
        //   Notification.Error({ message: getMessageError(error) });
      },

      retry: false,
      enabled,
    },
  );

  return { data, isLoading, isError };
};

export const useCreateComment = () => {
  const queryClient = useQueryClient();

  const { data, isLoading, isError, mutate, isSuccess } =
    useCreateCommentMutation(graphqlClientRequest(true), {
      onSuccess(data) {
        // queryClient.invalidateQueries(['getComments']);
      },

      retry: false,
    });

  return { data, isLoading, isError, mutate, isSuccess };
};

export const useUpdateComment = () => {
  const { data, isLoading, isError, mutate, isSuccess } =
    useUpdateCommentMutation(graphqlClientRequest(true), {
      onSuccess(data) {},

      retry: false,
    });

  return { data, isLoading, isError, mutate, isSuccess };
};

export const useDeleteComment = () => {
  const { data, isLoading, isError, mutate, isSuccess } =
    useDeleteCommentMutation(graphqlClientRequest(true), {
      onSuccess(data) {},

      retry: false,
    });

  return { data, isLoading, isError, mutate, isSuccess };
};

export const useUploadPhoto = () => {
  const { data, isLoading, isError, mutate, mutateAsync, isSuccess } =
    useUploadPhotoMutation(graphqlClientRequest(true), {
      onSuccess(data) {},
      onError(error: any) {
        //   Notification.Error({ message: getMessageError(error) });
      },

      retry: false,
    });

  return { data, isLoading, isError, mutate , mutateAsync , isSuccess };
};
