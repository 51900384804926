import * as React from 'react';

interface Props {
  tabs: React.ReactNode;
}

const FriendListMenuTab: React.FC<Props> = ({ tabs }: Props) => {
  return (
    <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
      <ul className="flex flex-wrap -mb-px" role="tablist">
        {tabs}
      </ul> 
    </div>
  );
};

export default FriendListMenuTab;
