import { AiOutlineCheckSquare } from 'react-icons/ai';
import { BsHeartFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import userImg from '../../assets/images/user.jpeg';
import { PageItemType } from '../../pages/ListPage/ListPage';
import Avatar from '../avatar/Avatar';
import ButtonGray from '../button/ButtonGray';
import ButtonOrange from '../button/ButtonOrange';
import '../friend-menu/index.scss';

export enum FriendItemType {
  MySuggestion = 'MySuggestion',
  MyFriend = 'MyFriend',
  MyInvite = 'MyInvite',
  UserFriend = 'UserFriend',
  Mutual = 'Mutual',
}

interface FriendItemProps {
  page: any;
  type: PageItemType;
  isDisplayAction?: boolean;
  isDisplayBothBtn?: boolean;
  isDisplayTimeRequest?: boolean;
}

const PageItem = ({
  page,
  type = PageItemType.MyPage,
  isDisplayBothBtn = true,
}: FriendItemProps) => {
  const { logo, name, _id: pageId, totalLikes } = page;
  let gridCount = 3;
  if (type == PageItemType.MyPage) {
    gridCount = 2;
  }
  return (
    <div
      className={`flex p-2 shadow-inner bg-white rounded  items-center  border border-solid border-1 h-28`}>
      <div className="ml-2">
        <Link to={'/page/' + pageId}>
          <Avatar
            src={logo ? logo.uri : userImg}
            className="  !w-[75px] h-[75px]"
          />
        </Link>
      </div>
      <div className={`ml-3`}>
        <div>
          <Link to={'/page/' + pageId} className="h-1">
            <span className="text-md w-15 font-semibold text-black">
              {name}
            </span>
          </Link>{' '}
          <br />
          {type !== PageItemType.MyPage && <span>{totalLikes} thích</span>}
        </div>
        {isDisplayBothBtn && (
          <div className="w-fit mt-2">
            {type === PageItemType.Discover ? (
              <ButtonOrange icon={<BsHeartFill />} text="Thích" />
            ) : (
              <ButtonGray
                icon={<AiOutlineCheckSquare className="text-black" />}
                text="Đã thích"
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PageItem;
