import { ChangeEvent, forwardRef, useEffect, useRef, useState } from 'react';
import { AiOutlineStar } from 'react-icons/ai';
import { IoMdClose } from 'react-icons/io';
import {
  concatFileFromFileList,
  createPreviewMedia,
  generateVideoThumbnail,
  removeFileFromFileList,
  validateFile,
} from '../../Ultis/files';
import { uploadVideoApi } from '../../Ultis/upload';
import { images } from '../../assets/images';
import {
  useCreatePost,
  useCreateSharePost,
  useUpdatePost,
} from '../../common/services/api';
import {
  GetPageQuery,
  MediaType,
  Post,
  PostType,
  User,
} from '../../graphql/generated';
import { useUploadPhoto } from '../../pages/DetailPost/services/api';
import LoadingExtra from '../loading/LoadingExtra';
import ConfirmVoteAddressModal from '../modal/ConfirmVoteAddressModal';
import VoteAddressModal from '../modal/VoteAddressModal';
import HeaderPost from '../posts/HeaderPost';
import ManyImageComponent from '../posts/ManyImageComponent';
import LocationModal from './Location';
import TagFriendModal from './TagFriendModal';
import './index.scss';
import { useLocation, useParams } from 'react-router-dom';

interface Props {
  user: User;
  post?: Post;
  type: PostType;
  isInMyPage?: string;
  page?: GetPageQuery['getPage'] | undefined;
  isUpdate?: boolean;
  onPostCreated: (post: any) => void;
  onReset: () => void;
}
export interface Preview {
  id?: string;
  key: string;
  idx: number;
  uri: string;
  type: 'image' | 'video';
  file: File;
  status: 'Uploading' | 'Completed' | 'Error' | 'Cancel';
  isSkip?: boolean;
}

interface UserTagged {
  __typename?: 'User';
  _id: string;
  fullName?: string;
  username?: string;
  totalMutualFriends?: number | null;
  verified?: boolean | null;
  avatar?: { __typename?: 'Media'; uri: string } | null;
  tagged?: boolean;
}

interface Location {
  city: string | null;
  country: string | null;
  latitude: number;
  longitude: number;
}

interface InterPlace {
  formattedAddress: string;
  location: Location;
  name: string;
  refId: string;
}

interface Error {
  code: string;
  message: string;
}

enum ErrorCode {
  MAX_FILE_SIZE = 'MAX_FILE_SIZE',
}

const _URL = window.URL || window.webkitURL;

const MAX_DISPLAY_FRIEND_TAGGED = 3;

const CreatePostModal = forwardRef(
  (
    {
      user,
      onPostCreated,
      onReset,
      type,
      page,
      post,
      isUpdate,
      isInMyPage,
    }: Props,
    ref: any,
  ) => {
    const defaultVisibleModals = {
      mainModal: true,
      tagFriendModal: false,
      locationModal: false,
    };
    const [visibleModals, setVisibleModals] = useState(defaultVisibleModals);
    const [preview, setPreview] = useState<Preview[]>([]);
    const [errors, setErrors] = useState<Error[]>([]);
    const [confirmVoteState, setConfirmVoteState] = useState(false);
    const [contentPost, setContentPost] = useState<string>('');
    const [titlePost, setTitlePost] = useState<string>('');
    const [location, setLocation] = useState<InterPlace>();
    const [rating, setRating] = useState<any>();
    const [isShowModalVote, setIsShowModalVote] = useState(false);
    const [taggedFriends, setTaggedFriends] = useState<UserTagged[]>([]);
    const [isDisableCreatePostBtn, setIsDisableCreatePostBtn] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isCloseModal, setIsCloseModal] = useState<number>(0);

    const refUploadInput = useRef<HTMLInputElement>(null);
    const refTextInput = useRef<HTMLTextAreaElement>(null);
    const { mutate: createPostMutate } = useCreatePost();
    const { mutate: createSharePostMutate } = useCreateSharePost();
    const { mutate: updatePostMutate, isSuccess } = useUpdatePost();
    const { mutateAsync: uploadPhotoAsync } = useUploadPhoto();
    const { pathname } = useLocation();
    const { groupId } = useParams();

    const locationCurrent = pathname.split('/')[1].toLocaleLowerCase();

    console.log('pathname', pathname);
    console.log('groupId', groupId);
    console.log('locationCurrent', locationCurrent);

    useEffect(() => {
      setIsDisableCreatePostBtn(false);
    }, [taggedFriends, location, preview]);

    const handleUploadPhoto = (file: File, key: string): void => {
      const img = new Image();
      const objectUrl = _URL.createObjectURL(file);
      img.onload = () => {
        _URL.revokeObjectURL(objectUrl);
        return uploadPhotoAsync({
          file,
          dimensions: {
            width: img.width,
            height: img.height,
          },
        }).then((data) => {
          setPreview((p) => {
            const idxKey = p.findIndex((i) => i.key === key);
            if (idxKey !== -1) {
              p[idxKey].id = data.uploadPhoto._id;
              p[idxKey].status = 'Completed';
            }
            return [...p];
          });
        });
      };
      img.src = objectUrl;
    };

    const handleUploadVideo = async (file: File, key: string) => {
      const { data: media } = await uploadVideoApi(file);
      setPreview((p) => {
        const idxKey = p.findIndex((i) => i.key === key);
        if (idxKey !== -1) {
          p[idxKey].id = media._id;
          p[idxKey].status = 'Completed';
        }
        return [...p];
      });
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (refUploadInput.current?.hidden !== undefined) {
        refUploadInput.current.hidden = true;
      }
      const files = e.target.files;
      if (!files || files.length === 0) {
        return;
      }
      try {
        validateFile(
          concatFileFromFileList(
            files,
            preview
              .filter((preview) => !preview.isSkip)
              .map((preview) => preview.file),
          ),
        );
        const newPreview = createPreviewMedia(files, preview.length);
        setPreview([...preview, ...newPreview]);
        Promise.all(
          newPreview.map((media) => {
            if (media.type === 'image') {
              return handleUploadPhoto(media.file, media.key);
            }
            return handleUploadVideo(media.file, media.key);
          }),
        );
      } catch (err) {
        refUploadInput.current &&
          (refUploadInput.current.files = new DataTransfer().files);
        setErrors([
          ...errors,
          {
            code: ErrorCode.MAX_FILE_SIZE,
            message: 'Upload tệp không được quá 5M',
          },
        ]);
      }
    };

    const handleRemovePreview = (idx: number, key: string) => {
      if (refUploadInput.current?.files) {
        refUploadInput.current.files = removeFileFromFileList(
          idx,
          refUploadInput.current.files,
        );
      }
      _URL.revokeObjectURL(preview.find((item) => item.uri)?.uri ?? '');
      setPreview(preview.filter((media) => media.key !== key));
    };

    const handleClickUploadFile = () => {
      if (refUploadInput.current?.hidden !== undefined) {
        refUploadInput.current.hidden = false;
      }
      if (refUploadInput.current !== null) {
        refUploadInput.current.click();
        document.body.onfocus = () => {
          refUploadInput.current && (refUploadInput.current.hidden = true);
        };
      }
    };

    const handleClickClose = () => {
      refUploadInput.current?.hidden && ref.current.close();
      setVisibleModals({ ...defaultVisibleModals });
      document.body.classList.remove('overflow-hidden');

      if (isUpdate && post?.medias) {
        setPreview(
          post.medias.map((item: any, idx: number) => {
            return {
              id: item._id,
              idx,
              type: item.type === 'PHOTO' ? 'image' : 'video',
              key: item._id,
              uri: item.uri,
              status: 'Completed',
              file: {} as File,
              isSkip: true,
            };
          }),
        );
      }

      if (isUpdate && post?.place) {
        setLocation({
          refId: post?.place?.refId,
          formattedAddress: post?.place?.formattedAddress,
          location: {
            city: post?.place?.location?.city || null,
            country: post?.place?.location?.city || null,
            latitude: post?.place?.location?.latitude || 0,
            longitude: post?.place?.location?.longitude || 0,
          },
          name: post?.place?.name,
        });
      }

      if (isUpdate && post?.content) {
        setContentPost(post?.content);
      }

      if (isUpdate && post?.allTaggedUsers) {
        setTaggedFriends(post?.allTaggedUsers);
      }

      if (!!errors) {
        setErrors([]);
      }

      setIsCloseModal(Math.random());
    };

    const handleTextContentChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
      e.preventDefault();
      setContentPost(e.target.value);
      if (contentPost.trim().length > 0) setIsDisableCreatePostBtn(false);
      if (preview.length === 0 && contentPost.trim().length === 0)
        setIsDisableCreatePostBtn(true);
      if (refTextInput.current) {
        refTextInput.current.style.height = 'inherit';
        refTextInput.current.style.fontSize = 'inherit';
        refTextInput.current.style.height = `${Math.max(
          refTextInput.current.scrollHeight,
          80,
        )}px`;
        if (refTextInput.current.scrollHeight > 200) {
          refTextInput.current.style.height = '200px';
          refTextInput.current.style.fontSize = '13px';
        }
      }
    };

    const handleTextTitleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
      e.preventDefault();
      setTitlePost(e.target.value);
      if (titlePost.trim().length > 0) setIsDisableCreatePostBtn(false);
      if (
        preview.length === 0 &&
        contentPost.trim().length === 0 &&
        titlePost.trim().length === 0
      )
        setIsDisableCreatePostBtn(true);
      if (refTextInput.current) {
        refTextInput.current.style.height = 'inherit';
        refTextInput.current.style.fontSize = 'inherit';
        refTextInput.current.style.height = `${Math.max(
          refTextInput.current.scrollHeight,
          80,
        )}px`;
        if (refTextInput.current.scrollHeight > 200) {
          refTextInput.current.style.height = '200px';
          refTextInput.current.style.fontSize = '13px';
        }
      }
    };

    const handleCreatePost = async () => {
      if (!!location && !isUpdate && !rating) {
        setConfirmVoteState(true);
      } else {
        if (!!post?.page && isUpdate) {
          setIsLoading(true);
          if (!!post?.rating) {
            if (locationCurrent === 'groups') {
              updatePostMutate(
                {
                  id: post?._id || '',
                  data: {
                    content: contentPost.trim(),
                    type: PostType.Review,
                    rating: rating,
                    groupId: groupId,
                    mediaIds:
                      preview
                        .filter(
                          (preview) =>
                            !!preview.id && preview.status === 'Completed',
                        )
                        .map((preview) => preview.id ?? '') ?? [],
                    place: location ? location : null,
                    pageId: post?.page?._id,
                    taggedUserIds:
                      taggedFriends.length > 0
                        ? taggedFriends.map((user) => user._id)
                        : [],
                  },
                },
                {
                  async onSuccess(data, variables, context) {
                    ref.current.close();
                    setIsLoading(false);
                    document.body.classList.remove('overflow-hidden');
                    if (data?.updatePost?.medias) {
                      data.updatePost.medias = await Promise.all(
                        data.updatePost.medias.map(async (media) => {
                          if (media.type === MediaType.Video) {
                            const previewOfMedia = preview.find(
                              (p) => p.id === media._id,
                            );
                            if (previewOfMedia) {
                              media.thumbnail = await generateVideoThumbnail(
                                previewOfMedia.file,
                              );
                            }
                          }
                          return media;
                        }) ?? [],
                      );
                    }
                    onPostCreated(data?.updatePost as Post);
                    onReset();
                  },
                },
              );
            } else {
              updatePostMutate(
                {
                  id: post?._id || '',
                  data: {
                    content: contentPost.trim(),
                    type: PostType.Review,
                    rating: rating,
                    mediaIds:
                      preview
                        .filter(
                          (preview) =>
                            !!preview.id && preview.status === 'Completed',
                        )
                        .map((preview) => preview.id ?? '') ?? [],
                    place: location ? location : null,
                    pageId: post?.page?._id,
                    taggedUserIds:
                      taggedFriends.length > 0
                        ? taggedFriends.map((user) => user._id)
                        : [],
                  },
                },
                {
                  async onSuccess(data, variables, context) {
                    ref.current.close();
                    setIsLoading(false);
                    document.body.classList.remove('overflow-hidden');
                    if (data?.updatePost?.medias) {
                      data.updatePost.medias = await Promise.all(
                        data.updatePost.medias.map(async (media) => {
                          if (media.type === MediaType.Video) {
                            const previewOfMedia = preview.find(
                              (p) => p.id === media._id,
                            );
                            if (previewOfMedia) {
                              media.thumbnail = await generateVideoThumbnail(
                                previewOfMedia.file,
                              );
                            }
                          }
                          return media;
                        }) ?? [],
                      );
                    }
                    onPostCreated(data?.updatePost as Post);
                    onReset();
                  },
                },
              );
            }
          } else {
            if (locationCurrent === 'groups') {
              updatePostMutate(
                {
                  id: post?._id || '',
                  data: {
                    content: contentPost.trim(),
                    type: type,
                    groupId: groupId,
                    mediaIds:
                      preview
                        .filter(
                          (preview) =>
                            !!preview.id && preview.status === 'Completed',
                        )
                        .map((preview) => preview.id ?? '') ?? [],
                    place: location ? location : null,
                    pageId: post?.page?._id,
                    taggedUserIds:
                      taggedFriends.length > 0
                        ? taggedFriends.map((user) => user._id)
                        : [],
                  },
                },
                {
                  async onSuccess(data, variables, context) {
                    ref.current.close();
                    setIsLoading(false);
                    document.body.classList.remove('overflow-hidden');
                    if (data?.updatePost?.medias) {
                      data.updatePost.medias = await Promise.all(
                        data.updatePost.medias.map(async (media) => {
                          if (media.type === MediaType.Video) {
                            const previewOfMedia = preview.find(
                              (p) => p.id === media._id,
                            );
                            if (previewOfMedia) {
                              media.thumbnail = await generateVideoThumbnail(
                                previewOfMedia.file,
                              );
                            }
                          }
                          return media;
                        }) ?? [],
                      );
                    }
                    onPostCreated(data?.updatePost as Post);
                    onReset();
                  },
                },
              );
            } else {
              updatePostMutate(
                {
                  id: post?._id || '',
                  data: {
                    content: contentPost.trim(),
                    type: type,
                    mediaIds:
                      preview
                        .filter(
                          (preview) =>
                            !!preview.id && preview.status === 'Completed',
                        )
                        .map((preview) => preview.id ?? '') ?? [],
                    place: location ? location : null,
                    pageId: post?.page?._id,
                    taggedUserIds:
                      taggedFriends.length > 0
                        ? taggedFriends.map((user) => user._id)
                        : [],
                  },
                },
                {
                  async onSuccess(data, variables, context) {
                    ref.current.close();
                    setIsLoading(false);
                    document.body.classList.remove('overflow-hidden');
                    if (data?.updatePost?.medias) {
                      data.updatePost.medias = await Promise.all(
                        data.updatePost.medias.map(async (media) => {
                          if (media.type === MediaType.Video) {
                            const previewOfMedia = preview.find(
                              (p) => p.id === media._id,
                            );
                            if (previewOfMedia) {
                              media.thumbnail = await generateVideoThumbnail(
                                previewOfMedia.file,
                              );
                            }
                          }
                          return media;
                        }) ?? [],
                      );
                    }
                    onPostCreated(data?.updatePost as Post);
                    onReset();
                  },
                },
              );
            }
          }
        } else {
          if (type === PostType.Share && !!post) {
            createSharePostMutate(
              {
                data: {
                  _id: post._id,
                  content: contentPost.trim(),
                  place: location ? location : null,
                  taggedUserIds:
                    taggedFriends.length > 0
                      ? taggedFriends.map((user) => user._id)
                      : [],
                },
              },
              {
                async onSuccess(data, variables, context) {
                  ref.current.close();
                  document.body.classList.remove('overflow-hidden');
                  onPostCreated(data?.sharePost as Post);
                  onReset();
                },
              },
            );
          } else if (isUpdate && !rating) {
            if (locationCurrent === 'groups') {
              updatePostMutate(
                {
                  id: post?._id || '',
                  data: {
                    content: contentPost.trim(),
                    type: type,
                    groupId: groupId,
                    mediaIds:
                      preview
                        .filter(
                          (preview) =>
                            !!preview.id && preview.status === 'Completed',
                        )
                        .map((preview) => preview.id ?? '') ?? [],
                    place: location ? location : null,
                    pageId: page ? page._id : null,
                    taggedUserIds:
                      taggedFriends.length > 0
                        ? taggedFriends.map((user) => user._id)
                        : [],
                  },
                },
                {
                  async onSuccess(data, variables, context) {
                    ref.current.close();
                    document.body.classList.remove('overflow-hidden');
                    if (data?.updatePost?.medias) {
                      data.updatePost.medias = await Promise.all(
                        data.updatePost.medias.map(async (media) => {
                          if (media.type === MediaType.Video) {
                            const previewOfMedia = preview.find(
                              (p) => p.id === media._id,
                            );
                            if (previewOfMedia) {
                              media.thumbnail = await generateVideoThumbnail(
                                previewOfMedia.file,
                              );
                            }
                          }
                          return media;
                        }) ?? [],
                      );
                    }
                    onPostCreated(data?.updatePost as Post);
                    onReset();
                  },
                },
              );
            } else {
              updatePostMutate(
                {
                  id: post?._id || '',
                  data: {
                    content: contentPost.trim(),
                    type: type,
                    mediaIds:
                      preview
                        .filter(
                          (preview) =>
                            !!preview.id && preview.status === 'Completed',
                        )
                        .map((preview) => preview.id ?? '') ?? [],
                    place: location ? location : null,
                    pageId: page ? page._id : null,
                    taggedUserIds:
                      taggedFriends.length > 0
                        ? taggedFriends.map((user) => user._id)
                        : [],
                  },
                },
                {
                  async onSuccess(data, variables, context) {
                    ref.current.close();
                    document.body.classList.remove('overflow-hidden');
                    if (data?.updatePost?.medias) {
                      data.updatePost.medias = await Promise.all(
                        data.updatePost.medias.map(async (media) => {
                          if (media.type === MediaType.Video) {
                            const previewOfMedia = preview.find(
                              (p) => p.id === media._id,
                            );
                            if (previewOfMedia) {
                              media.thumbnail = await generateVideoThumbnail(
                                previewOfMedia.file,
                              );
                            }
                          }
                          return media;
                        }) ?? [],
                      );
                    }
                    onPostCreated(data?.updatePost as Post);
                    onReset();
                  },
                },
              );
            }
          } else if (!!rating && !isUpdate) {
            if (locationCurrent === 'groups') {
              createPostMutate(
                {
                  data: {
                    content: contentPost.trim(),
                    type: PostType.Review,
                    groupId: groupId,
                    rating: rating,
                    title: titlePost,
                    mediaIds:
                      preview
                        .filter(
                          (preview) =>
                            !!preview.id && preview.status === 'Completed',
                        )
                        .map((preview) => preview.id ?? '') ?? [],
                    place: location ? location : null,
                    pageId: page ? page._id : null,
                    taggedUserIds:
                      taggedFriends.length > 0
                        ? taggedFriends.map((user) => user._id)
                        : [],
                  },
                },
                {
                  async onSuccess(data, variables, context) {
                    ref.current.close();
                    document.body.classList.remove('overflow-hidden');
                    if (data?.createPost?.medias) {
                      data.createPost.medias = await Promise.all(
                        data.createPost.medias.map(async (media) => {
                          if (media.type === MediaType.Video) {
                            const previewOfMedia = preview.find(
                              (p) => p.id === media._id,
                            );
                            if (previewOfMedia) {
                              media.thumbnail = await generateVideoThumbnail(
                                previewOfMedia.file,
                              );
                            }
                          }
                          return media;
                        }) ?? [],
                      );
                    }
                    onPostCreated(data?.createPost as Post);
                    onReset();
                  },
                },
              );
            } else {
              createPostMutate(
                {
                  data: {
                    content: contentPost.trim(),
                    type: PostType.Review,
                    rating: rating,
                    title: titlePost,
                    mediaIds:
                      preview
                        .filter(
                          (preview) =>
                            !!preview.id && preview.status === 'Completed',
                        )
                        .map((preview) => preview.id ?? '') ?? [],
                    place: location ? location : null,
                    pageId: page ? page._id : null,
                    taggedUserIds:
                      taggedFriends.length > 0
                        ? taggedFriends.map((user) => user._id)
                        : [],
                  },
                },
                {
                  async onSuccess(data, variables, context) {
                    ref.current.close();
                    document.body.classList.remove('overflow-hidden');
                    if (data?.createPost?.medias) {
                      data.createPost.medias = await Promise.all(
                        data.createPost.medias.map(async (media) => {
                          if (media.type === MediaType.Video) {
                            const previewOfMedia = preview.find(
                              (p) => p.id === media._id,
                            );
                            if (previewOfMedia) {
                              media.thumbnail = await generateVideoThumbnail(
                                previewOfMedia.file,
                              );
                            }
                          }
                          return media;
                        }) ?? [],
                      );
                    }
                    onPostCreated(data?.createPost as Post);
                    onReset();
                  },
                },
              );
            }
          } else if (isUpdate && !!rating) {
            if (locationCurrent === 'groups') {
              updatePostMutate(
                {
                  id: post?._id || '',
                  data: {
                    content: contentPost.trim(),
                    type: PostType.Review,
                    rating: rating,
                    title: titlePost,
                    groupId: groupId,
                    mediaIds:
                      preview
                        .filter(
                          (preview) =>
                            !!preview.id && preview.status === 'Completed',
                        )
                        .map((preview) => preview.id ?? '') ?? [],
                    place: location ? location : null,
                    pageId: page ? page._id : null,
                    taggedUserIds:
                      taggedFriends.length > 0
                        ? taggedFriends.map((user) => user._id)
                        : [],
                  },
                },
                {
                  async onSuccess(data, variables, context) {
                    ref.current.close();
                    document.body.classList.remove('overflow-hidden');
                    if (data?.updatePost?.medias) {
                      data.updatePost.medias = await Promise.all(
                        data.updatePost.medias.map(async (media) => {
                          if (media.type === MediaType.Video) {
                            const previewOfMedia = preview.find(
                              (p) => p.id === media._id,
                            );
                            if (previewOfMedia) {
                              media.thumbnail = await generateVideoThumbnail(
                                previewOfMedia.file,
                              );
                            }
                          }
                          return media;
                        }) ?? [],
                      );
                    }
                    onPostCreated(data?.updatePost as Post);
                    onReset();
                  },
                },
              );
            } else {
              updatePostMutate(
                {
                  id: post?._id || '',
                  data: {
                    content: contentPost.trim(),
                    type: PostType.Review,
                    rating: rating,
                    title: titlePost,
                    mediaIds:
                      preview
                        .filter(
                          (preview) =>
                            !!preview.id && preview.status === 'Completed',
                        )
                        .map((preview) => preview.id ?? '') ?? [],
                    place: location ? location : null,
                    pageId: page ? page._id : null,
                    taggedUserIds:
                      taggedFriends.length > 0
                        ? taggedFriends.map((user) => user._id)
                        : [],
                  },
                },
                {
                  async onSuccess(data, variables, context) {
                    ref.current.close();
                    document.body.classList.remove('overflow-hidden');
                    if (data?.updatePost?.medias) {
                      data.updatePost.medias = await Promise.all(
                        data.updatePost.medias.map(async (media) => {
                          if (media.type === MediaType.Video) {
                            const previewOfMedia = preview.find(
                              (p) => p.id === media._id,
                            );
                            if (previewOfMedia) {
                              media.thumbnail = await generateVideoThumbnail(
                                previewOfMedia.file,
                              );
                            }
                          }
                          return media;
                        }) ?? [],
                      );
                    }
                    onPostCreated(data?.updatePost as Post);
                    onReset();
                  },
                },
              );
            }
          } else {
            if (locationCurrent === 'groups') {
              createPostMutate(
                {
                  data: {
                    content: contentPost.trim(),
                    type: type,
                    groupId: groupId,
                    mediaIds:
                      preview
                        .filter(
                          (preview) =>
                            !!preview.id && preview.status === 'Completed',
                        )
                        .map((preview) => preview.id ?? '') ?? [],
                    place: location ? location : null,
                    pageId: page ? page._id : null,
                    taggedUserIds:
                      taggedFriends.length > 0
                        ? taggedFriends.map((user) => user._id)
                        : [],
                  },
                },
                {
                  async onSuccess(data, variables, context) {
                    ref.current.close();
                    document.body.classList.remove('overflow-hidden');
                    if (data?.createPost?.medias) {
                      data.createPost.medias = await Promise.all(
                        data.createPost.medias.map(async (media) => {
                          if (media.type === MediaType.Video) {
                            const previewOfMedia = preview.find(
                              (p) => p.id === media._id,
                            );
                            if (previewOfMedia) {
                              media.thumbnail = await generateVideoThumbnail(
                                previewOfMedia.file,
                              );
                            }
                          }
                          return media;
                        }) ?? [],
                      );
                    }
                    onPostCreated(data?.createPost as Post);
                    onReset();
                  },
                },
              );
            } else {
              createPostMutate(
                {
                  data: {
                    content: contentPost.trim(),
                    type: type,
                    mediaIds:
                      preview
                        .filter(
                          (preview) =>
                            !!preview.id && preview.status === 'Completed',
                        )
                        .map((preview) => preview.id ?? '') ?? [],
                    place: location ? location : null,
                    pageId: page ? page._id : null,
                    taggedUserIds:
                      taggedFriends.length > 0
                        ? taggedFriends.map((user) => user._id)
                        : [],
                  },
                },
                {
                  async onSuccess(data, variables, context) {
                    ref.current.close();
                    document.body.classList.remove('overflow-hidden');
                    if (data?.createPost?.medias) {
                      data.createPost.medias = await Promise.all(
                        data.createPost.medias.map(async (media) => {
                          if (media.type === MediaType.Video) {
                            const previewOfMedia = preview.find(
                              (p) => p.id === media._id,
                            );
                            if (previewOfMedia) {
                              media.thumbnail = await generateVideoThumbnail(
                                previewOfMedia.file,
                              );
                            }
                          }
                          return media;
                        }) ?? [],
                      );
                    }
                    onPostCreated(data?.createPost as Post);
                    onReset();
                  },
                },
              );
            }
          }
        }
      }
    };

    const handleConfirm = () => {
      setConfirmVoteState(false);
      setIsShowModalVote(true);
    };

    const handleCloseModalVote = () => {
      if (locationCurrent === 'groups') {
        createPostMutate(
          {
            data: {
              content: contentPost.trim(),
              type: type,
              groupId: groupId,
              mediaIds:
                preview
                  .filter(
                    (preview) => !!preview.id && preview.status === 'Completed',
                  )
                  .map((preview) => preview.id ?? '') ?? [],
              place: location ? location : null,
              pageId: page ? page._id : null,
              taggedUserIds:
                taggedFriends.length > 0
                  ? taggedFriends.map((user) => user._id)
                  : [],
            },
          },
          {
            async onSuccess(data, variables, context) {
              ref.current.close();
              document.body.classList.remove('overflow-hidden');
              if (data?.createPost?.medias) {
                data.createPost.medias = await Promise.all(
                  data.createPost.medias.map(async (media) => {
                    if (media.type === MediaType.Video) {
                      const previewOfMedia = preview.find(
                        (p) => p.id === media._id,
                      );
                      if (previewOfMedia) {
                        media.thumbnail = await generateVideoThumbnail(
                          previewOfMedia.file,
                        );
                      }
                    }
                    return media;
                  }) ?? [],
                );
              }
              onPostCreated(data?.createPost as Post);
              onReset();
            },
          },
        );
      } else {
        createPostMutate(
          {
            data: {
              content: contentPost.trim(),
              type: type,
              mediaIds:
                preview
                  .filter(
                    (preview) => !!preview.id && preview.status === 'Completed',
                  )
                  .map((preview) => preview.id ?? '') ?? [],
              place: location ? location : null,
              pageId: page ? page._id : null,
              taggedUserIds:
                taggedFriends.length > 0
                  ? taggedFriends.map((user) => user._id)
                  : [],
            },
          },
          {
            async onSuccess(data, variables, context) {
              ref.current.close();
              document.body.classList.remove('overflow-hidden');
              if (data?.createPost?.medias) {
                data.createPost.medias = await Promise.all(
                  data.createPost.medias.map(async (media) => {
                    if (media.type === MediaType.Video) {
                      const previewOfMedia = preview.find(
                        (p) => p.id === media._id,
                      );
                      if (previewOfMedia) {
                        media.thumbnail = await generateVideoThumbnail(
                          previewOfMedia.file,
                        );
                      }
                    }
                    return media;
                  }) ?? [],
                );
              }
              onPostCreated(data?.createPost as Post);
              onReset();
            },
          },
        );
      }
      setConfirmVoteState(false);
    };

    const handleClickTagFriend = () => {
      setVisibleModals({
        ...visibleModals,
        mainModal: !visibleModals.mainModal,
        tagFriendModal: !visibleModals.tagFriendModal,
      });
    };

    const handleClickPinLocation = () => {
      setVisibleModals({
        ...visibleModals,
        mainModal: !visibleModals.mainModal,
        locationModal: !visibleModals.locationModal,
      });
    };

    const handleClickBackMain = () => {
      setVisibleModals({ ...defaultVisibleModals });
    };

    const handleFriendTaggedChange = (users: UserTagged[]) => {
      setTaggedFriends([...users]);
    };

    const handleSelectLocation = (location: any) => {
      setLocation(location);
    };

    useEffect(() => {
      if (
        contentPost.trim().length === 0 &&
        preview.every((preview) => preview.status !== 'Completed')
      ) {
        setIsDisableCreatePostBtn(true);
      } else if (preview.length === 0 && contentPost.trim().length === 0) {
        setIsDisableCreatePostBtn(true);
      } else if (
        contentPost.trim().length !== 0 &&
        preview.every((preview) => preview.status !== 'Completed') &&
        preview.length !== 0
      ) {
        setIsDisableCreatePostBtn(true);
      } else if (!!rating) {
        if (contentPost.trim().length === 0 || titlePost.trim().length === 0) {
          setIsDisableCreatePostBtn(true);
        }
      } else {
        setIsDisableCreatePostBtn(false);
      }
    }, [preview, contentPost, titlePost, rating]);

    useEffect(() => {
      if (!location) {
        setRating(undefined);
      }
    }, [location]);

    useEffect(() => {
      if (isUpdate && post?.medias) {
        setPreview(
          post.medias.map((item: any, idx: number) => {
            return {
              id: item._id,
              idx,
              type: item.type === 'PHOTO' ? 'image' : 'video',
              key: item._id,
              uri: item.uri,
              status: 'Completed',
              file: {} as File,
              isSkip: true,
            };
          }),
        );
      } else {
        setPreview([]);
      }

      if (isUpdate && post?.place) {
        setLocation({
          refId: post?.place?.refId,
          formattedAddress: post?.place?.formattedAddress,
          location: {
            city: post?.place?.location?.city || null,
            country: post?.place?.location?.city || null,
            latitude: post?.place?.location?.latitude || 0,
            longitude: post?.place?.location?.longitude || 0,
          },
          name: post?.place?.name,
        });
      } else {
        setLocation(undefined);
      }

      if (isUpdate && post?.allTaggedUsers) {
        setTaggedFriends(post?.allTaggedUsers);
      } else {
        setTaggedFriends([]);
      }

      if (isUpdate && post?.content) {
        setContentPost(post?.content);
      } else {
        setContentPost('');
      }

      if (isUpdate && post?.title) {
        setTitlePost(post?.title);
      } else {
        setTitlePost('');
      }

      if (isUpdate && post?.rating) {
        setRating(post?.rating);
      } else {
        setRating(undefined);
      }
    }, [post, isUpdate]);

    const handleVote = (star: number) => {
      setIsShowModalVote(false);
      setRating(star);
    };

    const handleClickStarVote = () => {
      setIsShowModalVote(true);
    };

    const handleCancelVote = () => {
      setIsShowModalVote(false);
      setRating(post?.rating);
    };

    const handleClickSelf = (e: any) => {
      const dialogDimensions = ref.current.getBoundingClientRect();
      if (
        e.clientX < (dialogDimensions?.left ?? 0) ||
        e.clientX > (dialogDimensions?.right ?? 0) ||
        e.clientY < (dialogDimensions?.top ?? 0) ||
        e.clientY > (dialogDimensions?.bottom ?? 0)
      ) {
        handleClickClose();
      }
    };

    return (
      <>
        <dialog
          className={`create-post_modal w-[500px] rounded-md`}
          ref={ref}
          onClick={handleClickSelf}>
          <form
            className="form-createPost_modal flex relative"
            onClick={(e) => e.stopPropagation()}>
            <div className="w-full" hidden={!visibleModals.mainModal}>
              <div className="form-createPost_header relative h-[60px] text-xl font-bold text-center">
                <h2 className="leading-[60px] ">
                  <span>
                    {type === PostType.Share
                      ? 'Chia sẻ bài viết'
                      : isUpdate
                      ? 'Chỉnh sửa bài viết'
                      : 'Tạo bài viết'}
                  </span>
                </h2>
                <div
                  onClick={handleClickClose}
                  className="absolute cursor-pointer rounded-full w-[35px] h-[35px] right-[14px] max-[400px]:right-[4px] top-[12px] bg-slate-200 hover:bg-slate-300">
                  <IoMdClose size={'25px'} className="mt-[5px] ml-[5px]" />
                </div>
              </div>
              <div className="p-4">
                <div className="flex justify-between items-center">
                  <div className="flex">
                    <img
                      src={
                        page
                          ? page.logo?.uri ?? images.AvatarDefault
                          : user?.avatar?.uri ?? images.AvatarDefault
                      }
                      alt={page ? page.name ?? '' : user?.username ?? ''}
                      className={`h-[45px] w-[45px]  rounded-full shadow_verify`}
                    />
                    <span
                      className={`text-md ml-4 ${
                        taggedFriends.length > 0 || location
                          ? 'text-wrap leading-[22px]'
                          : 'leading-[45px]'
                      } `}>
                      <span className="font-medium">
                        {page ? page.name : user.fullName}
                      </span>
                      {taggedFriends.length > 0 && (
                        <>
                          {' với '}
                          {taggedFriends.map((user, idx) => {
                            if (idx > 3) return;
                            if (idx === 3)
                              return (
                                <>
                                  {' và '}
                                  <span className="font-medium">
                                    {taggedFriends.length -
                                      MAX_DISPLAY_FRIEND_TAGGED +
                                      ' ' +
                                      'người khác'}
                                  </span>
                                </>
                              );

                            if (idx === 2 || !taggedFriends[idx + 1]) {
                              return (
                                <span className="font-medium">
                                  {user.fullName}
                                </span>
                              );
                            }
                            return (
                              <span className="font-medium">
                                {user.fullName + ', '}
                              </span>
                            );
                          })}
                        </>
                      )}
                      {location && (
                        <>
                          {' tại '}
                          <span className="font-medium">{location.name}</span>
                        </>
                      )}
                    </span>
                  </div>
                  {rating && (
                    <div
                      className="bg-yellow-400 rounded-full h-[30px] px-[10px] flex gap-1 items-center text-white"
                      onClick={handleClickStarVote}>
                      <span>{rating}</span>
                      <AiOutlineStar />
                    </div>
                  )}
                </div>
                {!!rating && (
                  <textarea
                    rows={1}
                    tabIndex={1}
                    value={titlePost}
                    onChange={handleTextTitleChange}
                    className={`text-xl max-[400px]:text-base w-full mt-3 outline-none min-h-[40px] bg-white`}
                    placeholder="Tiêu đề"
                  />
                )}
                <textarea
                  rows={1}
                  tabIndex={0}
                  value={contentPost}
                  onChange={handleTextContentChange}
                  className={`text-xl max-[400px]:text-base w-full mt-3 outline-none min-h-[80px] bg-white`}
                  placeholder={`Bạn đang nghĩ gì, ${
                    page ? page.name : user.fullName
                  }`}
                />
                {errors.length > 0 && (
                  <div className="w-full my-4">
                    {errors.map((error, idx) => {
                      if (error.code === '') return;
                      return (
                        <div
                          key={error.code + idx}
                          className={`flex toast-danger my-2 items-center w-full p-3 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800`}
                          role="alert">
                          <div className="inline-flex items-center justify-center  w-8 h-8 text-red-500 bg-red-100 rounded-lg dark:bg-red-800 dark:text-red-200">
                            <svg
                              aria-hidden="true"
                              className="w-5 h-5"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                fillRule="evenodd"
                                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                clipRule="evenodd"></path>
                            </svg>
                            <span className="sr-only">Error icon</span>
                          </div>
                          <div className="ml-3 text-sm font-normal">
                            {error.message}
                          </div>
                          <button
                            type="button"
                            onClick={() => {
                              setErrors(
                                errors.filter((err) => err.code !== error.code),
                              );
                            }}
                            className="ml-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
                            data-dismiss-target="#toast-danger"
                            aria-label="Close">
                            <span className="sr-only">Close</span>
                            <svg
                              aria-hidden="true"
                              className="w-5 h-5"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                fillRule="evenodd"
                                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                clipRule="evenodd"></path>
                            </svg>
                          </button>
                        </div>
                      );
                    })}
                  </div>
                )}

                {preview.length !== 0 && (
                  <div className="py-2 box-border md:py-3 relative flex overflow-x-auto w-[468px">
                    {preview.map((media, idx) => {
                      return (
                        <div
                          key={media.key}
                          className="media-upload w-48 inline-block">
                          {media.type === 'video' ? (
                            <div className="relative">
                              {media.status === 'Uploading' && (
                                <LoadingExtra
                                  className="absolute right-[40%]"
                                  label=""
                                />
                              )}
                              <video
                                className={`${
                                  media.status === 'Uploading' && 'opacity-40'
                                } object-cover h-48 w-48 max-[400px]:h-38 max-[400px]:w-[38] rounded-md`}
                                src={media.uri}
                              />
                            </div>
                          ) : (
                            <div className="">
                              {media.status === 'Uploading' && (
                                <LoadingExtra
                                  className="absolute right-[40%]"
                                  label=""
                                />
                              )}
                              <img
                                key={media.key}
                                className={`object-cover h-48 w-48  max-[400px]:h-40 max-[400px]:w-40 rounded-md inline-block ${
                                  media.status === 'Uploading' && 'opacity-40'
                                }`}
                                src={media.uri}
                              />
                            </div>
                          )}
                          <span
                            className="remove-media max-[400px]:!right-[40px]"
                            onClick={() => {
                              handleRemovePreview(media.idx, media.key);
                            }}>
                            <IoMdClose color="#fff" />
                          </span>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
              {type === PostType.Share && (
                <div className="mx-4  shadow-md flex  justify-between border-solid rounded-b-lg border-[1px] border-gray-400 mb-6">
                  <div className="flex flex-col">
                    {!!post?.medias && post?.medias?.length > 0 && (
                      <ManyImageComponent medias={post?.medias} />
                    )}
                    {!!post && (
                      <HeaderPost
                        post={post}
                        onPostCreated={handleCreatePost}
                      />
                    )}
                  </div>
                </div>
              )}

              <div className="mx-4 h-[58px] shadow-md flex p-4 justify-between border-solid rounded-lg border-[1px] border-gray-400">
                <div className="text-md">
                  <span className="">{'Thêm vào bài viết'}</span>
                </div>
                <div className="flex justify-evenly w-[120px]">
                  {type === PostType.Share ? (
                    <img
                      src={images.PhotoUploadBlack}
                      className="cursor-not-allowed create-post_uploadIcon "
                      alt="photo-upload-icon"
                    />
                  ) : (
                    <img
                      src={images.PhotoUpload}
                      onClick={handleClickUploadFile}
                      className="cursor-pointer create-post_uploadIcon"
                      alt="photo-upload-icon"
                    />
                  )}

                  <img
                    src={images.PinLocation}
                    onClick={handleClickPinLocation}
                    className="cursor-pointer"
                    alt="pin-location-icon"
                  />
                  {!(isInMyPage === 'inPage') && (
                    <img
                      src={images.TagFriend}
                      onClick={handleClickTagFriend}
                      className="cursor-pointer"
                      alt="tag-friend-icon"
                    />
                  )}
                </div>
              </div>
              <div className="w-full px-4">
                <button
                  type="button"
                  disabled={isDisableCreatePostBtn}
                  onClick={handleCreatePost}
                  className={`h-[36px] text-sm font-medium ${
                    isDisableCreatePostBtn
                      ? 'bg-slate-200 text-slate-400 cursor-no-drop'
                      : 'bg-primary-yellow text-white hover:bg-teal-400 cursor-pointer'
                  }  w-full px-4 my-4 rounded-lg`}>
                  {isUpdate ? 'Cập nhật' : 'Đăng bài'}
                </button>
              </div>
              {isLoading && (
                <LoadingExtra
                  className="loading-createPost w-full h-full cursor-not-allowed"
                  label=""
                />
              )}
            </div>
          </form>
          {!(isInMyPage === 'inPage') && (
            <TagFriendModal
              hidden={!visibleModals.tagFriendModal}
              handleClickBack={handleClickBackMain}
              handleChangeFriendTaggeds={handleFriendTaggedChange}
              defaultValue={post?.allTaggedUsers || []}
              isCloseModal={isCloseModal}
            />
          )}

          <LocationModal
            typePost={post?.type}
            defaultValue={!!post?.place ? post?.place : undefined}
            hidden={!visibleModals.locationModal}
            handleClickBack={handleClickBackMain}
            handleSelectLocation={handleSelectLocation}
          />
          <ConfirmVoteAddressModal
            address={location?.name || ''}
            isShow={confirmVoteState}
            onOk={handleConfirm}
            onCancel={handleCloseModalVote}
          />
          <VoteAddressModal
            defaultValue={post?.rating || 0}
            address={location?.name || ''}
            onOk={(star: number) => handleVote(star)}
            isShow={isShowModalVote}
            onCancel={handleCancelVote}
          />
        </dialog>
        <input
          type="file"
          ref={refUploadInput}
          hidden={true}
          style={{ display: 'none' }}
          accept="image/*,video/*"
          multiple={true}
          onChange={handleFileChange}
        />
      </>
    );
  },
);

export default CreatePostModal;
