import {
  useState,
  useImperativeHandle,
  forwardRef,
  Ref,
  useEffect,
} from 'react';
import { images } from '../../assets/images';
import { User } from '../../graphql/generated';
import './index.scss';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import { useRemoveGroupMember } from '../../pages/Groups/services/api';
import Toast, { ToastProps, ToastType } from '../toast/Toast';
import { getUserLogin } from '../../Ultis/token';

export interface ModalShowMemberRef {
  open: () => void;
  close: () => void;
}

export interface Props {
  info: any[];
  isOwner: boolean;
}

const ModalShowGroupMembers = (props: Props, ref: Ref<ModalShowMemberRef>) => {
  const { info, isOwner } = props;
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [toast, setToast] = useState<ToastProps>({
    className: 'fixed top-0 right-0',
    isHidden: true,
    type: ToastType.Success,
    message: '',
  });

  const user = getUserLogin();

  const closeModal = () => {
    setVisible(false);
  };

  const { mutateAsync: removeGroupMember } = useRemoveGroupMember();

  const openModal = () => {
    setVisible(!visible);
  };

  useEffect(() => {
    let resetToast: any;
    if (!toast.isHidden) {
      resetToast = setTimeout(() => {
        setToast((preState) => {
          return { ...preState, isHidden: true };
        });
      }, 3000);
    }
    return () => {
      clearTimeout(resetToast);
    };
  }, [toast]);

  useImperativeHandle(ref, () => ({
    open: () => openModal(),
    close: () => closeModal(),
  }));

  const handleGoToProfile = (userId: string) => {
    navigate(`/profile/${userId}`);
  };

  const handleRemoveFromGroup = (userId: string, groupId: string) => {
    removeGroupMember(
      {
        groupId: groupId,
        id: userId,
      },
      {
        onSuccess() {
          setToast({
            ...toast,
            message: `Xóa thành công`,
            isHidden: false,
            type: ToastType.Success,
          });
        },
      },
    );
  };

  return (
    <>
      <Toast
        message={toast.message}
        className={`${toast.className} absolute top-1`}
        type={toast.type}
        isHidden={toast.isHidden}
      />
      {visible && (
        <div className="overlay flex justify-center items-center">
          <div className="absolute   modal_show_member bg-white w-[350px]">
            <div className="relative">
              <div className="flex justify-center py-3 bg-primary-yellow header_modal">
                <p className="text-white">Thành viên trong nhóm </p>
              </div>
              <img
                src={images.Close}
                onClick={closeModal}
                className="cursor-pointer close_modal absolute right-3 top-3"
              />
            </div>
            <div className="min-h-[200px] max-h-[350px] overflow-auto">
              {isOwner && (
                <div
                  className="border-b-[1px] border-solid border-gray-200 cursor-pointer hover:bg-gray-200 item-last-member"
                  onClick={() => handleGoToProfile(user?._id || '')}>
                  <div className="flex items-center justify-between p-2">
                    <div className="flex items-center">
                      <div className="">
                        <div className="img-1-1 h-[40px] w-[40px] relative border-[1px] border-solid border-gray-100">
                          <img src={user?.avatar?.uri || images.Avatar1} />
                        </div>
                      </div>
                      <p className="ml-5 text-break-word max-w-[235px]">
                        {user.fullName}
                      </p>
                    </div>
                  </div>
                </div>
              )}

              {info.map((item: any) => {
                return (
                  <>
                    <div
                      className="border-b-[1px] border-solid border-gray-200 cursor-pointer hover:bg-gray-200 item-last-member"
                      onClick={() => handleGoToProfile(item?.userId || '')}>
                      <div className="flex items-center justify-between p-2">
                        <div className="flex items-center">
                          <div className="">
                            <div className="img-1-1 h-[40px] w-[40px] relative border-[1px] border-solid border-gray-100">
                              <img
                                src={item.user?.avatar?.uri || images.Avatar1}
                              />
                            </div>
                          </div>
                          <p className="ml-5 text-break-word max-w-[235px]">
                            {item.user.fullName}
                          </p>
                        </div>
                        {isOwner && (
                          <button
                            onClick={(
                              event: React.MouseEvent<HTMLButtonElement>,
                            ) => {
                              event.stopPropagation();
                              handleRemoveFromGroup(
                                item?.userId || '',
                                item?.groupId || '',
                              );
                            }}>
                            <RiDeleteBin6Line className="text-xl text-gray-300 hover:text-red-400 " />
                          </button>
                        )}
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default forwardRef(ModalShowGroupMembers);
