import React, { useState } from 'react';
import { AiOutlineClose, AiOutlineCloseCircle } from 'react-icons/ai';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';

interface Props {}

const NotificationAddInfor = (props: Props) => {
  const [display, setDisplay] = useState(true);
  const navigate = useNavigate();

  return (
    <div
      className={` p-2 pb-6 h-fit bg-[#e8e8e8] mt-4 rounded-lg ${
        display ? 'block' : 'hidden'
      }`}>
      <div className="flex items-start justify-between">
        <div>
          <div className="flex items-start">
            <IoIosInformationCircleOutline className="text-5xl" />
            <div className="ml-4 text-lg mt-2">
              Thêm thông tin cá nhân để mọi người biết thêm về bạn hơn
            </div>
          </div>
          <button
            className="border-0 bg-none mt-4 ml-16"
            onClick={() => navigate('/menu/edit')}>
            <div className="text-primary-yellow border-primary-yellow border inline p-3 rounded-lg">
              <b>Thêm thông tin</b>
            </div>
          </button>
        </div>
        <button
          className="border-0 bg-none mt-2"
          onClick={() => setDisplay(false)}>
          <AiOutlineClose className="text-2xl" />
        </button>
      </div>
    </div>
  );
};

export default NotificationAddInfor;
