import { FC, memo } from 'react';
import { Media, MediaType } from '../../graphql/generated';
import './photo-item.scss';
import { AiFillCaretRight } from 'react-icons/ai';

interface Props {
  className?: string;
  data: Media;
  onClick?: () => void;
}

const PhotoItemUI: FC<Props> = ({ data, onClick, className = '' }) => {
  const { type, uri, thumbnail } = data;
  const isVideo = type === MediaType.Video;
  const uriImg = isVideo ? thumbnail || '' : uri;
  return (
    <div className={`photo-album-item ${className}`}>
      <div className="photo-item">
        <div className="photo-item-preview" onClick={onClick}>
          <img src={uriImg} className="h-auto max-w-full rounded-lg" alt="" />
        </div>
        {isVideo && <div className="play-btn"></div>}
      </div>
    </div>
  );
};

export default memo(PhotoItemUI);
