import { useState, useImperativeHandle, forwardRef, Ref } from 'react';
import { images } from '../../assets/images';
import { User } from '../../graphql/generated';
import './index.scss';
import { useLeaveGroupConversation } from '../../pages/Messenger/services/api';
import { useNavigate } from 'react-router-dom';
import { queryClient } from '../..';

export interface ModalConfirmLeaveRef {
  open: () => void;
  close: () => void;
}

export interface Props {
  conversationId: string;
}

const ModalConfirmLeave = (props: Props, ref: Ref<ModalConfirmLeaveRef>) => {
  const { conversationId } = props;
  const { mutateAsync: leaveGroupConversationMutate } =
    useLeaveGroupConversation();

  const navigate = useNavigate();

  const handleLeaveGroup = async () => {
    leaveGroupConversationMutate(
      {
        _id: conversationId || '',
      },
      {
        onSuccess() {
          queryClient.invalidateQueries(['getConversations']);
          navigate('/messenger');
        },
        onError() {},
      },
    );
  };

  const [visible, setVisible] = useState(false);
  const closeModal = () => {
    setVisible(false);
  };

  const openModal = () => {
    setVisible(!visible);
  };

  useImperativeHandle(ref, () => ({
    open: () => openModal(),
    close: () => closeModal(),
  }));

  return (
    <>
      {visible && (
        <div className="overlay flex justify-center items-center">
          <div className="absolute   modal_show_member bg-white">
            <div className="flex justify-center py-3 bg-primary-yellow header_modal relative">
              <p className="text-white">Cảnh báo</p>
              <img
                src={images.Close}
                onClick={closeModal}
                className="cursor-pointer close_modal absolute top-3 right-3"
              />
            </div>
            <div className="p-3">
              <h3 className="text-center">Bạn có chắc chắn muốn rời nhóm ? </h3>
              <div className="mt-4 flex justify-end">
                <button
                  className="px-4 py-2 bg-[#05A2AB] text-white rounded-md mr-2"
                  onClick={handleLeaveGroup}>
                  Xác nhận
                </button>
                <button
                  className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md"
                  onClick={closeModal}>
                  Hủy bỏ
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default forwardRef(ModalConfirmLeave);
