import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Slide } from 'yet-another-react-lightbox/*';
import {
  GetGroupMediaListQuery,
  GetPageMediaListQuery,
  Media,
} from '../../graphql/generated';
import LightboxImage from '../lightbox/LightboxImage';
import { tranFormSlider } from '../photo/PhotoAlbum';
import PhotoItem from '../photo/PhotoItem';

interface Props {
  media: any;
  redirectUrl?: string;
}

const ListImage = (props: Props) => {
  const { media, redirectUrl } = props;
  const { id } = useParams();
  const [slider, setSlider] = useState<Slide[]>([]);
  const [idxMedia, setIdxMedia] = useState(-1);

  useEffect(() => {
    setSlider(tranFormSlider((media as Media[]) || []));
  }, [media]);

  const onCloseMedia = () => {
    setIdxMedia(-1);
  };

  const onOpenMedia = (index: number) => {
    setIdxMedia(index);
  };

  return (
    <div className="ml-2 mt-2 pr-2">
      <div className="flex items-center justify-between">
        <div>
          <b className="text-lg">Ảnh</b>
        </div>
        {media.length !== 0 && (
          <Link to={id ? `/profile/${id}/photos` : '/profile/photos'}>
            <div className="text-primary-yellow text-sm">Xem tất cả</div>
          </Link>
        )}
      </div>
      {media.length !== 0 ? (
        <div
          className={`photo-album grid grid-cols-5 items-center gap-1 mt-4 mb-4`}>
          {media?.map((item: any, index: number) => {
            return (
              <PhotoItem
                key={item._id}
                onClick={() => onOpenMedia(index)}
                data={item as Media}
              />
            );
          })}
          <LightboxImage
            index={idxMedia}
            slides={slider}
            isOpen={idxMedia >= 0}
            onClose={onCloseMedia}
          />
        </div>
      ) : (
        <h2 className="text-center py-4">Hiện tại chưa có ảnh nào.</h2>
      )}
    </div>
  );
};

export default ListImage;
