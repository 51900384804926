import { FC } from 'react';
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai';
import { GoComment } from 'react-icons/go';
import { TbShare3 } from 'react-icons/tb';
import { Post } from '../../graphql/generated';

interface Props {
  post: Post;
}
const PostInfo: FC<Props> = ({ post }) => {
  return (
    <div className="mt-2 mb-2 flex">
      <div className="mr-6 flex items-center text-sm">
        {post?.liked ? <AiFillHeart className="mr-2" /> : <AiOutlineHeart className="mr-2" />} {post?.totalLikes}
      </div>
      <div className="mr-6 flex items-center text-sm">
        <button className="border-0 bg-none">
          <GoComment className="mr-2" />
        </button>
        0
      </div>
      <div className="flex items-center text-sm">
        <TbShare3 className="mr-2" /> {post?.totalShares}
      </div>
    </div>
  );
};

export default PostInfo;
