import { Link } from 'react-router-dom';
import Avatar from '../../../components/avatar/Avatar';
import userImg from '../../../assets/images/user.jpeg';

export enum FriendItemType {
  MySuggestion = 'MySuggestion',
  MyFriend = 'MyFriend',
  MyInvite = 'MyInvite',
  UserFriend = 'UserFriend',
  Mutual = 'Mutual',
}

interface GroupProps {
  group: any;
  type: any;
  isDisplayAction?: boolean;
  isDisplayBothBtn?: boolean;
  isDisplayTimeRequest?: boolean;
}

const Group = ({ group }: GroupProps) => {
  const { cover, name, _id: groupId, totalMembers } = group;
  return (
    <div
      className={`flex p-2 shadow-inner bg-white rounded  items-center  border border-solid border-1 h-28`}>
      <div className="ml-2">
        <Link to={'/groups/' + groupId}>
          <div
            className={`avatar_menu border border-solid border-gray-200 rounded-full  !w-[75px] h-[75px]`}
            style={{
              backgroundImage: `url("${cover ? cover?.uri : userImg}")`,
            }}></div>
        </Link>
      </div>
      <div className={`ml-3`}>
        <div>
          <Link to={'/groups/' + groupId} className="h-1">
            <span className="text-md w-15 font-semibold text-black text-break-word">
              {name || 'Demo'}
            </span>
          </Link>{' '}
          <br />
          <span>{totalMembers} thành viên</span>
        </div>
      </div>
    </div>
  );
};

export default Group;
