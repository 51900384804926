import { create } from 'zustand';

interface IDUserProps {
  idUser: string;
  setIdUser: (item: string) => void;
}

const useIDUser = create<IDUserProps>((set) => ({
  idUser: '',
  setIdUser: (item: string) => set({ idUser: item }),
}));

export default useIDUser;
