import { FC, useEffect, useState } from 'react';
import { queryClient } from '../..';
import { useGetSummaryReactions } from '../../common/services/api';
import {
  GetReactionInput,
  GetSummaryUserReactionsQuery,
  User,
} from '../../graphql/generated';
import './index.scss';
import { isEqual } from 'lodash';
interface Props {
  filter: GetReactionInput & { wasChanged?: boolean };
  totalReaction: number;
  customClassCssImportant?: string;
}

const getUsers = (
  reactionsData: GetSummaryUserReactionsQuery['getReactions'],
) => {
  return (
    reactionsData?.map(
      (reaction) => reaction?.actor ?? { fullName: '', _id: '' },
    ) ?? []
  );
};

const LIMIT_DISPLAY = 10;

const SummamryUserReaction: FC<Props> = ({
  filter,
  totalReaction,
  customClassCssImportant,
}) => {
  const [users, setUsers] = useState<Pick<User, 'fullName' | '_id'>[]>([]);
  const [filterReaction, setFilterReaction] = useState(filter);
  const reactionsData = useGetSummaryReactions({
    data: {
      ...filterReaction,
    },
    limit: LIMIT_DISPLAY,
  });
  !isEqual(filterReaction, filter) && setFilterReaction({ ...filter });
  !isEqual(users, getUsers(reactionsData)) &&
    setUsers([...getUsers(reactionsData)]);
  useEffect(() => {
    queryClient.invalidateQueries(['getSummaryUserReactions']);
  }, []);
  return users.length > 0 ? (
    <div className={`sumaryUserReaction ${customClassCssImportant} rounded-md`}>
      <ul className="p-3">
        {users.map((user) => (
          <li key={user._id} className="">
            <p className="text-white line-clamp text-sm">{user.fullName}</p>
          </li>
        ))}
        {users.length > LIMIT_DISPLAY && (
          <li>
            <p className="text-white text-sm">{`và ${
              totalReaction - LIMIT_DISPLAY
            } người khác`}</p>
          </li>
        )}
      </ul>
    </div>
  ) : (
    <></>
  );
};

export default SummamryUserReaction;
