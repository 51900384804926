export const formatCurrency = (amount: number): string => {
  // Chuyển đổi số thành chuỗi và đảo ngược chuỗi
  const reversedAmountStr = amount.toString().split('').reverse().join('');

  // Tách chuỗi thành các đoạn con có 3 ký tự
  const chunks = reversedAmountStr.match(/.{1,3}/g);

  // Đảo ngược lại các đoạn con và nối chúng lại bằng dấu chấm
  const formattedAmountStr = chunks && chunks.join('.').split('').reverse().join('');

  return formattedAmountStr || '0';
};
