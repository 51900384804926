import { createBrowserRouter } from 'react-router-dom';
import BlockList from '../components/page/BlockList';
import ListReviewsPage from '../components/page/ListReviewsPage';
import SettingPage from '../components/page/SettingPage';
import Login from '../modules/auth/Login';
import { RequireAuth } from '../modules/auth/RequireAuth';
import DetailPost from '../pages/DetailPost/DetailPost';
import MyFriendListMenu from '../pages/FriendListMenu/MyFriendListMenu';
import UserFriendListMenu from '../pages/FriendListMenu/UserFriendListMenu';
import Groups from '../pages/Groups';
import CreateGroupsPage from '../pages/Groups/components/CreateGroupPage';
import DetailGroup from '../pages/Groups/components/DetailGroup';
import Home from '../pages/Home/Home';
import ListPage from '../pages/ListPage/ListPage';
import Menu from '../pages/Menu';
import EditInfo from '../pages/Menu/Edit';
import CreatePage from '../pages/Menu/Page/Create';
import InviteFriend from '../pages/Menu/Page/InviteFriend';
import Messenger from '../pages/Messenger';
import CreateGroupPage from '../pages/Messenger/CreateGroup';
import MessengerItem from '../pages/Messenger/MessengerItem';
import WatchMess from '../pages/Messenger/MessengerItem/WatchMess';
import SharingPhotos from '../pages/Messenger/MessengerSharingPhoto';
import Notification from '../pages/Notification/Notification';
import Page from '../pages/Page/Page';
import ProductsPage from '../pages/Products';
import CreateProduct from '../pages/Products/Create';
import Profile from '../pages/Profile/Profile';
import PageProfilePhotoAlbum from '../pages/Profile/ProfilePhotoAlbum';
import SearchPage from '../pages/Search';
import Video from '../pages/Video';
import WatchVideo from '../pages/Video/VideoItem/WatchVideo';
import ContainerLayout from '../parts/ContainerLayout';
import DetailPostLayout from '../parts/DetailPostLayout';
import MenuLayout from '../parts/MenuLayout';
import MessageLayout from '../parts/MessageLayout';
import ProfileLayout from '../parts/ProfileLayout';
import GroupLayout from '../parts/GroupLayout';
import SettingGroup from '../components/page/SettingGroup';

export const router = createBrowserRouter([
  { path: '/mediamessenger', element: <WatchMess /> },
  { path: '/watchvideo', element: <WatchVideo /> },
  {
    path: '/',
    element: <Login />,
  },
  {
    element: (
      <RequireAuth>
        <ContainerLayout />
      </RequireAuth>
    ),
    children: [
      {
        path: '/home',
        element: <Home />,
      },
      {
        path: '/notification',
        element: <Notification />,
      },
      {
        path: '/video',
        element: <Video />,
      },
      {
        path: '/messenger',
        element: <Messenger />,
      },
      {
        path: '/menu',
        element: <Menu />,
      },

      {
        path: '/messenger/:id',
        element: <MessengerItem />,
      },
      {
        path: '/detail/:postId',
        element: <DetailPost />,
      },
      {
        path: '/menu/edit',
        element: <EditInfo />,
      },
      {
        path: '/friends',
        element: <MyFriendListMenu />,
      },
      {
        path: '/profile/pages',
        element: <ListPage />,
      },
      {
        path: '/groups',
        element: <Groups />,
      },
      {
        path: '/groups/create',
        element: <CreateGroupsPage />,
      },
      {
        path: '/messenger/:id/sharing-photos',
        element: <SharingPhotos />,
      },
    ],
  },
  {
    element: (
      <RequireAuth>
        <ProfileLayout />
      </RequireAuth>
    ),
    children: [
      {
        path: '/profile',
        element: <Profile />,
      },
      {
        path: '/profile/:id',
        element: <Profile />,
      },
      {
        path: '/page/:pageId',
        element: <Page />,
      },
    ],
  },
  {
    element: (
      <RequireAuth>
        <GroupLayout />
      </RequireAuth>
    ),
    children: [
      {
        path: '/groups/:groupId',
        element: <DetailGroup />,
      },
    ],
  },
  {
    element: (
      <RequireAuth>
        <MessageLayout />
      </RequireAuth>
    ),
    children: [
      {
        path: '/messenger/new-group',
        element: <CreateGroupPage />,
      },
      {
        path: '/groups/setting/:groupId',
        element: <SettingGroup />,
      },
    ],
  },
  {
    element: (
      <RequireAuth>
        <MenuLayout />
      </RequireAuth>
    ),
    children: [
      {
        path: '/profile/:id/friends',
        element: <UserFriendListMenu />,
      },
      {
        path: '/search',
        element: <SearchPage />,
      },

      {
        path: '/page/:pageId/products',
        element: <ProductsPage />,
      },
      {
        path: '/page/:pageId/reviews',
        element: <ListReviewsPage />,
      },
      {
        path: '/page/:pageId/products/create',
        element: <CreateProduct />,
      },
      {
        path: '/profile/photos',
        element: <PageProfilePhotoAlbum />,
      },
      {
        path: '/profile/:id/photos',
        element: <PageProfilePhotoAlbum />,
      },
      {
        path: '/profile/:id/pages',
        element: <ListPage />,
      },
      {
        path: '/profile/:id/setting',
        element: <SettingPage />,
      },
      {
        path: '/profile/:id/list-block',
        element: <BlockList />,
      },
      {
        path: '/page/:id/invite-friend',
        element: <InviteFriend />,
      },

      {
        path: 'profile/pages/create',
        element: <CreatePage />,
      },
    ],
  },
  {
    element: (
      <RequireAuth>
        <DetailPostLayout />
      </RequireAuth>
    ),
    children: [
      {
        path: '/detail/:postId',
        element: <DetailPost />,
      },
    ],
  },
]);
