const REGEX_PHONE = /(0[3|5|7|8|9])+([0-9]{8})\b/;
const REGEX_NUMBER = /^\d+$/;

const validateLogin = (inputName: string, value: string) => {
  let error = '';
  switch (inputName) {
    case 'phone': {
      if (!REGEX_PHONE.test(value) || !REGEX_NUMBER.test(value)) {
        error = `Số điện thoại sai định dạng`;
      } else {
        error = '';
      }
      break;
    }
    // case 'password': {
    //   if (value.length < 8) {
    //     error = `Mật khẩu tối thiểu 8 ký tự`;
    //   } else {
    //     error = '';
    //   }
    //   break;
    // }
  }
  return error;
};

export default validateLogin;
