import { useLocation, useNavigate } from 'react-router-dom';
import { images } from '../../../assets/images';
import VideoPlayer from '../../../components/VideoPlayer';
import './index.scss';

const WatchMess = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="content_video flex justify-center bg-black relative">
      <div className="btn_close absolute cursor-pointer" onClick={() => navigate(-1)}>
        <img src={images.Close} />
      </div>
      <div className={`${location.state.data.medias[0].type === 'PHOTO' && 'flex w-full justify-center items-center'} app_videos`}>
        <div className="video">
          {location.state.data.medias[0].type === 'PHOTO' ? (
            <img src={location.state.data.medias[0].thumbnail} width={200} height={200} />
          ) : (
            <VideoPlayer
              offset={0}
              width={300}
              height={300}
              options={{
                autoplay: true,
                poster: '',
                sources: location.state.data.medias[0].uri,
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default WatchMess;
