import moment from 'moment';
import DateTimeHelpers from './DateTimeHelpers';

export const pluralize = (
  singular?: string,
  plural?: any,
  count?: number,
  zero?: number,
) => {
  if (count === 0 && zero) return zero;
  const output = singular;

  return output;
};

export function DiffDateToMinutes(dateCreated: string) {
  const dateFrom = moment(dateCreated);
  const dateTo = moment(new Date());
  const diff = dateTo.diff(dateFrom);
  return moment.duration(diff).minutes();
}

export function DiffDate(DateCreated: any) {
  const dateFrom = moment(DateCreated);
  const dateTo = moment(new Date());
  const diff = dateTo.diff(dateFrom);
  const diffDuration = moment.duration(diff).asMilliseconds();

  if (diffDuration < DateTimeHelpers.TIME_VALUE.Minute) {
    return 'Vừa xong';
  }

  if (diffDuration < DateTimeHelpers.TIME_VALUE.Hour) {
    return `${moment.duration(diff).minutes()} ${pluralize(
      'phút trước',
      'phút trước',
      moment.duration(diff).minutes(),
    )}`;
  }

  if (diffDuration < DateTimeHelpers.TIME_VALUE.Day) {
    return `${moment.duration(diff).hours()} ${pluralize(
      'giờ trước',
      'giờ trước',
      moment.duration(diff).hours(),
    )}`;
  }

  if (diffDuration <= DateTimeHelpers.TIME_VALUE.Month) {
    return `${moment.duration(diff).days()} ${pluralize(
      'ngày trước',
      'ngày trước',
      moment.duration(diff).days(),
    )}`;
  }
  if (diffDuration < DateTimeHelpers.TIME_VALUE.Year) {
    return `${Math.floor(diff / DateTimeHelpers.TIME_VALUE.Month)} ${pluralize(
      'tháng trước',
      'tháng trước',
      Math.floor(diff / DateTimeHelpers.TIME_VALUE.Month),
    )}`;
  }
  return `${moment.duration(diff).years()} ${pluralize(
    'năm trước',
    'năm trước',
    moment.duration(diff).years(),
  )}`;
}

export function FormatDate(dateCreated: any) {
  let date = moment(dateCreated).format('DD/MM/YYYY, HH:mm');
  const isInYear = date.indexOf(moment().format('/YYYY')) > -1;
  if (isInYear) {
    date = date.replace(moment().format('/YYYY'), '');
  }
  return date;
}

export const formatBirthDay = (time: Date) => {
  let result = '';
  const dateObject = new Date(time);

  const day = dateObject.toLocaleString('en-US', { day: 'numeric' });
  const month = dateObject.toLocaleString('en-US', { month: 'numeric' });
  const year = dateObject.toLocaleString('en-US', { year: 'numeric' });

  result = `${day}/${month}/${year}`;
  return result;
};
