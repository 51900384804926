import { FC, memo, useEffect, useState } from 'react';
import { Slide } from 'yet-another-react-lightbox/*';
import { Media, MediaType } from '../../graphql/generated';
import { useGetMedia } from '../../pages/Profile/services/api';
import LightboxImage from '../lightbox/LightboxImage';
import PhotoItem from './PhotoItem';

interface Props {
  userId: string;
}

export const tranFormSlider = (medias: Media[]): Slide[] =>
  medias.map((item) => {
    const isVideo = item.type === MediaType.Video;

    return {
      id: item._id,
      width: isVideo ? item?.videoAspectRatio?.width : item?.dimensions?.width,
      height: isVideo ? item?.videoAspectRatio?.height : item?.dimensions?.height,
      src: item.uri,
      type: isVideo ? 'video' : 'image',
      poster: isVideo ? item.thumbnail : undefined,
      sources: [
        {
          src: item.uri,
          type: 'application/x-mpegURL',
        },
      ],
    };
  });

const PhotoAlbumUI: FC<Props> = ({ userId }) => {
  /* State */
  const [mediaIndex, setMediaIndex] = useState<number>(-1);
  const [slider, setSlider] = useState<Slide[]>([]);
  /* State - end*/

  /* API */
  const { data: media } = useGetMedia({ id: userId, limit: 999 });
  /* API - end*/

  /* useEffect */
  useEffect(() => {
    const data = tranFormSlider((media?.getUserMediaList as Media[]) || []);
    setSlider(data);
  }, [media?.getUserMediaList]);
  /* useEffect -end */

  /* Function */

  const onOpenMedia = (index: number) => {
    setMediaIndex(index);
  };

  const onCloseMedia = () => {
    setMediaIndex(-1);
  };

  /* Function - end*/
  return (
    <div className="ml-2 pr-2">
      <div className={`photo-album grid grid-cols-3 lg:grid-cols-5 items-center gap-1 mb-4`}>
        {media?.getUserMediaList?.map((item, index) => {
          return <PhotoItem key={item._id} onClick={() => onOpenMedia(index)} data={item as Media} />;
        })}
        <LightboxImage index={mediaIndex} slides={slider} isOpen={mediaIndex >= 0} onClose={onCloseMedia} />
      </div>
    </div>
  );
};

export default memo(PhotoAlbumUI);
