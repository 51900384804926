import dayjs from 'dayjs';

export const requestedAtFormat = (requestedAt: Date) => {
  if (!requestedAt) return 'Bây giờ';
  const diffBySeconds = dayjs().diff(requestedAt, 'seconds');
  const diffByMinutes = dayjs().diff(requestedAt, 'minutes');
  const diffByDays = dayjs().diff(requestedAt, 'days');
  const diffByHours = dayjs().diff(requestedAt, 'hours');
  const diffByMonths = dayjs().diff(requestedAt, 'months');
  const diffByYears = dayjs().diff(requestedAt, 'years');
  if (diffByYears > 0) {
    return `${diffByYears} năm trước`;
  } else if (diffByMonths > 0) {
    return `${diffByMonths} tháng trước`;
  } else if (diffByDays > 0) {
    return `${diffByDays} ngày trước`;
  } else if (diffByHours > 0) {
    return `${diffByHours} giờ trước`;
  } else if (diffByMinutes > 0) {
    return `${diffByMinutes} phút trước`;
  } else if (diffBySeconds > 0) {
    return `${diffBySeconds} giây trước`;
  }
};
