import { Notify } from './textNotification';
import { Notification } from '../../../graphql/generated';

interface TypeRepliedCommentProps {
  item: Notification;
  isPostToPage: boolean;
  isCreater: boolean;
  isCheckPostYourSelf: boolean;
  isProduct: boolean;
  isCheckUsersAction?: any;
}

const TypeRepliedComment = (props: TypeRepliedCommentProps) => {
  /* eslint-disable prefer-const */
  let { item, isCreater, isPostToPage, isCheckPostYourSelf, isCheckUsersAction, isProduct } = props;

  isPostToPage = !!(item.forPost && item.forPost.page);

  const commentOnPage = isProduct ? `${Notify.reply_comment_on_post_page_not_me_product} ` : `${Notify.reply_comment_on_post_page_not_me} `;

  const lableReplyCommentPageOrPost = isPostToPage ? commentOnPage : `${Notify.reply_comment_on_post_not_me}`;

  const valueReplyCommentPageOrPost = isPostToPage
    ? `${(item.forPost && item.forPost.page && item.forPost.page.name) || ''}.`
    : `${(item.forPost && item.forPost.creator && item.forPost.creator.fullName) || ''}.`;

  return (
    <span>
      {isCheckUsersAction || ' '}
      {/* TODO: */}
      {`${Notify.friend_reply_comment} `}
      {isCreater && !isPostToPage ? `${Notify.in_an_post}` : lableReplyCommentPageOrPost}
      {!isPostToPage && !isCreater && isCheckPostYourSelf && `${Notify.they}.`}
      <span className="font-semibold">
        {!isCheckPostYourSelf && (!isCreater || isPostToPage) && valueReplyCommentPageOrPost}
        {isCheckPostYourSelf && isPostToPage && valueReplyCommentPageOrPost}
      </span>
    </span>
  );
};

export default TypeRepliedComment;
