import { Notification, NotificationType } from '../../../graphql/generated';
import { VariablesNotiType } from '../../../types/NotificationTypes';
import { Notify, Code } from './textNotification';

interface TitleNotifyProps {
  item: Notification;
  variables: VariablesNotiType;
}

const TitleNotify = (props: TitleNotifyProps) => {
  const { item } = props;
  if (item.type == NotificationType.System) {
    return <span>{item.customMessage || ''}</span>;
  }
  if (item.type == NotificationType.EnteredReferralCode) {
    const variables = item.variables ? JSON.parse(item.variables) : null;
    let message = '';
    if (variables && variables.creators && variables.creators.length > 0) {
      /* eslint-disable prefer-const */
      let names: Array<string> = [];
      variables.creators.forEach((element: { _id: string; name: string }) => {
        names.push(element.name);
      });

      let namesStr = names.join(', ');
      if (names.length > 2) {
        namesStr = `${names[0]}, ${names[1]} ${Code.refer_code_and} ${names.length - 2} ${Code.refer_code_other}`;
      }
      message = `${namesStr}`;
    }

    return <span className="font-semibold leading-[17px] text-sm">{message}</span>;
  }

  const isCheckTitle = (item: Notification) => {
    if (item.forPost && item.fromPage) {
      return item.fromPage.name || '';
    }
    if (item.fromUser) {
      return item.fromUser.fullName || '';
    }
    if (item.type == NotificationType.SetTermStatus) {
      return `${Notify.title}`;
    }
    return '';
  };

  return <span className="font-semibold text-sm">{isCheckTitle(item)}</span>;
};

export default TitleNotify;
