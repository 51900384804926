import { GraphQLClient } from 'graphql-request';
import { getAuthToken } from '../../Ultis/token';
import env from '../../configs/configs';
import wsClient from './socket-client';
import { OperationVariables } from '@apollo/client';

export const endpoint = env.apiEndPoint;

export const graphqlClientRequest = (
  auth = true,
  isSubScription?: boolean,
  signal?: any,
) => {
  const headers: any = {};

  if (auth) {
    headers.authorization = auth ? `Bearer ${getAuthToken()}` : undefined;
    if (isSubScription) {
      headers.idToken = auth ? `Bearer ${getAuthToken()}` : undefined;
    }
    // headers['X-Time-Zone'] = getCurrentTimeZone();
  }

  // @ts-ignore
  return new GraphQLClient(endpoint, {
    headers,
    signal,
  });
};

export const appSubscription = (
  query: string,
  variables?: OperationVariables,
) => {
  return wsClient.request({
    query,
    variables,
  });
};

export default {
  graphqlClientRequest,
};
