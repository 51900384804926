import { BsFillBellFill, BsPencil, BsPeopleFill } from 'react-icons/bs';
import { IoIosArrowBack } from 'react-icons/io';
import { RxExit } from 'react-icons/rx';
import { Link, useNavigate } from 'react-router-dom';
import { images } from '../../../../assets/images';
import { Conversation } from '../../../../graphql/generated';
import './index.scss';

interface Props {
  conversation?: Partial<Conversation>;
  showModal?: () => void;
}

const AvatarUsersCountDisplay = 2;
export default function GroupConversationInfo({
  conversation,
  showModal,
}: Props) {
  const navigate = useNavigate();

  const renderThumbnailConversation = () => {
    const users = conversation?.users ?? [];
    const userAvatar = conversation?.avatar;

    return (
      <>
        <div className="w-[240px] relative ">
          <div className="absolute left-0 w-[120px] h-[120px]">
            <div
              className={`img-1-1 ${
                users[0]?.verified
                  ? 'avatar-shadow_verify'
                  : 'avatar-border_default'
              }`}>
              <img
                className={` ${
                  userAvatar &&
                  '-translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 '
                } first-letter:friend-avatar  mb-2   `}
                src={
                  userAvatar
                    ? userAvatar?.uri
                    : users[0]?.avatar?.uri ?? images.AvatarDefault
                }
                alt={users[0]?.username}
              />
            </div>
          </div>

          {users.length >= AvatarUsersCountDisplay && !userAvatar && (
            <div className="absolute left-[60px] h-[120px] w-[120px]">
              <div className="img-1-1">
                <img
                  className={`friend-avatar  conversation-group_img2 rounded-full mb-2 bg-cover self-center  ${
                    users[1]?.verified
                      ? 'avatar-shadow_verify'
                      : 'avatar-border_default'
                  }`}
                  src={users[1]?.avatar?.uri ?? images.AvatarDefault}
                  height={120}
                  width={120}
                  alt={users[1]?.username}
                />
              </div>
            </div>
          )}

          {users.length > AvatarUsersCountDisplay && !userAvatar && (
            <div
              className="rounded-full left-[120px] flex justify-center items-center bg-gray-100 absolute conversation-group_img3 avatar-border_default"
              style={{ height: '120px', width: '120px' }}>
              <p className="text-2xl text-center font-semibold text-primary-yellow">
                {'+'}
                {users.length - AvatarUsersCountDisplay}
              </p>
            </div>
          )}
        </div>
      </>
    );
  };
  return (
    <div className="border-b-8 pb-3 ">
      <div className="grid row-grid-3 place-content-center gap-2 w-full">
        <IoIosArrowBack
          size="30px"
          className="absolute left-4 top-5 cursor-pointer text-gray-400 z-50"
          onClick={() => {
            navigate(-1);
          }}
        />
        <div className="w-full flex  justify-center relative conversation-group_thumbnail">
          {renderThumbnailConversation()}
        </div>
        <div className="flex items-center justify-center">
          <span className=" flex relative items-center justify-center text-lg text-center font-bold text-black  cursor-pointer w-full  ">
            <h1 className="mx-5 text-break-word">
              {conversation?.name ?? ' '}
            </h1>
            <BsPencil
              size={'14px'}
              className="absolute right-0"
              onClick={showModal}
            />
          </span>
        </div>
        <h2 className="text-sm text-center font-light text-gray-600 line-clamp-1">{`Được tạo bởi ${conversation?.creator?.fullName}`}</h2>
      </div>
      <div className="grid row-grid-3 gap-2 m-2">
        <Link to={''}>
          <span className="text-md hover:text-gray-600 flex items-center font-medium line-clamp-1">
            <BsPeopleFill className="mr-1 text-center" /> {'Thành viên'}
          </span>
        </Link>
        <span className="text-md relative hover:text-gray-600 flex items-center font-medium cursor-pointer line-clamp-1">
          <BsFillBellFill className="mr-1 text-center" />
          {'Nhận thông báo của nhóm'}
        </span>
        <Link to={''}>
          {' '}
          <span className="text-md hover:text-gray-600 flex items-center font-medium line-clamp-1">
            <RxExit className="mr-1" />
            {'Rời khỏi nhóm'}
          </span>
        </Link>
      </div>
    </div>
  );
}
