import { images } from '../../assets/images';

export interface IConfirmModal {
  title?: string;
  content: any;
  isVisible: boolean;
  onCancel?: () => void;
  onOk?: () => void;
}

export const ConfirmModal = ({
  title,
  content,
  isVisible,
  onOk,
  onCancel,
}: IConfirmModal) => {
  return (
    <>
      <div
        className={`fixed inset-0 flex items-center justify-center z-50 drop-shadow-[2px_4px_12px_rgba(0,0,0,0.4)] ${
          !isVisible && 'hidden'
        }`}>
        <div className="bg-white   rounded-lg p-6 ">
          <div className="flex items-center justify-between pb-4">
            <h3 className="text-xl font-semibold text-gray-900 dark:text-white ">
              {title}
            </h3>
            <div onClick={onCancel}>
              <img src={images.BlackRemove} alt="hidden" />
            </div>
          </div>
          <p>{content}</p>
          <div className="mt-4 flex justify-end">
            <button
              className="px-4 py-2 bg-[#05A2AB] text-white rounded-md mr-2"
              onClick={onOk}>
              Xác nhận
            </button>
            <button
              className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md"
              onClick={onCancel}>
              Hủy bỏ
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
