import { getUserLogin } from '../../Ultis/token';
import { useGetUser } from './services/api';
import { useParams } from 'react-router-dom';
import PhotoAlbum from '../../components/photo/PhotoAlbum';
import HeaderExtra from '../../components/header/HeaderExtra';

interface Props {}

const PageProfilePhotoAlbum = (props: Props) => {
  const { id } = useParams();
  const user = getUserLogin();
  const userId = id || user._id;

  /* API */
  const { data } = useGetUser(userId);
  /* API - end*/

  return (
    <>
      <HeaderExtra title="Ảnh" />
      <PhotoAlbum userId={userId} />
    </>
  );
};

export default PageProfilePhotoAlbum;
