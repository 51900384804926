import Pencil from '../images/icon/pencil.svg';
import LogoLogin from '../images/icon/logo_login.svg';
import Phone from '../images/icon/phone.svg';
import Lock from '../images/icon/lock.svg';
import Facebook from '../images/icon/facebook.svg';
import Apple from '../images/icon/apple.svg';
import TriangleDown from '../images/icon/triangle-down.svg';
import Close from '../images/icon/close.svg';
import Search from '../images/icon/search.svg';
import ArrowLeft from '../images/icon/arrow_left.svg';
import Avatar from '../images/icon/avatar.svg';
import Edit from '../images/icon/edit.svg';
import Friend from '../images/icon/friend.svg';
import Flag from '../images/icon/flag.svg';
import Language from '../images/icon/language.svg';
import Cog from '../images/icon/cog.svg';
import Bookmark from '../images/icon/bookmark.svg';
import Book from '../images/icon/book.svg';
import Logout from '../images/icon/logout.svg';
import FlagVietNam from '../images/icon/flag_vietnam.svg';
import Tick from '../images/icon/tick.svg';
import User from '../images/icon/user.svg';
import Location from '../images/icon/location.svg';
import Pen from '../images/icon/pen.svg';
import PhoneGray from '../images/icon/phone_gray.svg';
import Graduation from '../images/icon/graduation.svg';
import SearchWhite from '../images/icon/search_white.svg';
import World from '../images/icon/world.svg';
import Play from '../images/icon/play.svg';
import Heart from '../images/icon/heart.svg';
import Mess from '../images/icon/mess.svg';
import Share from '../images/icon/share.svg';
import TwoMess from '../images/icon/two_mess.svg';
import TwoShare from '../images/icon/two_share.svg';
import NewFeedHeart from '../images/icon/newfeed_heart.svg';
import BlackRemove from '../images/icon/black_remove.svg';
import Camera from '../images/icon/camera.svg';
import Had_Like from '../images/icon/had_like.svg';
import Send from '../images/icon/send.svg';
import Yellow_Send from '../images/icon/yellow_send.svg';
import Notify_Heart from '../images/icon/notify_heart.svg';
import Comment from '../images/icon/comment.svg';
import Notification from '../images/icon/notification.svg';
import Request_Friend from '../images/icon/request_friend.svg';
import Request_Like_Page from '../images/icon/request_like_page.svg';
import Request_Join_Group from '../images/icon/request_join_group.svg';
import No_Notification from '../images/icon/no_notification.svg';
import SearchBlack from '../images/icon/search_black.svg';
import Avatar1 from '../images/icon/avatar_chat_1.svg';
import Avatar2 from '../images/icon/avatar_chat_2.svg';
import Avatar3 from '../images/icon/avatar_chat_3.svg';
import HasSend from '../images/icon/has_send.svg';
import LeftWhite from '../images/icon/left_white.svg';
import AvatarDefault from '../images/icon/avatar_default.svg';
import Bag from '../images/icon/bag.svg';
import NoThumnail from '../images/icon/no_thumnail.svg';
import GallerySlash from '../images/icon/gallery-slash.svg';
import Eye from '../images/icon/eye.svg';
import Like from '../images/icon/like.svg';
import Dislike from '../images/icon/dislike.svg';
import PhotoUpload from '../images/icon/photo_upload.svg';
import PhotoUploadBlack from '../images/icon/photo_upload_black.svg';
import PinLocation from '../images/icon/pin_location.svg';
import TagFriend from '../images/icon/tag_friend.svg';
import MessPage from '../images/icon/mess-page.svg';
import DotDotDot from '../images/icon/dotdotdot.svg';
import DotDotDotGray from '../images/icon/dotdotdot-gray.svg';
import Shop1 from '../images/icon/shop_1.svg';
import CameraGray from '../images/icon/camera_gray.svg';
import Plus from '../images/icon/plus.svg';
import CirclePlus from '../images/icon/circle-plus.svg';
import WhiteRemove from '../images/icon/white-remove.svg';
import IconHome from '../images/icon/home.svg';
import IconMessage from '../images/icon/message-text.svg';
import IconNotify from '../images/icon/icon-notify.svg';
import IconMedia from '../images/icon/icon-media.svg';
import IconUser from '../images/icon/icon-user.svg';
import IconHomeActive from '../images/icon/icon-home-active.svg';
import IconMessageActive from '../images/icon/message-text-active.svg';
import IconNotifyActive from '../images/icon/icon-notify-active.svg';
import IconMediaActive from '../images/icon/icon-media-active.svg';
import IconUserActive from '../images/icon/icon-user-active.svg';
import BgLoginPage from '../images/icon/bg-login.svg';
import IconGroup from '../images/icon/multiple-neutral-circle.svg';

export const images = {
  IconGroup: IconGroup,
  BgLoginPage: BgLoginPage,
  IconNotify: IconNotify,
  IconMessage: IconMessage,
  IconMedia: IconMedia,
  IconUser: IconUser,
  IconHome: IconHome,
  IconNotifyActive: IconNotifyActive,
  IconMessageActive: IconMessageActive,
  IconMediaActive: IconMediaActive,
  IconUserActive: IconUserActive,
  IconHomeActive: IconHomeActive,
  LogoLogin: LogoLogin,
  Phone: Phone,
  Lock: Lock,
  Facebook: Facebook,
  Apple: Apple,
  TriangleDown: TriangleDown,
  Pencil: Pencil,
  Close: Close,
  Search: Search,
  ArrowLeft: ArrowLeft,
  Avatar: Avatar,
  Edit: Edit,
  Friend: Friend,
  Flag: Flag,
  Language: Language,
  Cog: Cog,
  Bookmark: Bookmark,
  Book: Book,
  Logout: Logout,
  FlagVietNam: FlagVietNam,
  Tick: Tick,
  User: User,
  Location: Location,
  Pen: Pen,
  PhoneGray: PhoneGray,
  Graduation: Graduation,
  SearchWhite: SearchWhite,
  World: World,
  Play: Play,
  Heart: Heart,
  Share: Share,
  Mess: Mess,
  TwoMess: TwoMess,
  TwoShare: TwoShare,
  NewFeedHeart: NewFeedHeart,
  BlackRemove: BlackRemove,
  Camera: Camera,
  Had_Like: Had_Like,
  Send: Send,
  Yellow_Send: Yellow_Send,
  Notify_Heart: Notify_Heart,
  Comment: Comment,
  Notification: Notification,
  Request_Friend: Request_Friend,
  Request_Like_Page: Request_Like_Page,
  Request_Join_Group: Request_Join_Group,
  No_Notification: No_Notification,
  SearchBlack: SearchBlack,
  Avatar1: Avatar1,
  Avatar2: Avatar2,
  Avatar3: Avatar3,
  HasSend: HasSend,
  LeftWhite: LeftWhite,
  AvatarDefault: AvatarDefault,
  Bag: Bag,
  NoThumnail: NoThumnail,
  GallerySlash: GallerySlash,
  Eye: Eye,
  Like: Like,
  Dislike: Dislike,
  PhotoUpload: PhotoUpload,
  PhotoUploadBlack: PhotoUploadBlack,
  PinLocation: PinLocation,
  TagFriend: TagFriend,
  MessPage: MessPage,
  DotDotDot: DotDotDot,
  DotDotDotGray: DotDotDotGray,
  Shop1,
  CameraGray,
  Plus,
  CirclePlus,
};
