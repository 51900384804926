import { FC } from 'react';
import Login from './Login';
import { useGetUser } from '../../pages/Profile/services/api';
import { getUserLogin } from '../../Ultis/token';

export const RequireAuth: FC<{ children: React.ReactElement }> = ({ children }) => {
  const user = getUserLogin();
  const idUser = user._id;
  const { data } = useGetUser(idUser);
  const token = localStorage.getItem('token');

  if (!token) {
    return <Login />;
  }
  return children;
};
