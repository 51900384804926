import {
  GetCommentVideoQueryVariables,
  useGetCommentVideoQuery,
} from '../../../graphql/generated';
import { graphqlClientRequest } from '../../../graphql/services/graphql-client';

export const useGetCommentMedia = (input: GetCommentVideoQueryVariables) => {
  const { data, isSuccess } = useGetCommentVideoQuery(
    graphqlClientRequest(true),
    input,
    {
      onSuccess(data) {},
      onError(error: any) {
        //   Notification.Error({ message: getMessageError(error) });
      },
      retry: false,
      cacheTime: 0,
    },
  );
  return { data, isSuccess };
};
