import { queryClient } from '../../..';
import {
  GetExploreGroupListQueryVariables,
  GetGroupMediaListQueryVariables,
  GetGroupMembersQueryVariables,
  GetGroupPostsQueryVariables,
  GetGroupQueryVariables,
  GetJoinGroupRequestsQueryVariables,
  GetMyGroupsQueryVariables,
  GetMyJoinedGroupsQueryVariables,
  ListFriendNotInGroupQueryVariables,
  SearchGroupMembersQueryVariables,
  ListFriendNotInChatGroupQueryVariables,
  useAddUserToGroupConversationMutation,
  useAcceptJoinGroupRequestMutation,
  useApproveAllJoinGroupRequestsMutation,
  useCreateGroupMutation,
  useDeleteGroupMutation,
  useGetExploreGroupListQuery,
  useGetGroupMediaListQuery,
  useGetGroupMembersQuery,
  useGetGroupPostsQuery,
  useGetGroupQuery,
  useGetJoinGroupRequestsQuery,
  useGetMyGroupsQuery,
  useGetMyJoinedGroupsQuery,
  useInviteUserToGroupMutation,
  useLeaveGroupMutation,
  useListFriendNotInGroupQuery,
  useListFriendNotInChatGroupQuery,
  useRejectAllJoinGroupRequestsMutation,
  useRejectJoinGroupRequestMutation,
  useRemoveGroupMemberMutation,
  useRequestToJoinToGroupMutation,
  useSearchGroupMembersQuery,
  useUpdateGroupMutation,
} from '../../../graphql/generated';
import { graphqlClientRequest } from '../../../graphql/services/graphql-client';

export const useGetMyGroups = (input: GetMyGroupsQueryVariables) => {
  const { data, isLoading } = useGetMyGroupsQuery(graphqlClientRequest(true), {
    limit: input.limit,
    offset: input.offset,
  });
  return { data, isLoading };
};

export const useGetMyJoinedGroups = (
  input: GetMyJoinedGroupsQueryVariables,
) => {
  const { data, isLoading } = useGetMyJoinedGroupsQuery(
    graphqlClientRequest(true),
    {
      limit: input.limit,
      offset: input.offset,
    },
  );
  return { data, isLoading };
};

export const useGetListFriendNotInChatGroup = (
  input: ListFriendNotInChatGroupQueryVariables,
) => {
  const { data, isLoading } = useListFriendNotInChatGroupQuery(
    graphqlClientRequest(true),
    {
      id: input.id,
      limit: input.limit,
      q: input.q,
    },
  );
  return { data, isLoading };
};

export const useGetJoinGroupRequests = (
  input: GetJoinGroupRequestsQueryVariables,
) => {
  const { data, isLoading } = useGetJoinGroupRequestsQuery(
    graphqlClientRequest(true),
    {
      limit: input.limit,
      groupId: input.groupId,
      status: input.status,
    },
  );
  return { data, isLoading };
};

export const useGetExploreGroupList = (
  input: GetExploreGroupListQueryVariables,
) => {
  const { data, isLoading } = useGetExploreGroupListQuery(
    graphqlClientRequest(true),
    {
      limit: input.limit,
      offset: input.offset,
    },
  );
  return { data, isLoading };
};

export const useGetGroup = (input: GetGroupQueryVariables) => {
  const { data, isLoading } = useGetGroupQuery(graphqlClientRequest(true), {
    id: input.id,
  });
  return { data, isLoading };
};

export const useCreateGroup = () => {
  const { data, mutate, mutateAsync } = useCreateGroupMutation(
    graphqlClientRequest(true),
    {},
  );
  return { data, mutate, mutateAsync };
};

export const useAcceptJoinGroupRequest = () => {
  const { data, mutate, mutateAsync } = useAcceptJoinGroupRequestMutation(
    graphqlClientRequest(true),
    {},
  );
  return { data, mutate, mutateAsync };
};

export const useRejectJoinGroupRequest = () => {
  const { data, mutate, mutateAsync } = useRejectJoinGroupRequestMutation(
    graphqlClientRequest(true),
    {},
  );
  return { data, mutate, mutateAsync };
};

export const useRejectAllJoinGroupRequest = () => {
  const { data, mutate, mutateAsync } = useRejectAllJoinGroupRequestsMutation(
    graphqlClientRequest(true),
    {},
  );
  return { data, mutate, mutateAsync };
};

export const useApproveAllJoinGroupRequest = () => {
  const { data, mutate, mutateAsync } = useApproveAllJoinGroupRequestsMutation(
    graphqlClientRequest(true),
    {},
  );
  return { data, mutate, mutateAsync };
};

export const useGetGroupPosts = (input: GetGroupPostsQueryVariables) => {
  const { data } = useGetGroupPostsQuery(graphqlClientRequest(true), input, {
    enabled: !!input.groupId,
  });
  return { data };
};

export const useGetListFriendNotInGroup = (
  input: ListFriendNotInGroupQueryVariables,
) => {
  const { data } = useListFriendNotInGroupQuery(
    graphqlClientRequest(true),
    input,
    {
      enabled: !!input.id,
    },
  );
  return { data };
};

export const useGetGroupMedia = (input: GetGroupMediaListQueryVariables) => {
  const { data } = useGetGroupMediaListQuery(
    graphqlClientRequest(true),
    input,
    {
      enabled: !!input.id,
    },
  );
  return { data };
};

export const useSearchGroupMembers = (
  input: SearchGroupMembersQueryVariables,
) => {
  const { data, isLoading } = useSearchGroupMembersQuery(
    graphqlClientRequest(true),
    input,
  );
  return { data, isLoading };
};

export const useGetGroupMembers = (input: GetGroupMembersQueryVariables) => {
  const { data } = useGetGroupMembersQuery(graphqlClientRequest(true), input, {
    enabled: !!input.groupId,
  });
  return { data };
};

export const useUpdateGroupMembers = () => {
  const { mutate, mutateAsync } = useUpdateGroupMutation(
    graphqlClientRequest(true),
    {
      onSuccess() {
        queryClient.invalidateQueries(['getGroup']);
      },
    },
  );
  return { mutateAsync, mutate };
};

export const useDeleteGroupMembers = () => {
  const { mutate, mutateAsync } = useDeleteGroupMutation(
    graphqlClientRequest(true),
    {
      onSuccess() {
        queryClient.invalidateQueries(['getMyGroups']);
      },
    },
  );
  return { mutateAsync, mutate };
};

export const useInviteUserToGroup = () => {
  const { mutate, mutateAsync } = useInviteUserToGroupMutation(
    graphqlClientRequest(true),
    {
      onSuccess() {
        queryClient.invalidateQueries(['listFriendNotInGroup']);
      },
    },
  );
  return { mutateAsync, mutate };
};

export const useLeaveGroupMembers = () => {
  const { mutate, mutateAsync } = useLeaveGroupMutation(
    graphqlClientRequest(true),
    {
      onSuccess() {
        queryClient.invalidateQueries(['getMyGroups']);
      },
    },
  );
  return { mutateAsync, mutate };
};

export const useRemoveGroupMember = () => {
  const { mutate, mutateAsync } = useRemoveGroupMemberMutation(
    graphqlClientRequest(true),
    {
      onSuccess() {
        queryClient.invalidateQueries(['getGroupMembers']);
      },
    },
  );
  return { mutateAsync, mutate };
};

export const useAddUserToGroupConversation = () => {
  const { mutate, mutateAsync } = useAddUserToGroupConversationMutation(
    graphqlClientRequest(true),
    {
      onSuccess() {
        queryClient.invalidateQueries(['getConversation']);
        queryClient.invalidateQueries(['listFriendNotInChatGroup']);
      },
    },
  );
  return { mutateAsync, mutate };
};

export const useRequestToJoinToGroup = () => {
  const { mutate, mutateAsync } = useRequestToJoinToGroupMutation(
    graphqlClientRequest(true),
    {
      onSuccess() {
        queryClient.invalidateQueries(['searchGroups']);
      },
    },
  );
  return { mutateAsync, mutate };
};
