import env from '../configs/configs';
import { User } from '../graphql/generated';

let TOKEN: any = '';
export const TOKEN_KEY = env.tokenKey;

export const setAuthData = (authData: any) => {
  localStorage.setItem(TOKEN_KEY, JSON.stringify(authData || {}));
  TOKEN = authData;
};

export const setUser = (data: any) => {
  localStorage.setItem('user', JSON.stringify(data || {}));
};

export const getUserLogin = () => {
  const data = localStorage.getItem('user');
  if (data) {
    try {
      return JSON.parse(data);
    } catch (error) {
      return { _id: '' };
    }
  }
  return { _id: '' };
};

export const setRefreshToken = (refreshToken: any) => {
  localStorage.setItem('refreshToken', JSON.stringify(refreshToken));
};

export const getAuthToken = () => {
  const data = localStorage.getItem(TOKEN_KEY);
  if (data) {
    try {
      return JSON.parse(data);
    } catch (error) {
      return null;
    }
  }
  return null;
};

export const getToken = () => {
  return `Bearer ${TOKEN}`;
};

export const removeToken = () => {
  localStorage.removeItem(TOKEN_KEY);
  TOKEN = '';
};
