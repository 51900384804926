import { FC } from 'react';
import { Place } from '../../../graphql/generated';
import { useNavigate } from 'react-router-dom';
import userImg from '../../../assets/images/user.jpeg';
import Avatar from '../../../components/avatar/Avatar';

interface ListPlaceItemProps {
  item: Place;
}

const ListPlaceItem: FC<ListPlaceItemProps> = ({ item }) => {
  const navigate = useNavigate();

  const onPressPage = () => {
    //navigate('/page/' + item._id);
  };

  return (
    <div className="flex items-center mt-5 hover:bg-white pr-5" onClick={onPressPage}>
      {/* avatar */}
      <div className="min-w-[70px] min-h-[70px] mr-5 ml-5">
        <Avatar src={item?.primaryImage?.uri || userImg} width={75} height={75} className="rounded-full  !h-[70px] " />
      </div>
      <div className="w-full ">
        <p className="font-semibold text-lg">{item.name}</p>
        <div className="flex items-center">
          <p className="mr-1">{item.reviews}</p>
          <p>Review</p>
          <p className="w-1 h-1 rounded-full bg-black mx-2"></p>
          <p className="mr-1">{item.checkins}</p>
          <p>Check-in</p>
        </div>
        <hr className="w-full mt-4" />
      </div>
    </div>
  );
};

export default ListPlaceItem;
