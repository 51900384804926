import React, { useEffect, useState } from 'react';
import { GetUserQuery, Media, MediaType } from '../../graphql/generated';
import { GetUserMediaListQuery } from '../../graphql/generated/index';
import { Link, useParams } from 'react-router-dom';
import LightboxImage from '../lightbox/LightboxImage';
import { tranFormSlider } from '../photo/PhotoAlbum';
import { Slide } from 'yet-another-react-lightbox/*';
import PhotoItem from '../photo/PhotoItem';

interface Props {
  data: GetUserQuery | undefined;
  media: GetUserMediaListQuery | undefined;
}

const ListImage = (props: Props) => {
  const { data, media } = props;
  const { id } = useParams();
  const [slider, setSlider] = useState<Slide[]>([]);
  const [idxMedia, setIdxMedia] = useState(-1);

  useEffect(() => {
    setSlider(tranFormSlider((media?.getUserMediaList as Media[]) || []));
  }, [media?.getUserMediaList]);

  const onCloseMedia = () => {
    setIdxMedia(-1);
  };

  const onOpenMedia = (index: number) => {
    setIdxMedia(index);
  };

  return (
    <div className="ml-2 mt-2 pr-2">
      <div className="flex items-center justify-between">
        <div>
          <b className="text-lg">Ảnh</b>
        </div>
        <Link to={id ? `/profile/${id}/photos` : '/profile/photos'}>
          <div className="text-primary-yellow text-sm">Xem tất cả</div>
        </Link>
      </div>
      <div
        className={`photo-album grid grid-cols-5 items-center gap-1 mt-4 mb-4`}>
        {media?.getUserMediaList?.map((item, index) => {
          return (
            <PhotoItem
              key={item._id}
              onClick={() => onOpenMedia(index)}
              data={item as Media}
            />
            // <button className="border-0 background-none" onClick={() => onOpenMedia(index)}>
            //   <div className="relative ml-3">
            //     {item.type === MediaType.Video ? (
            //       <div className="profileImage w-44 h-44 border box-border rounded-2xl mt-4" style={{ backgroundImage: `url("${item?.thumbnail}")` }} />
            //     ) : (
            //       <div className="profileImage w-44 h-44 border box-border rounded-2xl mt-4" style={{ backgroundImage: `url("${item?.uri}")` }} />
            //     )}
            //   </div>
            // </button>
          );
        })}
        <LightboxImage
          index={idxMedia}
          slides={slider}
          isOpen={idxMedia >= 0}
          onClose={onCloseMedia}
        />
      </div>
      <hr className=".solid mt-4" />
    </div>
  );
};

export default ListImage;
