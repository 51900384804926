import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { images } from '../../../assets/images';
import {
  CommentFor,
  GetCommentsQueryVariables,
  Media,
  useGetCommentVideoQuery,
  useGetInfoUserQuery,
  useGetVideoQuery,
  Comment,
  PostStatus,
  Post,
  ReactToModel,
  useCreateReactionMutation,
  ReactionTypeInput,
  ReactionType,
} from '../../../graphql/generated';
import { graphqlClientRequest } from '../../../graphql/services/graphql-client';
import { DiffDate } from '../../../Ultis/Datetime';
import { checkThumnail } from '../../../Ultis/string';
import './Video.scss';
import ButtonReaction from '../../../components/reaction/ButtonReaction';
import {
  AiFillDislike,
  AiFillLike,
  AiOutlineDislike,
  AiOutlineLike,
} from 'react-icons/ai';
import SummamryUserReaction from '../../../components/reaction/SummaryUserReaction';
import ListUserReactionModal from '../../../components/reaction/ListUserReaction';
import CommentComponent from '../../../components/posts/Comment';
import InputComment from '../../../components/posts/InputComment';
import { useGetCommentMedia } from '../service/api';

interface Props {
  video?: Media | undefined;
}

export const ITEM_LOAD = 2;
const VideoItem = ({ video }: Props) => {
  const navigate = useNavigate();
  const [comments, setComments] = useState<any[]>([]);
  const [totalComment, setTotalComment] = useState<number>(0);

  const [reactiveAtribute, setReactiveAtribute] = useState({
    isLiked: video?.liked ?? false,
    isDisliked: video?.disliked ?? false,
    totalLikes: video?.totalLikes ?? 0,
    totalDislikes: video?.totalDislikes ?? 0,
    totalComments: video?.totalComments ?? 0,
  });

  const [isOpenLikeReactionModal, setIsOpenLikeReactionModal] = useState(false);

  const [isOpenDislikeReactionModal, setIsOpenDisLikeReactionModal] =
    useState(false);

  const { mutateAsync } = useCreateReactionMutation(graphqlClientRequest(true));

  const [isShowSummaryUserLikeReaction, setIsShowSummaryUserLikeReaction] =
    useState(false);

  const [
    isShowSummaryUserDisLikeReaction,
    setIsShowSummaryUserDisLikeReaction,
  ] = useState(false);

  const handleOnClickTotalLike = () => {
    if (reactiveAtribute.totalLikes) {
      document.body.classList.add('body_modal');
      setIsOpenLikeReactionModal(true);
    }
  };

  const handleOnClickTotalDisLike = () => {
    if (reactiveAtribute.totalDislikes) {
      document.body.classList.add('body_modal');
      setIsOpenDisLikeReactionModal(true);
    }
  };
  const handleDislike = async () => {
    mutateAsync({
      data: {
        reactionToModel: ReactToModel.Media,
        reactionToId: video?._id ?? '',
        reactionType: ReactionTypeInput.Dislike,
      },
    });
    setReactiveAtribute({
      ...reactiveAtribute,
      isLiked: false,
      isDisliked: true,
      totalLikes: reactiveAtribute.isLiked
        ? reactiveAtribute.totalLikes - 1
        : reactiveAtribute.totalLikes,
      totalDislikes: (reactiveAtribute.totalDislikes ?? 0) + 1,
    });
  };
  const handleUnDislike = async () => {
    mutateAsync({
      data: {
        reactionToModel: ReactToModel.Media,
        reactionToId: video?._id ?? '',
        reactionType: ReactionTypeInput.Undislike,
      },
    });
    setReactiveAtribute({
      ...reactiveAtribute,
      isDisliked: false,
      totalDislikes: (reactiveAtribute.totalDislikes ?? 0) - 1,
    });
  };
  const handleLike = async () => {
    mutateAsync({
      data: {
        reactionToModel: ReactToModel.Media,
        reactionToId: video?._id ?? '',
        reactionType: ReactionTypeInput.Like,
      },
    });
    setReactiveAtribute({
      ...reactiveAtribute,
      isLiked: true,
      isDisliked: false,
      totalLikes: reactiveAtribute.totalLikes + 1,
      totalDislikes: reactiveAtribute.isDisliked
        ? (reactiveAtribute.totalDislikes ?? 0) - 1
        : reactiveAtribute.totalDislikes,
    });
  };
  const handleUnLike = async () => {
    mutateAsync({
      data: {
        reactionToModel: ReactToModel.Media,
        reactionToId: video?._id ?? '',
        reactionType: ReactionTypeInput.Unlike,
      },
    });
    setReactiveAtribute({
      ...reactiveAtribute,
      isLiked: false,
      totalLikes: reactiveAtribute.totalLikes - 1,
    });
  };
  const [isOpenComment, setIsOpenComment] = useState<boolean>(false);

  const [filterComment, setFilterComment] = useState<GetCommentsQueryVariables>(
    {
      limit: ITEM_LOAD,
      refId: video?._id || '',
      for: CommentFor.Post,
      CLimit: 10,
    },
  );

  const { data: commentData, isSuccess } = useGetCommentMedia(filterComment);

  useEffect(() => {
    if (isSuccess && commentData && commentData?.getComments.items) {
      setComments(commentData?.getComments.items);
      setTotalComment(commentData.getComments.counter || 0);
    }
  }, [commentData]);

  const [lastComment, setLastComment] = useState(false);

  const moveWatchScreen = (video: Media) => {
    navigate('/watchvideo', { state: { video } });
  };

  const displayComment = () => {
    setIsOpenComment(!isOpenComment);
  };

  const onLoadMoreCmt = () => {
    setFilterComment({ ...filterComment, limit: 999 });
    setLastComment(true);
  };

  const onHideCmt = () => {
    setFilterComment({
      limit: ITEM_LOAD,
      refId: video?._id as string,
      for: CommentFor.Post,
      CLimit: ITEM_LOAD,
      orderAsc: false,
    });
    setLastComment(false);
  };

  const onAddComment = (value: Comment) => {
    setComments((prevItems) => [value, ...prevItems]);
  };

  const onRemove = (_id: string) => {
    setComments((prevItems) => prevItems.filter((obj) => obj._id !== _id));
  };

  const onUpdateComment = (value: Comment) => {
    setComments(comments.map((obj) => (obj._id === value._id ? value : obj)));
  };
  const renderStatus = (post: Post) => {
    switch (post.status) {
      case PostStatus.Pending: {
        return (
          <>
            <p className="text-primary-yellow lg:text-lg text-xs">
              Chờ kiểm duyệt
            </p>
          </>
        );
      }
      case PostStatus.Verified: {
        return (
          <>
            <p className="text-primary-green lg:text-lg text-xs">Đã duyệt</p>
          </>
        );
      }
      case PostStatus.Incorrect: {
        return (
          <>
            <p className="text-primary-red lg:text-lg text-xs">Thông tin sai</p>
          </>
        );
      }
      case PostStatus.NotVerified: {
        return (
          <>
            <p className="text-primary-red lg:text-lg text-xs">
              Chờ kiểm chứng
            </p>
          </>
        );
      }
    }
  };

  return (
    <div>
      <div className="flex justify-between items-center my-3">
        <div className="flex items-center cursor-pointer">
          {video?.posts?.map((avatar) => {
            return (
              <>
                <Link to={`/profile/${avatar.creator?._id}`}>
                  <img
                    src={
                      avatar?.creator?.avatar?.thumbnail || images.AvatarDefault
                    }
                    alt="avatar"
                    width={60}
                    height={60}
                    className="rounded-full"
                  />
                </Link>
              </>
            );
          })}
          <div className="ml-3">
            <p className="lg:text-lg text-xs">
              {video?.posts?.map((value) => {
                return (
                  <>
                    <Link to={`/profile/${value.creator?._id}`}>
                      {value.creator?.fullName}
                    </Link>
                  </>
                );
              })}
            </p>

            <div className="flex items-center">
              {video?.posts?.map((post) => {
                return <>{renderStatus(post)}</>;
              })}
              <p className="lg:text-lg text-xs text-primary-gray ml-2">
                {DiffDate(video?.uploadedAt)}
              </p>
            </div>
          </div>
        </div>
        <div className="flex cursor-pointer">
          <div className="w-1 h-1 bg-gray-500 rounded-full "></div>
          <div className="w-1 h-1 bg-gray-500 rounded-full mx-1"></div>
          <div className="w-1 h-1 bg-gray-500 rounded-full "></div>
        </div>
      </div>
      {video?.posts?.map((post) => {
        return (
          <>
            {post.status === PostStatus.Incorrect ? (
              <div className="flex flex-col items-center container_video_incorrect py-10 border border-gray-200 rounded-lg">
                <img
                  src={images.GallerySlash}
                  width={60}
                  height={60}
                  className=""
                />
                <p className="lg:text-xl text-sm my-8 mx-5 text-center">
                  Bài viết đã bị ẩn đi do chứa thông tin không chính xác.{' '}
                </p>
                <div className="flex bg-secondary-gray px-4 py-2 rounded-lg cursor-pointer items-center">
                  <img
                    src={images.Eye}
                    width={30}
                    height={30}
                    className="mr-1"
                  />
                  <span
                    className="lg:text-xl text-sm"
                    onClick={() => moveWatchScreen(video as Media)}>
                    Xem Bài Viết
                  </span>
                </div>
              </div>
            ) : (
              <div
                className={`flex justify-center mt-5 cursor-pointer relative container_video ${
                  post?.status === PostStatus.Verified
                    ? 'opacity-1'
                    : 'opacity-50'
                }`}>
                {checkThumnail(video?.thumbnail || '') ? (
                  <img
                    src={video?.thumbnail || ''}
                    className="thumnail_video"
                  />
                ) : (
                  <img src={images.NoThumnail} className="thumnail_video" />
                )}
                <div
                  className="play-btn"
                  onClick={() => moveWatchScreen(video as Media)}></div>
              </div>
            )}
          </>
        );
      })}

      <div className="box_action flex justify-end">
        <div className="flex items-center mt-4 cursor-pointer">
          <div className="relative mr-6 flex items-center">
            {reactiveAtribute.isLiked ? (
              <ButtonReaction
                children={
                  <AiFillLike
                    size={'21px'}
                    className="mr-2 text-primary-yellow"
                    onClick={handleUnLike}
                  />
                }
              />
            ) : (
              <ButtonReaction
                children={
                  <AiOutlineLike
                    className="mr-2"
                    size={'21px'}
                    onClick={handleLike}
                  />
                }
              />
            )}
            {isShowSummaryUserLikeReaction && (
              <SummamryUserReaction
                filter={{
                  reactionToId: video?._id ?? '',
                  reactionToModel: ReactToModel.Media,
                  reactionType: ReactionType.Like,
                }}
                totalReaction={reactiveAtribute.totalLikes ?? 0}
              />
            )}
            <div>
              <span
                className="hover:underline cursor-pointer"
                onClick={handleOnClickTotalLike}
                onPointerEnter={() => {
                  setIsShowSummaryUserLikeReaction(true);
                }}
                onPointerLeave={() => {
                  setIsShowSummaryUserLikeReaction(false);
                }}>
                {reactiveAtribute.totalLikes}
              </span>
            </div>
          </div>
          <div className="mr-6 relative flex items-center ">
            {reactiveAtribute.isDisliked ? (
              <ButtonReaction
                children={
                  <AiFillDislike
                    size={'21px'}
                    className="mr-2 text-primary-yellow"
                    onClick={handleUnDislike}
                  />
                }
              />
            ) : (
              <ButtonReaction
                children={
                  <AiOutlineDislike
                    className="mr-2"
                    size={'21px'}
                    onClick={handleDislike}
                  />
                }
              />
            )}
            {isShowSummaryUserDisLikeReaction && (
              <SummamryUserReaction
                filter={{
                  reactionToId: video?._id ?? '',
                  reactionToModel: ReactToModel.Media,
                  reactionType: ReactionType.Dislike,
                }}
                totalReaction={reactiveAtribute.totalDislikes ?? 0}
                customClassCssImportant="summaryDislikeReaction"
              />
            )}
            <div>
              <p
                className="hover:underline cursor-pointer"
                onClick={handleOnClickTotalDisLike}
                onPointerEnter={() => {
                  setIsShowSummaryUserDisLikeReaction(true);
                }}
                onPointerLeave={() => {
                  setIsShowSummaryUserDisLikeReaction(false);
                }}>
                {reactiveAtribute.totalDislikes}
              </p>
            </div>
          </div>
          <div className="flex mr-6">
            <img
              src={images.TwoMess}
              className="mr-2"
              onClick={displayComment}
            />
            <p className="">{video?.totalComments}</p>
          </div>
          <img src={images.TwoShare} />
        </div>
        {isOpenLikeReactionModal && (
          <ListUserReactionModal
            filter={{
              data: {
                reactionToId: video?._id ?? '',
                reactionToModel: ReactToModel.Media,
                reactionType: ReactionType.Like,
              },
              limit: 10,
            }}
            onCloseModal={() => {
              document.body.classList.remove('body_modal');
              setIsOpenLikeReactionModal(false);
            }}
            totalReaction={video?.totalLikes ?? 0}
          />
        )}
        {isOpenDislikeReactionModal && (
          <ListUserReactionModal
            filter={{
              data: {
                reactionToId: video?._id ?? '',
                reactionToModel: ReactToModel.Media,
                reactionType: ReactionType.Dislike,
              },
              limit: 10,
            }}
            onCloseModal={() => {
              document.body.classList.remove('body_modal');
              setIsOpenDisLikeReactionModal(false);
            }}
            totalReaction={video?.totalDislikes ?? 0}
          />
        )}
      </div>
      <div className={`${isOpenComment ? 'block' : 'hidden'}`}>
        {!!comments.length && (
          <>
            <hr className=".solid mt-2" />

            {totalComment > ITEM_LOAD &&
              (lastComment ? (
                <button
                  className="border-0 bg-none pl-6 mt-4 text-xs md:text-base"
                  onClick={onHideCmt}>
                  Ẩn bớt bình luận
                </button>
              ) : (
                <button
                  className="border-0 bg-none min-[700px]:pl-6 pl-4  mt-4 text-xs md:text-base"
                  onClick={onLoadMoreCmt}>
                  Xem thêm bình luận
                </button>
              ))}
            {comments?.map((item) => (
              <CommentComponent
                onDelete={onRemove}
                comment={item as Comment}
                onUpdateComment={onUpdateComment}
              />
            ))}
          </>
        )}

        {video && (
          <InputComment
            refId={video?._id}
            commentFor={CommentFor.Media}
            onAdd={onAddComment}
          />
        )}
      </div>
    </div>
  );
};
export default VideoItem;
