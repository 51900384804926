import {
  getCountries,
  getCountryCallingCode,
} from 'react-phone-number-input/input';

interface Props {
  value?: any;
  onChange: (item: any) => void;
  labels?: any;
}
const truncateCode = (text: string, size: number) => {
  return text.length > size ? text.slice(0, size - 1) + '…' : text;
};

const CountrySelect = (props: Props) => {
  const { value, onChange, labels } = props;
  return (
    <select
      value={value}
      onChange={(event) => onChange(event.target.value || undefined)}
      className="select_country"
      defaultChecked={true}
      defaultValue="84">
      {getCountries().map((country) => (
        <option key={country} value={getCountryCallingCode(country)}>
          {truncateCode(labels[country], 10)}+{getCountryCallingCode(country)}
        </option>
      ))}
    </select>
  );
};

export default CountrySelect;
