import { FC, useEffect, useState } from 'react';
import { FriendItemType } from '../../components/friend-menu/FriendItem';
import HeaderExtra from '../../components/header/HeaderExtra';
import MyFriendListMenuTab from './FriendListMenuTab';
import ListMyFriend from './components/ListMyFriends';
import ListMyPendingFriendRequest from './components/ListMyPendingFriendRequest';
import { default as ListMyRecommendFriendRequest } from './components/ListMyRecommendedFriend';
import './index.scss';
import { useGetMyListFriendTabInfo } from './services/api';

export const LIMIT_PAGE = 20;

export interface FriendListMenuProps {}

const tabs = [
  {
    id: 1,
    name: 'Gợi ý',
    type: FriendItemType.MySuggestion,
  },
  {
    id: 2,
    name: 'Bạn bè',
    type: FriendItemType.MyFriend,
  },
  {
    id: 3,
    name: 'Lời mời',
    type: FriendItemType.MyInvite,
  },
];
const MyFriendListMenu: FC<FriendListMenuProps> = (props) => {
  // const loadingRef = useRef<LoadingRef>(null);
  const [type, setType] = useState(FriendItemType.MyFriend);
  const tabInfo = useGetMyListFriendTabInfo();

  useEffect(() => {
    console.log('render');
  });

  const refresh = () => {};
  return (
    <div className="bg-white">
      <MyFriendListMenuTab
        tabs={tabs.map((item, idx) => (
          <li key={item.id} className={`mr-2 flex-auto`}>
            <a
              onClick={(e) => {
                e.preventDefault();
                setType(item.type);
              }}
              href="#"
              className={`${
                item.type === FriendItemType.MyInvite && 'relative'
              } ${
                item.type === type
                  ? 'inline-block p-4 primaryColor border-b-2 border-primary-color rounded-t-lg active dark:text-blue-500 dark:border-blue-500'
                  : 'inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'
              }`}>
              {item.name}
              {item.type === FriendItemType.MyFriend &&
                ` (${tabInfo?.totalFriends ?? 0})`}
              {item.type === FriendItemType.MyInvite &&
                tabInfo?.totalPendingFriendRequests !== 0 && (
                  <div className="absolute pending-friendsCount_icon">
                    {tabInfo?.totalPendingFriendRequests ?? 0}
                  </div>
                )}
            </a>
          </li>
        ))}
      />
      {type === FriendItemType.MyFriend && (
        <ListMyFriend totalItems={tabInfo?.totalFriends ?? 0} />
      )}
      {type === FriendItemType.MySuggestion && (
        <ListMyRecommendFriendRequest
          totalItems={tabInfo?.recommendedFriendUserIds?.length ?? 0}
        />
      )}
      {type === FriendItemType.MyInvite && (
        <ListMyPendingFriendRequest
          totalItems={tabInfo?.totalPendingFriendRequests ?? 0}
        />
      )}
    </div>
  );
};

export default MyFriendListMenu;
