import { useEffect, useState } from 'react';
import HeaderSearch from '../../components/header/HeaderSearch';
import { TabItemSearchBy, SearchTabs } from './components/SearchTabs';
import ListUser from './components/ListUser';
import { getUserLogin } from '../../Ultis/token';
import _ from 'lodash';
import { useSearchSuggestions } from './services/api';
import ListPage from './components/ListPage';
import ListPlace from './components/ListPlace';
import ListPost from './components/ListPost';
import ListSearchAll from './components/ListSearchAll';
import ListGroups from './components/ListGroups';

interface dataSearch {
  _id: string;
  data: string[];
}

export const formatData = (r: any[]) => {
  return r.reduce((r, e) => {
    if (!r) r = [e];
    else {
      r.push(e);
    }
    return r;
  }, []);
};

const tabs = [
  {
    index: 0,
    title: 'Tất cả',
    type: TabItemSearchBy.All,
  },
  {
    index: 1,
    title: 'Người',
    type: TabItemSearchBy.User,
  },
  {
    index: 2,
    title: 'Trang',
    type: TabItemSearchBy.Page,
  },
  {
    index: 3,
    title: 'Nhóm',
    type: TabItemSearchBy.Group,
  },
  {
    index: 4,
    title: 'Bài viết',
    type: TabItemSearchBy.Post,
  },
  {
    index: 5,
    title: 'Địa điểm',
    type: TabItemSearchBy.Location,
  },
];
const SearchPage = () => {
  const [query, setQuery] = useState('');
  const [searchBy, setSearchBy] = useState(TabItemSearchBy.All);
  const [keyword, setKeyword] = useState('');
  const [dataSearch, setDataSearch] = useState([]);
  const [checkDel, setCheckDel] = useState(false);
  const { data } = useSearchSuggestions({ q: keyword });
  const user = getUserLogin();

  useEffect(() => {
    const dataSearch = localStorage.getItem('list_search');
    const dataList: any[] = dataSearch
      ? JSON.parse(dataSearch).filter((e: dataSearch) => e._id === user._id)
      : [];

    setDataSearch(dataList.length > 0 ? dataList[0].data : []);

    return () => {};
  }, []);

  const applyKeyWord = async (keyword: string) => {
    setKeyword(keyword.replace(/[+()?\\\[\]]/g, ''));
    const regex = new RegExp(keyword, 'gi');
    const dataSearch = await localStorage.getItem('list_search');

    const dataList: any[] = dataSearch
      ? JSON.parse(dataSearch).filter((e: dataSearch) => e._id === user._id)
      : [];
    const dataFilter: any[] = dataList.length > 0 ? dataList[0].data : [];
    const newData = [...dataFilter].filter((e) =>
      e.toLocaleLowerCase().match(regex),
    );
    const newDataNew = formatData(newData);

    setDataSearch(Object.values(newDataNew));
    setCheckDel(keyword ? true : false);
  };

  const handlerSearchSuggestions = (keyword: string) => {
    try {
      const dataFilter: any[] = [];

      if (data && data.searchSuggestions) {
        const posts: any = data?.searchSuggestions.posts;
        const users: any = data.searchSuggestions.users;
        const places: any = data.searchSuggestions.places;
        const dataSearchSuggest = posts.concat(users, places);
        dataSearchSuggest.filter((e: any) => {
          dataFilter.push(e.text);
        });

        setDataSearch(dataFilter as []);
        setCheckDel(keyword ? true : false);
      }
    } catch (error) {
      console.log('error: ', error);
    }
  };

  const onSearch = (keyword: string) => {
    applyKeyWord(keyword);
    if (keyword != '' && keyword.trim()) {
      handlerSearchSuggestions(keyword);
    }
  };

  return (
    <>
      <HeaderSearch query={keyword} onChange={setKeyword} onSearch={onSearch} />
      <center>
        <SearchTabs
          type={searchBy}
          onChange={(type) => {
            setSearchBy(type);
          }}
          tabs={tabs}
        />
      </center>
      {searchBy === TabItemSearchBy.All && (
        <ListSearchAll keyword={keyword} searchBy={searchBy} />
      )}
      {searchBy === TabItemSearchBy.User && <ListUser query={keyword} />}
      {searchBy === TabItemSearchBy.Page && <ListPage keyword={keyword} />}
      {searchBy === TabItemSearchBy.Group && <ListGroups keyword={keyword} />}
      {searchBy === TabItemSearchBy.Location && <ListPlace keyword={keyword} />}
      {searchBy === TabItemSearchBy.Post && <ListPost keyword={keyword} />}
    </>
  );
};

export default SearchPage;
