import { useEffect, useState } from 'react';
import { IoMdClose } from 'react-icons/io';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getUserLogin } from '../../Ultis/token';
import {
  useGetMyFriendIds,
  useGetUserReactions,
} from '../../common/services/api';
import {
  GetUserReactionsQuery,
  GetUserReactionsQueryVariables,
  User,
} from '../../graphql/generated';
import { FriendEntity } from '../friend-menu/FriendItem';
import LoadingExtra from '../loading/LoadingExtra';
import UserItemReaction from './UserItemReaction';
interface Props {
  filter: GetUserReactionsQueryVariables;
  totalReaction: number;
  onCloseModal: () => void;
}

const getUsers = (reactionsData: GetUserReactionsQuery['getReactions']) => {
  return (
    reactionsData?.map(
      (reaction) =>
        reaction?.actor ??
        ({
          fullName: '',
          _id: '',
          avatar: { uri: '' },
          verified: false,
          username: '',
          totalMutualFriends: 0,
        } as any),
    ) ?? []
  );
};

const LIMIT_PAGE = 10;

const ListUserReactionModal = ({
  filter,
  totalReaction,
  onCloseModal,
}: Props) => {
  const me = getUserLogin();
  const { data: myFriendData } = useGetMyFriendIds({ limit: 5000 });
  const [reactionsFilter, setReactionsFilter] =
    useState<GetUserReactionsQueryVariables>({
      ...filter,
      limit: LIMIT_PAGE,
    });
  const {
    status,
    isSuccess,
    data: reactionsData,
    isLoading,
  } = useGetUserReactions(reactionsFilter);
  const [users, setUsers] = useState<
    Pick<
      User,
      | '_id'
      | 'avatar'
      | 'verified'
      | 'fullName'
      | 'username'
      | 'totalMutualFriends'
    >[]
  >([]);

  useEffect(() => {
    setUsers([...users, ...getUsers(reactionsData?.getReactions)]);
  }, [reactionsData]);

  const fetchData = () => {
    setReactionsFilter({
      ...reactionsFilter,
      after: reactionsData?.getReactions?.length
        ? reactionsData.getReactions[reactionsData.getReactions.length - 1]?._id
        : '',
    });
  };

  const refresh = () => {};

  return (
    <div
      onClick={onCloseModal}
      className="listUserReactionModel-container z-[50]"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true">
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div
        className="listUserReactionModel bg-white rounded-lg w-[300px] min-[400px]:w-[460px] py-4 overflow-y-auto min-[400px]:px-5 min-[400px]:py-8"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true">
        <div className="relative mt-[1rem] h-[10px] border-b-2 border-bg-slate-200">
          <div className="absolute cursor-pointer rounded-full w-[35px] h-[35px] right-0 max-[400px]:right-[4px] top-[-35px] bg-slate-200 hover:bg-slate-300">
            <IoMdClose size={'25px'} className="mt-[5px] ml-[5px]" />
          </div>
        </div>
        <InfiniteScroll
          className={`listUserReactionModel-content flex flex-col gap-0 min-[400px]:gap-4 pt-4`}
          dataLength={users?.length ?? 0}
          next={fetchData}
          height={'460px'}
          hasMore={users.length < totalReaction ? true : false}
          loader={
            isLoading && (
              <LoadingExtra className="loading-useReaction" label="" />
            )
          }
          endMessage={''}
          refreshFunction={refresh}>
          {isLoading && (
            <LoadingExtra className="loading-userReaction" label="" />
          )}
          {!isLoading &&
            users.length !== 0 &&
            users.map((user, idx) => {
              return (
                <UserItemReaction
                  key={user._id}
                  isMe={me._id === user._id}
                  isMyFriend={
                    myFriendData?.getFriends
                      ?.map((e) => e._id)
                      .includes(user._id) ?? false
                  }
                  user={user as FriendEntity}
                />
              );
            })}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default ListUserReactionModal;
