import { FC, useEffect, useState } from 'react';
import { Media } from '../../../graphql/generated';
import PhotoItem from '../../../components/photo/PhotoItem';
import LightboxImage from '../../../components/lightbox/LightboxImage';
import { Slide } from 'yet-another-react-lightbox';
import { tranFormSlider } from '../../../components/photo/PhotoAlbum';

interface Props {
  medias?: Media[];
}
const MediaMessage: FC<Props> = ({ medias }) => {
  if (!medias || medias?.length === 0) return <></>;

  const [slider, setSlider] = useState<Slide[]>([]);
  const [idxMedia, setIdxMedia] = useState(-1);

  useEffect(() => {
    setSlider(tranFormSlider((medias as Media[]) || []));
  }, [medias]);

  const onCloseMedia = () => {
    setIdxMedia(-1);
  };

  const onOpenMedia = (index: number) => {
    setIdxMedia(index);
  };

  const col = medias?.length > 3 ? 3 : medias?.length;
  return (
    <>
      <div
        className={`photo-album mt-1 min-w-[300px] grid grid-cols-${col} items-center gap-1 mb-4`}>
        {medias?.map((media, index) => {
          return (
            <PhotoItem
              key={media._id}
              onClick={() => {
                onOpenMedia(index);
              }}
              data={media as Media}
            />
          );
        })}
        <LightboxImage
          index={idxMedia}
          slides={slider}
          isOpen={idxMedia >= 0}
          onClose={onCloseMedia}
        />
      </div>
    </>
  );
};

export default MediaMessage;
