import { AiOutlineCamera } from 'react-icons/ai';
import { getUserLogin } from '../../Ultis/token';
import { useGetUser } from '../../pages/Profile/services/api';
import {
  FC,
  ForwardedRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import {
  useUpdateComment,
  useUploadPhoto,
} from '../../pages/DetailPost/services/api';
import { Comment, MediaType } from '../../graphql/generated';
import { uploadVideoApi } from '../../Ultis/upload';
import { MdSend } from 'react-icons/md';
import { InputCommentRef } from './InputComment';
const _URL = window.URL || window.webkitURL;
import userImg from '../../assets/images/user.jpeg';

interface Props {
  comment: Comment;
  isReply: boolean;
  fatherId?: string;
  onUpdate?: (value: Comment) => void;
  onClose?: () => void;
}

interface Preview {
  uri: string;
  type: 'image' | 'video';
  file: File | null;
  mediaId?: string;
}

const InputCommentUpdate = (
  { comment, fatherId, onClose, isReply, onUpdate }: Props,
  refInput: ForwardedRef<InputCommentRef>,
) => {
  const user = getUserLogin();
  const ref = useRef<HTMLTextAreaElement>(null);
  const refUpload = useRef<HTMLInputElement>(null);

  const { data } = useGetUser(user._id);

  const { mutate } = useUpdateComment();
  const { mutate: upload } = useUploadPhoto();
  const [message, setMessage] = useState('');
  const [preview, setPreview] = useState<Preview>();
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (event: any) => {
    setMessage(event.target.value);
  };

  useImperativeHandle(refInput, () => ({
    focus: () => {
      ref.current && ref.current.focus();
    },
  }));

  useEffect(() => {
    if (comment) {
      if (comment.content) {
        setMessage(comment.content);
      }
      if (comment.medias && comment.medias?.length > 0) {
        setPreview({
          uri:
            comment.medias[0].type === MediaType.Video
              ? (comment.medias[0].thumbnail as string)
              : comment.medias[0].uri || '',
          file: null,
          type: comment.medias[0].type === MediaType.Video ? 'video' : 'image',
          mediaId: comment.medias[0]._id,
        });
      }
    }
  }, [comment]);

  const uploadPhoto = (file: File) => {
    const img = new Image();
    const objectUrl = _URL.createObjectURL(file);
    img.onload = function () {
      _URL.revokeObjectURL(objectUrl);

      upload(
        {
          file,
          dimensions: {
            width: img.width,
            height: img.height,
          },
        },
        {
          onSuccess(data, variables, context) {
            mutate(
              {
                _id: comment._id,
                data: {
                  content: message,
                  for: comment.for,
                  refId: comment.refId,
                  mediaIds: [data.uploadPhoto._id],
                  fatherId,
                },
              },
              {
                onSuccess(data, variables, context) {
                  onUpdate && onUpdate(data.updateComment as Comment);
                  onClose && onClose();
                },
              },
            );
          },
        },
      );
    };
    img.src = objectUrl;
  };

  const uploadVideo = async (file: File) => {
    const res = await uploadVideoApi(file);
    if (res)
      mutate(
        {
          _id: comment._id,
          data: {
            content: message,
            refId: comment.refId,
            for: comment.for,
            mediaIds: [res.data._id],
          },
        },
        {
          onSuccess(data, variables, context) {
            onUpdate && onUpdate(data.updateComment as Comment);
            onClose && onClose();
          },
        },
      );
  };

  const onSend = () => {
    if (isLoading) return;
    if (message.trim() || preview)
      if (preview && preview.file) {
        if (preview.type === 'image') {
          uploadPhoto(preview.file);
        } else {
          uploadVideo(preview.file);
        }
      } else {
        mutate(
          {
            _id: comment._id,
            data: {
              content: message,
              refId: comment.refId,
              for: comment.for,
              mediaIds: preview?.mediaId ? [preview?.mediaId] : undefined,
            },
          },
          {
            onSuccess(data, variables, context) {
              onUpdate && onUpdate(data.updateComment as Comment);
              onClose && onClose();
            },
          },
        );
      }
  };

  const handleKeyDown = async (
    event: React.KeyboardEvent<HTMLTextAreaElement>,
  ) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      onSend();
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (!file) {
      return;
    }
    const objectUrl = URL.createObjectURL(file);

    if (file.type.split('/')[0] === 'image') {
      setPreview({ uri: objectUrl, file, type: 'image' });
    } else {
      setPreview({ uri: objectUrl, file, type: 'video' });
    }
  };

  useEffect(() => {
    if (ref.current) {
      ref.current.style.height = 'auto'; // reset height to auto to recalculate
      ref.current.style.height = `${ref.current.scrollHeight}px`;
    }
  }, [message]);

  const onOpenUploadFile = () => {
    refUpload.current && refUpload.current.click();
  };

  // const onClearMedia = () => {
  //   setPreview(undefined);
  // };

  const classRoot = isReply
    ? 'md:pr-6  md:pb-4 pr-2 flex items-center'
    : 'md:p-6 md:pt-4 md:pb-4 p-2 flex items-center';
  const isContent = !!(preview || message);

  return (
    <>
      <div className={classRoot}>
        <div
          style={{
            backgroundImage: `url("${
              data?.getPublicProfile.avatar?.uri || userImg
            }")`,
          }}
          className={`md:w-[55px] md:h-[55px] w-[52px] h-[52px] rounded-full profileImage ${
            data?.getPublicProfile?.verified && 'shadow_verify'
          }`}
        />
        {/* <span
          onClick={onOpenUploadFile}
          className="color-[#888888] cursor-pointer md:text-3xl text-xl flex  p-2 rounded-md text-gray-500 hover:bg-gray-100 enabled:dark:hover:text-gray-400 dark:hover:bg-gray-900 disabled:hover:bg-transparent dark:disabled:hover:bg-transparent disabled:opacity-40">
          <AiOutlineCamera className="" />
        </span> */}

        <div className="bd-30 ml-6 md:w-[92%] w-[80%] flex flex-col w-full pt-2 pb-2 flex-grow box-border md:py-3 pl-3 md:pl-4 relative  bg-[#f3f3f3]     ">
          {/* <div className="ml-6 rounded-full bg-[#f3f3f3] md:w-[92%] w-[80%] p-4 box-border flex items-center justify-between"> */}
          {preview && (
            <div className="media-upload w-40 md:w-48">
              {preview.type === 'video' ? (
                <video
                  className="object-cover h-40 w-40 md:h-48 md:w-48 rounded-md"
                  src={preview.uri}
                />
              ) : (
                <img
                  className="object-cover h-40 w-40 md:h-48 md:w-48 rounded-md"
                  src={preview.uri}
                />
              )}
              {/* <span className="remove-media" onClick={onClearMedia}>
                <IoMdClose color="#fff" />
              </span> */}
            </div>
          )}

          <textarea
            ref={ref}
            tabIndex={0}
            data-id="root"
            rows={1}
            placeholder="Viết bình luận"
            className="md:h-[34px] h-[26px] min-h-[26px] md:min-h-[34px] max-h-[200px] outline-none border-transparent focus:border-transparent text-base m-0 w-full resize-none border-0 bg-transparent p-0 pr-7 focus:ring-0 focus-visible:ring-0 dark:bg-transparent pl-2 md:pl-1 pr-6 md:pr-4"
            value={message}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
          />

          {/* <span
            onClick={onOpenUploadFile}
            className="color-[#888888] cursor-pointer md:text-3xl text-xl flex absolute p-1 rounded-md text-gray-500 top-1.5 md:top-2.5 hover:bg-gray-100 enabled:dark:hover:text-gray-400 dark:hover:bg-gray-900 disabled:hover:bg-transparent dark:disabled:hover:bg-transparent right-1 md:right-2 disabled:opacity-40">
            <AiOutlineCamera className="md:mr-2 mr-1" />
          </span> */}
          {isLoading ? (
            <div
              className="color-[#888888] cursor-pointer md:text-3xl text-xl flex absolute md:p-1 rounded-md text-gray-500 top-[8px] md:top-2.5 hover:bg-gray-100 enabled:dark:hover:text-gray-400 dark:hover:bg-gray-900 disabled:hover:bg-transparent dark:disabled:hover:bg-transparent right-1 md:right-2 disabled:opacity-40"
              role="status">
              <svg
                aria-hidden="true"
                className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            <span
              onClick={onSend}
              className="color-[#888888] cursor-pointer md:text-3xl text-xl flex absolute p-1 rounded-md text-gray-500 top-[8px] md:top-2.5 hover:bg-gray-100 enabled:dark:hover:text-gray-400 dark:hover:bg-gray-900 disabled:hover:bg-transparent dark:disabled:hover:bg-transparent right-1 md:right-2 disabled:opacity-40">
              <MdSend
                className="md:mr-2 mr-1"
                color={isContent ? '#0073f5' : '#bcc0c4'}
              />
            </span>
          )}
          <input
            type="file"
            ref={refUpload}
            style={{ display: 'none' }}
            accept="image/*,video/*"
            onChange={handleFileChange}
          />
        </div>
      </div>
      <div className={classRoot}>
        <button onClick={onClose} className="mt-[-10px] ml-[80px]">
          Huỷ chỉnh sửa
        </button>{' '}
      </div>
    </>
  );
};

export default forwardRef(InputCommentUpdate);
