import { nanoid } from 'nanoid';
interface Preview {
  id?: string;
  key: string;
  idx: number;
  uri: string;
  type: 'image' | 'video';
  file: File;
  status: 'Uploading' | 'Completed' | 'Error' | 'Cancel';
}

export function removeFileFromFileList(index: number, files: FileList) {
  const dt = new DataTransfer();
  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    if (index !== i) dt.items.add(file); // here you exclude the file. thus removing it.
  }

  files = dt.files; // Assign the updates list
  return files;
}

export function concatFileFromFileList(
  files: FileList,
  nextFiles: FileList | File[],
) {
  const dt = new DataTransfer();
  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    dt.items.add(file);
  }

  for (let i = 0; i < nextFiles.length; i++) {
    const file = nextFiles[i];
    dt.items.add(file);
  }
  files = dt.files; // Assign the updates list
  return files;
}

const MAX_FILE_SIZE = 10485760;

export function validateFile(files: FileList) {
  let totalFileSize = 0;
  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    if (file.size > MAX_FILE_SIZE) {
      throw new Error('file size exceeded!');
    }
  }
  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    totalFileSize += file.size;
    if (totalFileSize > MAX_FILE_SIZE) {
      throw new Error('file size exceeded!');
    }
  }
}

export function createPreviewMedia(files: FileList, length: number):Preview[] {
  const medias: Preview[] = [];
  for (let i = 0; i < files.length; i++) {
    const objectUrl = URL.createObjectURL(files[i]);
    if (files[i].type.split('/')[0] === 'image') {
      medias.push({
        idx: length + i,
        key: nanoid(),
        uri: objectUrl,
        file: files[i],
        type: 'image',
        status: 'Uploading',
      });
    } else {
      medias.push({
        idx: length + i,
        key: nanoid(),
        uri: objectUrl,
        file: files[i],
        type: 'video',
        status: 'Uploading',
      });
    }
  }
  return medias;
}


export const generateVideoThumbnail = (file: File): Promise<string> => {
  return new Promise((resolve) => {
    const canvas = document.createElement("canvas");
    const video = document.createElement("video");

    // this is important
    video.autoplay = true;
    video.muted = true;
    video.src = URL.createObjectURL(file);

    video.onloadeddata = () => {
      const ctx = canvas.getContext("2d");

      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      ctx && ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
      video.pause();
      return resolve(canvas.toDataURL("image/png"));
    };
  });
};