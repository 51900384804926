import { useEffect, useRef, useState } from 'react';
import { BsFillPersonFill } from 'react-icons/bs';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import { queryClient } from '../../..';
import { DiffDate } from '../../../Ultis/Datetime';
import { useOnClickOutside } from '../../../Ultis/hooks/useClickOutside';
import { getUserLogin } from '../../../Ultis/token';
import { images } from '../../../assets/images';
import { PropsOption } from '../../../components/modal/ModalChat';
import SelectOptionItem from '../../../components/selectOption/SelectOptionItem';
import Toast, { ToastProps, ToastType } from '../../../components/toast/Toast';
import { Conversation, ConversationType } from '../../../graphql/generated';
import { useClearMessages } from '../services/api';

interface Props {
  value: any;
}

const ItemMess = ({ value }: Props) => {
  const [isShow, setIsShow] = useState<boolean>(false);
  const [toast, setToast] = useState<ToastProps>({
    className: 'fixed top-0 right-0',
    isHidden: true,
    type: ToastType.Success,
    message: '',
  });

  const me = getUserLogin();
  const uid = me._id;
  const ref = useRef<any>();
  const navigate = useNavigate();
  useOnClickOutside(ref, () => setIsShow(false));
  const { mutateAsync: removeConversation } = useClearMessages();

  const renderAvatar = (item: Conversation) => {
    switch (item.conversationType) {
      case 'GROUP': {
        return (
          <>
            {item.avatar?.uri || item.avatar?.uri != '' ? (
              <img
                src={item.avatar?.uri || images.AvatarDefault}
                className="rounded-full w-[40px] h-[40px] object-cover"
              />
            ) : (
              item.users?.slice(0, 2).map((avatar: any) => {
                return (
                  <img
                    src={avatar.avatar?.uri || images.AvatarDefault}
                    className="rounded-full w-[40px] h-[40px]"
                  />
                );
              })
            )}
          </>
        );
      }
      case 'SUPPORT': {
        return (
          <>
            {' '}
            {item.partners?.map((avatar) => {
              return (
                <div className="h-10 w-10">
                  <div className="img-1-1">
                    <img
                      src={avatar?.avatar?.uri || images.AvatarDefault}
                      width={60}
                      height={60}
                      className={`${
                        avatar.verified && 'shadow_verify '
                      } rounded-full`}
                    />
                  </div>
                </div>
              );
            })}
          </>
        );
      }
      case 'PAGE': {
        if ((item?.users || [])[0]?._id === me?._id) {
          return (
            <div className="h-10 w-10">
              <div className="img-1-1">
                <img
                  src={item?.page?.logo?.uri || ''}
                  width={60}
                  height={60}
                  className="rounded-full"
                />
              </div>
            </div>
          );
        } else {
          return (
            <img
              src={(item?.users || [])[0]?.avatar?.uri || ''}
              width={60}
              height={60}
              className="rounded-full"
            />
          );
        }
      }
    }
    if (item.conversationType === 'DIRECT') {
      return (
        <>
          {item.partners?.map((avatar) => {
            return (
              <div className="h-10 w-10">
                <div className="img-1-1">
                  <img
                    src={avatar?.avatar?.uri || images.AvatarDefault}
                    width={60}
                    height={60}
                    className={`${
                      avatar.verified && 'shadow_verify '
                    } rounded-full`}
                  />
                </div>
              </div>
            );
          })}
        </>
      );
    }
  };

  useEffect(() => {
    let resetToast: any;
    if (!toast.isHidden) {
      resetToast = setTimeout(() => {
        setToast((preState) => {
          return { ...preState, isHidden: true };
        });
      }, 3000);
    }
    return () => {
      clearTimeout(resetToast);
    };
  }, [toast]);

  const moveMessItem = () => {
    if (!!value?.page) {
      navigate(`/messenger/${value._id}?pageId=${value?.page?._id}`, {
        state: value,
      });
    } else {
      navigate(`/messenger/${value._id}`, { state: value });
    }
  };

  const renderName = (item: any) => {
    switch (item.conversationType) {
      case 'SUPPORT': {
        return <>{item.partners[0].fullName}</>;
      }
      case 'DIRECT': {
        return <>{item.partners[0].fullName}</>;
      }
      case 'PAGE': {
        if ((item?.users || [])[0]?._id === me?._id) {
          return <>{item?.page?.name}</>;
        } else {
          return <>{(item?.users || [])[0]?.fullName}</>;
        }
      }
    }
    if (item.conversationType === 'GROUP') {
      return <>{item.name}</>;
    }
  };

  const renderTextMess = (item: any) => {
    const lastSendIsMe =
      item?.lastMessage?.createdBy === uid?.replace(/['"]+/g, '');
    if (item?.lastMessage?.type === 'CREATE_CONVERSATION') {
      return (
        <p
          className={`${
            item?.seen || lastSendIsMe
              ? 'text-primary-gray font-normal'
              : 'text-black font-semibold'
          } lg:text-lg text-xs`}>
          {lastSendIsMe ? 'Bạn' : item?.lastMessage?.creator?.fullName} đã tạo
          nhóm {item?.name}
        </p>
      );
    }
    if (item?.lastMessage?.type === 'ADD_USERS_TO_CONVERSATION') {
      return (
        <p
          className={`${
            item?.seen
              ? 'text-primary-gray font-normal'
              : 'text-black font-semibold'
          } lg:text-lg text-xs`}>
          Bạn đã thêm{' '}
          {item?.lastMessage?.mentionUsers?.length > 0
            ? item?.lastMessage?.mentionUsers[0].fullName
            : ''}
        </p>
      );
    }

    //Nếu là tin nhắn từ page
    if (
      item?.conversationType === 'PAGE' &&
      item?.lastMessage?.medias?.length > 0 &&
      item?.lastMessage?.medias[0].type === 'VIDEO'
    ) {
      return (
        <p
          className={`${
            item?.seen
              ? 'text-primary-gray font-normal'
              : 'text-black font-semibold'
          } lg:text-lg text-xs`}>
          Bạn đã gửi {item?.lastMessage?.medias?.length} video
        </p>
      );
    } else if (
      item?.conversationType === 'PAGE' &&
      item?.lastMessage?.medias?.length > 0 &&
      item?.lastMessage?.medias[0]?.type === 'PHOTO'
    ) {
      return (
        <p
          className={`${
            item?.seen
              ? 'text-primary-gray font-normal'
              : 'text-black font-semibold'
          } lg:text-lg text-xs`}>
          Bạn đã gửi {item?.lastMessage?.medias?.length} ảnh
        </p>
      );
    } else {
      <p
        className={`${
          item?.seen
            ? 'text-primary-gray font-normal'
            : 'text-black font-semibold'
        } lg:text-lg text-xs`}>
        {item?.lastMessage?.text}
      </p>;
    }

    //Nếu tin nhắn từ group chat
    if (
      item?.conversationType === 'GROUP' &&
      item?.lastMessage?.medias?.length > 0 &&
      item?.lastMessage?.medias[0]?.type === 'VIDEO'
    ) {
      return (
        <p
          className={`${
            item?.seen
              ? 'text-primary-gray font-normal'
              : 'text-black font-semibold'
          } lg:text-lg text-xs`}>
          Bạn đã gửi {item?.lastMessage?.medias?.length} video
        </p>
      );
    } else if (
      item?.conversationType === 'GROUP' &&
      item?.lastMessage?.medias?.length > 0 &&
      item?.lastMessage?.medias[0]?.type === 'PHOTO'
    ) {
      return (
        <p
          className={`${
            item?.seen
              ? 'text-primary-gray font-normal'
              : 'text-black font-semibold'
          } lg:text-lg text-xs`}>
          Bạn đã gửi {item?.lastMessage?.medias?.length} ảnh
        </p>
      );
    } else {
      <p
        className={`${
          item?.seen
            ? 'text-primary-gray font-normal'
            : 'text-black font-semibold'
        } lg:text-lg text-xs`}>
        {item?.lastMessage?.text}
      </p>;
    }

    //NẾU tin nhắn 1-1
    if (
      item?.conversationType === 'DIRECT' &&
      item?.lastMessage?.medias?.length > 0 &&
      item?.lastMessage?.medias[0]?.type === 'PHOTO'
    ) {
      return (
        <p
          className={`${
            item?.seen
              ? 'text-primary-gray font-normal'
              : 'text-black font-semibold'
          } lg:text-lg text-xs`}>
          {lastSendIsMe ? 'Bạn' : item?.partners[0]?.fullName} đã gửi{' '}
          {item?.lastMessage?.medias?.length} ảnh
        </p>
      );
    } else if (
      item?.conversationType === 'DIRECT' &&
      item?.lastMessage?.medias?.length > 0 &&
      item?.lastMessage?.medias[0]?.type === 'VIDEO'
    ) {
      return (
        <p
          className={`${
            item?.seen
              ? 'text-primary-gray font-normal'
              : 'text-black font-semibold'
          } lg:text-lg text-xs`}>
          {lastSendIsMe ? 'Bạn' : item?.partners[0]?.fullName} đã gửi{' '}
          {item?.lastMessage?.medias?.length} video
        </p>
      );
    } else {
      return (
        <p
          className={`${
            item?.seen
              ? 'text-primary-gray font-normal'
              : 'text-black font-semibold'
          } lg:text-lg text-xs`}>
          {item?.lastMessage?.text}
        </p>
      );
    }
  };

  const handleRemoveConversation = async (id: string) => {
    removeConversation(
      {
        conversationId: id,
      },
      {
        onSuccess() {
          setToast({
            ...toast,
            message: `Đã xóa cuộc thoại`,
            isHidden: false,
            type: ToastType.Success,
          });
          queryClient.invalidateQueries(['getChat']);
        },
        onError() {
          setToast({
            ...toast,
            message: `Có gì đó không đúng. Vui lòng thử lại `,
            isHidden: false,
            type: ToastType.Danger,
          });
        },
      },
    );
  };

  const handleMoveProfile = () => {
    if (value?.conversationType === ConversationType.Direct) {
      navigate(`/profile/${value?.users[1]?._id}`, { state: value });
    } else if (value?.conversationType === ConversationType.Page) {
      navigate(`/page/${value?.page?._id}`, { state: value });
    }
  };

  const options: PropsOption[] = [
    {
      content: 'Xem trang cá nhân',
      handleClick: () => handleMoveProfile(),
      icon: <BsFillPersonFill color="gray" />,
    },
    {
      content: 'Xóa cuộc trò chuyện',
      handleClick: () => handleRemoveConversation(value?._id),
      icon: <RiDeleteBin6Line color="gray" />,
    },
  ];
  const optionsGroup: PropsOption[] = [
    {
      content: 'Xóa cuộc trò chuyện',
      handleClick: () => handleRemoveConversation(value?._id),
      icon: <RiDeleteBin6Line color="gray" />,
    },
  ];

  const handleShowOption = () => {
    setIsShow(!isShow);
  };

  return (
    <>
      <Toast
        message={toast.message}
        className={`${toast.className} fixed top-3`}
        type={toast.type}
        isHidden={toast.isHidden}
      />
      <div
        ref={ref}
        className="flex justify-between border-b-light-gray border-t-white border border-r-white border-l-white py-3 cursor-pointer message-item"
        key={value?._id}>
        <div className="flex items-center content-left">
          <div className=" flex  -space-x-4 box_info_chat">
            {renderAvatar(value)}
          </div>
          <div
            className="ml-5 lg:text-lg text-xs content-left-right text-break-word"
            onClick={moveMessItem}>
            {renderName(value)}
            {renderTextMess(value)}
          </div>
        </div>
        <div className={`flex gap-2 justify-end  items-center relative`}>
          <p className="text-sm font-normal text-primary-gray w-[90px] mess-created-at">
            {DiffDate(value.lastMessage?.createdAt)}
          </p>
          <div
            onClick={handleShowOption}
            className="w-[30px] h-[30px] flex items-center justify-center rounded-full  hover:bg-gray-200">
            <img src={images.DotDotDotGray} alt="" />
          </div>
          {isShow && (
            <div className="absolute py-1 button_show_member top-[50px] right-0 rounded-[10px] bg-white border-[1px] border-solid border-gray-300">
              {(value?.conversationType === ConversationType.Group ||
              value?.conversationType === ConversationType.Support
                ? optionsGroup
                : options
              ).map((item, index) => {
                return (
                  <SelectOptionItem
                    content={item?.content}
                    handleClick={item?.handleClick}
                    icon={item?.icon}
                    key={index}
                  />
                );
              })}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ItemMess;
