import {
  GetPageMediaListQueryVariables,
  GetPagePostsQueryVariables,
  GetPageProductQueryVariables,
  GetPageReviewsQueryVariables,
  useDeletePageReviewMutation,
  useGetBlockedUsersQuery,
  useDeletePageMutation,
  useGetPagePostsQuery,
  useGetPageProductQuery,
  useGetPageQuery,
  useGetPageReviewsQuery,
  useUnBlockUserMutation,
  useUpdatePageReviewMutation,
} from '../../../graphql/generated';
import { useGetPageMediaListQuery } from '../../../graphql/generated/index';
import { graphqlClientRequest } from '../../../graphql/services/graphql-client';
import { queryClient } from '../../..';
import { useQueryClient } from '@tanstack/react-query';

export const useGetPage = (id: string) => {
  const { data, isLoading } = useGetPageQuery(
    graphqlClientRequest(true),
    { id: id },
    { enabled: !!id },
  );
  return { data, isLoading };
};

export const useGetPageMedia = (input: GetPageMediaListQueryVariables) => {
  const { data } = useGetPageMediaListQuery(graphqlClientRequest(true), input, {
    enabled: !!input.id,
  });
  return { data };
};

export const useGetPageReview = (input: GetPageReviewsQueryVariables) => {
  const { data } = useGetPageReviewsQuery(graphqlClientRequest(true), input, {
    enabled: !!input.pageId,
  });
  return { data };
};

export const useGetPagePosts = (input: GetPagePostsQueryVariables) => {
  const { data } = useGetPagePostsQuery(graphqlClientRequest(true), input, {
    enabled: !!input.pageId,
  });
  return { data };
};

export const useGetPageProducts = (input: GetPageProductQueryVariables) => {
  const { data } = useGetPageProductQuery(graphqlClientRequest(true), input, {
    enabled: !!input.pageId,
  });
  return { data };
};

export const useGetBlockedUsers = (input: number) => {
  const { data } = useGetBlockedUsersQuery(graphqlClientRequest(true), {
    limit: input,
  });
  return { data };
};

export const useUnBlockUser = () => {
  const { data, isLoading, isError, mutate, isSuccess } =
    useUnBlockUserMutation(graphqlClientRequest(true), {
      onSuccess(data) {
        queryClient.invalidateQueries(['getBlockedUsers']);
      },
      onError(error, variables, context) {
        console.log(error);
      },
      retry: false,
    });

  return { data, isLoading, isError, mutate, isSuccess };
};
export const useDeletePage = () => {
  const queryClient = useQueryClient();
  return useDeletePageMutation(graphqlClientRequest(true), {
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries(['getMyPages']);
    },
    retry: true,
  });
};

export const useUpdatePageReview = () => {
  const { data, isLoading, isError, mutate, isSuccess, mutateAsync } =
    useUpdatePageReviewMutation(graphqlClientRequest(true), {
      onSuccess(data) {
        queryClient.invalidateQueries(['getPageReviews']);
        queryClient.invalidateQueries(['getPage']);
      },
      onError(error, variables, context) {
        console.log(error);
      },
      retry: false,
    });

  return { data, isLoading, isError, mutate, isSuccess, mutateAsync };
};

export const useDeletePageReview = () => {
  const { data, isLoading, isError, mutate, isSuccess, mutateAsync } =
    useDeletePageReviewMutation(graphqlClientRequest(true), {
      onSuccess(data) {
        queryClient.invalidateQueries(['getPageReviews']);
        queryClient.invalidateQueries(['getPage']);
      },
      onError(error, variables, context) {
        console.log(error);
      },
      retry: false,
    });

  return { data, isLoading, isError, mutate, isSuccess, mutateAsync };
};
