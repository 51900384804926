import { useState } from 'react';
import { BsThreeDots } from 'react-icons/bs';

const items = [
  {
    id: 1,
    label: 'Kết bạn',
  },
  {
    id: 2,
    label: 'Theo dõi',
  },
  {
    id: 3,
    label: 'Huỷ kết bạn',
  },
];

class FriendActionProps {}

const FriendAction = (props: FriendActionProps) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <div className="relative">
      <BsThreeDots
        className="cursor-pointer"
        onClick={() => {
          isOpen ? setOpen(false) : setOpen(true);
        }}
      />
      {isOpen && (
        <div className="absolute w-36 min-[630px]:left-3 min-[630px]:top-5 right-0">
          <ul className="grid w-36 divide-y divide-solid place-content-center py-3 bg-white rounded-lg shadow-inner grid-row-3 gap-0">
            {items.map((item) => (
              <li key={item.id} className=" cursor-pointer font-medium text-sm text-center w-36 px-2 py-1 hover:bg-gray-100">
                {item.label}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default FriendAction;
