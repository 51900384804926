import { useEffect, useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import CreatePost from '../../components/create-post/CreatePost';
import Loading, { LoadingRef } from '../../components/loading/Loading';
import PostItem from '../../components/posts/Post';
import { usePosts } from './hooks/usePosts';

interface Props {}

export enum StateFetch {
  GETTING_FIRST = 'GETTING_FIRST',
  GETTING_MORE = 'GETTING_MORE',
}

const Home = (props: Props) => {
  const { posts, isLoading, fetchMoreData, onPostCreated, refetch } =
    usePosts();
  const loadingRef = useRef<LoadingRef>(null);

  useEffect(() => {
    if (isLoading) {
      loadingRef.current?.open();
    } else {
      loadingRef.current?.close();
    }

    return () => {};
  }, [isLoading]);

  useEffect(() => {
    return () => {
      refetch();
    };
  }, []);

  return (
    <div>
      <div className="fixed top-[15%] md:left-[47%] left-[42%] z-50">
        <Loading ref={loadingRef} />
      </div>
      <CreatePost onPostCreated={onPostCreated} />
      <InfiniteScroll
        dataLength={posts?.length || 0} //This is important field to render the next data
        next={fetchMoreData}
        hasMore={true}
        loader={<></>}
        endMessage={<p style={{ textAlign: 'center' }}></p>}
        // refreshFunction={refresh}
      >
        {posts?.map((item, idx) => {
          return (
            <PostItem
              key={item._id + idx}
              post={item}
              onChangePost={onPostCreated}
              onPostCreated={onPostCreated}
            />
          );
        })}
      </InfiniteScroll>
    </div>
  );
};

export default Home;
