import React from 'react';
import { GetUserQuery, GetFriendsQuery } from '../../graphql/generated/index';
import user from '../../assets/images/user.jpeg';
import { Link, useLocation, useNavigation } from 'react-router-dom';

interface Props {
  data: GetUserQuery | undefined;
  friends: any;
}

export const maxText = (text: string, maxText: number) => {
  if (text.length >= maxText) {
    return text.slice(0, maxText - 3) + '...';
  }
  return text;
};

const Friends = (props: Props) => {
  const { pathname } = useLocation();
  const { data, friends } = props;
  return (
    <div className="ml-2 mt-2 ">
      <div className="flex items-center justify-between">
        <Link to={pathname?.split('/')[2] ? pathname + '/friends' : '/friends'}>
          <div>
            <b className="text-lg">Bạn bè</b>{' '}
            <span className="text-lg ml-2">
              {data?.getPublicProfile.totalFriends}
            </span>
          </div>
        </Link>
        <Link to={pathname?.split('/')[2] ? pathname + '/friends' : '/friends'}>
          <div className="text-primary-yellow text-sm">Xem tất cả</div>
        </Link>
      </div>
      <div className="mt-2 overflow-auto">
        <div className="flex gap-1">
          {friends?.map((item: any) => {
            return (
              <Link
                className="relative"
                to={'/profile/' + item._id}
                key={item._id}>
                <img
                  src={`${item.avatar?.uri || user}`}
                  className="profileImage max-w-[130px] max-h-[130px] border box-border rounded-2xl"
                />
                <div className="absolute bottom-0 text-center p-4">
                  <span className="text-sm font-semibold line-clamp line-clamp-2 text-[#fff]">
                    {maxText(item.fullName, 31)}
                  </span>
                </div>
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Friends;
