import { useState } from 'react';
import { AiFillStar, AiOutlineDelete } from 'react-icons/ai';
import { BsDot, BsPencil, BsThreeDots } from 'react-icons/bs';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { DiffDate } from '../../Ultis/Datetime';
import { getUserLogin } from '../../Ultis/token';
import userImg from '../../assets/images/user.jpeg';
import { PostStatus } from '../../graphql/generated';
import {
  useDeletePageReview,
  useUpdatePageReview,
} from '../../pages/Page/services/api';
import NotifyModal from '../modal/NotifyModal';
import VotePageModal from '../modal/VotePageModal';
import { maxText } from '../profile/Friends';
interface Props {
  post: any;
}

const HeaderReviewPost = (props: Props) => {
  const { post } = props;
  const [isFullContent, setIsFullContent] = useState(false);
  const [isShowOption, setIsShowOption] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const { postId } = useParams();
  const navigate = useNavigate();
  const me = getUserLogin();
  const { mutate: deletePostMutate } = useDeletePageReview();
  const { mutateAsync: updateReviewPost } = useUpdatePageReview();
  const [voteContext, setVoteContext] = useState('');
  const [starVote, setStarVote] = useState(0);
  const [isShowModalNotify, setIsShowModalNotify] = useState(false);
  const [messNotify, setMessNotify] = useState<any>();

  const handleClickCreatePost = (id: string) => {
    if (id) {
      setIsUpdate(true);
    }
    setIsShowOption(false);
    setVoteContext(post?.content);
    setStarVote(post?.rating);
  };

  const handleClickOption = () => {
    setIsShowOption(!isShowOption);
  };

  const handleClickDeletePost = (id: string) => {
    deletePostMutate({ _id: id });
    setIsShowOption(false);
  };

  const handleVoteContentChange = (e: any) => {
    setVoteContext(e.target.value);
  };

  const onchangeStar = (nextValue: number, prevValue: number, name: string) => {
    setStarVote(nextValue);
  };

  const handleClickCancel = () => {
    setStarVote(0);
    setVoteContext('');
    setIsUpdate(false);
  };

  const handleCloseModalNotify = () => {
    setIsShowModalNotify(false);
  };

  const updatePostReview = async () => {
    updateReviewPost(
      {
        data: {
          content: voteContext,
          rating: starVote,
          pageId: post?.page?._id,
        },
        _id: post?._id,
      },
      {
        onSuccess(data, variables, context) {
          setIsUpdate(false);
          setIsShowModalNotify(true);
          setMessNotify(true);
        },
        onError(error, variables, context) {
          setIsUpdate(false);
          setIsShowModalNotify(true);
          setMessNotify(true);
        },
      },
    );
  };

  return (
    <>
      <div className="pb-4 pt-2 px-3 md:px-5">
        <div className="flex items-start justify-between gap-3">
          <Link to={'/profile/' + post?.reviewer?._id}>
            <div
              style={{
                backgroundImage: `url("${
                  post?.reviewer?.avatar?.uri || userImg
                }")`,
              }}
              className={`w-[40px] h-[40px] rounded-full profileImage ${
                post.reviewer?.verified && !post?.page
                  ? 'shadow_verify'
                  : 'avartar-border_default'
              }`}
            />
          </Link>
          <div className="text-md md:text-lg flex-auto">
            <div>
              <Link to={'/profile/' + post?.reviewer?._id}>
                <b>{post?.reviewer?.fullName}</b>
              </Link>{' '}
              <span>đánh giá cho</span>
              <b className="pl-1">{post?.page?.name}</b>
            </div>
            <div className="flex items-center text-[#8a8d91] text-xs md:text-base">
              <BsDot />
              <div
                className="cursor-pointer"
                onClick={() => navigate('/detail/' + post._id)}>
                {DiffDate(post?.createdAt)}
              </div>
            </div>
          </div>
          <div className=" flex justify-end items-center relative">
            {post?.rating && (
              <div className="mr-3 flex items-center md:text-lg bg-amber-500 rounded-2xl p-2 pt-0 pb-0 text-base text-white">
                {post?.rating}{' '}
                <AiFillStar className="ml-1 md:text-lg text-base" />
              </div>
            )}
            <div className="relative inline-block text-left cursor-pointer">
              {me?._id === post?.reviewer?._id && (
                <div className="button-option-post" onClick={handleClickOption}>
                  <BsThreeDots className="text-2xl " />
                </div>
              )}

              {isShowOption && (
                <div
                  className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="menu-button">
                  <div className="py-1" role="none">
                    <div
                      className="flex gap-3 text-gray-700  px-4 py-2 text-md mx-1 rounded cursor-pointer item-select-option"
                      onClick={() => handleClickCreatePost(post?._id)}>
                      <BsPencil className="text-2xl " />
                      <span>Sửa bài viết</span>
                    </div>
                    <div
                      className="flex gap-3 text-gray-700  px-4 py-2 text-md mx-1 rounded cursor-pointer item-select-option"
                      onClick={() => handleClickDeletePost(post?._id)}>
                      <AiOutlineDelete className="text-2xl " />
                      <span>Xóa</span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {!!post?.title && (
          <div className="mt-2">
            <span className="font-semibold ">{post?.title}</span>
          </div>
        )}
        {(post.status !== PostStatus.Incorrect || !!postId) && (
          <div
            className={`mt-2 text-lg  ${
              (post?.status === PostStatus.Pending ||
                post.status === PostStatus.NotVerified ||
                post.status === PostStatus.Incorrect) &&
              'opacity-60'
            }`}>
            <span className="break-all">
              {!isFullContent
                ? maxText(post?.content || '', 339)
                : post?.content}{' '}
            </span>
            {!isFullContent && (post?.content || '').length >= 339 && (
              <button
                className={`border-0 bg-none`}
                onClick={() => setIsFullContent(true)}>
                <b className="hover:underline">Xem thêm</b>
              </button>
            )}
            {isFullContent && (post?.content || '').length >= 339 && (
              <button
                className={`border-0 bg-none`}
                onClick={() => setIsFullContent(false)}>
                <b className="hover:underline">Ẩn bớt nội dung</b>
              </button>
            )}
          </div>
        )}
      </div>
      {isUpdate && (
        <VotePageModal
          isShow={isUpdate}
          isUpdate={true}
          defaultValue={post}
          voteContent={voteContext}
          handleClickVote={updatePostReview}
          currentWord={voteContext.length}
          handleVoteContentChange={handleVoteContentChange}
          onchangeStar={onchangeStar}
          star={starVote}
          handleClickCancel={handleClickCancel}
        />
      )}

      {isShowModalNotify && (
        <NotifyModal
          isSuccess={messNotify}
          isShow={isShowModalNotify}
          message={
            messNotify
              ? 'Sửa đánh giá thành công'
              : 'Có lỗi xảy ra, vui lòng thử lại'
          }
          onClose={handleCloseModalNotify}
        />
      )}
    </>
  );
};

export default HeaderReviewPost;
