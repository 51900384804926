interface ButtonOrangeProps {
  text: string;
  className?: string;
}

const ButtonSecondary = (props: ButtonOrangeProps) => {
  const { text, className } = props;

  return (
    <div className={`bg-secondary-yellow flex items-center px-8 py-2 rounded-lg cursor-pointer text-white ${className}`}>
      <p className="text-primary-yellow">{text}</p>
    </div>
  );
};

export default ButtonSecondary;
