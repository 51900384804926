import { FC, useEffect, useRef, useState } from 'react';
import { MdSearchOff } from 'react-icons/md';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loading, { LoadingRef } from '../../../components/loading/Loading';
import { SearchPagesQueryVariables } from '../../../graphql/generated';
import { useSearchGroup } from '../services/api';
import ListGroupItem from './ListGroupItem';
import { TabItemSearchBy } from './SearchTabs';

interface ListPageProps {
  keyword: string;
  searchBy?: TabItemSearchBy;
}

const ListGroups: FC<ListPageProps> = ({ keyword, searchBy }) => {
  const [params, setParams] = useState<SearchPagesQueryVariables>({
    q: keyword,
    limit: 20,
  });
  const [dataPage, setDataPage] = useState<any>([]);
  const { data, isLoading } = useSearchGroup(params);
  const loadingRef = useRef<LoadingRef>(null);
  const refresh = () => {};

  const handleSearchPage = () => {
    try {
      const newData = params.after
        ? [...dataPage, ...(data?.searchGroups || [])]
        : data?.searchGroups;
      if (data && data.searchGroups) {
        setDataPage(newData);
        setParams({ q: keyword, limit: 20 });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchMoreData = () => {
    setParams({ ...params, after: dataPage[dataPage.length - 1]?._id || '' });
  };

  useEffect(() => {
    handleSearchPage();
  }, [keyword, data?.searchGroups?.length]);

  useEffect(() => {
    if (isLoading) {
      loadingRef.current?.open();
    } else {
      loadingRef.current?.close();
    }
  }, [isLoading]);

  return (
    <div>
      {keyword === '' && (
        <div className="mt-10 font-semibold text-2xl">Đề xuất</div>
      )}

      {searchBy === TabItemSearchBy.All && dataPage.length !== 0 && (
        <div className="font-semibold text-2xl mt-4">Nhóm</div>
      )}

      {searchBy === TabItemSearchBy.All ? (
        <></>
      ) : (
        <>
          {dataPage.length === 0 && keyword !== '' && (
            <center className="mt-20">
              <MdSearchOff size={'45px'} />
              <p className="mt-2">{`Không tìm thấy kết quả tìm kiếm cho từ khoá "${keyword}"`}</p>
            </center>
          )}
        </>
      )}

      <div className="fixed top-[15%] left-[47%] z-50">
        <Loading ref={loadingRef} />
      </div>
      <InfiniteScroll
        dataLength={dataPage?.length || 0}
        next={fetchMoreData}
        hasMore={true}
        loader={<></>}
        endMessage={<p style={{ textAlign: 'center' }}></p>}
        refreshFunction={refresh}>
        {dataPage?.map((item: any, index: number) => {
          return <ListGroupItem key={index} item={item} />;
        })}
      </InfiniteScroll>
    </div>
  );
};

export default ListGroups;
