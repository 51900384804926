const Second = 1000;
const Minute = 60 * Second;
const Hour = Minute * 60;
const Day = 24 * Hour;
const Month = 30 * Day;
const Year = 12 * Month;

const TIME_VALUE = {
  Second,
  Minute,
  Hour,
  Day,
  Month,
  Year,
};

export default {
  TIME_VALUE,
};
