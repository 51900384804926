import { useState } from 'react';
import { AiFillFlag, AiOutlineCloseCircle, AiOutlineClose } from 'react-icons/ai';

interface Props {}

const NotificationAddInfor = (props: Props) => {
  const [display, setDisplay] = useState(true);

  return (
    <div className={`w-full p-2 pb-6 bg-[#e8e8e8] mt-4 rounded-lg ${display ? 'block' : 'hidden'}`}>
      <div className="flex items-start justify-between">
        <div>
          <div className="flex items-start">
            <AiFillFlag className="text-5xl text-primary-yellow" />
            <div className="ml-4 text-lg mt-2">Thêm thông tin cá nhân để mọi người biết thêm về bạn hơn</div>
          </div>
          <button className="border-0 bg-none mt-4 ml-16">
            <div className="text-primary-yellow border-primary-yellow border inline p-3 rounded-lg">
              <b>Thêm thông tin</b>
            </div>
          </button>
        </div>
        <button className="border-0 bg-none mt-2" onClick={() => setDisplay(false)}>
          <AiOutlineClose className="text-2xl" />
        </button>
      </div>
    </div>
  );
};

export default NotificationAddInfor;
