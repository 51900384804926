import { GetVideoQueryVariables, Media, useGetVideoQuery } from '../../graphql/generated';
import { graphqlClientRequest } from '../../graphql/services/graphql-client';
import './index.scss';
import VideoItem from './VideoItem/VideoItem';
import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

const LIMIT_PAGE = 5;
const Video = () => {
  const [loadMore, setLoadMore] = useState(false);
  const [filter, setFiter] = useState<GetVideoQueryVariables>({
    limit: LIMIT_PAGE,
  });
  const [videos, setVideos] = useState<any[]>([]);
  const { data } = useGetVideoQuery(graphqlClientRequest(true), filter , { cacheTime : 0});

  useEffect(() => {
    if (!loadMore) {
      setVideos([...(data?.getVideos || [])]);
    } else {
      setVideos([...videos, ...(data?.getVideos || [])]);
    }
  }, [data?.getVideos, data?.getVideos?.length]);

  const refresh = () => {};

  const fetchData = () => {
    setLoadMore(!loadMore);
    setFiter({ limit: LIMIT_PAGE + 20 });
  };
  return (
    <div className="bg-white pt-5 lg:px-10 px-5">
      <InfiniteScroll dataLength={videos.length || 0} next={fetchData} hasMore={true} loader={loadMore && <center>Loading ... </center>} refreshFunction={refresh}>
        {videos?.map((item) => (
          <VideoItem video={item as Media} />
        ))}
      </InfiniteScroll>
    </div>
  );
};

export default Video;
