import { useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { MdPersonAddAlt1 } from 'react-icons/md';
import { GetPageQuery } from '../../graphql/generated';
import ButtonOrange from '../button/ButtonOrange';
import { maxText } from '../profile/Friends';
import { useNavigate } from 'react-router-dom';

interface Props {
  data: GetPageQuery | undefined;
}

const InviteFriends = (props: Props) => {
  const [display, setDisplay] = useState(true);
  const { data } = props;
  const navigate = useNavigate()

  return (
    <div className={`w-full p-2 h-36 bg-[#e8e8e8] mt-4 rounded-lg ${display ? 'block' : 'hidden'} relative`}>
      <div className="m-auto text-center">
        <b>Mời bạn bè thích trang</b> <br />
        <span className="text-center">
          Mời bạn bè thích {maxText(data?.getPage?.name || '', 24)} để mở rộng <br /> cộng đồng của trang
        </span>
        <div className="w-fit m-auto mt-2" onClick={()=>{
          navigate(`/page/${props.data?.getPage?._id}/invite-friend`)
        }}>
          <ButtonOrange text="Mời bạn bè" icon={<MdPersonAddAlt1 />} />
        </div>
      </div>
      <button className="border-0 bg-none absolute right-2 top-4" onClick={() => setDisplay(false)}>
        <AiOutlineClose className="text-2xl" />
      </button>
    </div>
  );
};

export default InviteFriends;
