import { useNavigate } from 'react-router-dom';
import { images } from '../../../assets/images';
import ButtonComponent from '../../../components/button/ButtonComponent';
import { FriendItemType } from '../../../components/friend-menu/FriendItem';
import {
  useAcceptFriendRequestByUserId,
  useRejectFriendRequestByUserId,
} from '../services/api';

interface Props {
  friendItem: any;
  type?: FriendItemType;
}

const FriendItem = ({ friendItem, type }: Props) => {
  const navigate = useNavigate();

  const { mutateAsync: rejectFriendRequest } = useRejectFriendRequestByUserId();
  const { mutateAsync: acceptFriendRequest } = useAcceptFriendRequestByUserId();

  const handleClick = () => {
    if (type === FriendItemType.MyInvite) {
      navigate(`/profile/${friendItem?.user?._id}`);
    } else {
      navigate(`/profile/${friendItem?._id}`);
    }
  };

  const handleAccept = () => {
    acceptFriendRequest(
      {
        userId: friendItem?.user?._id,
      },
      {
        onSuccess() {},
      },
    );
  };

  const handleReject = () => {
    rejectFriendRequest(
      {
        userId: friendItem?.user?._id,
      },
      {
        onSuccess() {},
      },
    );
  };

  return (
    <div
      className={`pb-3 flex gap-2 ${
        type === FriendItemType.MyInvite ? 'items-start' : 'items-center'
      } `}>
      <div className="h-[40px] w-[40px] cursor-pointer" onClick={handleClick}>
        <div className="img-1-1 ">
          <img
            src={
              (type === FriendItemType.MyInvite
                ? friendItem?.user?.avatar?.uri
                : friendItem?.avatar?.uri) || images.AvatarDefault
            }
            alt=""
          />
        </div>
      </div>
      <div>
        <p
          className="font-semibold text-base cursor-pointer"
          onClick={handleClick}>
          {type === FriendItemType.MyInvite
            ? friendItem?.user?.fullName
            : friendItem?.fullName}
        </p>
        {friendItem?.totalMutualFriends > 0 && (
          <p className="text-xs text-gray-400">
            {type === FriendItemType.MyInvite
              ? friendItem?.user?.totalMutualFriends
              : friendItem?.totalMutualFriends}{' '}
            bạn chung
          </p>
        )}
        {type === FriendItemType.MyInvite && (
          <div className="flex gap-2 items-center pt-1">
            <ButtonComponent
              content="Chấp nhận"
              hover="hover:bg-cyan-500"
              text_size="text-sm"
              padding="py-1 px-3"
              margin="mt-0"
              size="w-[100px]"
              onPress={handleAccept}
            />
            <ButtonComponent
              content="Xoá"
              hover="hover:bg-gray-300"
              text_size="text-sm"
              padding="py-1 px-3"
              type="bg-gray-200"
              margin="mt-0"
              textColor="text-black"
              size="w-[100px]"
              onPress={handleReject}
            />
          </div>
        )}
      </div>
    </div>
  );
};
export default FriendItem;
