import { Link, useNavigate } from 'react-router-dom';
import { images } from '../../../../assets/images';
import { Conversation, ConversationType } from '../../../../graphql/generated';
import { IoIosArrowBack } from 'react-icons/io';

interface Props {
  conversation?: Partial<Conversation>;
}
export default function DirectConversationInfo({ conversation }: Props) {
  const navigate = useNavigate();
  const user = conversation?.partners && conversation?.partners[0];
  return (
    <div className=" place-content-center gap-1 w-ful border-b-8 pb-3">
      <div className=" rounded-full w-full">
        <div className="relative w-full flex justify-center items-center">
          <img
            className={`friend-avatar mb-2 bg-cover self-center rounded-full ${
              user?.verify && 'shadow_verify'
            }`}
            src={
              (conversation?.conversationType === ConversationType.Direct
                ? user?.avatar?.uri
                : conversation?.conversationType === ConversationType.Page
                ? conversation?.page?.logo?.uri
                : images.AvatarDefault) ?? images.AvatarDefault
            }
            height={120}
            width={120}
            alt={user?.username}
          />
          <IoIosArrowBack
            size="30px"
            color="gray"
            className="absolute left-1 top-1 cursor-pointer"
            onClick={() => {
              navigate(-1);
            }}
          />
        </div>
      </div>
      <h1 className="text-lg text-center font-semibold text-black line-clamp-1">
        {conversation?.conversationType === ConversationType.Direct
          ? user?.fullName
          : conversation?.conversationType === ConversationType.Page
          ? conversation?.page?.name
          : 'Hỗ trợ'}
      </h1>
      <Link
        className="text-sm text-center text-blue-600/75 block"
        to={
          conversation?.conversationType === ConversationType.Direct
            ? `/profile/${user?._id}`
            : conversation?.conversationType === ConversationType.Page
            ? `/page/${conversation?.page?._id}`
            : '#'
        }>
        {conversation?.conversationType === ConversationType.Direct
          ? 'Xem trang cá nhân'
          : 'Xem thông tin trang'}
      </Link>
    </div>
  );
}
