import { FC } from 'react';

enum TabItemSearchBy {
  All = 'All',
  User = 'User',
  Page = 'Page',
  Post = 'Post',
  Location = 'Location',
  Group = 'Group',
}
interface TabItem {
  index: number;
  title: string;
  type: TabItemSearchBy;
}

interface Props {
  tabs: TabItem[];
  type: TabItemSearchBy;
  onChange: (type: TabItemSearchBy) => void;
}
const SearchTabs: FC<Props> = ({ tabs, type, onChange }) => {
  return (
    <div className="search-tabs  bg-primary-yellow ">
      <div className="p-2">
        {tabs.map((item) => (
          <div
            key={`search-${item.index}`}
            onClick={() => onChange(item.type)}
            className={`${
              type === item.type
                ? 'bg-white primaryColor'
                : 'text-white bg-primary-yellow '
            }  search-tab-item text-base px-5  pt-2 pb-2 inline-flex items-center font-bold rounded-full`}>
            {item.title}
          </div>
        ))}
      </div>
    </div>
  );
};

export { SearchTabs, TabItemSearchBy };
