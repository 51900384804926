import { AiFillDislike, AiOutlineLike } from 'react-icons/ai';
import { images } from '../../../assets/images';
import { Notification, NotificationType } from '../../../graphql/generated';

interface IconNotifyProps {
  item: Notification;
}

const IconNotify = (props: IconNotifyProps) => {
  const { item } = props;

  const renderIconNotify = () => {
    switch (item.type) {
      case NotificationType.AddedPost:
        return (
          <img src={images.Pencil} className="justify-center items-center" />
        );

      case NotificationType.DislikedPost:
      case NotificationType.DislikedComment:
      case NotificationType.DislikedMedia:
      case NotificationType.DislikedPageProduct:
      case NotificationType.DislikedPostThatTaggedYou:
      case NotificationType.DislikedPage:
        return (
          <img src={images.Dislike} className="justify-center items-center" />
        );
      case NotificationType.LikedPost:
      case NotificationType.LikedPostThatTaggedYou:
      case NotificationType.LikedMedia:
      case NotificationType.LikedComment:
      case NotificationType.InvitedToLikeAPage:
        return (
          <img src={images.Like} className="justify-center items-center" />
        );

      case NotificationType.LikedPage:
      case NotificationType.FollowedPage:
      case NotificationType.LikedPageProduct:
        return (
          <img
            src={images.Request_Like_Page}
            className="justify-center items-center"
          />
        );

      case NotificationType.AddedCommentToPostThatTaggedYou:
      case NotificationType.AddedCommentToPost:
      case NotificationType.TaggedInAComment:
      case NotificationType.AlsoRepliedCommentByFriend:
      case NotificationType.RepliedComment:
      case NotificationType.RepliedCommentOnYourPost:
        return (
          <img src={images.Comment} className="justify-center items-center" />
        );

      case NotificationType.SharedPost:
      case NotificationType.SharedTaggedPost:
      case NotificationType.TaggedInAPost:
      case NotificationType.AddedCommentToMedia:
      case NotificationType.VerifyPageAccepted:
      case NotificationType.VerifyPageRejected:
        return (
          <img
            src={images.Notification}
            className="justify-center items-center"
          />
        );

      case NotificationType.AcceptedFriendRequest:
      case NotificationType.NewFriendRequested:
      case NotificationType.FollowedUser:
        return (
          <img
            src={images.Request_Friend}
            className="justify-center items-center"
          />
        );

      default:
        return (
          <img
            src={images.Notification}
            className="justify-center items-center"
          />
        );
    }
  };

  return <div className="flex-end">{renderIconNotify()}</div>;
};

export default IconNotify;
