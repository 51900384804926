import React, { ChangeEvent, useState, forwardRef } from 'react';
import { images } from '../../assets/images';
import './input.scss';
import CountrySelect from './CountrySelect';
import en from 'react-phone-number-input/locale/en.json';

interface PropsPhoneInput {
  onChange: (type: string, value: string | number) => void;
  getCountryCode: (item: number) => void;
  allow?: boolean;
}

const PhoneInputComponent = (props: PropsPhoneInput, ref: any) => {
  const { onChange, getCountryCode, allow } = props;
  const getPhone =
    (type: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      onChange(value, type);
    };

  const getCountry = (value: number) => {
    getCountryCode(value);
  };

  return (
    <div className="mt-4 flex   items-center ">
      <div className="flex  absolute   pt-1 border border-l-0 border-b-0 border-t-0 border-r-slate-300 pl-4 py-1">
        <img src={images.Phone} alt="phone" className="pr-1" />

        <CountrySelect labels={en} onChange={getCountry} />
      </div>
      <input
        type="text"
        placeholder="Số điện thoại"
        onChange={getPhone('phone')}
        className={`${
          allow ? 'not_allow' : ''
        } input_phone border-[1px] w-full border-solid border-gray-200 rounded-[16px] h-[50px]`}
        ref={ref}
      />
    </div>
  );
};

export default forwardRef(PhoneInputComponent);
