import { queryClient } from '../../..';
import {
  GetMyFriendsQueryVariables,
  GetMyListFriendTabInfoQueryVariables,
  GetMyPendingFriendRequestsQueryVariables,
  GetMyRecommendedFriendsQueryVariables,
  GetUserFriendsQueryVariables,
  GetUserListFriendTabInfoQueryVariables,
  GetUserMutualFriendsQueryVariables,
  useAcceptFriendRequestByUserIdMutation,
  useAddFriendRequestMutation,
  useGetMyFriendsQuery,
  useGetMyListFriendTabInfoQuery,
  useGetMyPendingFriendRequestsQuery,
  useGetMyRecommendedFriendsQuery,
  useGetUserFriendsQuery,
  useGetUserListFriendTabInfoQuery,
  useGetUserMutualFriendsQuery,
  useRejectFriendRequestByUserIdMutation,
} from '../../../graphql/generated';
import { graphqlClientRequest } from '../../../graphql/services/graphql-client';

export const useGetMyFriends = (input: GetMyFriendsQueryVariables) => {
  const { data } = useGetMyFriendsQuery(graphqlClientRequest(true), input, {
    onSuccess(data) {},
    onError(error: any) {
      //   Notification.Error({ message: getMessageError(error) });
    },
    retry: false,
  });
  return data?.getFriends;
};

export const useGetUserFriends = (input: GetUserFriendsQueryVariables) => {
  const { data } = useGetUserFriendsQuery(graphqlClientRequest(true), input, {
    onSuccess(data) {},
    onError(error: any) {
      //   Notification.Error({ message: getMessageError(error) });
    },
    retry: false,
  });
  return data?.getUserFriends;
};

export const useGetUserMutualFriends = (
  input: GetUserMutualFriendsQueryVariables,
) => {
  const { data } = useGetUserMutualFriendsQuery(
    graphqlClientRequest(true),
    input,
    {
      onSuccess(data) {},
      onError(error: any) {
        //   Notification.Error({ message: getMessageError(error) });
      },
      retry: false,
    },
  );
  return data?.getMutualFriends;
};

export const useGetMyRecommendedFriend = (
  input: GetMyRecommendedFriendsQueryVariables,
) => {
  const { data } = useGetMyRecommendedFriendsQuery(
    graphqlClientRequest(true),
    input,
    {
      onSuccess(data) {},
      onError(error: any) {
        //   Notification.Error({ message: getMessageError(error) });
      },
      retry: false,
    },
  );
  return data?.recommendedFriends;
};

export const useGetMyPendingFriends = (
  input: GetMyPendingFriendRequestsQueryVariables,
) => {
  const { data } = useGetMyPendingFriendRequestsQuery(
    graphqlClientRequest(true),
    input,
    {
      onSuccess(data) {},
      onError(error: any) {
        //   Notification.Error({ message: getMessageError(error) });
      },
      retry: false,
    },
  );
  return data?.getPendingFriendRequests;
};

export const useGetMyListFriendTabInfo = () => {
  const { data } = useGetMyListFriendTabInfoQuery(
    graphqlClientRequest(true),
    undefined,
    {
      onSuccess(data) {},
      onError(error: any) {
        //   Notification.Error({ message: getMessageError(error) });
      },
      retry: false,
    },
  );
  return data?.me;
};

export const useGetUserListFriendTabInfo = (
  input: GetUserListFriendTabInfoQueryVariables,
) => {
  const { data } = useGetUserListFriendTabInfoQuery(
    graphqlClientRequest(true),
    input,
    {
      onSuccess(data) {},
      onError(error: any) {
        //   Notification.Error({ message: getMessageError(error) });
      },
      retry: false,
    },
  );
  return data?.getPublicProfile;
};

export const useAcceptFriendRequestByUserId = () => {
  const { mutate, mutateAsync } = useAcceptFriendRequestByUserIdMutation(
    graphqlClientRequest(true),
    {
      onSuccess() {
        queryClient.invalidateQueries(['getMyPendingFriendRequests']);
      },
    },
  );
  return { mutate, mutateAsync };
};

export const useAddFriendRequest = () => {
  const { mutate, mutateAsync } = useAddFriendRequestMutation(
    graphqlClientRequest(true),
    {
      onSuccess() {
        // queryClient.invalidateQueries(['getMyPendingFriendRequests'])
      },
    },
  );
  return { mutate, mutateAsync };
};

export const useRejectFriendRequestByUserId = () => {
  const { mutate, mutateAsync } = useRejectFriendRequestByUserIdMutation(
    graphqlClientRequest(true),
    {
      onSuccess() {
        queryClient.invalidateQueries(['getMyPendingFriendRequests']);
      },
    },
  );
  return { mutate, mutateAsync };
};
