import { GetUserQuery } from '../../graphql/generated';
import coin from '../../assets/images/Coin.svg';
import Action from './Action';
import { useParams } from 'react-router-dom';
import { useGetFriends } from '../../pages/Profile/services/api';
import { useMemo } from 'react';

interface Props {
  data: GetUserQuery | undefined;
}

const HeaderProfile = (props: Props) => {
  const { data } = props;
  const { id } = useParams();
  // const { data: friends } = useGetFriends({ limit: 999 });

  // const isFriends = useMemo(
  //   () => friends?.getFriends?.findIndex((item) => item._id === id) !== -1,
  //   [id],
  // );

  return (
    <div>
      <div className="md:ml-52 ml-48 md:text-xl text-lg pt-[25px]">
        <div className="relative">
          <b>{data?.getPublicProfile.fullName}</b>
        </div>
        <div className="flex mt-2">
          <span className="text-primary-yellow">
            {data?.getPublicProfile.point}
          </span>
          <img src={coin} alt="" width={24} height={24} className="ml-2" />
        </div>
      </div>
      {id && (
        <div className="md:absolute md:right-2 md:top-72 mt-2">
          <Action userId={id} />
        </div>
      )}

      <div className="flex items-center mt-4 md:justify-between justify-around">
        <div className="text-center  text-lg flex-1">
          <span>{data?.getPublicProfile.totalFriends}</span>
          <br />
          <span>Bạn bè</span>
        </div>
        <div className="text-center  text-lg border-l border-r flex-1">
          <span>{data?.getPublicProfile.totalFollowers}</span>
          <br />
          <span>Theo dõi</span>
        </div>
        <div className="text-center  text-lg flex-1">
          <span>{data?.getPublicProfile.totalPosts}</span>
          <br />
          <span>Bài viết</span>
        </div>
      </div>
      <hr className=".solid mt-3" />
    </div>
  );
};

export default HeaderProfile;
