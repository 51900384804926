import { useRef, useState } from 'react';
import { AiFillLock, AiFillStar, AiOutlineDelete } from 'react-icons/ai';
import {
  BsBookmark,
  BsDot,
  BsExclamationTriangle,
  BsPencil,
  BsPinAngle,
  BsPlusSquare,
  BsThreeDots,
  BsXLg,
} from 'react-icons/bs';
import { FaUserFriends } from 'react-icons/fa';
import { GiEarthAsiaOceania } from 'react-icons/gi';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { DiffDate, DiffDateToMinutes } from '../../Ultis/Datetime';
import { getUserLogin } from '../../Ultis/token';
import userImg from '../../assets/images/user.jpeg';
import {
  Post,
  PostSharingTo,
  PostStatus,
  PostType,
} from '../../graphql/generated';
import { maxText } from '../profile/Friends';
import TaggedUserModal from './TaggedUserModal';
import CreatePostModal from '../create-post/CreatePostModal';
import ErrorModal from '../modal/ErrorModal';
import {
  useDeletePost,
  useFollowUser,
  usePinPost,
  useRemovePinPost,
  useSavePost,
  useUnFollowUser,
} from '../../common/services/api';
import SelectOptionItem from '../selectOption/SelectOptionItem';
import { useOnClickOutside } from '../../Ultis/hooks/useClickOutside';
interface Props {
  post: Post;
  isSharedPost?: boolean;
  onPostCreated: (post: any) => void;
}

interface PropsOption {
  content: string;
  handleClick: () => void;
  icon: any;
  subText?: string;
}

const MAX_DISPLAY_FRIEND_TAGGED = 2;

const HeaderPost = (props: Props) => {
  const { post, isSharedPost, onPostCreated } = props;
  const [isFullContent, setIsFullContent] = useState(false);
  const [isVisibleMoreTaggedUser, setIsVisibleMoreTaggedUser] = useState(false);
  const [isShowOption, setIsShowOption] = useState(false);
  const [isShowError, setIsShowError] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const { postId } = useParams();
  const [isReset, setIsReset] = useState(false);

  const ref = useRef<any>();
  const navigate = useNavigate();
  const listTaggedUserModalRef = useRef<HTMLDialogElement>(null);
  const me = getUserLogin();
  const modalRef = useRef<HTMLDialogElement>(null);
  useOnClickOutside(ref, () => setIsShowOption(false));
  const { mutate: deletePostMutate } = useDeletePost();
  const { mutate: savePostMutate } = useSavePost();
  const { mutate: followUserMutate } = useFollowUser();
  const { mutate: unFollowUserMutate } = useUnFollowUser();
  const { mutate: pinPostMutate } = usePinPost();
  const { mutate: removePinPostMutate } = useRemovePinPost();

  const onReset = () => {
    setIsReset(!isReset);
  };

  const handleCloseModelError = () => {
    setIsShowError(false);
  };

  const handleClickCreatePost = (id: string) => {
    if (id) {
      setIsUpdate(true);
    }
    if (DiffDateToMinutes(post?.createdAt) <= 10) {
      modalRef?.current?.showModal();
      document.body.className = 'overflow-hidden';
      setIsShowOption(false);
    } else {
      setIsShowOption(false);
      setIsShowError(true);
    }
  };

  const handleClickMoreTaggedUser = () => {
    listTaggedUserModalRef?.current?.showModal();
    setIsVisibleMoreTaggedUser(true);
  };

  const renderScope = (post: Post) => {
    switch (post?.sharingTo) {
      case PostSharingTo.Public:
        return <GiEarthAsiaOceania className="ml-3" />;
      case PostSharingTo.Friends:
        return <FaUserFriends className="ml-3" />;
      case PostSharingTo.OnlyMe:
        return <AiFillLock className="ml-3" />;
    }
  };

  const handleClickOption = () => {
    setIsShowOption(!isShowOption);
  };

  const handleClickDeletePost = (id: string) => {
    deletePostMutate({ id: id });
    setIsShowOption(false);
  };

  const handleClickSavePost = (id: string) => {
    savePostMutate({ id: id });
  };

  const handleFollowUser = () => {
    if (!!post && !!post?.creator?._id) {
      if (post?.creator?.following) {
        unFollowUserMutate({ userId: post.creator._id });
      } else {
        followUserMutate({ userId: post.creator._id });
      }
    }
  };

  const handleClickPinPost = (id: string) => {
    pinPostMutate({ postId: id });
    if (!!post && !!post?._id) {
      if (!!post?.pinnedAt) {
        removePinPostMutate({});
      } else {
        pinPostMutate({ postId: id });
      }
    }
  };

  const optionForPost: PropsOption[] = [
    {
      content: 'Lưu bài viết',
      handleClick: () => handleClickSavePost(post?._id),
      icon: <BsBookmark className="text-2xl " />,
    },
    {
      content: `${post?.creator?.following ? 'Bỏ theo dõi' : 'Theo dõi'} `,
      handleClick: () => handleFollowUser(),
      icon: <BsBookmark className="text-2xl " />,
      subText: `${post?.creator?.fullName}`,
    },
    {
      content: 'Ẩn bài viết',
      handleClick: () => {},
      icon: <BsXLg className="text-2xl " />,
    },
    {
      content: 'Gửi phản hồi',
      handleClick: () => {},
      icon: <BsExclamationTriangle className="text-2xl " />,
    },
  ];

  const optionForMyPost: PropsOption[] = [
    {
      content: `${!!post?.pinnedAt ? 'Bỏ ghim bài viết' : 'Ghim bài viết'}`,
      handleClick: () => handleClickPinPost(post?._id),
      icon: <BsPinAngle className="text-2xl " />,
    },
    {
      content: `Lưu bài viết`,
      handleClick: () => handleClickSavePost(post?._id),
      icon: <BsBookmark className="text-2xl " />,
      subText: `${post?.creator?.fullName}`,
    },
    {
      content: 'Sửa bài viết',
      handleClick: () => handleClickCreatePost(post?._id),
      icon: <BsPencil className="text-2xl " />,
    },
    {
      content: 'Xóa',
      handleClick: () => handleClickDeletePost(post?._id),
      icon: <AiOutlineDelete className="text-2xl " />,
    },
  ];

  return (
    <>
      <div className="pb-4 pt-6 px-3 md:px-5">
        <div className="flex items-start justify-between gap-3">
          {post?.groupId ? (
            <Link to={`groups/${post?.group?._id}`}>
              <div className="w-[40px] h-[40px] border-[1px] border-solid border-gray-200 rounded-full">
                <div className="img-1-1 ">
                  <img src={post?.group?.cover?.uri || userImg} alt="" />
                </div>
              </div>
            </Link>
          ) : (
            <Link
              to={
                post?.page
                  ? '/page/' + post?.page?._id
                  : '/profile/' + post?.creator?._id
              }>
              <div
                style={{
                  backgroundImage: `url("${
                    post?.page?.logo?.uri ||
                    post?.creator?.avatar?.uri ||
                    userImg
                  }")`,
                }}
                className={`w-[40px] h-[40px] rounded-full profileImage ${
                  post.creator?.verified && !post?.page
                    ? 'shadow_verify'
                    : 'avartar-border_default'
                }`}
              />
            </Link>
          )}

          <div className="text-md md:text-lg flex-auto">
            <div>
              {post?.groupId ? (
                <>
                  <div onClick={() => navigate(`/groups/${post?.group?._id}`)}>
                    <b>{post?.group?.name}</b>
                  </div>
                  <div>
                    <span
                      className="text-base font-bold"
                      onClick={() =>
                        navigate(`/profile/${post?.creator?._id}`)
                      }>
                      {post?.creator?.fullName}
                    </span>
                    {!!post?.place &&
                      (post?.rating ? (
                        <>
                          <span className="px-1 text-base">đánh giá cho</span>
                          <span className="mr-1 text-base font-bold">
                            {post?.place.name}
                          </span>
                        </>
                      ) : (
                        <>
                          tại <b>{post?.place.name}</b>{' '}
                        </>
                      ))}
                    {post?.allTaggedUserIds &&
                      post?.allTaggedUserIds?.length > 0 && (
                        <>
                          {' với '}
                          {post?.allTaggedUsers?.map((user, idx) => {
                            if (idx > 2) return;
                            if (
                              idx === 1 ||
                              (post?.allTaggedUsers &&
                                !post?.allTaggedUsers[idx + 1])
                            ) {
                              return (
                                <Link
                                  key={user._id}
                                  to={`/profile/${user._id}`}
                                  className="font-medium">
                                  {user.fullName}
                                </Link>
                              );
                            }
                            return (
                              <Link
                                to={`/profile/${user._id}`}
                                className="font-medium">
                                {user.fullName + ', '}
                              </Link>
                            );
                          })}
                        </>
                      )}
                    {post?.allTaggedUserIds &&
                      post?.allTaggedUserIds?.length > 2 && (
                        <>
                          {' và '}
                          <span
                            className="font-medium cursor-pointer"
                            onClick={handleClickMoreTaggedUser}>
                            {(post?.allTaggedUserIds?.length ?? 0) -
                              MAX_DISPLAY_FRIEND_TAGGED +
                              ' ' +
                              'người khác'}
                          </span>
                        </>
                      )}
                  </div>
                </>
              ) : (
                <>
                  <Link
                    to={
                      post?.page
                        ? '/page/' + post?.page?._id
                        : '/profile/' + post?.creator?._id
                    }>
                    <b>{post?.page?.name || post?.creator?.fullName}</b>
                  </Link>{' '}
                  {!!post?.place &&
                    (post?.rating ? (
                      <>
                        đánh giá cho <b className="mr-1">{post?.place.name}</b>
                      </>
                    ) : (
                      <>
                        tại <b>{post?.place.name}</b>{' '}
                      </>
                    ))}
                  {post?.allTaggedUserIds &&
                    post?.allTaggedUserIds?.length > 0 && (
                      <>
                        {' với '}
                        {post?.allTaggedUsers?.map((user, idx) => {
                          if (idx > 2) return;
                          if (
                            idx === 1 ||
                            (post?.allTaggedUsers &&
                              !post?.allTaggedUsers[idx + 1])
                          ) {
                            return (
                              <Link
                                key={user._id}
                                to={`/profile/${user._id}`}
                                className="font-medium">
                                {user.fullName}
                              </Link>
                            );
                          }
                          return (
                            <Link
                              to={`/profile/${user._id}`}
                              className="font-medium">
                              {user.fullName + ', '}
                            </Link>
                          );
                        })}
                      </>
                    )}
                  {post?.allTaggedUserIds &&
                    post?.allTaggedUserIds?.length > 2 && (
                      <>
                        {' và '}
                        <span
                          className="font-medium cursor-pointer"
                          onClick={handleClickMoreTaggedUser}>
                          {(post?.allTaggedUserIds?.length ?? 0) -
                            MAX_DISPLAY_FRIEND_TAGGED +
                            ' ' +
                            'người khác'}
                        </span>
                      </>
                    )}
                </>
              )}
            </div>
            <div>
              {post?.creator?.badge && (
                <div className="flex">
                  <div className="w-6 h-6 overflow-hidden mr-2">
                    <img
                      src={post?.creator?.badge.media?.uri}
                      alt=""
                      width={48}
                      height={48}
                    />
                  </div>
                  {post?.creator?.badge.title}
                </div>
              )}
            </div>
            <div className="flex items-center text-[#8a8d91] text-xs md:text-base">
              {post.status === PostStatus.Pending ? (
                <span className="text-[#05A2AB]">Chờ kiểm duyệt</span>
              ) : post?.status === PostStatus.Verified ? (
                <span className="text-[#2BC105]">Đã duyệt</span>
              ) : post.status === PostStatus.NotVerified ? (
                <span className="text-[#E8002E]">Chờ kiểm chứng</span>
              ) : (
                <span className="text-[#E8002E]">Thông tin sai</span>
              )}{' '}
              <BsDot />
              <div
                className="cursor-pointer"
                onClick={() => navigate('/detail/' + post._id)}>
                {DiffDate(post?.createdAt)}
              </div>
              {/* Comment cho đến khi có sự thay đổi */}
              {/* <BsDot /> {renderScope(post)} */}
            </div>
          </div>
          <div className=" flex justify-end items-center relative">
            {!!post?.place && post?.rating && (
              <div className="mr-3 flex items-center md:text-lg bg-secondary-yellow rounded-2xl p-2 pt-0 pb-0 text-base text-primary-yellow">
                {post?.rating}{' '}
                <AiFillStar className="ml-1 md:text-lg text-base" />
              </div>
            )}
            <div className="relative inline-block text-left cursor-pointer">
              {!isSharedPost && (
                <div className="button-option-post" onClick={handleClickOption}>
                  <BsThreeDots className="text-2xl " />
                </div>
              )}

              {isShowOption && (
                <div
                  ref={ref}
                  className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="menu-button">
                  <div className="py-1" role="none">
                    {me?._id !== post?.createdBy ? (
                      <>
                        {optionForPost.map((item, index) => {
                          return (
                            <SelectOptionItem
                              content={item?.content}
                              handleClick={item?.handleClick}
                              icon={item?.icon}
                              subText={item?.subText}
                              key={index}
                            />
                          );
                        })}
                      </>
                    ) : (
                      <>
                        {optionForMyPost.map((item, index) => {
                          return (
                            <SelectOptionItem
                              content={item?.content}
                              handleClick={item?.handleClick}
                              icon={item?.icon}
                              key={index}
                            />
                          );
                        })}
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {!!post?.title && (
          <div className="mt-2">
            <span className="font-semibold ">{post?.title}</span>
          </div>
        )}
        {(post.status !== PostStatus.Incorrect || !!postId) && (
          <div
            className={`mt-2 text-lg  ${
              (post?.status === PostStatus.Pending ||
                post.status === PostStatus.NotVerified ||
                post.status === PostStatus.Incorrect) &&
              'opacity-60'
            }`}>
            {!isFullContent ? maxText(post?.content || '', 339) : post?.content}{' '}
            {!isFullContent && (post?.content || '').length >= 339 && (
              <button
                className={`border-0 bg-none`}
                onClick={() => setIsFullContent(true)}>
                <b className="hover:underline">Xem thêm</b>
              </button>
            )}
            {isFullContent && (post?.content || '').length >= 339 && (
              <button
                className={`border-0 bg-none`}
                onClick={() => setIsFullContent(false)}>
                <b className="hover:underline">Ẩn bớt nội dung</b>
              </button>
            )}
          </div>
        )}
      </div>
      <TaggedUserModal
        visible={isVisibleMoreTaggedUser}
        postId={post._id}
        ref={listTaggedUserModalRef}
      />
      <CreatePostModal
        isInMyPage={!!post?.page ? 'inPage' : ''}
        isUpdate={isUpdate}
        post={post}
        type={PostType.Status}
        user={me}
        ref={modalRef}
        onReset={onReset}
        onPostCreated={onPostCreated}
        key="false"
      />
      <ErrorModal
        isShow={isShowError}
        message="Bạn không thể chỉnh sửa bài viết sau 10 phút tính từ khi bạn tạo bài viết này"
        onClose={handleCloseModelError}
      />
    </>
  );
};

export default HeaderPost;
