import { ChangeEvent, useEffect, useState } from 'react';
import SelectCategorySvg from '../../../../assets/images/icon/select_category_page_bg.svg';
import SetNameBannerSvg from '../../../../assets/images/icon/setname_page_bg.svg';
import { PageInput, PageType } from '../../../../graphql/generated';
import SelectAvatar from './components/SelectAvatar';
import SelectCategory from './components/SelectCategory';
import SetName from './components/SetName';
import StepsLayout, {
  IPersonalPageMedia,
  Steps,
  TypeMediaFile,
} from './components/StepsLayout';
import TypePageCard from './components/TypePageCard';
import {
  useGetPersonalCategories,
  useMutationCreatePersonalPage,
} from './services/apis';
import { useUploadPhoto } from '../../../DetailPost/services/api';
import { useNavigate } from 'react-router-dom';
import HeaderExtra from '../../../../components/header/HeaderExtra';
import Toast, { ToastProps, ToastType } from '../../../../components/toast/Toast';

const _URL = window.URL || window.webkitURL;

export default function CreatePage() {
  const [step, setStep] = useState<Steps>(Steps.SelectType);
  const [isValidName, setIsValidName] = useState(true);
  const [personalPage, setPersonalPage] = useState<PageInput>({
    name: '',
    type: PageType.BusinessOrBrand,
  });
  const [mediaFile, setMediaFile] = useState<IPersonalPageMedia[]>();
  const { data: personalCategories } = useGetPersonalCategories();
  const { mutateAsync, isLoading: creatingPersonalPageLoading } =
    useMutationCreatePersonalPage();
  const { mutateAsync: uploadPhotoAsync } = useUploadPhoto();
  const navigate = useNavigate();
  const [toast, setToast] = useState<ToastProps>({ className: "fixed top-0 right-0", isHidden: true, type: ToastType.Success, message: "" })

  useEffect(() => {
    let resetToast: any
    if (!toast.isHidden) {
      resetToast = setTimeout(() => {
        setToast((preState) => {
          return { ...preState, isHidden: true }
        })
      }, 3000);
    }
    return () => {
      clearTimeout(resetToast)
    }
  }, [toast])

  const handleSelectPageType = (type: PageType) => {
    setPersonalPage((prevState) => {
      return { ...prevState, type };
    });
  };

  const handleChangeInputSetName = (e: ChangeEvent<HTMLInputElement>) => {
    setPersonalPage((prevState) => {
      return { ...prevState, name: e.target.value };
    });
  };

  const handleSelectCategory = (e: ChangeEvent<HTMLInputElement>) => {

    setPersonalPage((prevState) => {
      const categoryIds = prevState.categoryIds || [];
      const selectedCategory = e.target.value;
      const index = categoryIds.indexOf(selectedCategory);
      if (index === -1) {
        categoryIds.push(selectedCategory); // Add the item if it doesn't exist
      } else {
        categoryIds.splice(index, 1); // Remove the item if it exists
      }

      return { ...prevState, categoryIds };
    });
  };

  const getDimensionOfPhoto = async (file: File) => {
    return new Promise((rs, rj) => {
      const img = new Image();
      const objectUrl = _URL.createObjectURL(file);

      img.onload = () => {
        rs({
          width: img.width,
          height: img.height,
        });
      };
      img.onerror = (e) => {
        rj(e);
      };
      img.src = objectUrl;
    });
  };

  const uploadImage = async (file: File, type: TypeMediaFile) => {
    const imageDimensions: any = await getDimensionOfPhoto(file);
    const uploadPhoto = await uploadPhotoAsync({
      file,
      dimensions: {
        width: imageDimensions.width,
        height: imageDimensions.height,
      },
    });
    return { uploadPhoto, type };
  };

  const handleMedia = (file: FileList, type: TypeMediaFile) => {
    setMediaFile((preState) => {
      if (preState) {
        const mediaFiles = preState.filter((media) => {
          return media.type != type;
        });
        mediaFiles.push({ file: file, type: type });
        return [...mediaFiles];
      }
      return [{ file: file, type: type }];
    });
  };

  const handleSubmitCreatePersonalPage = () => {
    if (mediaFile && mediaFile?.length > 0) {
      Promise.all(
        mediaFile.map((media) => {
          return uploadImage(media.file[0], media.type);
        }),
      )
        .then((data) => {
          let result = personalPage;
          data.forEach((item) => {
            if (item.type == TypeMediaFile.Avatar) {
              result = {
                ...result,
                logoMediaId: item.uploadPhoto.uploadPhoto._id,
              };
            }
            if (item.type == TypeMediaFile.Cover) {
              result = {
                ...result,
                coverMediaId: item.uploadPhoto.uploadPhoto._id,
              };
            }
          });

          mutateAsync({ data: result })
            .then((data) => {
              navigate(`/page/${data.createPage?._id}`);
            })
            .catch((err) => {
              setToast({ ...toast, message: `Có lỗi xảy ra, tạo trang không thành công`, isHidden: false, type: ToastType.Success })
            });
        })
        .catch((err) => {
          setToast({ ...toast, message: `Có lỗi xảy ra, tạo trang không thành công`, isHidden: false, type: ToastType.Success })
        })
    } else {
      if (!personalPage.logoMediaId || personalPage.coverMediaId) {
        setToast({ ...toast, message: "Trang phải có ảnh bìa hoặc ảnh đại diện", isHidden: false, type: ToastType.Warning })
        return
      }
    }
  };

  const isValidPersonalPageName = (pageName: string): boolean => {
    if (pageName.trim() == '') {
      return false;
    }
    return true;
  };

  const handleChangeStep = (isStep: Steps) => {
    switch (step) {
      case Steps.SelectType:
        setStep(Steps.SetName);
        break;
      case Steps.SetName:
        if (!isValidPersonalPageName(personalPage.name)) {
          setIsValidName(false);
          return;
        }
        setStep(Steps.SelectCategory);
        break;
      case Steps.SelectCategory:
        if (!personalPage.categoryIds || personalPage.categoryIds.length <= 0) {
          setToast({ ...toast, message: "Chọn ít nhất một danh mục cho trang của bạn", isHidden: false, type: ToastType.Warning })
          return
        }
        setStep(Steps.SelectAvatar);
        break;
      case Steps.SelectAvatar:

        handleSubmitCreatePersonalPage();
        break;
      default:
        setStep(Steps.SelectType);
    }
  };

  return (
    <>
      <Toast message={toast.message} className={`${toast.className} absolute top-16`} type={toast.type} isHidden={toast.isHidden} />

      {step == Steps.SelectType && (
        <>
          {' '}
          <HeaderExtra title="Tạo trang" />{' '}
          <TypePageCard
            type={PageType.CommunityOrPublicFigure}
            title={'Cá nhân'}
            subTitle={
              'Kết nối và chia sẻ đến mọi người trong cộng đồng của bạn'
            }
            handleChangeStep={handleChangeStep}
            handleSelectPageType={() =>
              handleSelectPageType(PageType.CommunityOrPublicFigure)
            }
          />
          <TypePageCard
            type={PageType.BusinessOrBrand}
            title={'Doanh nghiệp'}
            subTitle={
              'Thể hiện sản phẩm, dịch vụ, thương hiệu đến khách hàng của bạn trên In2Spire'
            }
            handleChangeStep={handleChangeStep}
            handleSelectPageType={() =>
              handleSelectPageType(PageType.BusinessOrBrand)
            }
          />
        </>
      )}
      {step == Steps.SetName && (
        <>
          <StepsLayout
            bg={SetNameBannerSvg}
            child={
              <SetName
                isValid={isValidName}
                handleSetName={handleChangeInputSetName}
              />
            }
            handleChangeStep={handleChangeStep}
            step={Steps.SetName}
            title={'Đặt tên cho trang'}
          />{' '}
        </>
      )}
      {step == Steps.SelectCategory && (
        <>
          <StepsLayout
            bg={SelectCategorySvg}
            child={
              <SelectCategory
                categories={personalCategories}
                handleSelectCategory={handleSelectCategory}
              />
            }
            handleChangeStep={handleChangeStep}
            step={Steps.SelectCategory}
            title={'Chọn danh mục'}
          />
        </>
      )}
      {step == Steps.SelectAvatar && (
        <>
          <StepsLayout
            handleMedia={handleMedia}
            step={Steps.SelectAvatar}
            bg={SelectCategorySvg}
            child={
              <SelectAvatar
                name={personalPage.name}
                categories={personalCategories?.getPageCategories?.filter(
                  (category) => {
                    const existPick = personalPage.categoryIds?.includes(
                      category._id,
                    );
                    if (existPick) return true;
                    return false;
                  },
                )}
                handleMedia={handleMedia}
              />
            }
            handleChangeStep={handleChangeStep}
            disableButton={creatingPersonalPageLoading}
            title={'Upload ảnh'}
          />
        </>
      )}
    </>
  );
}
