import React, { FC } from 'react';
import { ButtonType, TextColorBtn, Padding } from './type';

interface BtnProps {
  onPress?: () => void;
  type?: ButtonType;
  textColor?: TextColorBtn;
  padding?: string;
  content?: string;
  margin?: string;
  size?: string;
  text_size?: string;
  hover?: string;
}

const ButtonComponent: FC<BtnProps> = ({
  content = 'Đăng nhập',
  type = 'bg-primary-yellow',
  textColor = 'text-white',
  size,
  padding = 'py-3',
  margin = 'mt-4',
  text_size,
  hover,
  onPress,
}) => {
  return (
    <div
      className={`${type} text-center font-medium ${size} rounded-md ${margin} ${padding} ${size} ${hover} cursor-pointer`}
      onClick={onPress}>
      <p className={`${textColor} ${text_size}`}>{content}</p>
    </div>
  );
};

export default ButtonComponent;
