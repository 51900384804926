import React, { FC, useEffect, useRef, useState } from 'react';
import { useSearchPage } from '../services/api';
import ListPageItem from './ListPageItem';
import Loading, { LoadingRef } from '../../../components/loading/Loading';
import InfiniteScroll from 'react-infinite-scroll-component';
import { SearchPagesQueryVariables } from '../../../graphql/generated';
import { TabItemSearchBy } from './SearchTabs';
import { MdSearchOff } from 'react-icons/md';

interface ListPageProps {
  keyword: string;
  searchBy?: TabItemSearchBy;
}

const ListPage: FC<ListPageProps> = ({ keyword, searchBy }) => {
  const [params, setParams] = useState<SearchPagesQueryVariables>({ q: keyword, limit: 20 });
  const [dataPage, setDataPage] = useState<any>([]);
  const { data, isLoading } = useSearchPage(params);
  const loadingRef = useRef<LoadingRef>(null);
  const refresh = () => { };

  const handleSearchPage = () => {
    try {
      const newData = params.after ? [...dataPage, ...(data?.searchPages || [])] : data?.searchPages;
      if (data && data.searchPages) {
        setDataPage(newData);
        setParams({ q: keyword, limit: 20 });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchMoreData = () => {
    setParams({ ...params, after: dataPage[dataPage.length - 1]?._id || '' });
  };

  useEffect(() => {
    handleSearchPage();
  }, [keyword, data?.searchPages?.length]);

  useEffect(() => {
    if (isLoading) {
      loadingRef.current?.open();
    } else {
      loadingRef.current?.close();
    }
  }, [isLoading]);

  return (
    <div>
      {keyword === '' && <div className="mt-10 font-semibold text-2xl">Đề xuất</div>}

      {searchBy === TabItemSearchBy.All && dataPage.length !== 0 && <div className="font-semibold text-2xl mt-4">Trang</div>}

      {searchBy === TabItemSearchBy.All ? (
        <></>
      ) : (
        <>
          {dataPage.length === 0 && keyword !== '' && (
            <center className="mt-20">
              <MdSearchOff size={'45px'} />
              <p className="mt-2">{`Không tìm thấy kết quả tìm kiếm cho từ khoá "${keyword}"`}</p>
            </center>
          )}
        </>
      )}

      <div className="fixed top-[15%] left-[47%] z-50">
        <Loading ref={loadingRef} />
      </div>
      <InfiniteScroll
        dataLength={dataPage?.length || 0}
        next={fetchMoreData}
        hasMore={true}
        loader={<></>}
        endMessage={<p style={{ textAlign: 'center' }}></p>}
        refreshFunction={refresh}>
        {dataPage?.map((item: any, index: number) => {
          return <ListPageItem key={index} item={item} />;
        })}
      </InfiniteScroll>
    </div>
  );
};

export default ListPage;
