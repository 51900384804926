import { FC } from 'react';
import { ProductType } from '../../../graphql/generated';

interface OnChangeInput {
  q?: string;
  filter?: ProductType[];
}
interface Props {
  q?: string;
  filter?: ProductType[];
  onChange: (input: OnChangeInput) => void;
}
const FilterProduct: FC<Props> = ({ q, filter, onChange }) => {
  const onChangeQ = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange({
      filter,
      q: e.target.value,
    });
  };
  const onChangeType = (e: React.ChangeEvent<HTMLSelectElement>) => {
    onChange({
      q,
      filter: e.target.value === 'null' ? [] : [e.target.value as ProductType],
    });
  };

  return (
    <>
      <div className="mt-4 mb-4 grid grid-cols-2 gap-x-6 gap-y-8 sm:grid-cols-6">
        <div className="sm:col-span-4">
          <div className="mt-2">
            <input
              placeholder="Tìm kiếm"
              onChange={onChangeQ}
              type="text"
              name="first-name"
              id="first-name"
              autoComplete="given-name"
              className="p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset  placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        <div className="sm:col-span-2 ">
          <div className="mt-2">
            <select
              onChange={onChangeType}
              className="p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset  placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              style={{ height: 36 }}>
              <option value="null">Tất cả</option>
              <option value={ProductType.Tour}>Tour</option>
              <option value={ProductType.Hotel}>Khách sạn</option>
            </select>
          </div>
        </div>
      </div>
    </>
  );
};

export default FilterProduct;
