import { FC, useEffect, useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import TagFriendList from './TagFriendList';
import { User } from '../../graphql/generated';

interface Props {
  query: string;
  hidden: boolean;
  defaultValue?: any | [];
  isCloseModal?: number;
  handleChangeFriendTaggeds: (users: UserForTag[]) => void;
}

interface UserForTag {
  __typename?: 'User';
  _id: string;
  fullName: string;
  username?: string;
  totalMutualFriends?: number | null;
  verified?: boolean | null;
  avatar?: { __typename?: 'Media'; uri: string } | null;
}

const TagFriendSelection: FC<Props> = ({
  query,
  hidden,
  defaultValue,
  isCloseModal,
  handleChangeFriendTaggeds,
}) => {
  const [userTaggeds, setUserTaggeds] = useState<UserForTag[] | []>([]);
  const [visibleList, setVisbleList] = useState(false);
  const [displayTagged, setDisplayTagged] = useState(userTaggeds.length !== 0);

  useEffect(() => {
    setDisplayTagged(userTaggeds.length !== 0);
  }, [userTaggeds.length]);

  const handleClickTagUser = (user: UserForTag) => {
    const newUserTagged = user && [...userTaggeds, user];
    setUserTaggeds(newUserTagged);
    handleChangeFriendTaggeds(newUserTagged);
  };

  const handleClickRemoveTagged = (e: any) => {
    const userId = e.currentTarget.dataset.id;
    setUserTaggeds([...userTaggeds.filter((user) => user._id !== userId)]);
    handleChangeFriendTaggeds([
      ...userTaggeds.filter((user) => user._id !== userId),
    ]);
  };

  useEffect(() => {
    if (!!defaultValue && defaultValue.length > 0) {
      setUserTaggeds(
        defaultValue.map((item: any) => {
          return {
            _id: item?._id,
            fullName: item?.fullName,
          };
        }),
      );
    }
  }, [isCloseModal]);

  useEffect(() => {
    setVisbleList(!hidden);
    return () => setVisbleList(false);
  }, [hidden]);

  return (
    <>
      <div id="scrollableFriendTagSelection" className="">
        <ul>
          {displayTagged && (
            <div className="">
              <div className="font-medium text-sm py-2 px-4 text-slate-400">
                <span>TAGGED</span>
              </div>
              <div className="rounded-md p-2 mx-2 max-h-[200px] overflow-auto shadow-md rounded-lg border-[1px] border-gray-400">
                <div className="p-1 flex gap-2 flex-wrap max-h-[200px]">
                  {userTaggeds.map((user) => {
                    return (
                      <div
                        className="p-[5px] bg-teal-100 text-primary-yellow flex rounded-md "
                        key={user?._id}>
                        <span className="text-md ">{user?.fullName}</span>
                        <div
                          onClick={handleClickRemoveTagged}
                          data-id={user._id}
                          className=" p-1 mt-[1px] cursor-pointer rounded-full hover:bg-teal-300">
                          <AiOutlineClose />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
          <div className="">
            {visibleList && (
              <TagFriendList
                query={query}
                userTaggeds={userTaggeds}
                handleClickTagUser={handleClickTagUser}
              />
            )}
          </div>
        </ul>
      </div>
    </>
  );
};

export default TagFriendSelection;
