import './index.scss';
interface Props {
  top?: number;
  right?: number;
  classes?: string;
}

const LoadingGlobal = ({ right, top, classes }: Props) => {
  return (
    <div
      className={`loading-container absolute z-[999999] top-[${top}%] right-[${right}%] ${classes}`}>
      <div className="loading-spinner"></div>
    </div>
  );
};

export default LoadingGlobal;
