import { GetPersonalPageCategoriesQuery } from '../../../../../graphql/generated';
import BagSvg from '../../../../../assets/images/icon/bag.svg';
import { ChangeEvent } from 'react';

export interface ISelectCategoryParams {
  categories?: GetPersonalPageCategoriesQuery;
  handleSelectCategory: (e: ChangeEvent<HTMLInputElement>) => void;
}

export default function SelectCategory({
  categories,
  handleSelectCategory,
}: ISelectCategoryParams) {
  return (
    <>
      <div className="text-center">
        <div className="my-3">
          <h1 className="text-xl font-semibold py-2">Danh mục</h1>
          <p>
            Chọn danh mục sẽ giúp trang của bạn hiển thị chính xác hơn trên kết
            quả tìm kiếm
          </p>
        </div>
        <ul className="list-none">
          {(categories?.getPageCategories || []).map((category, index) => {
            return (
              <>
                <li className="border-b py-2 px-3">
                  <div className="flex justify-between items-center">
                    <div className="flex justify-start items-center gap-3">
                      <img src={BagSvg} alt="icon" />
                      <label htmlFor={index.toString()}>
                        {category.name.VI_VN}
                      </label>
                    </div>
                    <input
                      type="checkbox"
                      name="category"
                      id={index.toString()}
                      value={category._id}
                      onChange={(e) => handleSelectCategory(e)}
                    />
                  </div>
                </li>
              </>
            );
          })}
        </ul>
      </div>
    </>
  );
}
