import { FC, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useParams } from 'react-router-dom';
import userDefaultImg from '../../assets/images/user.jpeg';
import { useGetMyFriends } from '../../common/services/api';
import {
  GetMyFriendsQuery,
  GetMyFriendsQueryVariables,
  SearchGroupMembersQueryVariables,
} from '../../graphql/generated';
import { useSearchGroupMembers } from '../../pages/Groups/services/api';
import LoadingExtra from '../loading/LoadingExtra';

interface Props {
  query: string;
  userTaggeds: UserForTag[];
  handleClickTagUser: (user: UserForTag) => void;
}

interface UserForTag {
  __typename?: 'User';
  _id: string;
  fullName: string;
  username?: string;
  totalMutualFriends?: number | null;
  verified?: boolean | null;
  avatar?: { __typename?: 'Media'; uri: string } | null;
}

const LIMIT_PAGE = 20;

const listUserTransformer = (
  users: GetMyFriendsQuery['getFriends'],
): UserForTag[] | [] => {
  return (
    users?.map((user) => {
      return {
        ...user,
        tagged: false,
      };
    }) ?? []
  );
};

const TagFriendList: FC<Props> = ({
  query,
  userTaggeds,
  handleClickTagUser,
}) => {
  const { groupId } = useParams();
  const [listMember, setListMember] = useState<any>();
  const [filter, setFilter] = useState<GetMyFriendsQueryVariables>({
    q: '',
    limit: LIMIT_PAGE,
  });
  const [filterMembers, setFilterMembers] =
    useState<SearchGroupMembersQueryVariables>({
      q: '',
      limit: LIMIT_PAGE,
      groupId: groupId || '',
      offset: 0,
    });
  const {
    status,
    isSuccess,
    data: listUserData,
    isLoading,
  } = useGetMyFriends(filter);

  const { data, isLoading: loadingMembers } =
    useSearchGroupMembers(filterMembers);

  useEffect(() => {
    if (!!data) {
      setListMember(
        data?.searchGroupMembers?.map((item) => {
          return item?.user;
        }),
      );
    }
  }, [data]);

  const [users, setUsers] = useState<UserForTag[] | []>([]);

  useEffect(() => {
    delete filter.after;
    const timeOutId = setTimeout(() => {
      if (groupId) {
        setFilterMembers({
          ...filterMembers,
          q: query,
        });
        query !== filterMembers.q && setUsers([]);
      } else {
        setFilter({
          ...filter,
          q: query,
        });
        query !== filter.q && setUsers([]);
      }
    }, 300);
    return () => {
      clearTimeout(timeOutId);
    };
  }, [query]);

  useEffect(() => {
    if (groupId) {
      setUsers([...users, ...listUserTransformer(listMember)]);
    } else {
      setUsers([...users, ...listUserTransformer(listUserData?.getFriends)]);
    }
  }, [listUserData]);

  const fetchData = () => {
    if (filter.q === query && users.length > 0) {
      setFilter({
        ...filter,
        after: users[users?.length - 1]._id,
      });
    }
  };

  const refresh = () => {};

  return (
    <>
      <InfiniteScroll
        className="!overflow-hidden py-2 "
        dataLength={users?.length ?? 0}
        next={fetchData}
        hasMore={true}
        loader={''}
        endMessage={''}
        scrollableTarget={'scrollableFriendTagSelection'}
        refreshFunction={refresh}>
        <div className="font-medium text-sm px-4 text-slate-400">
          <span>{query === '' ? 'SUGGESTIONS' : 'SEARCH'}</span>
        </div>
        {users.length > 0 &&
          users
            ?.filter(
              (user) => !userTaggeds.map((user) => user._id).includes(user._id),
            )
            .map((user, idx) => {
              return (
                <div
                  className="hover:bg-slate-200 rounded-lg mx-2"
                  onClick={(e) => {
                    const userId = e.currentTarget.dataset.id;
                    const user = users.find((user) => user._id === userId);
                    user && handleClickTagUser(user);
                  }}
                  role="button"
                  key={user._id}
                  data-id={user._id}>
                  <li>
                    <div className="flex h-[60px] p-2 flex-row ">
                      <div className="w-[14%] avatar-wrap flex rounded-full">
                        <img
                          className={`max-[400px]:h-[40px] max-[400px]:w-[40px] friend-avatar bg-cover self-center rounded-full ${
                            user.verified
                              ? 'avartar-shadow_verify'
                              : 'avartar-border_default'
                          }`}
                          src={user?.avatar?.uri ?? userDefaultImg}
                          height={45}
                          width={45}
                          alt={'avatar_' + user?.username}
                        />
                      </div>
                      <div
                        className={`max-[400px]:ml-3 flex flex-col place-content-center w-full`}>
                        <span className="max-[400px]:text-xs w-15 font-medium text-black line-clamp-1">
                          {user.fullName}
                        </span>
                      </div>
                    </div>
                  </li>
                </div>
              );
            })}
        {!!groupId ? (
          <>
            {!loadingMembers &&
              filterMembers.q !== '' &&
              data?.searchGroupMembers &&
              data?.searchGroupMembers.length === 0 && (
                <center className="my-2">
                  <p className="text-sm text-slate-400">{`Không thấy bạn bè`}</p>
                </center>
              )}
            {loadingMembers && (
              <center>
                <LoadingExtra className="" label="" />
              </center>
            )}
          </>
        ) : (
          <>
            {!isLoading &&
              filter.q !== '' &&
              listUserData?.getFriends &&
              listUserData?.getFriends.length === 0 && (
                <center className="my-2">
                  <p className="text-sm text-slate-400">{`Không thấy bạn bè`}</p>
                </center>
              )}
            {isLoading && (
              <center>
                <LoadingExtra className="" label="" />
              </center>
            )}
          </>
        )}
      </InfiniteScroll>
    </>
  );
};

export default TagFriendList;
