import { SearchLikedPageQueryVariables, useGetExplorePageListQuery, useGetMyPagesQuery, useSearchLikedPageQuery } from '../../../graphql/generated';
import { graphqlClientRequest } from '../../../graphql/services/graphql-client';
import { GetExplorePageListQueryVariables } from '../../../graphql/generated/index';

export const useGetMyPages = () => {
  const { data, isLoading } = useGetMyPagesQuery(graphqlClientRequest(true));
  return { data, isLoading };
};

export const useGetDiscoveryPages = (input: GetExplorePageListQueryVariables) => {
  const { data, isLoading } = useGetExplorePageListQuery(graphqlClientRequest(true), input);
  return { data, isLoading };
};

export const useGetLikedPages = (input: SearchLikedPageQueryVariables) => {
  const { data, isLoading } = useSearchLikedPageQuery(graphqlClientRequest(true), input);
  return { data, isLoading };
};
