import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  GetExplorePageListQueryVariables,
  GetMyJoinedGroupsQueryVariables,
} from '../../graphql/generated';
import MyFriendListMenuTab from '../FriendListMenu/FriendListMenuTab';
import { StateFetch } from '../Home/Home';
import CreateGroupItem from './components/CreateGroupItem';
import EmptyGroup from './components/EmptyGroup';
import Group from './components/Group';
import {
  useGetExploreGroupList,
  useGetMyGroups,
  useGetMyJoinedGroups,
} from './services/api';

interface Props {}

export enum GroupItemType {
  MyGroup = 'MyGroup',
  Discover = 'Discover',
  Joined = 'Joined',
}

const tabs = [
  {
    id: 1,
    name: 'Nhóm của bạn',
    type: GroupItemType.MyGroup,
  },
  {
    id: 2,
    name: 'Khám phá',
    type: GroupItemType.Discover,
  },
  {
    id: 3,
    name: 'Đã gia nhập',
    type: GroupItemType.Joined,
  },
];

export interface filterPage {
  discoveryPage: GetExplorePageListQueryVariables;
  joinedGroup: GetMyJoinedGroupsQueryVariables;
}

const Groups = (props: Props) => {
  const [type, setType] = useState(GroupItemType.MyGroup);
  const [filter, setFilter] = useState<filterPage>({
    discoveryPage: { limit: 20, offset: 0 },
    joinedGroup: { limit: 20, offset: 0 },
  });
  const [fetchDataType, setFetchDataType] = useState({
    discovery: StateFetch.GETTING_FIRST,
    liked: StateFetch.GETTING_FIRST,
  });
  const [discoveryData, setDiscoveryData] = useState<any>([]);
  const [joinedData, setJoinedData] = useState<any>([]);
  const { data, isLoading } = useGetMyGroups({
    limit: 99,
    offset: 0,
  });
  const { data: discovery } = useGetExploreGroupList(filter.discoveryPage);
  const { data: liked } = useGetMyJoinedGroups(filter.joinedGroup);

  useEffect(() => {
    if (fetchDataType.discovery === StateFetch.GETTING_FIRST) {
      setDiscoveryData(discovery?.getExploreGroupList);
    } else {
      setDiscoveryData(() => [
        ...discoveryData,
        ...(discovery?.getExploreGroupList || []),
      ]);
    }
  }, [discovery]);

  useEffect(() => {
    setJoinedData(liked?.getMyJoinedGroups);
    if (fetchDataType.liked === StateFetch.GETTING_FIRST) {
      setJoinedData(liked?.getMyJoinedGroups);
    } else {
      setJoinedData(() => [...joinedData, ...(liked?.getMyJoinedGroups || [])]);
    }
  }, [liked]);

  const fetchData = () => {
    switch (type) {
      case GroupItemType.Discover:
        setFilter({
          ...filter,
          discoveryPage: { ...filter.discoveryPage, offset: 20 },
        });
        setFetchDataType({
          ...fetchDataType,
          discovery: StateFetch.GETTING_MORE,
        });
        break;
      case GroupItemType.Joined:
        setFilter({
          ...filter,
          joinedGroup: { ...filter.joinedGroup, offset: 20 },
        });
        setFetchDataType({ ...fetchDataType, liked: StateFetch.GETTING_MORE });
        break;
      default:
        break;
    }
  };

  const refresh = () => {};

  return (
    <div>
      <MyFriendListMenuTab
        tabs={tabs.map((item, idx) => (
          <li key={item.id} className={`mr-2 flex-auto`}>
            <a
              onClick={(e) => {
                e.preventDefault();
                setType(item.type);
              }}
              href="#"
              className={`${
                item.type === GroupItemType.MyGroup && 'relative'
              } ${
                item.type === type
                  ? 'inline-block p-4 primaryColor border-b-2 border-primary-color rounded-t-lg active dark:text-blue-500 dark:border-blue-500'
                  : 'inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'
              }`}>
              {item.name}
            </a>
          </li>
        ))}
      />
      <InfiniteScroll
        className={`mb-5 mt-5 grid ${
          type !== GroupItemType.MyGroup &&
          'min-[375px]:grid-cols-1 sm:grid-cols-2'
        } min-[375px]:gap-5 sm:gap-2`}
        dataLength={
          type === GroupItemType.MyGroup
            ? data?.getMyGroups?.length || 0
            : type === GroupItemType.Discover
            ? discovery?.getExploreGroupList?.length || 0
            : liked?.getMyJoinedGroups?.length || 0
        }
        next={fetchData}
        hasMore={type === GroupItemType.MyGroup ? false : true}
        loader={<h4></h4>}
        endMessage={<p className="mt-5" style={{ textAlign: 'center' }}></p>}
        refreshFunction={refresh}>
        {type === GroupItemType.MyGroup &&
          (data?.getMyGroups && data?.getMyGroups.length > 0 ? (
            <>
              {data?.getMyGroups?.map((friend, idx) => {
                return (
                  <Group
                    key={friend._id + idx}
                    group={friend}
                    type={type}
                    isDisplayAction={true}
                    isDisplayBothBtn={false}
                  />
                );
              })}{' '}
              <CreateGroupItem />
            </>
          ) : (
            <EmptyGroup />
          ))}
        {type === GroupItemType.Discover &&
          discoveryData?.map((group: any, idx: number) => {
            return (
              <Group
                key={group._id + idx}
                group={group}
                type={type}
                isDisplayTimeRequest={true}
              />
            );
          })}
        {type === GroupItemType.Joined &&
          joinedData?.map((group: any, idx: number) => {
            return (
              <Group
                key={group._id + idx}
                group={group}
                type={type}
                isDisplayTimeRequest={true}
              />
            );
          })}
      </InfiniteScroll>
    </div>
  );
};

export default Groups;
