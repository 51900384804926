import axios from 'axios';
import { getAuthToken } from './token';

export const uploadVideoApi = async (file: File) => {
  const formData = new FormData();
  formData.append('file', file);
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      'x-access-token': `${getAuthToken()}`,
    },
  };
  const response = await axios.post('https://media.social.viziple.com/api/v1/upload', formData, config);
  return response;
};
