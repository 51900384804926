import { ChangeEvent } from 'react';
import { images } from '../../assets/images';

export interface IEditModal {
  content: string;
  isVisible: boolean;
  onCancel?: () => void;
  onOk?: () => void;
  handleChangeInput?: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const EditModal = ({
  content,
  isVisible,
  onOk,
  onCancel,
  handleChangeInput,
}: IEditModal) => {
  return (
    <>
      <div
        className={`fixed inset-0 flex items-center justify-center z-50 drop-shadow-lg ${
          !isVisible && 'hidden'
        }`}>
        <div className="bg-white 2xl:w-96 xl:w-96 lg:w-96 md:w-64 sm:w-52  rounded-lg p-6">
          <h3 className="text-center text-lg font-medium pb-3">Sửa tên nhóm</h3>
          <div>
            <input
              className="w-full p-3 border rounded outline-none"
              value={content}
              type="text"
              placeholder="Nhập tên nhóm"
              onChange={handleChangeInput}
            />
          </div>
          <div className="mt-4 flex justify-end">
            <button
              className="px-4 py-2 bg-[#05A2AB] text-white rounded-md mr-2"
              onClick={onOk}>
              Xác nhận
            </button>
            <button
              className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md"
              onClick={onCancel}>
              Hủy bỏ
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
