import { Link } from 'react-router-dom';
import TwoGirlsTravel from '../../../assets/images/icon/two_girls_travel.svg';

export default function EmptyGroup() {
  return (
    <>
      <div className="relative text-center w-full">
        <div className="mt-24 px-12">
          <div className="flex justify-center w-full mb-6">
            <img src={TwoGirlsTravel} alt="two girls travel" />
          </div>
          <h1 className="text-xl text-[#111111] mb-2">
            Kết nối với tập người dùng của bạn trên In2Spire
          </h1>
          <p className="text-sm text-[#444444]">
            Tạo nhóm cho công ty, tổ chức dễ dàng chỉ với vài bước
          </p>
        </div>

        <div className="fixed bottom-0 text-center px-6 py-3">
          <p className="mb-3.5">
            Bằng cách nhấp vào Tạo nhóm, bạn đồng ý với{' '}
            <Link className="text-[#05A2AB]" to="">
              Điều khoản, Chính sách dữ liệu
            </Link>{' '}
            của In2Spire
          </p>
          <Link
            className="block w-full py-3 rounded text-white bg-[#05A2AB]"
            to={'/groups/create'}>
            Tạo nhóm
          </Link>
        </div>
      </div>
    </>
  );
}
