import { FC } from 'react';
import { AiFillCheckCircle, AiFillWarning } from 'react-icons/ai';
import { MdError } from 'react-icons/md';

interface Props {
  isSuccess?: boolean;
  isShow: boolean;
  message: string;
  onClose: () => void;
}
const NotifyModal: FC<Props> = ({ message, onClose, isShow, isSuccess }) => {
  return (
    <div
      className={`${
        isShow ? '' : 'hidden'
      } fixed top-0 bg-[#00000075] left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full justify-center items-center flex`}>
      <div className="relative w-full max-w-md max-h-full">
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
          {/* Modal body */}

          <div className="p-6 space-y-6 text-center ">
            <div className="w-full flex justify-center">
              {isSuccess ? (
                <AiFillCheckCircle size={60} className="text-green-500" />
              ) : (
                <MdError size={60} className="text-red-500" />
              )}
            </div>

            <p className="text-base leading-relaxed">{message}</p>
            <button
              onClick={onClose}
              type="button"
              className="text-white min-w-[100px] bg-primary-yellow focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
              Đồng ý
            </button>
          </div>
          {/* Modal footer */}
        </div>
      </div>
    </div>
  );
};

export default NotifyModal;
