import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { images } from '../assets/images';
import logo from '../assets/images/logo-header.svg';
import { useGetChatQuery } from '../graphql/generated';
import { graphqlClientRequest } from '../graphql/services/graphql-client';
import { StateFetch } from '../pages/Home/Home';
import { usePosts } from '../pages/Home/hooks/usePosts';
import { useGetNotificationUnRead } from '../pages/Home/services/api';
import './index.scss';

interface Props {}
const active = 'border-solid border-primary-color border-b-[2px]';
const inActive = 'opacity-80';

const MenuComponents = (props: Props) => {
  const navigate = useNavigate();
  const { data: listChat } = useGetChatQuery(graphqlClientRequest(true), {
    limit: 100,
    after: undefined,
    forward: undefined,
  });

  const { pathname } = useLocation();
  const url = pathname.split('/')[1].toLocaleLowerCase();

  const { data } = useGetNotificationUnRead({});
  const { setFilter, refetchData: refetch, setStateFetch } = usePosts();

  const refetchData = () => {
    setFilter({ limit: 5, offset: 0 });
    setStateFetch(StateFetch.GETTING_FIRST);
    refetch();
  };

  const handelRefetchData = () => {
    if (window.location.pathname === '/Home') {
      refetchData();
    }
  };

  return (
    <div className="bg-white py-2 flex justify-between px-4 header-menu">
      <button
        className="border-0 bg-none w-[300px] hidden-on-screen-tablet-logo"
        onClick={() => {
          navigate('/home');
        }}>
        <img src={logo} alt="logo" className=" h-[44px]" />
      </button>
      <div className="container">
        <div className="flex items-center justify-around border-0">
          <button
            className="border-0 bg-none  hidden-on-screen-mobile-logo pr-8 pl-6"
            onClick={() => refetchData()}>
            <img src={logo} alt="logo" className=" h-[44px]" />
          </button>
          <Link
            to={'/Home'}
            className={`p-1 w-1/5 box-border  pb-2 flex justify-center  text-2xl ${
              url === 'home' ? active : inActive
            }`}
            onClick={handelRefetchData}>
            <img
              src={url === 'home' ? images.IconHomeActive : images.IconHome}
              alt=""
            />
          </Link>
          <Link
            to={'/messenger'}
            className={`p-1 w-1/5 box-border pb-2 flex justify-center relative  text-2xl ${
              url === 'messenger' ? active : inActive
            }`}>
            <img
              src={
                url === 'messenger'
                  ? images.IconMessageActive
                  : images.IconMessage
              }
              alt=""
            />
            {listChat?.getConversations.filter((item) => !item.seen).length !==
            0 ? (
              <div className="bg-red-600 w-4 h-4 rounded-full absolute left-1/2">
                <p className="text-white text-xs text-center">
                  {
                    listChat?.getConversations?.filter(
                      (item: any) => !item.seen,
                    ).length
                  }
                </p>
              </div>
            ) : (
              ''
            )}
          </Link>
          <Link
            to={'/Notification'}
            className={`relative p-1 w-1/5 box-border pb-2 flex justify-center  text-2xl ${
              url === 'notification' ? active : inActive
            }`}>
            <img
              src={
                url === 'notification'
                  ? images.IconNotifyActive
                  : images.IconNotify
              }
              alt=""
            />
            {data?.getMyTotalUnreadNotifications !== 0 && (
              <span className="bg-red-600 w-4 h-4 rounded-full absolute left-1/2">
                <p className="text-white text-xs text-center">
                  {data?.getMyTotalUnreadNotifications}
                </p>
              </span>
            )}
          </Link>
          <Link
            to={'/video'}
            className={`p-1 w-1/5 box-border flex justify-center pb-2 text-2xl ${
              url === 'video' ? active : inActive
            }`}>
            <img
              src={url === 'video' ? images.IconMediaActive : images.IconMedia}
              alt=""
            />
          </Link>
          <Link
            to={'/menu'}
            className={`p-1 w-1/5 box-border flex justify-center pb-2 text-2xl ${
              url === 'menu' ? active : inActive
            }`}>
            <img
              src={url === 'menu' ? images.IconUserActive : images.IconUser}
              alt=""
            />
          </Link>
        </div>
      </div>
      <div className="flex items-center justify-end w-[300px] hidden-on-screen-tablet">
        <Link to={'/search'}>
          <div className="relative ">
            <input
              type="text"
              disabled
              placeholder="Tìm kiếm"
              className="h-[40px] rounded-[20px] pl-[35px] border-[1px] border-solid border-slate-400 w-[300px]"
            />
            <FontAwesomeIcon
              icon={faMagnifyingGlass}
              color="#bdbebe"
              className="text-xl absolute top-[10px] left-[10px]"
            />
          </div>
        </Link>
      </div>
    </div>
  );
};

export default MenuComponents;
