import {
  forwardRef,
  Ref,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { GoSearch } from 'react-icons/go';
import {
  useAddUserToGroupConversation,
  useGetListFriendNotInChatGroup,
} from '../../pages/Groups/services/api';
import { useOnClickOutside } from '../../Ultis/hooks/useClickOutside';
import Toast, { ToastProps, ToastType } from '../toast/Toast';

export interface ModalListFriendRef {
  open: () => void;
  close: () => void;
}

export interface PropsOption {
  content: string;
  handleClick: () => void;
  icon: any;
  subText?: string;
}

export interface Props {
  id: string;
}
const ModalListFriend = (props: Props, ref: Ref<ModalListFriendRef>) => {
  const { id } = props;
  const [visible, setVisible] = useState(false);
  const [keySearch, setKeySearch] = useState<string>();
  const [checkedIds, setCheckedIds] = useState<any[]>([]);
  const [toast, setToast] = useState<ToastProps>({
    className: 'fixed top-0 right-0',
    isHidden: true,
    type: ToastType.Success,
    message: '',
  });

  useEffect(() => {
    let resetToast: any;
    if (!toast.isHidden) {
      resetToast = setTimeout(() => {
        setToast((preState) => {
          return { ...preState, isHidden: true };
        });
      }, 3000);
    }
    return () => {
      clearTimeout(resetToast);
    };
  }, [toast]);

  const { data: listFriend } = useGetListFriendNotInChatGroup({
    id: id,
    limit: 999,
    q: keySearch,
  });

  const { mutateAsync } = useAddUserToGroupConversation();

  const refModal = useRef<any>();
  useOnClickOutside(refModal, () => setVisible(false));

  const openModal = () => {
    setVisible(!visible);
  };

  const closeModal = () => {
    setKeySearch('');
    setVisible(false);
  };

  const handleOk = () => {
    if (checkedIds.length > 0) {
      mutateAsync(
        {
          id: id,
          userIds: checkedIds,
        },
        {
          onSuccess() {
            setVisible(false);
            setCheckedIds([]);
            setToast({
              ...toast,
              message: `Thêm thành viên thành công`,
              isHidden: false,
              type: ToastType.Success,
            });
          },
          onError() {
            setCheckedIds([]);
            setToast({
              ...toast,
              message: `Đã xảy ra lỗi, vui lòng thử lại`,
              isHidden: false,
              type: ToastType.Danger,
            });
          },
        },
      );
    }
  };

  useImperativeHandle(ref, () => ({
    open: () => openModal(),
    close: () => closeModal(),
  }));

  const handleChangeInput = (e: any) => {
    setKeySearch(e.target.value);
  };

  const handleCheckedItem = (event: any, item: any) => {
    if (event.target.checked) {
      if (checkedIds.length > 0 && checkedIds.includes(item)) {
        setCheckedIds(
          checkedIds.filter((itemChecked: string) => itemChecked !== item),
        );
      } else {
        setCheckedIds([...checkedIds, item]);
      }
    } else {
      setCheckedIds(
        checkedIds.filter((itemChecked: string) => itemChecked !== item),
      );
    }
  };

  return (
    <>
      <Toast
        message={toast.message}
        className={`${toast.className} absolute top-1`}
        type={toast.type}
        isHidden={toast.isHidden}
      />
      {visible && (
        <div
          className="overlay flex justify-center items-center"
          ref={refModal}>
          <div className="absolute   modal_show_member bg-white">
            <div className="flex justify-center py-3 bg-primary-yellow header_modal relative">
              <h3 className="text-white">Danh sách bạn bè chưa vào nhóm</h3>
            </div>
            <div className="p-3">
              <div className="relative pb-3">
                <input
                  type="text"
                  placeholder="Tìm kiếm bạn bè..."
                  onChange={handleChangeInput}
                  className="border-[1px] border-solid border-gray-200 w-full rounded-[8px] h-[35px] pr-[30px] pl-[10px]"
                />
                <GoSearch className="absolute right-[10px] top-[10px] text-gray-300" />
              </div>
              <div className="max-h-[150px] overflow-auto">
                {(listFriend?.listFriendNotInChatGroup || []).map((item) => (
                  <div key={item?._id} className="flex items-center gap-2 ">
                    <input
                      type="checkbox"
                      className="cursor-pointer"
                      id={item?._id}
                      onChange={(event: any) =>
                        handleCheckedItem(event, item?._id)
                      }
                    />
                    <label className="m-0 cursor-pointer " htmlFor={item?._id}>
                      <span className="hover:text-primary-yellow">
                        {item.fullName}
                      </span>
                    </label>
                  </div>
                ))}
                {!((listFriend?.listFriendNotInChatGroup || []).length > 0) && (
                  <h3 className="text-center">
                    Không có bạn bè nào chưa vào nhóm
                  </h3>
                )}
              </div>

              <div className="mt-4 flex justify-end">
                {(listFriend?.listFriendNotInChatGroup || []).length > 0 && (
                  <button
                    disabled={!(checkedIds.length > 0)}
                    className={`px-4 py-2 ${
                      checkedIds.length > 0
                        ? 'bg-[#05A2AB]'
                        : 'bg-gray-300 text-gray-800 opacity-50 cursor-not-allowed'
                    }  text-white rounded-md mr-2`}
                    onClick={handleOk}>
                    Thêm
                  </button>
                )}

                <button
                  className="px-4 py-2 bg-gray-300 text-gray-800 rounded-md"
                  onClick={closeModal}>
                  Hủy bỏ
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default forwardRef(ModalListFriend);
