import { useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate, useParams } from 'react-router-dom';
import { getUserLogin } from '../../Ultis/token';
import CreatePost from '../../components/create-post/CreatePost';
import Loading, { LoadingRef } from '../../components/loading/Loading';
import PostItem from '../../components/posts/Post';
import Friends from '../../components/profile/Friends';
import HeaderProfile from '../../components/profile/HeaderProfile';
import InformationProfile from '../../components/profile/InformationProfile';
import ListImage from '../../components/profile/ListImage';
import NotificationAddInfor from '../../components/profile/NotificationAddInfor';
import { queryClient } from '../../index';
import { useGetPostUser } from '../Home/services/api';
import {
  useGetFriends,
  useGetMedia,
  useGetOtherFriends,
  useGetUser,
} from './services/api';
import { images } from '../../assets/images';

interface Props {}

const Profile = (props: Props) => {
  const user = getUserLogin();
  const { id } = useParams();
  const idUser = id || user._id;
  const [filter, setFilter] = useState({ limit: 5 });
  const [posts, setPosts] = useState<any[]>([]);
  const [next, setNext] = useState(true);
  const { data, isLoading } = useGetUser(idUser);
  const { data: friends } = useGetFriends({ limit: 5 });
  const { data: media } = useGetMedia({ id: idUser, limit: 5 });
  const navigate = useNavigate();
  const {
    data: post,
    refetch,
    isSuccess,
  } = useGetPostUser({ ...filter, uid: idUser });
  const { data: otherFriends } = useGetOtherFriends({
    limit: 5,
    uid: id || '',
  });
  const loadingRef = useRef<LoadingRef>(null);

  useEffect(() => {
    if (post?.getUserTimelinePosts) {
      setPosts(post?.getUserTimelinePosts || []);
    }
  }, [post?.getUserTimelinePosts, post?.getUserTimelinePosts?.length]);

  useEffect(() => {
    queryClient.invalidateQueries(['getUserTimelinePost']);
    window.scrollTo(0, 0);
  }, [id]);

  const fetchData = () => {
    setFilter({ ...filter, limit: 999 });
    setNext(false);
  };

  useEffect(() => {
    if (isLoading) {
      loadingRef.current?.open();
    } else {
      loadingRef.current?.close();
    }
  }, [isLoading]);

  const isMyProfile = user._id === idUser;

  const handleOnPostCreated = (post: any) => {
    refetch();
    setPosts([post, ...posts]);
  };

  const refresh = () => {};

  return (
    <div className="profile-page">
      <div className="fixed top-[15%] left-[47%] z-50">
        <Loading ref={loadingRef} />
      </div>
      <div
        className=" cursor-pointer absolute left-4 top-4  bg-slate-950 flex items-center justify-center rounded-full p-3 opacity-50"
        onClick={() => navigate(-1)}>
        <img src={images.LeftWhite} className="w-[20px] h-[20px]" />
      </div>
      <div
        className=" cursor-pointer absolute right-4 top-4  bg-slate-950 flex items-center justify-center rounded-full p-3 opacity-50"
        onClick={() => {}}>
        <img src={images.DotDotDot} className="w-[20px] h-[20px]" />
      </div>
      <div className="bg-white p-2 rounded-b-[8px]">
        <HeaderProfile data={data} />
      </div>
      <div className="section-content">
        <div className="content-left ">
          {!id && <NotificationAddInfor />}

          {!!data?.getPublicProfile.informationListing?.length && (
            <div className="bg-white p-2 rounded-lg mt-2">
              <InformationProfile data={data} />
            </div>
          )}

          <div className="bg-white p-2 rounded-lg mt-2">
            <Friends
              data={data}
              friends={id ? otherFriends?.getUserFriends : friends?.getFriends}
            />
          </div>
          <div className="bg-white p-2 rounded-lg mt-2">
            <ListImage data={data} media={media} />
          </div>
        </div>
        <div className="content-right w-full">
          {isMyProfile && (
            <div className="mt-2">
              <CreatePost onPostCreated={handleOnPostCreated} />
            </div>
          )}
          <InfiniteScroll
            dataLength={posts?.length || 0} //This is important field to render the next data
            next={fetchData}
            hasMore={next}
            loader={<h4>Loading...</h4>}
            endMessage={<p style={{ textAlign: 'center' }}></p>}
            // below props only if you need pull down functionality
            refreshFunction={refresh}
            pullDownToRefresh
            pullDownToRefreshThreshold={50}>
            {posts?.map((item) => (
              <div className="mb-4" key={item._id}>
                <PostItem post={item} onPostCreated={handleOnPostCreated} />
              </div>
            ))}
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
};

export default Profile;
