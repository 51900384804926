import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import userImg from '../../../assets/images/user.jpeg';
import Avatar from '../../../components/avatar/Avatar';
import ButtonGray from '../../../components/button/ButtonGray';
import ButtonOrange from '../../../components/button/ButtonOrange';
import { Group } from '../../../graphql/generated';
import { useRequestToJoinToGroup } from '../../Groups/services/api';
import './index.scss';

interface ListGroupItemProps {
  item: Group;
}

const ListGroupItem: FC<ListGroupItemProps> = ({ item }) => {
  const navigate = useNavigate();
  const { mutateAsync } = useRequestToJoinToGroup();

  const onPressPage = () => {
    navigate('/groups/' + item._id);
  };

  const handleSendRequest = () => {
    mutateAsync(
      {
        id: item._id,
      },
      {
        onSuccess() {},
      },
    );
  };

  return (
    <>
      <div
        className="flex items-center mt-5 hover:bg-white pr-5 list-group-item"
        onClick={onPressPage}>
        {/* avatar */}
        <div className="mr-5 ml-5 min-w-[70px] min-h-[70px]">
          <Avatar
            src={item?.cover?.uri || userImg}
            width={80}
            height={80}
            className="rounded-full !h-[70px]"
          />
        </div>
        <div className="w-full ">
          <div className="flex justify-between items-center group-item">
            <div className="group-item-left">
              <p className="font-semibold text-lg sm:max-w-md min-w-min">
                {item.name}
              </p>
              <div className="flex">
                <p className="mr-1">{item.totalMembers}</p>
                <p>thành viên</p>
              </div>
            </div>
            <div className="group-item-right">
              {!item.joined ? (
                <ButtonOrange
                  text="Tham gia"
                  className="w-[120px] justify-center "
                  onClick={handleSendRequest}
                />
              ) : (
                <ButtonGray
                  text="Truy cập"
                  className="w-[120px] justify-center "
                  onClick={onPressPage}
                />
              )}
            </div>
          </div>

          <hr className="w-full mt-4" />
        </div>
      </div>
    </>
  );
};

export default ListGroupItem;
