import { useEffect, useState } from 'react';
import { AiFillStar, AiOutlineDelete } from 'react-icons/ai';
import { BsPencil, BsThreeDots } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import StarRatingComponent from 'react-star-rating-component';
import { DiffDate } from '../../Ultis/Datetime';
import { getUserLogin } from '../../Ultis/token';
import userImg from '../../assets/images/user.jpeg';
import { GetPageQuery, GetPageReviewsQuery } from '../../graphql/generated';
import {
  useDeletePageReview,
  useUpdatePageReview,
} from '../../pages/Page/services/api';
import ButtonGray from '../button/ButtonGray';
import ButtonSecondary from '../button/ButtonSecondary';
import NotifyModal from '../modal/NotifyModal';
import VotePageModal from '../modal/VotePageModal';

interface Props {
  reviewData: GetPageReviewsQuery | undefined;
  data: GetPageQuery | undefined;
  pageId: string;
  onVotePage: (id: string) => void;
}

const Review = (props: Props) => {
  const { reviewData, data, pageId, onVotePage } = props;

  const [isShowOption, setIsShowOption] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [voteContext, setVoteContext] = useState('');
  const [starVote, setStarVote] = useState(0);
  const [isShowModalNotify, setIsShowModalNotify] = useState(false);
  const [currentItem, setCurrentItem] = useState<any>();
  const [messNotify, setMessNotify] = useState<any>();
  const [isShowSuggestReview, setIsShowSuggestReview] = useState(false);

  const me = getUserLogin();
  const { mutateAsync: updateReviewPost } = useUpdatePageReview();
  const { mutate: deletePostMutate } = useDeletePageReview();

  const handleClickOption = (item: any) => {
    setIsShowOption(!isShowOption);
    setCurrentItem(item);
  };

  const handleVoteContentChange = (e: any) => {
    setVoteContext(e.target.value);
  };

  const onchangeStar = (nextValue: number, prevValue: number, name: string) => {
    setStarVote(nextValue);
  };

  const handleClickCancel = () => {
    setStarVote(0);
    setVoteContext('');
    setIsUpdate(false);
  };

  const handleClickCreatePost = (post: any) => {
    if (post?._id) {
      setIsUpdate(true);
    }
    setIsShowOption(false);
    setVoteContext(post?.content);
    setStarVote(post?.rating);
  };

  const handleClickDeletePost = (id: string) => {
    deletePostMutate({ _id: id });
    setIsShowOption(false);
  };

  const handleCloseModalNotify = () => {
    setIsShowModalNotify(false);
  };

  const updatePostReview = async () => {
    updateReviewPost(
      {
        data: {
          content: voteContext,
          rating: starVote,
          pageId: currentItem?.page?._id,
        },
        _id: currentItem?._id,
      },
      {
        onSuccess(data, variables, context) {
          setIsUpdate(false);
          setIsShowModalNotify(true);
          setMessNotify(true);
        },
        onError(error, variables, context) {
          setIsUpdate(false);
          setIsShowModalNotify(true);
          setMessNotify(false);
        },
      },
    );
  };

  useEffect(() => {
    if (data?.getPage?.wroteReview) {
      setIsShowSuggestReview(false);
    } else {
      setIsShowSuggestReview(true);
    }
  }, [data?.getPage?.wroteReview]);

  const handleCloseSuggestReview = () => {
    setIsShowSuggestReview(false);
  };
  return (
    <div className="p-2">
      <div className="flex justify-between">
        <b className="text-2xl">Đánh giá</b>
        <Link to={`/page/${pageId}/reviews`}>
          <div className="text-primary-yellow">Xem tất cả</div>
        </Link>
      </div>
      <div className="flex items-center">
        <div className="flex items-center rounded-full bg-amber-500 w-fit py-2 px-3  pt-1 pb-1 text-white mt-2 gap-1">
          {(data?.getPage?.rating || 0).toFixed(1)} <AiFillStar />
        </div>
        {!!data?.getPage?.totalReviews && data?.getPage?.totalReviews > 0 ? (
          <div className="mt-2 ml-2 text-lg">
            {data?.getPage?.totalReviews} người đánh giá cho{' '}
            <b>{data?.getPage?.name}</b>
          </div>
        ) : (
          <div className="mt-2 ml-2 text-lg">
            Chưa có người đánh giá cho <b>{data?.getPage?.name}</b>
          </div>
        )}
      </div>
      <hr className=".solid mt-4" />
      {isShowSuggestReview && (
        <div>
          <div className="flex justify-center mt-4">
            <div className="text-lg">
              Bạn có muốn đánh giá cho {data?.getPage?.name} ?
            </div>
          </div>
          <div className="w-fit flex justify-center m-auto mt-2">
            <div
              className="mr-3"
              onClick={() => onVotePage(data?.getPage?._id || '')}>
              <ButtonSecondary text="Đánh giá" className="mr-3" />
            </div>
            <div onClick={handleCloseSuggestReview}>
              <ButtonGray text="Không" />
            </div>
          </div>
          <hr className=".solid mt-4" />
        </div>
      )}
      {reviewData?.getPageReviews?.map((item) => (
        <div className="mt-3 relative">
          <div className="flex">
            <div
              style={{
                backgroundImage: `url("${
                  item.reviewer?.avatar?.uri || userImg
                }")`,
              }}
              className="w-[48px] h-[48px] rounded-full profileImage border"
            />
            <div className="ml-4 md:w-[82%] w-1/2">
              <StarRatingComponent
                name={'Đánh giá'} /* name of the radio input, it is required */
                value={
                  item.rating
                } /* number of selected icon (`0` - none, `1` - first) */
                starCount={5} /* number of icons in rating, default `5` */
                starColor={
                  '#FFB800'
                } /* color of selected icons, default `#ffb400` */
                emptyStarColor="#DDDDDD"
              />
              <br />
              <span className="break-all">{item.content}</span>
            </div>
          </div>

          <div className="absolute top-1 right-1  ">
            <div className="relative flex gap-2">
              <span className="text-[#AAAAAA]">{DiffDate(item.createdAt)}</span>
              {me?._id === item?.reviewer?._id && (
                <>
                  <div
                    className="button-option-post"
                    onClick={() => handleClickOption(item || undefined)}>
                    <BsThreeDots className="text-2xl " />
                  </div>
                  {isShowOption && (
                    <div
                      className="absolute top-7 right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="menu-button">
                      <div className="py-1" role="none">
                        <div
                          className="flex gap-3 text-gray-700  px-4 py-2 text-md mx-1 rounded cursor-pointer item-select-option"
                          onClick={() => handleClickCreatePost(item)}>
                          <BsPencil className="text-2xl " />
                          <span>Sửa bài viết</span>
                        </div>
                        <div
                          className="flex gap-3 text-gray-700  px-4 py-2 text-md mx-1 rounded cursor-pointer item-select-option"
                          onClick={() => handleClickDeletePost(item?._id)}>
                          <AiOutlineDelete className="text-2xl " />
                          <span>Xóa</span>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          {isUpdate && (
            <VotePageModal
              isShow={isUpdate}
              isUpdate={true}
              defaultValue={item}
              voteContent={voteContext}
              handleClickVote={updatePostReview}
              currentWord={voteContext.length}
              handleVoteContentChange={handleVoteContentChange}
              onchangeStar={onchangeStar}
              star={starVote}
              handleClickCancel={handleClickCancel}
            />
          )}
        </div>
      ))}

      {isShowModalNotify && (
        <NotifyModal
          isSuccess={messNotify}
          isShow={isShowModalNotify}
          message={
            messNotify
              ? 'Sửa đánh giá thành công'
              : 'Có lỗi xảy ra, vui lòng thử lại'
          }
          onClose={handleCloseModalNotify}
        />
      )}
    </div>
  );
};

export default Review;
