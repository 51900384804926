import {
  GetMyNotificationsQueryVariables,
  MarkNotificationAsReadMutationVariables,
  useGetMyNotificationsQuery,
  useMarkNotificationAsReadMutation,
} from '../../../graphql/generated';
import { graphqlClientRequest } from '../../../graphql/services/graphql-client';

export const useGetNotification = (input: GetMyNotificationsQueryVariables) => {
  const { data, isLoading, isError } = useGetMyNotificationsQuery(graphqlClientRequest(true), input, {
    onSuccess(data) {},
    onError(error: any) {
      //   Notification.Error({ message: getMessageError(error) });
    },

    retry: false,
  });

  return { data, isLoading, isError };
};

export const useMarkNotificationAsRead = () => {
  const { mutateAsync, isLoading, isError } = useMarkNotificationAsReadMutation(graphqlClientRequest(true), {
    onError(error: any) {
      //   Notification.Error({ message: getMessageError(error) });
    },

    retry: false,
  });

  return { mutateAsync, isLoading, isError };
};
