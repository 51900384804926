import { useQueryClient } from '@tanstack/react-query';
import {
  useCreatePersonalPageMutation,
  useGetPersonalPageCategoriesQuery,
} from '../../../../../graphql/generated';
import { graphqlClientRequest } from '../../../../../graphql/services/graphql-client';

export const useGetPersonalCategories = () => {
  return useGetPersonalPageCategoriesQuery(graphqlClientRequest(true));
};

export const useMutationCreatePersonalPage = () => {
  const queryClient = useQueryClient();

  return useCreatePersonalPageMutation(graphqlClientRequest(true), {
    onSuccess: () => {
      queryClient.invalidateQueries(['getMyPages']);
    },
    retry: false,
  });
};
