import { Notification } from '../../../graphql/generated';
import { Notify } from './textNotification';

interface TypeAlsoRepliedCommentByFriendProps {
  item: Notification;
}

const TypeAlsoRepliedCommentByFriend = (props: TypeAlsoRepliedCommentByFriendProps) => {
  const { item } = props;

  return (
    <span>
      <span className="font-semibold">{item.forComment && item.forComment.creator && item.forComment.creator.fullName}</span>
      {Notify.in_an_post}
    </span>
  );
};

export default TypeAlsoRepliedCommentByFriend;
