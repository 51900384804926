import { BsBriefcaseFill } from 'react-icons/bs';
import { MdLocationOn } from 'react-icons/md';
import { GetPageQuery, UserInformationType } from '../../graphql/generated';
import { FaHeart } from 'react-icons/fa';
import { AiOutlinePlusSquare } from 'react-icons/ai';
interface Props {
  data: GetPageQuery | undefined;
}

const InformationPage = (props: Props) => {
  const { data } = props;

  return (
    <div className="ml-6 mt-4 mb-4">
      <div className="text-lg mt-2 flex">
        <div className="flex items-center">
          <FaHeart className="text-2xl mr-2" /> {data?.getPage?.totalLikes} lượt
          thích
        </div>
        <br />
      </div>
      <div className="text-lg mt-2 flex">
        <div className="flex items-center">
          <AiOutlinePlusSquare className="text-2xl mr-2" />{' '}
          {data?.getPage?.totalFollows} lượt theo dõi
        </div>
        <br />
      </div>
    </div>
  );
};

export default InformationPage;
