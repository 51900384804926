import { queryClient } from '../../..';
import {
  GetUserFriendsQueryVariables,
  GetUserMediaListQueryVariables,
  useGetFriendsQuery,
  useGetUserFriendsQuery,
  useGetUserMediaListQuery,
  useGetUserQuery,
  useUpdateProfileMutation,
} from '../../../graphql/generated';
import { GetFriendsQueryVariables } from '../../../graphql/generated/index';
import { graphqlClientRequest } from '../../../graphql/services/graphql-client';
import { TOKEN_KEY } from '../../../Ultis/token';

export const useGetUser = (id: string) => {
  const { data, isLoading, error } = useGetUserQuery(
    graphqlClientRequest(),
    { userId: id },
    {
      onSuccess: () => {},
      onError: (error: any) => {
        if (error.response?.errors[0].message === 'Unauthenticated!') {
          localStorage.removeItem('token');
          localStorage.removeItem('user');
          localStorage.removeItem(TOKEN_KEY);
          localStorage.removeItem('uid');
          localStorage.removeItem('refreshToken');
        }
      },
      enabled: !!id,
      retry: false,
    },
  );
  return { data, isLoading, error };
};

export const useGetFriends = (input: GetFriendsQueryVariables) => {
  const { data } = useGetFriendsQuery(graphqlClientRequest(), input, {
    onSuccess: () => {},
  });
  return { data };
};

export const useGetOtherFriends = (input: GetUserFriendsQueryVariables) => {
  const { data } = useGetUserFriendsQuery(graphqlClientRequest(), input, {
    onSuccess: () => {},
    enabled: !!input.uid,
  });
  return { data };
};

export const useGetMedia = (input: GetUserMediaListQueryVariables) => {
  const { data } = useGetUserMediaListQuery(graphqlClientRequest(), input, {
    onSuccess: () => {},
  });
  return { data };
};

export const useUpdateProfile = () => {
  const { mutateAsync, mutate } = useUpdateProfileMutation(
    graphqlClientRequest(true),
    {
      onSuccess(data) {
        queryClient.invalidateQueries(['getUser']);
      },
      onError(error, variables, context) {
        console.log(error);
      },
    },
  );
  return { mutate, mutateAsync };
};
