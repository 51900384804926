import { useQueryClient } from '@tanstack/react-query';
import {
  ListFriendNotLikedPageQueryVariables,
  useInviteAllLikePageMutation,
  useInviteLikePageMutation,
  useListFriendNotLikedPageQuery,
} from '../../../../../graphql/generated';
import { graphqlClientRequest } from '../../../../../graphql/services/graphql-client';

export const useMutationInviteLikePage = () => {
  const queryClient = useQueryClient();

  return useInviteLikePageMutation(graphqlClientRequest(true), {
    onSuccess: () => {
      setTimeout(() => {
        queryClient.invalidateQueries(['ListFriendNotLikedPage']);
      }, 2000);
    },
    retry: false,
  });
};

export const useMutationInviteAllLikePage = () => {
  const queryClient = useQueryClient();

  return useInviteAllLikePageMutation(graphqlClientRequest(true), {
    onSuccess: () => {
     setTimeout(() => {
       queryClient.invalidateQueries(['ListFriendNotLikedPage']);
     }, 2000);
    },
    retry: false,
  });
};

export const useQueryListFriendNotLikedPage = (
  input: ListFriendNotLikedPageQueryVariables,
) => {
  return useListFriendNotLikedPageQuery(graphqlClientRequest(true), input);
};
