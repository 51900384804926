import { FC, memo } from 'react';
import Lightbox, { CustomSlide, Slide } from 'yet-another-react-lightbox';
import Counter from 'yet-another-react-lightbox/plugins/counter';
// import 'yet-another-react-lightbox/plugins/video';
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/counter.css';
import VideoPlayer from '../VideoPlayer';

function isCustomSlide(slide: Slide): slide is CustomSlide {
  return slide.type === 'video';
}
interface Props {
  index?: number;
  isOpen?: boolean;
  slides?: Slide[];
  onClose?: () => void;
}
const LightBoxImage: FC<Props> = ({ index, isOpen, onClose, slides }) => {
  return (
    <Lightbox
      index={index}
      open={isOpen}
      close={onClose}
      slides={slides}
      render={{
        slide: ({ slide, offset }) =>
          isCustomSlide(slide) ? (
            <>
              {offset === 0 && (
                <VideoPlayer
                  offset={offset}
                  width={slide.width}
                  height={slide.height}
                  options={{
                    autoplay: true,
                    poster: slide.poster ? slide.poster : ``,
                    sources: slide.sources,
                  }}
                />
              )}
            </>
          ) : undefined,
        iconNext() {
          return <></>;
        },
        iconPrev() {
          return <></>;
        },
      }}
    />
  );
};

export default memo(LightBoxImage);
