import { memo, useEffect, useRef, useState } from 'react';
import {
  AiFillDislike,
  AiFillLike,
  AiOutlineDislike,
  AiOutlineLike,
} from 'react-icons/ai';
import { GoComment } from 'react-icons/go';
import { TbShare3 } from 'react-icons/tb';
import {
  Post,
  PostType,
  ReactToModel,
  ReactionType,
  ReactionTypeInput,
  useCreateReactionMutation,
} from '../../graphql/generated';
import { graphqlClientRequest } from '../../graphql/services/graphql-client';
import ButtonPostReaction from '../reaction/ButtonReaction';
import ListUserReactionModal from '../reaction/ListUserReaction';
import SummamryUserReaction from '../reaction/SummaryUserReaction';
import { queryClient } from '../..';
import CreatePostModal from '../create-post/CreatePostModal';
import { getUserLogin } from '../../Ultis/token';
interface Props {
  post: Post;
  handleOpenComment: () => void;
  onPostCreated?: (post: any) => void;
}

const Action = ({ post, onPostCreated, handleOpenComment }: Props) => {
  const [reactiveAtribute, setReactiveAtribute] = useState({
    isLiked: false,
    isDisliked: false,
    totalLikes: 0,
    totalDislikes: 0,
    totalShares: 0,
    totalComments: 0,
  });

  const me = getUserLogin();
  const modalRef = useRef<HTMLDialogElement>(null);
  const handleClickCreatePost = () => {
    modalRef?.current?.showModal();
    document.body.className = 'overflow-hidden';
  };

  useEffect(() => {
    if (post)
      setReactiveAtribute({
        isLiked: post.liked || false,
        isDisliked: post.disliked ?? false,
        totalLikes: post.totalLikes ?? 0,
        totalDislikes: post.totalDislikes ?? 0,
        totalShares: post.totalShares ?? 0,
        totalComments: post.totalComments ?? 0,
      });
  }, [post]);

  const [isOpenLikeReactionModal, setIsOpenLikeReactionModal] = useState(false);

  const [isOpenDislikeReactionModal, setIsOpenDisLikeReactionModal] =
    useState(false);

  const { mutateAsync } = useCreateReactionMutation(graphqlClientRequest(true));

  const [isShowSummaryUserLikeReaction, setIsShowSummaryUserLikeReaction] =
    useState(false);

  const [
    isShowSummaryUserDisLikeReaction,
    setIsShowSummaryUserDisLikeReaction,
  ] = useState(false);

  const handleOnClickTotalLike = () => {
    if (reactiveAtribute.totalLikes) {
      document.body.classList.add('body_modal');
      setIsOpenLikeReactionModal(true);
    }
  };

  const handleOnClickTotalDisLike = () => {
    if (reactiveAtribute.totalDislikes) {
      document.body.classList.add('body_modal');
      setIsOpenDisLikeReactionModal(true);
    }
  };
  const handleDislike = async () => {
    mutateAsync({
      data: {
        reactionToModel: ReactToModel.Post,
        reactionToId: post._id,
        reactionType: ReactionTypeInput.Dislike,
      },
    });
    setReactiveAtribute({
      ...reactiveAtribute,
      isLiked: false,
      isDisliked: true,
      totalLikes: reactiveAtribute.isLiked
        ? reactiveAtribute.totalLikes - 1
        : reactiveAtribute.totalLikes,
      totalDislikes: (reactiveAtribute.totalDislikes ?? 0) + 1,
    });
  };
  const handleUnDislike = async () => {
    mutateAsync({
      data: {
        reactionToModel: ReactToModel.Post,
        reactionToId: post._id,
        reactionType: ReactionTypeInput.Undislike,
      },
    });
    setReactiveAtribute({
      ...reactiveAtribute,
      isDisliked: false,
      totalDislikes: (reactiveAtribute.totalDislikes ?? 0) - 1,
    });
  };
  const handleLike = async () => {
    mutateAsync({
      data: {
        reactionToModel: ReactToModel.Post,
        reactionToId: post._id,
        reactionType: ReactionTypeInput.Like,
      },
    });
    setReactiveAtribute({
      ...reactiveAtribute,
      isLiked: true,
      isDisliked: false,
      totalLikes: reactiveAtribute.totalLikes + 1,
      totalDislikes: reactiveAtribute.isDisliked
        ? (reactiveAtribute.totalDislikes ?? 0) - 1
        : reactiveAtribute.totalDislikes,
    });
  };
  const handleUnLike = async () => {
    mutateAsync({
      data: {
        reactionToModel: ReactToModel.Post,
        reactionToId: post._id,
        reactionType: ReactionTypeInput.Unlike,
      },
    });
    setReactiveAtribute({
      ...reactiveAtribute,
      isLiked: false,
      totalLikes: reactiveAtribute.totalLikes - 1,
    });
  };

  return (
    <>
      <div
        className={`flex justify-between items-center   px-3 md:px-5 ${
          !!post?.sharedPost ? 'pb-2' : 'pt-2'
        }`}>
        <div>
          <div className="mt-2 mb-2 flex">
            <div className="relative mr-6 flex items-center md:text-2xl text-lg">
              {reactiveAtribute.isLiked ? (
                <ButtonPostReaction
                  children={
                    <AiFillLike
                      className="mr-2 text-primary-yellow"
                      onClick={handleUnLike}
                    />
                  }
                />
              ) : (
                <ButtonPostReaction
                  children={
                    <AiOutlineLike className="mr-2" onClick={handleLike} />
                  }
                />
              )}
              {isShowSummaryUserLikeReaction && (
                <SummamryUserReaction
                  filter={{
                    reactionToId: post._id,
                    reactionToModel: ReactToModel.Post,
                    reactionType: ReactionType.Like,
                  }}
                  totalReaction={post.totalLikes}
                />
              )}
              <div>
                <span
                  className="hover:underline cursor-pointer"
                  onClick={handleOnClickTotalLike}
                  onPointerEnter={() => {
                    setIsShowSummaryUserLikeReaction(true);
                  }}
                  onPointerLeave={() => {
                    setIsShowSummaryUserLikeReaction(false);
                  }}>
                  {reactiveAtribute.totalLikes}
                </span>
              </div>
            </div>
            <div className="mr-6 relative flex items-center md:text-2xl text-lg">
              {reactiveAtribute.isDisliked ? (
                <ButtonPostReaction
                  children={
                    <AiFillDislike
                      className="mr-2 text-primary-yellow"
                      onClick={handleUnDislike}
                    />
                  }
                />
              ) : (
                <ButtonPostReaction
                  children={
                    <AiOutlineDislike
                      className="mr-2"
                      onClick={handleDislike}
                    />
                  }
                />
              )}
              {isShowSummaryUserDisLikeReaction && (
                <SummamryUserReaction
                  filter={{
                    reactionToId: post._id,
                    reactionToModel: ReactToModel.Post,
                    reactionType: ReactionType.Dislike,
                  }}
                  totalReaction={post.totalDislikes ?? 0}
                />
              )}
              <div>
                <span
                  className="hover:underline cursor-pointer"
                  onClick={handleOnClickTotalDisLike}
                  onPointerEnter={() => {
                    setIsShowSummaryUserDisLikeReaction(true);
                  }}
                  onPointerLeave={() => {
                    setIsShowSummaryUserDisLikeReaction(false);
                  }}>
                  {reactiveAtribute.totalDislikes}
                </span>
              </div>
            </div>
            <div className="mr-6 flex items-center md:text-2xl text-lg">
              <button className="border-0 bg-none ">
                <GoComment className="mr-2" onClick={handleOpenComment} />
              </button>
              {reactiveAtribute.totalComments ?? 0}
            </div>
            <div
              className="flex items-center md:text-2xl text-lg cursor-pointer"
              onClick={handleClickCreatePost}>
              <TbShare3 className="mr-2" /> {reactiveAtribute.totalShares ?? 0}
            </div>
          </div>
        </div>
        {isOpenLikeReactionModal && (
          <ListUserReactionModal
            filter={{
              data: {
                reactionToId: post._id,
                reactionToModel: ReactToModel.Post,
                reactionType: ReactionType.Like,
              },
              limit: 10,
            }}
            onCloseModal={() => {
              document.body.classList.remove('body_modal');
              setIsOpenLikeReactionModal(false);
            }}
            totalReaction={reactiveAtribute.totalLikes}
          />
        )}
        {isOpenDislikeReactionModal && (
          <ListUserReactionModal
            filter={{
              data: {
                reactionToId: post._id,
                reactionToModel: ReactToModel.Post,
                reactionType: ReactionType.Dislike,
              },
              limit: 10,
            }}
            onCloseModal={() => {
              document.body.classList.remove('body_modal');
              setIsOpenDisLikeReactionModal(false);
            }}
            totalReaction={reactiveAtribute.totalDislikes ?? 0}
          />
        )}
        {/* <div className="flex items-center rounded-full h-[28px] bg-secondary-yellow p-3 text-primary-yellow">
        {post?.earnedPoints}
        <img src={coin} alt="" width={24} height={24} className="ml-1" />
      </div> */}
      </div>
      <CreatePostModal
        ref={modalRef}
        onPostCreated={() => onPostCreated}
        user={me}
        post={!!post?.sharedPost ? post?.sharedPost : post}
        type={PostType.Share}
        onReset={() => {}}
      />
    </>
  );
};

export default memo(Action);
