import { AiFillStar } from 'react-icons/ai';
import { useParams } from 'react-router-dom';
import HeaderExtra from '../header/HeaderExtra';
import { useGetPage, useGetPageReview } from '../../pages/Page/services/api';
import PostReviewItem from '../posts/PostReview';

const ListReviewsPage = () => {
  const { pageId } = useParams();
  const { data: currentPage } = useGetPage(pageId || '');
  const { data } = useGetPageReview({
    limit: 50,
    pageId: pageId || '',
  });

  return (
    <>
      <HeaderExtra title="Đánh giá" />
      <div className="section-content-setting-page">
        <div className="bg-primary-yellow">
          <div className=" bg-white items-center p-4  rounded-t-[10px] ">
            <div className="border-b-[10px] border-solid border-gray-300 pb-4">
              <div className="mx-auto my-0 h-[110px] w-[110px] bg-amber-100 text-amber-500 rounded-full flex items-center justify-center text-6xl">
                {(currentPage?.getPage?.rating || 0).toFixed(1)}
              </div>
              <div className="flex justify-center items-center py-2 tracking-[3px]">
                <p>{(currentPage?.getPage?.rating || 0).toFixed(1)}</p>
                <div className="flex items-center ">
                  <span>/5</span>
                  <AiFillStar />
                </div>
              </div>
              {!!currentPage?.getPage?.totalReviews &&
              currentPage?.getPage?.totalReviews > 0 ? (
                <div className="text-center text-lg">
                  {!!currentPage?.getPage?.wroteReview ? (
                    <>
                      {!!currentPage?.getPage?.totalReviews &&
                      currentPage?.getPage?.totalReviews > 1 ? (
                        <span>
                          Bạn và {currentPage?.getPage?.totalReviews - 1} người
                          đã đánh giá cho <b>{currentPage?.getPage?.name}</b>
                        </span>
                      ) : (
                        <span>
                          Bạn đã đánh giá cho{' '}
                          <b>{currentPage?.getPage?.name}</b>
                        </span>
                      )}
                    </>
                  ) : (
                    <>
                      {!!currentPage?.getPage?.totalReviews &&
                      currentPage?.getPage?.totalReviews > 1 ? (
                        <span>
                          {' '}
                          {currentPage?.getPage?.totalReviews} người đã đánh giá
                          cho <b>{currentPage?.getPage?.name}</b>
                        </span>
                      ) : (
                        <span>
                          1 người đã đánh giá cho{' '}
                          <b>{currentPage?.getPage?.name}</b>
                        </span>
                      )}
                    </>
                  )}
                </div>
              ) : (
                <div className="flex justify-center items-center">
                  Chưa có bài viết nào đánh giá page
                </div>
              )}
            </div>
            {data?.getPageReviews?.map((item) => {
              return <PostReviewItem post={item} />;
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default ListReviewsPage;
