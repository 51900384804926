import { FC, useEffect, useRef, useState } from 'react';
import Loading, { LoadingRef } from '../../../components/loading/Loading';
import {
  Post,
  SearchPostsQueryVariables,
  TermStatus,
} from '../../../graphql/generated';
import { useSearchPost } from '../services/api';

import { MdSearchOff } from 'react-icons/md';
import InfiniteScroll from 'react-infinite-scroll-component';
import PostItem from '../../../components/posts/Post';
import { TabItemSearchBy } from './SearchTabs';
interface ListPostProps {
  keyword: string;
  searchBy?: TabItemSearchBy;
}

const ListPost: FC<ListPostProps> = ({ keyword, searchBy }) => {
  const [dataPost, setDataPost] = useState<any>([]);
  const [params, setParams] = useState<SearchPostsQueryVariables>({
    q: keyword,
    limit: 10,
  });
  const { data, isLoading } = useSearchPost(params);
  const loadingRef = useRef<LoadingRef>(null);
  const refresh = () => {};

  const handleSearchPost = () => {
    try {
      if (data && data.searchPosts) {
        const newData = params.after
          ? [...dataPost, ...data.searchPosts]
          : data.searchPosts;

        const newDataFilter = newData.filter((val: Post) => {
          return !val.page || val.page.termStatus !== TermStatus.Blocked;
        });

        setDataPost(newDataFilter);
        setParams({ q: keyword, limit: 10 });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchMoreData = () => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    setParams({ ...params, after: dataPost[dataPost.length - 1]!._id || '' });
  };

  useEffect(() => {
    handleSearchPost();
  }, [keyword, data?.searchPosts?.length]);

  useEffect(() => {
    if (isLoading) {
      loadingRef.current?.open();
    } else {
      loadingRef.current?.close();
    }
  }, [isLoading]);

  return (
    <div>
      {keyword === '' && (
        <div className="mt-10 font-semibold text-2xl">Đề xuất</div>
      )}

      {searchBy === TabItemSearchBy.All && dataPost.length !== 0 && (
        <div className="font-semibold text-2xl mt-4">Bài viết</div>
      )}

      {searchBy === TabItemSearchBy.All ? (
        <></>
      ) : (
        <>
          {dataPost.length === 0 && keyword !== '' && (
            <center className="mt-20">
              <MdSearchOff size={'45px'} />
              <p className="mt-2">{`Không tìm thấy kết quả tìm kiếm cho từ khoá "${keyword}"`}</p>
            </center>
          )}
        </>
      )}

      <div className="fixed top-[15%] left-[47%] z-50">
        <Loading ref={loadingRef} />
      </div>
      <InfiniteScroll
        dataLength={dataPost?.length || 0}
        next={fetchMoreData}
        hasMore={true}
        loader={<></>}
        endMessage={<p style={{ textAlign: 'center' }}></p>}
        refreshFunction={refresh}>
        {dataPost?.map((item: any, index: number) => {
          return (
            <div
              className="mt-6 border-[1px] border-solid border-gray-200 rounded-[10px]"
              key={index}>
              <PostItem post={item} />
            </div>
          );
        })}
      </InfiniteScroll>
    </div>
  );
};

export default ListPost;
