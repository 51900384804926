// import 'video.js/dist/video-js.css';
import '../../assets/styles/player.css';
import React from 'react';
import videojs from 'video.js';
import { useContainerRect } from '../../hooks/useContainer';

interface IVideoPlayerProps {
  offset?: number;
  options: videojs.PlayerOptions;
  width: number;
  height: number;
}

const initialOptions: videojs.PlayerOptions = {
  controls: true,
  fluid: true,
  loop: true,
  controlBar: {
    volumePanel: {
      inline: true,
    },
  },
};
const VidePlayer: React.FC<IVideoPlayerProps> = ({ options, offset, width, height }) => {
  const videoNode: any = React.useRef<HTMLVideoElement>();
  const player = React.useRef<videojs.Player>();
  const { setContainerRef, containerRect } = useContainerRect();
  const container: any = React.useRef<any>();
  const scaleWidthAndHeight = () => {
    if (!width || !height || !containerRect) return null;

    const widthBound = width / height > containerRect.width / containerRect.height;
    const elementWidth = widthBound ? containerRect.width : Math.round((containerRect.height / height) * width);
    const elementHeight = !widthBound ? containerRect.height : Math.round((containerRect.width / width) * height);

    return {
      width: elementWidth,
      height: elementHeight,
      style: {
        width: elementWidth,
        height: elementHeight,

        maxWidth: '100%',
        maxHeight: '100%',
      },
    };
  };

  React.useEffect(
    () => {
      player.current = videojs(videoNode.current, {
        ...initialOptions,
        ...options,
      }).ready(function () {
        // console.log('onPlayerReady', this);
      });
    },
    // eslint-disable-next-line
    [options],
  );

  React.useEffect(
    () => {
      return () => {
        if (player.current) {
          player.current.dispose();
        }
      };
    },
    // eslint-disable-next-line
    [],
  );

  return (
    <div ref={setContainerRef} className="video-container-auto">
      <div className="data-vjs-player" {...scaleWidthAndHeight()}>
        <video ref={videoNode} className="video-js vjs-default-skin"></video>
      </div>
    </div>
  );
};

export default React.memo(VidePlayer);
