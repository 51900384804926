import { FC } from 'react';
import { MdSearchOff } from 'react-icons/md';
import {
  useSearchGroup,
  useSearchPage,
  useSearchPlace,
  useSearchPost,
  useSearchUsers,
} from '../services/api';
import ListGroups from './ListGroups';
import ListPage from './ListPage';
import ListPlace from './ListPlace';
import ListPost from './ListPost';
import ListUser from './ListUser';
import { TabItemSearchBy } from './SearchTabs';

interface ListSearchAllProps {
  keyword: string;
  searchBy: TabItemSearchBy;
}

const ListSearchAll: FC<ListSearchAllProps> = ({ keyword, searchBy }) => {
  const params = { q: keyword, limit: 10 };

  const { data: dataPost } = useSearchPost(params);
  const { data: dataPage } = useSearchPage(params);
  const { data: dataPlace } = useSearchPlace(params);
  const { data: usersData } = useSearchUsers(params);
  const { data: groupsData } = useSearchGroup(params);

  return (
    <div>
      {dataPost?.searchPosts?.length === 0 &&
        dataPage?.searchPages?.length === 0 &&
        groupsData?.searchGroups?.length === 0 &&
        dataPlace?.topPlaces?.length === 0 &&
        usersData?.searchUsers?.length === 0 && (
          <center className="mt-20">
            <MdSearchOff size={'45px'} />
            <p className="mt-2">{`Không tìm thấy kết quả tìm kiếm cho từ khoá "${keyword}"`}</p>
          </center>
        )}
      {keyword && (
        <>
          <ListUser query={keyword} searchBy={searchBy} />
          <ListPage keyword={keyword} searchBy={searchBy} />
          <ListGroups keyword={keyword} searchBy={searchBy} />
          <ListPlace keyword={keyword} searchBy={searchBy} />
          <ListPost keyword={keyword} searchBy={searchBy} />
        </>
      )}
    </div>
  );
};

export default ListSearchAll;
