import { useEffect, useState } from 'react';
import { getReadPost, setReadPost } from '../../../Ultis/post';
import {
  GetHomeFeedsQueryVariables,
  Post,
} from '../../../graphql/generated/index';
import { StateFetch } from '../Home';
import { useGetPost } from '../services/api';

export const usePosts = () => {
  const exclude = getReadPost();
  const [filter, setFilter] = useState<GetHomeFeedsQueryVariables>({
    limit: 5,
    offset: 0,
    excludePostIds: exclude,
  });
  const [posts, setPosts] = useState<any[]>([]);
  const [stateFetch, setStateFetch] = useState(StateFetch.GETTING_FIRST);
  const { data, isLoading, refetch } = useGetPost(
    {
      ...filter,
    },
    0,
  );
  useEffect(() => {
    if (stateFetch === StateFetch.GETTING_FIRST) {
      setPosts(data?.getFeedPosts || []);
    } else {
      setPosts([...posts, ...(data?.getFeedPosts || [])]);
    }
  }, [data?.getFeedPosts, data?.getFeedPosts?.length]);

  const setPost = () => {
    setReadPost(posts.map((item) => item._id));
  };

  window.onbeforeunload = setPost;

  const fetchMoreData = () => {
    setStateFetch(StateFetch.GETTING_MORE);
    setFilter({ ...filter, offset: (filter.offset || 0) + 5 });
  };

  const refetchData = () => {
    window.location.reload();
  };

  const onPostCreated = (post: Post) => {
    setPosts([post, ...posts]);
  };

  return {
    posts,
    isLoading,
    fetchMoreData,
    onPostCreated,
    setFilter,
    refetchData,
    refetch,
    setStateFetch,
  };
};
