import { FC, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import userDefaultImg from '../../../assets/images/user.jpeg';
import { useGetMyFriends } from '../../../common/services/api';
import LoadingExtra from '../../../components/loading/LoadingExtra';
import {
  GetMyFriendsQuery,
  GetMyFriendsQueryVariables,
} from '../../../graphql/generated';

interface Props {
  query: string;
  taggedUsers: UserForTag[];
  handleClickTagUser: (user: UserForTag) => void;
}

export interface UserForTag {
  __typename?: 'User';
  _id: string;
  fullName: string;
  username: string;
  totalMutualFriends?: number | null;
  verified?: boolean | null;
  avatar?: { __typename?: 'Media'; uri: string } | null;
}

const LIMIT_PAGE = 20;

const listUserTransformer = (
  users: GetMyFriendsQuery['getFriends'],
): UserForTag[] | [] => {
  return (
    users?.map((user) => {
      return {
        ...user,
        tagged: false,
      };
    }) ?? []
  );
};

const TagFriendList: FC<Props> = ({
  query,
  taggedUsers,
  handleClickTagUser,
}) => {
  const [filter, setFilter] = useState<GetMyFriendsQueryVariables>({
    q: '',
    limit: LIMIT_PAGE,
  });
  const {
    status,
    isSuccess,
    data: listUserData,
    isLoading,
  } = useGetMyFriends(filter);
  const [users, setUsers] = useState<UserForTag[] | []>([]);

  useEffect(() => {
    delete filter.after;
    const timeOutId = setTimeout(() => {
      setFilter({
        ...filter,
        q: query,
      });
      query !== filter.q && setUsers([]);
    }, 300);
    return () => {
      clearTimeout(timeOutId);
    };
  }, [query]);

  useEffect(() => {
    setUsers([...users, ...listUserTransformer(listUserData?.getFriends)]);
  }, [listUserData]);

  const fetchData = () => {
    if (filter.q === query && users.length > 0) {
      setFilter({
        ...filter,
        after: users[users?.length - 1]._id,
      });
    }
  };

  return (
    <>
      <InfiniteScroll
        className="mt-3"
        dataLength={users?.length ?? 0}
        next={fetchData}
        hasMore={true}
        loader={''}
        endMessage={''}
        scrollableTarget={'scrollableFriendTagSelection'}>
        {users.length > 0 &&
          users.map((user, idx) => {
            return (
              <div
                className="hover:bg-slate-200 rounded-lg px-4"
                onClick={(e) => {
                  const userId = e.currentTarget.dataset.id;
                  const user = users.find((user) => user._id === userId);
                  user && handleClickTagUser(user);
                }}
                role="button"
                key={user._id}
                data-id={user._id}>
                <div className="flex w-full justify-between items-center">
                  <div className="flex h-[60px] p-2 flex-row w-full">
                    <div className="w-1/6 avatar-wrap flex rounded-full">
                      <div className="h-[45px] w-[45px]">
                        <div
                          className={`img-1-1 ${
                            user.verified
                              ? 'avartar-shadow_verify'
                              : 'avartar-border_default'
                          }`}>
                          <img
                            className={`max-[400px]:h-[40px] max-[400px]:w-[40px] friend-avatar  `}
                            src={user?.avatar?.uri ?? userDefaultImg}
                            alt={'avatar_' + user?.username}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className={`max-[400px]:ml-3 flex flex-col place-content-center w-full`}>
                      <span className="max-[400px]:text-xs w-15 font-medium text-black line-clamp-1">
                        {user.fullName}
                      </span>
                    </div>
                  </div>
                  <div className="round mr-3">
                    <input
                      type="checkbox"
                      checked={
                        taggedUsers.findIndex((i) => i._id === user._id) > -1
                      }
                    />
                    <label></label>
                  </div>
                </div>
              </div>
            );
          })}
        {!isLoading &&
          filter.q !== '' &&
          listUserData?.getFriends &&
          listUserData?.getFriends.length === 0 && (
            <center className="my-2">
              <p className="text-sm text-slate-400">{`Không tìm thấy kết quả phù hợp`}</p>
            </center>
          )}
        {!isLoading &&
          filter.q === '' &&
          listUserData?.getFriends &&
          listUserData?.getFriends.length === 0 && (
            <center className="my-2">
              <p className="text-sm text-slate-400">{`Bạn không có bạn bè`}</p>
            </center>
          )}
        {isLoading && (
          <center>
            <LoadingExtra className="" label="" />
          </center>
        )}
      </InfiniteScroll>
    </>
  );
};

export default TagFriendList;
