export const Notify = {
  title: 'Thông báo',
  no_notify: 'Bạn chưa có thông báo',
  invite_like_page: 'đã mời bạn thích trang ',
  friend_add_post: `đã đăng một bài viết mới.`,
  friend_add_product: 'đã đăng một sản phẩm mới.',
  friend_like_post: 'đã thích bài viết của bạn.',
  friend_dislike_post: 'đã không thích bài viết của bạn.',
  like_post_page: 'đã thích bài viết trong trang ',
  dislike_post_page: 'đã không thích bài viết trong trang ',
  like_product_page: 'đã thích sản phẩm trong trang ',
  like_comment_post: `đã thích bình luận của bạn trong một `,
  like_comment_media: 'đã thích bình luận của bạn trong một {{media}}',
  dislike_product_page: 'đã không thích sản phẩm trong trang ',
  dislike_comment_post: `đã không thích bình luận của bạn trong một `,
  dislike_comment_media: 'đã thích bình luận của bạn trong một {{media}}',
  on_page: 'tại trang',
  friend_comment_post: 'đã bình luận về bài viết của bạn.',
  comment_post: `đã bình luận về bài viết của`,
  comment_post_product: `đã bình luận về sản phẩm của`,
  comment_post_page: `đã bình luận về bài viết trong trang `,
  comment_post_page_product: `đã bình luận về sản phẩm trong trang `,

  is_product: "'s product",
  in_post: 'trong bài viết',
  in_image: 'trong 1 ảnh',
  system_login: 'Chúng tôi nhận thấy một lần đăng nhập khác thường từ thiết bị của...',
  accept_request_friend: 'đã chấp nhận lời mời kết bạn.',
  request_friend: 'đã gửi cho bạn lời mời kết bạn.',
  became_friend: 'đã trở thành bạn bè của bạn.',
  reply_comment_on_your_post: 'đã trả lời bình luận của ',
  friend_reply_comment: 'đã trả lời bình luận của bạn',
  reply_comment_on_post_not_me: 'trong bài viết của ',
  reply_comment_on_post_page_not_me: `trong bài viết của trang `,
  reply_comment_on_post_page_not_me_product: `trong sản phẩm của trang `,
  reply_comment_on_post_me: 'trong bài viết của bạn.',
  share_post: 'đã chia sẻ bài viết của bạn.',
  share_post_page: 'đã chia sẻ bài viết của trang ',
  share_post_tag: ' đã chia sẻ bài viết mà bạn được gắn thẻ',
  share_product_page: 'đã chia sẻ sản phẩm của trang ',
  accept: 'Chấp nhận',
  reject: 'Từ chối',
  delete_invite: 'Xoá lời mời',
  message: 'Nhắn tin',
  unfollow: 'Bỏ theo dõi',
  no_friend: 'Chưa có bạn bè',
  no_friend_request: 'Chưa có lời mời kết bạn.',
  accepted: 'Chấp nhận lời mời kết bạn',
  tag_in_post: ' đã gắn thẻ bạn trong 1 bài viết.',
  tag_you_andfriend: ' đã gắn thẻ bạn và ',
  tag_you_andfriend_2: ' người khác trong 1 bài viết.',
  accept_tag_post: 'Bạn có muốn thêm bài viết vào dòng thời gian không?',
  no_suggest: 'Chưa có gợi ý',
  friend_follow: 'đã theo dõi bạn',
  comment_img: 'đã bình luận ảnh của bạn.',
  comment_img_video: 'đã bình luận video của bạn.',
  comment_img_page: 'đã bình luận ảnh của trang ',
  comment_img_page_video: 'đã bình luận video của trang ',
  comment_img_friend: 'đã bình luận ảnh của: ',
  comment_img_your_self: 'đã bình luận ảnh của họ.',
  is_video: '',
  is_photo: '',
  like_post_tag_you: 'đã thích một bài viết mà bạn được gắn thẻ',
  like_post_tag_on_you: 'đã thích một bài viết mà bạn được gắn thẻ',
  dislike_post_tag_you: 'đã không thích một bài viết mà bạn được gắn thẻ',
  dislike_post_tag_on_you: 'đã không thích một bài viết mà bạn được gắn thẻ',
  view_post: 'Xem {{post}}',
  delete_comment_desc: 'Bình luận này có thể bị xoá hoặc có thể chỉ hiển thị với đối tượng không bao gồm bạn.',
  and: 'và',
  like_media: 'đã thích một hình ảnh của bạn.',
  like_media_page: 'đã thích một hình ảnh trong trang ',
  like_video: 'đã thích một video của bạn.',
  like_video_page: 'đã thích một video trong trang ',
  like_page: 'đã thích trang ',
  dislike_media: 'đã không thích một hình ảnh của bạn.',
  dislike_media_page: 'đã không thích một hình ảnh trong trang ',
  dislike_video: 'đã không thích một video của bạn.',
  dislike_video_page: 'đã không thích một video trong trang ',
  follow_page: ' đã theo dõi trang ',
  of_me: 'của bạn.',
  liked_in_post: 'đã thích sản phẩm của trang',
  together: 'cùng',
  others: 'người khác',
  added_comment_to_post_that_tagged_you: 'đã bình luận bài viết mà bạn được gắn thẻ.',
  done: 'Xong',
  they: 'họ',
  me: 'bạn',
  img: 'ảnh',
  picture: 'hình ảnh',
  video: 'video',
  post: 'bài viết',
  product: 'sản phẩm',
  comment_tag_you: 'đã nhắc đến bạn trong bình luận.',
  account: 'Tài khoản',
  page: 'Trang',
  verified_success: 'của bạn đã được xác thực thành công.',
  verified_acc_fail: 'Xác thực tài khoản không thành công. Vui lòng cung cấp lại thông tin.',
  verified_page_fail: 'Xác thực trang không thành công. Vui lòng cung cấp lại thông tin xác minh trang.',
  in_an_post: 'trong một bài viết.',
  new_group_conversation: 'đã thêm bạn vào nhóm chat',
  invited_to_join_a_conversation: 'đã mời bạn tham gia nhóm chat',
  mention_in_a_post: ' đã nhắc đến bạn trong một bài viết.',
  mention_in_a_comment: ' đã nhắc đến bạn trong một bình luận.',
  shared_product_on_page: ' đã chia sẻ một sản phẩm trong trang ',
  your: 'của bạn',
  comment_product_on_page: 'đã bình luận vào một sản phẩm trong trang ',
  comment_post_on_page: 'đã bình luận vào một bài viết trong trang ',
  reply_mention_comment: ' đã trả lời bình luận mà bạn được nhắc đến.',
  spin_point: 'đã quay được phần quà là {{gift}}. Quay ngay để không kém phần bạn bè nào!',
  got_prize_referral_campaign: 'Chúc mừng bạn đã giới thiệu được {{totalInvited}} người bạn, và nhận được quà tặng từ chương trình {{voucherTitle}}',
  verified_post: 'Quản trị viên vừa thay đổi trạng thái bài viết của bạn',
};

export const Code = {
  reference_code: 'Mã giới thiệu',
  label_reference_code: 'Mã giới thiệu của bạn',
  label_enter_reference_code: 'Nhập mã giới thiệu',
  cta_finish: 'Hoàn thành',
  _1_10_users: '1 - 10 người',
  _11_100_users: '11 - 100 người',
  _101_500_users: '101 - 500 người',
  _than_500_users: '> 500 người',
  label_invite: 'Giới thiệu bạn bè ngay để nhận được những phần quà có giá trị:',
  refer_code_success_noty: 'Bạn đã nhập thành công mã giới thiệu của người khác và nhận được',
  refer_code_require: 'Bạn chưa nhập mã giới thiệu!',
  refer_code_unsuccessful: 'Thêm mã giới thiệu không thành công!',
  refer_code_not_found: 'Mã giới thiệu không tồn tại!',
  refer_code_existed: 'Bạn đã có mã giới thiệu rồi!',
  refer_code_success_not_verify:
    'Nhập mã giới thiệu thành công. Bạn sẽ được nhận được điểm thưởng In2spire sau khi bạn và người giới thiệu hoàn thành xác thực tài khoản.',
  refer_code_success_point_zero: 'Bạn đã nhập mã giới thiệu thành công.',
  refer_code_copy: 'Đã sao chép!',
  refer_code_people_unit: 'người',
  refer_code_and: 'và',
  refer_code_other: 'người khác',
  refer_code_notify: 'đã nhập mã giới thiệu của bạn. Bạn nhận được',
  refer_code_notify_point_zero: 'đã nhập thành công mã giới thiệu của bạn.',
  refer_number_invited_user: ' Số người bạn đã giới thiệu',
  refer_congratulation_got_prize_1: 'Chúc mừng bạn đã giới thiệu được ',
  refer_congratulation_got_prize_2: ' người và nhận được',
  message_blocked:
    'Tài khoản của bạn tạm thời bị khóa trong vòng 7 ngày. Chúng tôi đã phát hiện những hoạt động đáng nghi ngờ trong tài khoản của bạn và đang thực hiện các biện pháp phòng ngừa bảo mật. Để được hỗ trợ và giải đáp thắc mắc, vui lòng liên hệ với đội ngũ chăm sóc khách hàng của In2spire qua email',
  or_phone_number: 'hoặc số điện thoại',
  block_error: 'Người dùng không tồn tại hoặc đã bị khóa vì vi phạm chính sách của In2spire.',
  refer_code_notify_points: 'In2spire Point',
  reference_code_skip: 'Bỏ qua',
  reference_code_noti_from: 'Bạn đã nhập mã giới thiệu từ',
  reference_code_noti_added: 'và được cộng',
};

export const AlertText = {
  page_block: 'Rất tiếc! Trang bạn truy cập hiện đang bị khóa hoặc chỉ hiển thị với đối tượng không bao gồm bạn',
};
