import { useRef } from 'react';
import { FaGraduationCap } from 'react-icons/fa';
import { Link, useMatch, useNavigate } from 'react-router-dom';
import { queryClient } from '../..';
import { getAuthToken, getUserLogin } from '../../Ultis/token';
import { images } from '../../assets/images';
import Loading, { LoadingRef } from '../../components/loading/Loading';
import {
  useGetInfoUserQuery,
  useLogoutUserMutation,
} from '../../graphql/generated';
import { graphqlClientRequest } from '../../graphql/services/graphql-client';
import bgAvatar from '../../assets/images/bg-avatar.png';
import './index.scss';

const Menu = () => {
  const me = getUserLogin();
  const uid = localStorage.getItem('uid');
  const loadingRef = useRef<LoadingRef>(null);
  const navigate = useNavigate();
  const match = useMatch('/menu');
  const isMenu = Boolean(match);
  const { data } = useGetInfoUserQuery(graphqlClientRequest(true), {
    id: uid?.replace(/['"]+/g, '') || '',
  });
  const token = getAuthToken();

  const { mutateAsync } = useLogoutUserMutation(graphqlClientRequest(true), {
    onMutate() {
      loadingRef.current?.open();
    },
    onSettled() {
      loadingRef.current?.close();
    },
  });

  const goToEditInfo = () => {
    navigate('/menu/edit');
  };

  const handleLogout = async () => {
    const refreshToken = localStorage.getItem('refreshToken') || '';
    await mutateAsync({ data: refreshToken }).then(() => {
      localStorage.clear();
      queryClient.clear();
      navigate('/');
    });
  };

  return (
    <div className="px-5 pt-6 bg-white">
      <div className="relative flex justify-center">
        <Loading ref={loadingRef} />
      </div>
      <Link to={'/profile'} className="flex items-center justify-between">
        <div className="avatar_menu flex items-center">
          <div className="relative">
            <div className="h-[110px] w-[110px] bg-white rounded-full shadow_verify_lg">
              <div className="img-1-1  ">
                <img
                  src={
                    data?.getPublicProfile.avatar?.uri || images.AvatarDefault
                  }
                  alt="avatar"
                />
              </div>
            </div>
          </div>

          <p className="sm:text-lg text-sm ml-5">
            {data?.getPublicProfile.fullName}
          </p>
        </div>

        <div>
          <img src={images.ArrowLeft} />
        </div>
      </Link>
      <div className="cursor-pointer mt-6">
        <div
          className="flex py-5 border-b-light-gray border-t-white border border-r-white border-l-white hover:bg-gray-100"
          onClick={goToEditInfo}>
          <img src={images.Edit} />
          <p className="ml-5 sm:text-lg text-sm font-semibold ">
            Chỉnh sửa thông tin
          </p>
        </div>
        <div
          className="flex py-5 border-b-light-gray border-t-white border border-r-white border-l-white hover:bg-gray-100"
          onClick={() => {
            navigate('/friends');
          }}>
          <img src={images.Friend} />
          <p className="ml-5 sm:text-lg text-sm font-semibold">Bạn bè</p>
        </div>
        <div className="flex py-5 border-b-light-gray border-t-white border border-r-white border-l-white hover:bg-gray-100">
          <img src={images.Bookmark} />
          <p className="ml-5 sm:text-lg text-sm font-semibold">Lưu</p>
        </div>
        <div
          className="flex py-5 border-b-light-gray border-t-white border border-r-white border-l-white hover:bg-gray-100"
          onClick={() => {
            navigate('/profile/pages');
          }}>
          <img src={images.Flag} />
          <p className="ml-5 sm:text-lg text-sm font-semibold">Trang</p>
        </div>
        <div
          className="flex py-5 border-b-light-gray border-t-white border border-r-white border-l-white hover:bg-gray-100"
          onClick={() => {
            navigate('/groups');
          }}>
          <img src={images.IconGroup} />
          <p className="ml-5 sm:text-lg text-sm font-semibold">Nhóm</p>
        </div>
        <a href={`https://training.comartek.com/login/${token}`}>
          <div className="flex justify-between py-5 border-b-light-gray border-t-white border border-r-white border-l-white hover:bg-gray-100">
            <div className="flex">
              <FaGraduationCap size={24} className="text-primary-yellow" />
              <p className="ml-5 sm:text-lg text-sm font-semibold">Coaching</p>
            </div>
          </div>
        </a>
        <div className="flex justify-between py-5 border-b-light-gray border-t-white border border-r-white border-l-white hover:bg-gray-100">
          <div className="flex">
            <img src={images.Language} />
            <p className="ml-5 sm:text-lg text-sm font-semibold">Ngôn ngữ</p>
          </div>
          <div className="flex">
            <p className="sm:text-lg text-sm font-semibold mr-3">VN</p>
            <img src={images.FlagVietNam} />
          </div>
        </div>
        <div className="flex py-5 border-b-light-gray border-t-white border border-r-white border-l-white hover:bg-gray-100">
          <img src={images.Cog} />
          <p className="ml-5 sm:text-lg text-sm font-semibold">Cài đặt</p>
        </div>
        <div className="flex py-5 hover:bg-gray-100">
          <img src={images.Book} />
          <p className="ml-5 sm:text-lg text-sm font-semibold">
            Điều khoản và Chính sách
          </p>
        </div>
      </div>
      <div className="  pt-1 bg-gray-400 cursor-pointer">
        <div
          className="flex bg-white py-4 border-b-light-gray border-t-white border border-r-white border-l-white"
          onClick={handleLogout}>
          <img src={images.Logout} />
          <p className="ml-5 sm:text-lg text-sm font-semibold">Đăng xuất</p>
        </div>
      </div>
    </div>
  );
};

export default Menu;
