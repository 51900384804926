import {
  forwardRef,
  Ref,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { GoSearch } from 'react-icons/go';
import {
  useGetListFriendNotInGroup,
  useInviteUserToGroup,
} from '../../pages/Groups/services/api';
import { useOnClickOutside } from '../../Ultis/hooks/useClickOutside';
import Toast, { ToastProps, ToastType } from '../toast/Toast';

export interface ModalListFriendRefNotInGroup {
  open: () => void;
  close: () => void;
}

export interface PropsOption {
  content: string;
  handleClick: () => void;
  icon: any;
  subText?: string;
}

export interface Props {
  id: string;
}
const ModalListFriendNotInGroup = (
  props: Props,
  ref: Ref<ModalListFriendRefNotInGroup>,
) => {
  const { id } = props;
  const [visible, setVisible] = useState(false);
  const [keySearch, setKeySearch] = useState<string>();
  const [toast, setToast] = useState<ToastProps>({
    className: 'fixed top-0 right-0',
    isHidden: true,
    type: ToastType.Success,
    message: '',
  });

  useEffect(() => {
    let resetToast: any;
    if (!toast.isHidden) {
      resetToast = setTimeout(() => {
        setToast((preState) => {
          return { ...preState, isHidden: true };
        });
      }, 3000);
    }
    return () => {
      clearTimeout(resetToast);
    };
  }, [toast]);

  const { data: listFriend } = useGetListFriendNotInGroup({
    id: id,
    limit: 999,
    q: keySearch,
  });

  const { mutateAsync } = useInviteUserToGroup();

  const refModal = useRef<any>();
  useOnClickOutside(refModal, () => setVisible(false));

  const openModal = () => {
    setVisible(!visible);
  };

  const closeModal = () => {
    setKeySearch('');
    setVisible(false);
  };

  useImperativeHandle(ref, () => ({
    open: () => openModal(),
    close: () => closeModal(),
  }));

  const handleChangeInput = (e: any) => {
    setKeySearch(e.target.value);
  };

  const handleInvite = (idUser: string) => {
    mutateAsync(
      {
        groupId: id,
        userId: idUser,
      },
      {
        onSuccess() {
          setToast({
            ...toast,
            message: `Gửi lời mời thành công`,
            isHidden: false,
            type: ToastType.Success,
          });
        },
        onError() {
          setToast({
            ...toast,
            message: `Không thành công, vui lòng thử lại`,
            isHidden: false,
            type: ToastType.Danger,
          });
        },
      },
    );
  };

  return (
    <>
      <Toast
        message={toast.message}
        className={`${toast.className} absolute top-1`}
        type={toast.type}
        isHidden={toast.isHidden}
      />
      <div
        className={`${
          visible ? '' : 'hidden'
        }  fixed top-0 bg-[#00000075] left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full justify-center items-center flex`}
        ref={refModal}>
        <div className="absolute   modal_show_member bg-white w-[350px]">
          <div className="flex justify-center py-3 bg-primary-yellow header_modal relative">
            <h3 className="text-white">Mời bạn bè</h3>
          </div>
          <div className="p-3">
            <div className="relative pb-3">
              <input
                type="text"
                placeholder="Tìm kiếm bạn bè..."
                onChange={handleChangeInput}
                className="border-[1px] border-solid border-gray-200 w-full rounded-[8px] h-[35px] pr-[30px] pl-[10px]"
              />
              <GoSearch className="absolute right-[10px] top-[10px] text-gray-300" />
            </div>
            <div className="max-h-[200px] overflow-auto">
              {(listFriend?.listFriendNotInGroup || []).map((item) => (
                <div
                  key={item?.user?._id}
                  className="flex items-center justify-between py-2 border-b-[1px] border-solid border-gray-200">
                  <div className="m-0 cursor-pointer ">
                    <div></div>
                    <span className="hover:text-primary-yellow">
                      {item.user?.fullName}
                    </span>
                  </div>
                  {item?.invited ? (
                    <button
                      className="px-2 py-1 bg-gray-300 text-[10px] text-white rounded-[6px]"
                      disabled>
                      Đã mời
                    </button>
                  ) : (
                    <button
                      onClick={() => handleInvite(item?.user?._id)}
                      className="px-2 py-1 bg-primary-yellow text-[10px] text-white rounded-[6px]">
                      Mời
                    </button>
                  )}
                </div>
              ))}
              {!((listFriend?.listFriendNotInGroup || []).length > 0) && (
                <h3 className="text-center">
                  Không có bạn bè nào chưa vào nhóm
                </h3>
              )}
            </div>

            <div className="mt-4 flex justify-center">
              <button
                className="px-4 py-2 bg-primary-yellow text-white rounded-md"
                onClick={closeModal}>
                Xong
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default forwardRef(ModalListFriendNotInGroup);
