import React, { FC, useEffect, useRef, useState } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { HiOutlineArrowSmLeft } from 'react-icons/hi';
import InfiniteScroll from 'react-infinite-scroll-component';
import { images } from '../../assets/images';
import {
  Place,
  PostType,
  TopPlacesQueryVariables,
} from '../../graphql/generated';
import { useSearchPlace } from '../../pages/Search/services/api';
import LoadingExtra from '../loading/LoadingExtra';

interface Props {
  hidden: boolean;
  defaultValue?: any;
  typePost?: PostType;
  handleClickBack: () => void;
  handleSelectLocation: (users: any) => void;
}

const listLocationTransformer = (locations: any[]) => {
  return locations.map((location) => ({
    ...location,
    selected: false,
  }));
};

const locationInputTransformer = (place: any) => {
  delete place.location.placeId;
  delete place.location._id;
  delete place.location.staticMapUri;
  const { name, location, refId, formattedAddress } = place;
  return { name, location, refId, formattedAddress };
};

const Locations: FC<Props> = ({
  hidden,
  defaultValue,
  typePost,
  handleClickBack,
  handleSelectLocation,
}) => {
  const initialFilter = {
    q: '',
    limit: 10,
  };
  const [filter, setFilter] = useState<TopPlacesQueryVariables>(initialFilter);
  const [locations, setLocations] = useState<any>([]);
  const [selectedLocation, setSelectedLocation] = useState<any>(null);
  const { data: locationData, isLoading } = useSearchPlace(filter);

  useEffect(() => {
    if (!!defaultValue) {
      setSelectedLocation(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    filter.after &&
      setLocations([
        ...locations,
        ...listLocationTransformer(locationData?.topPlaces ?? []),
      ]);
    !filter.after &&
      setLocations([...listLocationTransformer(locationData?.topPlaces ?? [])]);
  }, [locationData]);

  const inputSearchLocationRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    return () => {
      setFilter(initialFilter);
      inputSearchLocationRef.current &&
        (inputSearchLocationRef.current.value = '');
    };
  }, [hidden]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    delete filter.after;
    setFilter({
      ...filter,
      q: e.target.value.trim(),
    });
  };

  const fetchData = () => {
    if (locations.length > 0) {
      setFilter({
        ...filter,
        after: {
          q: String(locations[locations.length - 1]?.topPlacePoint),
          uid: locations[locations.length - 1]?._id,
        },
      });
    }
  };

  const handleClickLocation = (e: any) => {
    const locationId = e.currentTarget.dataset.id;
    if (typePost !== PostType.Review) {
      if (selectedLocation && locationId === selectedLocation._id) {
        setSelectedLocation(null);
        handleSelectLocation(null);
      } else {
        const locationSelected = locations.find(
          (location: any) => location._id === locationId,
        );
        setSelectedLocation(locationSelected);
        handleSelectLocation(locationInputTransformer(locationSelected));
      }
    } else {
      const locationSelected = locations.find(
        (location: any) => location._id === locationId,
      );
      setSelectedLocation(locationSelected);
      handleSelectLocation(locationInputTransformer(locationSelected));
    }
    handleClickBack();
  };

  const refresh = () => {};
  return (
    <div className="w-full overflow-hidden" hidden={hidden}>
      <div className="form-createPost_header relative h-[60px] ">
        <h2 className="leading-[60px] text-xl font-bold text-center">
          <span>{'Tìm kiếm địa điểm'}</span>
        </h2>
        <div
          onClick={handleClickBack}
          className="w-[30px] h-[30px] absolute top-[30%] left-[2%] rounded-full bg-slate-100 cursor-pointer hover:bg-slate-200">
          <HiOutlineArrowSmLeft size={'30px'} className="" />
        </div>
      </div>
      <div className="p-4 pb-2 flex">
        <label
          htmlFor="location_input"
          className="flex h-[36px] rounded-full w-full bg-slate-100 px-4">
          <span className="mt-[9px]">
            <AiOutlineSearch size={'18px'} />
          </span>
          <input
            ref={inputSearchLocationRef}
            onChange={handleSearchChange}
            type="text"
            className="ml-1 bg-slate-100 w-full outline-none"
            id="location_input"
            placeholder="Bạn đang ở đâu?"
          />
        </label>
      </div>
      <div className="p-2 pt-0" id="scrollableDiv">
        {/* {locations.length === 0 && filter.q !== '' && (
          <center className="my-2">
            <p className="text-sm text-slate-400">{`Không tìm thấy địa điểm`}</p>
          </center>
        )} */}
        <InfiniteScroll
          className="locations"
          dataLength={locations?.length ?? 0}
          next={fetchData}
          hasMore={true}
          loader={''}
          endMessage={''}
          refreshFunction={refresh}
          scrollableTarget="scrollableDiv">
          {
            <ul className="">
              <div className="">
                {locations.length > 0 &&
                  locations.map((location: Place) => {
                    return (
                      <div
                        className="hover:bg-slate-200 rounded-lg"
                        onClick={handleClickLocation}
                        role="button"
                        key={location._id}
                        data-id={location._id}>
                        <li>
                          <div className="flex h-[60px] p-2 max-[400px]:h-[50px] flex-row ">
                            <div className="w-1/6 avatar-wrap flex rounded-full">
                              <img
                                className={`max-[400px]:h-[35px] max-[400px]:w-[35px] friend-avatar object-cover rounded-full avartar-border_default`}
                                src={
                                  location?.primaryImage?.uri ??
                                  images.PinLocation
                                }
                                height={45}
                                width={45}
                                alt={'avatar_' + location?.name}
                              />
                            </div>
                            <div
                              className={`flex max-[400px]:ml-2 flex-col place-content-center w-full gap-0`}>
                              <span
                                className={`max-[400px]:text-xs text-md w-15 font-medium text-black line-clamp-1 ${
                                  location._id === selectedLocation?._id &&
                                  'text-primary-yellow'
                                }`}>
                                {location.name}
                              </span>
                            </div>
                          </div>
                        </li>
                      </div>
                    );
                  })}
                {!isLoading &&
                  filter.q !== '' &&
                  locationData?.topPlaces &&
                  locationData?.topPlaces.length === 0 && (
                    <center className="my-2">
                      <p className="text-sm text-slate-400">{`Không có địa điểm`}</p>
                    </center>
                  )}
                {isLoading && (
                  <center className="h-[50px]">
                    <LoadingExtra className="" label="" />
                  </center>
                )}
              </div>
            </ul>
          }
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default Locations;
