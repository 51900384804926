import { useState } from 'react';
import { useParams } from 'react-router-dom';
import PostInfo from '../../../components/posts/PostInfo';
import { GetPageProductQueryVariables, Post, ProductStatus, ProductType } from '../../../graphql/generated';
import { formatCurrency } from '../../../Ultis/number';
import { useGetPage, useGetPageProducts } from '../../Page/services/api';
import FilterProduct from './Filter';

const defaultFilter: GetPageProductQueryVariables = {
  limit: 10,
  pageId: '',
  status: ProductStatus.Total,
};

const tabs = [
  {
    id: 1,
    name: 'Tất cả',
    value: ProductStatus.Total,
  },
  {
    id: 2,
    name: 'Khả dụng',
    value: ProductStatus.Available,
  },
  {
    id: 3,
    name: 'Bị khóa',
    value: ProductStatus.Blocked,
  },
  {
    id: 4,
    name: 'Đã ẩn',
    value: ProductStatus.Blocked,
  },
];
interface Props {
  isOwner?: boolean;
}

const FormTab = (props: Props) => {
  const { isOwner } = props;
  const [openTab, setOpenTab] = useState(0);
  const { pageId } = useParams<{ pageId: string }>();
  const [filter, setFilter] = useState({ ...defaultFilter, pageId: pageId || '' });

  const { data } = useGetPageProducts({
    ...filter,
  });

  const onChangeFilter = (input: { q?: string; filter?: ProductType[] }) => {
    setFilter((value) => ({ ...value, ...input }));
  };

  const onChangeTab = (index: number) => {
    setOpenTab(index);

    setFilter((value) => ({ ...value, status: tabs[index].value }));
  };

  const returnHeaderTab = () => {
    return (
      <ul className="flex mb-4 flex-wrap -mb-px bg-gray-200 rounded-full" role="tablist" data-te-nav-ref>
        {tabs.map((item, index) => (
          <li key={item.id} className=" flex-auto text-center" role="presentation">
            <span
              onClick={(e) => {
                e.preventDefault();
                onChangeTab(index);
              }}
              className={`${
                openTab === index ? ' bgPrimaryColor text-white' : ''
              } cursor-pointer rounded-full block border-x-0 border-t-0 border-b-2 border-transparent px-4 pt-2 pb-2 text-xs font-medium leading-tight text-neutral-500  hover:border-transparent focus:isolate focus:border-transparent data-[te-nav-active]:border-primary data-[te-nav-active]:text-primary dark:text-neutral-400 dark:hover:bg-transparent dark:data-[te-nav-active]:border-primary-400 dark:data-[te-nav-active]:text-primary-400`}>
              {item.name}
            </span>
          </li>
        ))}
      </ul>
    );
  };

  const renderProduct = () => {
    return (
      <>
        <ul role="list" className="-my-6 divide-y divide-gray-200">
          {data?.pageProducts?.data?.map((item) => (
            <li className="flex py-6">
              <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
                {item?.medias && item?.medias.length > 0 && (
                  <img
                    src={item.medias[0].thumbnail || ''}
                    alt="Salmon orange fabric pouch with match zipper, gray zipper pull, and adjustable hip belt."
                    className="h-full w-full object-cover object-center"
                  />
                )}
              </div>
              <div className="ml-4 flex flex-1 flex-col">
                <div>
                  <div className="flex justify-between text-base font-medium text-gray-900">
                    <h3>{item.title}</h3>
                  </div>
                </div>
                <div className="flex flex-1 items-end justify-between text-sm">
                  <div>
                    <p className="text-sm primaryColor">{formatCurrency(item.price || 0) || 0} VND</p>
                    <PostInfo post={item as Post} />
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </>
    );
  };
  return (
    <>
      <div className="mb-6">
        {isOwner && returnHeaderTab()}
        {isOwner && <FilterProduct onChange={onChangeFilter} />}

        <div className="block" id="link1">
          <h4 className="mb-2">{data?.pageProducts?.total || 0} sản phẩm</h4>

          {renderProduct()}
        </div>
      </div>
    </>
  );
};

export default FormTab;
