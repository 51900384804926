import { useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { queryClient } from '../..';
import { getUserLogin } from '../../Ultis/token';
import { images } from '../../assets/images';
import { useCreatePageReview } from '../../common/services/api';
import CreatePost from '../../components/create-post/CreatePost';
import Loading, { LoadingRef } from '../../components/loading/Loading';
import { IConfirmModal } from '../../components/modal/ConfirmModal';
import NotifyModal from '../../components/modal/NotifyModal';
import VotePageModal from '../../components/modal/VotePageModal';
import HeaderPage from '../../components/page/HeaderPage';
import InformationPage from '../../components/page/InformationPage';
import InviteFriends from '../../components/page/InviteFriends';
import ListImage from '../../components/page/ListImage';
import NotificationAddInfor from '../../components/page/NotificationAddInfor';
import Products from '../../components/page/Product';
import Review from '../../components/page/Review';
import PostItem from '../../components/posts/Post';
import { ToastProps, ToastType } from '../../components/toast/Toast';
import { ProductStatus } from '../../graphql/generated';
import {
  useDeletePage,
  useGetPage,
  useGetPageMedia,
  useGetPagePosts,
  useGetPageProducts,
  useGetPageReview,
} from './services/api';

interface Props {}

export function countVoteString(voteString: string) {
  voteString = voteString.trim();
  const arrayVote = voteString.split(' ');
  if (!!voteString) {
    return arrayVote.length;
  } else {
    return 0;
  }
}

const Page = (props: Props) => {
  const { pageId } = useParams();
  const navigate = useNavigate();
  const [filter, setFilter] = useState({ limit: 5 });
  const [stateVotePage, setStateVotePage] = useState(false);
  const [isShowModalNotify, setIsShowModalNotify] = useState(false);
  const [starVote, setStarVote] = useState(0);
  const [posts, setPosts] = useState<any[]>([]);
  const [next, setNext] = useState(true);
  const [voteContext, setVoteContext] = useState('');
  const { data, isLoading } = useGetPage(pageId || '');
  const { data: mediaData } = useGetPageMedia({ id: pageId || '', limit: 5 });
  const { data: reviewData } = useGetPageReview({
    pageId: pageId || '',
    limit: 5,
  });
  const [confirmModal, setConfirmModal] = useState<IConfirmModal>({
    isVisible: false,
    content: '',
  });
  const { data: post } = useGetPagePosts({ pageId: pageId || '', limit: 5 });
  const { data: products } = useGetPageProducts({
    pageId: pageId || '',
    limit: 5,
    status: ProductStatus.Available,
  });
  const loadingRef = useRef<LoadingRef>(null);
  const user = getUserLogin();
  const {
    mutate: createPageReview,
    mutateAsync,
    isSuccess,
  } = useCreatePageReview();
  const { mutateAsync: deletePageAsync, isLoading: deletePageLoading } =
    useDeletePage();
  const [toast, setToast] = useState<ToastProps>({
    className: 'fixed top-0 right-0',
    isHidden: true,
    type: ToastType.Success,
    message: '',
  });

  useEffect(() => {
    let resetToast: any;
    if (!toast.isHidden) {
      resetToast = setTimeout(() => {
        setToast((preState) => {
          return { ...preState, isHidden: true };
        });
      }, 2000);
    }
    return () => {
      clearTimeout(resetToast);
    };
  }, [toast]);

  useEffect(() => {
    if (post?.getPagePosts) {
      setPosts(post?.getPagePosts || []);
    }
  }, [post?.getPagePosts, post?.getPagePosts?.length]);

  useEffect(() => {
    queryClient.invalidateQueries(['getPage']);
    window.scrollTo(0, 0);
  }, [pageId]);

  const fetchData = () => {
    setFilter({ ...filter, limit: 999 });
    setNext(false);
  };
  const refresh = () => {};

  useEffect(() => {
    if (isLoading) {
      loadingRef.current?.open();
    } else {
      loadingRef.current?.close();
    }
  }, [isLoading]);

  const handleOnPostCreate = (post: any) => {
    setPosts([post, ...posts]);
  };

  const isAdmin = data?.getPage?.adminIds?.includes(user._id);
  const isOwner = data?.getPage?.owner?._id === user._id;

  const handleClickVotePage = () => {
    setStateVotePage(true);
  };

  const handleClickCancel = () => {
    setStarVote(0);
    setVoteContext('');
    setStateVotePage(false);
  };

  const handleVoteContentChange = (e: any) => {
    setVoteContext(e.target.value);
  };
  const onchangeStar = (nextValue: number, prevValue: number, name: string) => {
    setStarVote(nextValue);
  };

  const handleClickVote = async () => {
    mutateAsync(
      {
        data: {
          pageId: pageId || '',
          rating: starVote,
          content: voteContext,
        },
      },
      {
        onSuccess() {
          setStateVotePage(false);
          setIsShowModalNotify(true);
          queryClient.invalidateQueries(['getPageReviews']);
          queryClient.invalidateQueries(['getPage']);
        },
      },
    );
  };

  const handleCloseModalNotify = () => {
    setIsShowModalNotify(false);
  };

  return (
    <>
      <div className="fixed top-[15%] left-[47%] z-50">
        <Loading ref={loadingRef} />
      </div>
      <div
        className=" cursor-pointer absolute left-4 top-4  bg-slate-950 flex items-center justify-center rounded-full p-3 opacity-50"
        onClick={() => navigate(-1)}>
        <img src={images.LeftWhite} className="w-[20px] h-[20px]" />
      </div>
      <Link to={`/profile/${pageId}/setting`} state={window.location.href}>
        <div className=" cursor-pointer absolute right-4 top-4  bg-slate-950 flex items-center justify-center rounded-full p-3 opacity-50">
          <img src={images.DotDotDot} className="w-[20px] h-[20px]" />
        </div>
      </Link>
      <div className="bg-white p-2 rounded-lg">
        <HeaderPage data={data} />
        <InformationPage data={data} />
      </div>
      {isOwner && <NotificationAddInfor />}
      {(data?.getPage?.liked || isOwner) && <InviteFriends data={data} />}
      <div className="bg-white p-2 rounded-lg mt-2">
        <ListImage media={mediaData?.getPageMediaList || []} />
      </div>
      <div className="bg-white p-2 rounded-lg mt-2">
        <Products data={products} pageId={pageId || ''} />
      </div>
      <div className="bg-white p-2 rounded-lg mt-2">
        <Review
          pageId={pageId || ''}
          reviewData={reviewData}
          data={data}
          onVotePage={handleClickVotePage}
        />
      </div>
      {(isAdmin || isOwner) && (
        <div className="">
          <CreatePost
            onPostCreated={handleOnPostCreate}
            page={data?.getPage}
            isInMyPage="inPage"
          />
        </div>
      )}
      <InfiniteScroll
        dataLength={posts?.length || 0} //This is important field to render the next data
        next={fetchData}
        hasMore={next}
        loader={<h4>Loading...</h4>}
        endMessage={<p style={{ textAlign: 'center' }}></p>}
        // below props only if you need pull down functionality
        refreshFunction={refresh}
        pullDownToRefresh
        pullDownToRefreshThreshold={50}>
        {posts?.map((item) => (
          <div className="mb-4">
            <PostItem post={item} />
          </div>
        ))}
      </InfiniteScroll>
      {stateVotePage && (
        <VotePageModal
          handleClickCancel={handleClickCancel}
          isShow={stateVotePage}
          handleClickVote={handleClickVote}
          currentWord={voteContext.length}
          handleVoteContentChange={handleVoteContentChange}
          onchangeStar={onchangeStar}
          star={starVote}
        />
      )}

      {isShowModalNotify && (
        <NotifyModal
          isSuccess={isSuccess}
          isShow={isShowModalNotify}
          message="Gửi đánh giá thành công"
          onClose={handleCloseModalNotify}
        />
      )}
    </>
  );
};

export default Page;
