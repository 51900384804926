import { forwardRef } from 'react';
import ListTaggedUser from './ListTaggedUser';

interface Props {
  postId: string;
  visible: boolean;
}

const LIMIT_PAGE = 10;

const TaggedUserModal = forwardRef(({ postId, visible }: Props, ref: any) => {
  const handleClickSelf = () => {
    ref.current.close();
  };
  return (
    <>
      <dialog onClick={handleClickSelf} className="outline-none rounded-lg" ref={ref}>
        {visible && <ListTaggedUser postId={postId} />}
      </dialog>
    </>
  );
});

export default TaggedUserModal;
