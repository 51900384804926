interface Props {
  content: string;
  handleClick: () => void;
  icon: any;
  subText?: string;
}

const SelectOptionItem = ({ content, handleClick, icon, subText }: Props) => {
  return (
    <>
      <div
        className="flex gap-3 text-gray-700 px-4 py-2 text-md mx-1 rounded cursor-pointer item-select-option items-center"
        onClick={handleClick}>
        {icon}
        {!!subText ? (
          <div>
            <span>{content}</span>
            <span className="text-black font-bold pl-1">{subText}</span>{' '}
          </div>
        ) : (
          <span>{content}</span>
        )}
      </div>
    </>
  );
};
export default SelectOptionItem;
