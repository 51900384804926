import React from 'react';
import { GetUserQuery, UserInformationType } from '../../graphql/generated';
import { MdLocationOn } from 'react-icons/md';
import { BsBriefcaseFill } from 'react-icons/bs';
interface Props {
  data: GetUserQuery | undefined;
}

const InformationProfile = (props: Props) => {
  const { data } = props;
  const checkTypeInfor = (type: UserInformationType) => {
    switch (type) {
      case UserInformationType.CurrentCity:
        return (
          <div className="flex items-center">
            <MdLocationOn className="text-2xl mr-2" /> Nơi ở hiện tại
          </div>
        );
      case UserInformationType.Hometown:
        return (
          <div className="flex items-center">
            <MdLocationOn className="text-2xl mr-2" /> Quê quán
          </div>
        );
      case UserInformationType.Education:
        return (
          <div className="flex items-center">
            <BsBriefcaseFill className="text-2xl mr-2" /> Học vấn
          </div>
        );
      case UserInformationType.Other:
        return '';
      case UserInformationType.SocialLink:
        return '';
      case UserInformationType.Website:
        return '';
      case UserInformationType.Work:
        return (
          <div className="flex items-center">
            <BsBriefcaseFill className="text-2xl mr-2" /> Công việc
          </div>
        );

      default:
        return '';
    }
  };
  return (
    <div className="ml-3 mt-4 mb-4">
      {data?.getPublicProfile.informationListing?.map((item) => {
        return (
          <div className="text-lg mt-2 flex">
            {checkTypeInfor(item.type)} <b className="ml-1"> {item.value}</b>{' '}
            {item.extra && (
              <div className="ml-1">
                tại <b className="ml-1">{item.extra}</b>
              </div>
            )}
            <br />
          </div>
        );
      })}
    </div>
  );
};

export default InformationProfile;
