import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useParams } from 'react-router-dom';
import { getUserLogin } from '../../Ultis/token';
import HeaderExtra from '../../components/header/HeaderExtra';
import PageItem from '../../components/listPage/pageItem';
import {
  GetExplorePageListQueryVariables,
  SearchLikedPageQueryVariables,
} from '../../graphql/generated';
import MyFriendListMenuTab from '../FriendListMenu/FriendListMenuTab';
import { StateFetch } from '../Home/Home';
import EmptyPersonalPage from './components/EmptyPersonalPage';
import {
  useGetDiscoveryPages,
  useGetLikedPages,
  useGetMyPages,
} from './services/api';
import CreatePageItem from './components/CreatePageItem';

interface Props {}

export enum PageItemType {
  MyPage = 'MyPage',
  Discover = 'Discover',
  Liked = 'Liked',
}

const tabs = [
  {
    id: 1,
    name: 'Trang của bạn',
    type: PageItemType.MyPage,
  },
  {
    id: 2,
    name: 'Khám phá',
    type: PageItemType.Discover,
  },
  {
    id: 3,
    name: 'Đã thích',
    type: PageItemType.Liked,
  },
];

export interface filterPage {
  discoveryPage: GetExplorePageListQueryVariables;
  likedPage: SearchLikedPageQueryVariables;
}

const ListPage = (props: Props) => {
  const [type, setType] = useState(PageItemType.MyPage);
  const [filter, setFilter] = useState<filterPage>({
    discoveryPage: { limit: 20, offset: 0 },
    likedPage: { limit: 20, offset: 0 },
  });
  const [fetchDataType, setFetchDataType] = useState({
    discovery: StateFetch.GETTING_FIRST,
    liked: StateFetch.GETTING_FIRST,
  });
  const [discoveryData, setDiscoveryData] = useState<any>([]);
  const [likedData, setLikedData] = useState<any>([]);
  const { data, isLoading } = useGetMyPages();
  const { data: discovery } = useGetDiscoveryPages(filter.discoveryPage);
  const { data: liked } = useGetLikedPages(filter.likedPage);
  const { _id } = getUserLogin();
  const { id } = useParams();

  useEffect(() => {
    if (fetchDataType.discovery === StateFetch.GETTING_FIRST) {
      setDiscoveryData(discovery?.getExplorePageList);
    } else {
      setDiscoveryData(() => [
        ...discoveryData,
        ...(discovery?.getExplorePageList || []),
      ]);
    }
  }, [discovery]);

  useEffect(() => {
    setLikedData(liked?.searchLikedPage);
    if (fetchDataType.liked === StateFetch.GETTING_FIRST) {
      setLikedData(liked?.searchLikedPage);
    } else {
      setLikedData(() => [...likedData, ...(liked?.searchLikedPage || [])]);
    }
  }, [liked]);

  const fetchData = () => {
    switch (type) {
      case PageItemType.Discover:
        setFilter({
          ...filter,
          discoveryPage: { ...filter.discoveryPage, offset: 20 },
        });
        setFetchDataType({
          ...fetchDataType,
          discovery: StateFetch.GETTING_MORE,
        });
        break;
      case PageItemType.Liked:
        setFilter({
          ...filter,
          likedPage: { ...filter.likedPage, offset: 20 },
        });
        setFetchDataType({ ...fetchDataType, liked: StateFetch.GETTING_MORE });
        break;
      default:
        break;
    }
  };

  const refresh = () => {};

  return (
    <div>
      <MyFriendListMenuTab
        tabs={tabs.map((item, idx) => (
          <li key={item.id} className={`mr-2 flex-auto`}>
            <a
              onClick={(e) => {
                e.preventDefault();
                setType(item.type);
              }}
              href="#"
              className={`${item.type === PageItemType.MyPage && 'relative'} ${
                item.type === type
                  ? 'inline-block p-4 primaryColor border-b-2 border-primary-color rounded-t-lg active dark:text-blue-500 dark:border-blue-500'
                  : 'inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'
              }`}>
              {item.name}
            </a>
          </li>
        ))}
      />
      <InfiniteScroll
        className={`mb-5 mt-5 grid ${
          type !== PageItemType.MyPage &&
          'min-[375px]:grid-cols-1 sm:grid-cols-2'
        } min-[375px]:gap-5 sm:gap-2`}
        dataLength={
          type === PageItemType.MyPage
            ? data?.getMyPages?.length || 0
            : type === PageItemType.Discover
            ? discovery?.getExplorePageList?.length || 0
            : liked?.searchLikedPage?.length || 0
        }
        next={fetchData}
        hasMore={type === PageItemType.MyPage ? false : true}
        loader={<h4></h4>}
        endMessage={<p className="mt-5" style={{ textAlign: 'center' }}></p>}
        refreshFunction={refresh}>
        {type === PageItemType.MyPage &&
          (data?.getMyPages && data?.getMyPages.length > 0 ? (
            <>
              {data?.getMyPages?.map((friend, idx) => {
                return (
                  <PageItem
                    key={friend._id + idx}
                    page={friend}
                    type={type}
                    isDisplayAction={true}
                    isDisplayBothBtn={false}
                  />
                );
              })}{' '}
              <CreatePageItem />
            </>
          ) : (
            <EmptyPersonalPage />
          ))}
        {type === PageItemType.Discover &&
          discoveryData?.map((page: any, idx: number) => {
            return (
              <PageItem
                key={page._id + idx}
                page={page}
                type={type}
                isDisplayTimeRequest={true}
              />
            );
          })}
        {type === PageItemType.Liked &&
          likedData?.map((page: any, idx: number) => {
            return <PageItem key={page._id + idx} page={page} type={type} />;
          })}
      </InfiniteScroll>
    </div>
  );
};

export default ListPage;
