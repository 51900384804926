import React from 'react';
import ButtonGray from '../../../components/button/ButtonGray';
import ButtonOrange from '../../../components/button/ButtonOrange';
import { Notification, NotificationType } from '../../../graphql/generated';

interface AcceptRequestFriendProps {
  item: Notification;
}

const AcceptRequestFriend = () => {
  return (
    <div className="flex pt-3">
      <div className="pr-3">
        <ButtonOrange text="Chấp nhận" className='!text-sm !py-2 !px-4' />
      </div>
      <ButtonGray text="Xoá lời mời" className='!text-sm !py-2 !px-4' />
    </div>
  );
};

export default AcceptRequestFriend;
